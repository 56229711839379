const initialState = {
	modalToShow: null,
	modalEyebrowText: '',
};

const GOT_MODAL_TO_ADD = 'GOT_MODAL_TO_ADD';
const GOT_MODAL_TO_REMOVE = 'GOT_MODAL_TO_REMOVE';

// action creators

const gotModalToAdd = (modal, eyebrow) => ({ type: GOT_MODAL_TO_ADD, modal, eyebrow });
const gotModalToRemove = () => ({ type: GOT_MODAL_TO_REMOVE });

// thunk creators

export const queueModal = (modal, eyebrow) => {
	return async (dispatch, getState) => {
		dispatch(gotModalToAdd(modal, eyebrow));
	};
};

export const dequeueModal = () => {
	return async (dispatch, getState) => {
		dispatch(gotModalToRemove());
	};
};

// reducer

export default function (state = initialState, action) {
	switch (action.type) {
		case GOT_MODAL_TO_ADD:
			return { ...state, modalToShow: action.modal, modalEyebrowText: action.eyebrow };

		case GOT_MODAL_TO_REMOVE:
			return { ...state, modalToShow: null, modalEyebrowText: null };

		default:
			return state;
	}
}
