import React from 'react';

const DrawerOpen = () => (
	<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.9115 12.1236L19.7576 12.2132L21.0395 10.8114C21.1666 10.6901 21.2821 10.5602 21.4279 10.3913L21.8032 9.98129L21.8379 9.8788C22.5987 8.80618 23 7.56321 23 6.2726C23 2.81365 20.1864 0 16.7274 0C14.5475 0 12.6231 1.11737 11.5 2.81076C10.3754 1.11882 8.45104 0 6.27259 0C2.81364 0 0 2.81365 0 6.2726C0 7.50113 0.358021 8.68635 1.03653 9.71567L1.27617 10.0376C1.51149 10.3494 1.78 10.6425 2.01387 10.846L2.73569 11.6386L2.90604 15.7327L4.99209 15.6461L4.92568 14.0394L11.5 21.2489L17.774 14.3858L22.001 14.2111L21.9115 12.1236ZM6.17298 12.3142L8.4958 12.5394L8.6979 10.4606L4.07249 10.0116L3.48349 9.3663C3.26983 9.1743 3.08072 8.96642 2.92047 8.74843L2.83385 8.64593C2.34446 7.94144 2.0875 7.12001 2.0875 6.27115C2.0875 3.96422 3.96422 2.0875 6.27115 2.0875C8.57808 2.0875 10.4548 3.96422 10.4548 6.27115H12.5437C12.5437 3.96422 14.4205 2.0875 16.7274 2.0875C19.0343 2.0875 20.9111 3.96422 20.9111 6.27115C20.9111 7.16187 20.6194 8.02372 20.0651 8.76287L19.8182 9.05881C19.7475 9.13966 19.6796 9.22194 19.5497 9.34898L18.3414 10.6714V9.47891H16.2524V12.9552L11.5 18.1523L6.17298 12.3142Z"
			fill="#442C2E"
		/>
	</svg>
);

export default DrawerOpen;
