import React, { Fragment, useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { CardSection } from '../index';
import './index.scss';
const BASE_CLASS = 'checkout-modal';
import axios from 'axios';
import Loader from '../atoms/Loader';

export default function CheckoutForm({ clientSecret, rental, onCancel, onSuccess }) {
	const stripe = useStripe();
	const elements = useElements();

	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		await stripe
			.confirmCardPayment(clientSecret, {
				payment_method: {
					card: elements.getElement(CardElement),
					// customer: rental.borrowerId,
					billing_details: {
						// name: 'testing!!!',
						name: `payment for ${rental.itemName} from borrower ${rental.borrowerId} to ${rental.lenderId}`,
					},
				},
			})
			.then(async function (res) {
				// Handle result.error or result.paymentIntent
				if (res.error) {
					// Show error to your customer (e.g., insufficient funds)
					console.log(res.error.message);
				} else {
					// The payment has been processed!
					if (res.paymentIntent.status === 'succeeded') {
						// 				1) status is 'confirmed - paid' in Database
						// 2) the rental instance is ‘confirmed - paid’ on lender and borrower dashboards
						// 3. lender is notified - and prompted to suggest a meet up location for the borrower
						try {
							await axios
								.put(`/api/rentals/${rental.articleItemId}`, {
									action: 'confirmed-paid',
									rentalId: rental.id,
								})
								.then(() => {
									setIsLoading(true);
									setTimeout(() => {
										setIsLoading(false);
										onSuccess();
										window.location.reload(false);
									}, 1000);
								});
						} catch (err) {
							next(err);
						}
					}
				}
			});

		// FIXME: put all modals in xstate - handle loaders, etc there
		// setIsLoading(true);
		// setTimeout(() => {
		// 	setIsLoading(false);
		// 	onSuccess();
		// }, 1000);
	};

	return !isLoading ? (
		<Fragment>
			<div className={`${BASE_CLASS}__container`} />
			<div className={`${BASE_CLASS}__inner`}>
				<h1>Pay through Stripe.</h1>
				<p>We use Stripe to securely handle payments.</p>

				<form onSubmit={handleSubmit}>
					<CardSection />
					<button disabled={!stripe}>Confirm order</button>
				</form>
				<button type="button" onClick={onCancel} className="checkout-cancel">
					Cancel
				</button>
			</div>
		</Fragment>
	) : (
		<Loader />
	);
}
