import React, { Fragment } from 'react';
import { NavBar, Footer } from './components';
import Routes from './routes';
import Modal from '../client/components/Modal';
import './style.scss';

const App = () => {
	return (
		<Fragment>
			<NavBar />
			<div id="content">
				<Modal />
				<Routes />
			</div>
			<Footer />
		</Fragment>
	);
};

export default App;
