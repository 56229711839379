import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import getUnixTime from 'date-fns/getUnixTime';

export default function getWeek(dayUnix) {
	// TODO: test this
	const stringDay = dayUnix.toString() + '000';
	const day = Number(stringDay);

	const tuesday = startOfWeek(day, { weekStartsOn: 2 });
	const monday = endOfWeek(day, { weekStartsOn: 2 });
	const week = eachDayOfInterval({
		start: tuesday,
		end: monday,
	});

	const weekTimestamps = week.map((day) => getUnixTime(day));

	return { week, weekTimestamps };
}
