import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import classnames from 'classnames';
import Illustration8 from '../../../../public/icons/Illustrations/Illustration8';

class Faq extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	toggleSection(section) {
		this.setState({
			[section]: !this.state[section],
		});
	}

	render() {
		const BASE_CLASS = this.props.styleProps === 'howitworksfaq' ? 'howitworksfaq' : 'faq';
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__illustrations`}>
					<Illustration8 />
				</div>
				<h1 className={`${BASE_CLASS}__title`}>Frequently Asked Questions</h1>
				<h5>
					Renting
					<div />
				</h5>
				<div className={classnames(`${BASE_CLASS}__section`)}>
					<div
						className={classnames({ display: this.state.q1 })}
						onClick={() => this.toggleSection('q1')}>
						<li>How do I rent something?</li>
						<p>
							To rent an item, browse lender closets on our <Link to="/rent">rent</Link> page.
							Filter by category, size, price, tag, availability, and location to find exactly what
							you’re looking for. Next, read over the details of each item and the lender’s
							description. You can post questions on the item, or message the lender privately in
							your rental request.
						</p>
						<p>
							<b>Getting and returning an item:</b>
							<p>
								Rental periods are one week, typically from Thursday – Thursday, unless you arrange
								otherwise with the lender.{' '}
							</p>
							<p>
								If you choose to{' '}
								<span>
									<b>pick up the item locally</b>
								</span>
								, you’ll meet up in a public location in the lender’s zip code (listed on the rental
								item page) to receive and return the item. You’ll coordinate with the lender to
								arrange these meetups. Always meet up in a public location.
							</p>
							<p>
								If you choose to{' '}
								<span>
									<b>have the item delivered in NYC</b>
								</span>
								, you’ll be charged a $10 delivery fee, and the item will be delivered and picked up
								to your specified location. Our team will reach out to coordinate these deliveries.
							</p>
							<p>
								If you choose to{' '}
								<span>
									<b>have the item shipped</b>
								</span>
								, you’ll pay $15 to cover shipping and return postage. The lender will mail the
								package to you, and the package will include a prepaid return label for you to ship
								it back.
							</p>
						</p>

						<p>
							<b>Tracking your rental:</b>
							We’ll email you when your rental request is confirmed or declined. From there you can
							track all your rental activity on your
							<Link to="/activity">Activity</Link> page, and we’ll also send you a reminder by email
							24 hours before your rental period begins, and 24 hours before your rental period
							ends.
						</p>
						<p>
							<b>Payment:</b>
							Once your rental is confirmed, you’ll pay through your{' '}
							<Link to="/activity">Activity</Link> page. We use the payment processor Stripe to
							handle payments securely.
						</p>
					</div>
					<div
						className={classnames({ display: this.state.q8 })}
						onClick={() => this.toggleSection('q8')}>
						<li>What about cleaning?</li>
						<p>
							Lenders are responsible for cleaning their items, not borrowers, unless you mutually
							agree otherwise. If an item needs to be cleaned urgently, for example, you can agree
							with the Lender to have it cleaned before returning it.
						</p>
					</div>
					<div
						className={classnames({ display: this.state.q2 })}
						onClick={() => this.toggleSection('q2')}>
						<li>What if there’s something wrong with the item I rent?</li>
						<p>
							If the item you receive is damaged or does not match the description in its listing,
							send us an email with a photo right away at{' '}
							<a
								href="mailto:hello@mysocialcloset.com"
								className={`${BASE_CLASS}__button__contact-cta`}>
								hello@mysocialcloset.com
							</a>{' '}
							If the description was inaccurate, we’ll refund your payment.{' '}
						</p>
					</div>

					<div
						className={classnames({ display: this.state.q3 })}
						onClick={() => this.toggleSection('q3')}>
						<li>What if I damage or lose an item?</li>
						<p>
							If you damage an item, let the lender know as soon as possible. You and the lender can
							try to work out an agreement on repairing or paying for minor damage of an item. If
							the lender submits a damage claim instead, we will ask the lender for photos of
							damage, and you will be charged the cost of repairing the item, with a maximum charge
							of 1.5x the cost of the rental.
						</p>
						<p>
							If you cause major damage to an item, you’ll be charged for the cost of repair, with a
							maximum of 4x the rental fee.
						</p>
						<p>
							If you lose or fail to return an item, you will be charged the cost of replacing the
							item according to the lender, with a maximum of 5x the rental fee.
						</p>
						<p>
							We expect lenders and borrowers to treat each other and articles of clothing with
							respect and acting outside of these community guidelines will negatively affect your
							account standing. Lenders and borrowers review each transaction, and these reviews are
							public to all users of Social Closet.
						</p>
					</div>
				</div>
				<h5>
					Lending
					<div />
				</h5>
				<div className={classnames(`${BASE_CLASS}__section`)}>
					<div
						className={classnames({ display: this.state.q4 })}
						onClick={() => this.toggleSection('q4')}>
						<li>How do I lend an item on Social Closet?</li>
						<p>
							To lend on Social Closet, start by creating an account and uploading your item(s).
						</p>
						<p>
							Add as much detail as you can in your listing, including multiple photos and
							dimensions of the article, to answer questions and avoid surprises for borrowers.
							Decide whether your item is available for local pickup, local delivery (only available
							in NYC), or shipping by mail.
						</p>
						<p>
							<b>Include any flaws:</b> Make sure to include any flaws that the item has. If you
							don’t include flaws, the borrower can submit a claim that the item is not as
							described, and then we'll have to refund the borrower.
						</p>
						<p>
							<b>Delivering and returning your item:</b>
							Rental periods are one week, typically from Thursday – Thursday, unless you arrange
							otherwise with the borrower.
							<br />
							If your is{' '}
							<span>
								<b>picked up locally,</b>
							</span>{' '}
							you’ll meet up in a public location in the your zip code to deliver and return the
							item. Always meet up in a public location.
							<br />
							If your item{' '}
							<span>
								<b>delivered locally in NYC,</b>
							</span>{' '}
							our team will reach out to coordinate these deliveries to and from your address.
							<br />
							If you choose to have the item shipped, we’ll send you a pre-paid shipping label, and
							return label that you can include in the package for the borrower.
						</p>
						<p>
							<b>Tracking:</b>
							You’ll be notified of each rental request via email.
							<br />
							You can track all your rental activity on your <Link to="/activity">
								Activity
							</Link>{' '}
							page, and we’ll also send you a reminder by email 24 hours before your rental period
							begins, and 24 hours before your rental period ends.
						</p>
					</div>

					<div
						className={classnames({ display: this.state.q5 })}
						onClick={() => this.toggleSection('q5')}>
						<li>What if my item is damaged or lost?</li>
						<p>
							If your item is damaged, you can either 1) work it out with the borrower directly, by
							letting the borrower know how much it will cost to repair the item, or 2) submit a
							claim via the Social Closet website. You’ll be reimbursed the cost of repairing the
							item, up to 1.5x the rental fee.
						</p>
						<p>
							If an item has major damage, you’ll be reimbursed the cost of repairing the item, up
							to 4x the rental fee.
						</p>
						<p>
							If the item is lost, you’ll be reimbursed for the item, with a maximum value of 5x the
							rental fee.
						</p>
						<p>
							We expect lenders and borrowers to treat each other and articles of clothing with
							respect, and acting outside of these community guidelines will negatively affect an
							account. Lenders and borrowers review each transaction, and these reviews are public
							to all users of Social Closet.
						</p>
					</div>
					<div
						className={classnames({ display: this.state.q6 })}
						onClick={() => this.toggleSection('q6')}>
						<li>What about cleaning?</li>
						<p>
							As a lender, you are responsible for cleaning your items, unless you mutually agree
							otherwise. If an item needs to be cleaned urgently, for example, you can agree with
							the Borrower for them to have it cleaned before returning it.
						</p>
					</div>
					<div
						className={classnames({ display: this.state.q7 })}
						onClick={() => this.toggleSection('q7')}>
						<li>When will I be paid?</li>
						<p>
							You’ll be paid within 24 hours of the start of the rental period. You’ll be paid
							through Stripe, our payments processor for secure payments.
						</p>
					</div>
				</div>

				<h2>Contact Us</h2>
				<h3>
					Email us with any questions at{' '}
					<a
						href="mailto:hello@mysocialcloset.com"
						className={`${BASE_CLASS}__button__contact-cta`}>
						hello@mysocialcloset.com
					</a>
					.
				</h3>
			</div>
		);
	}
}

export default Faq;
