import React from 'react';

const List = () => (
	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4394_61744)">
			<path
				d="M5 3.75H13.125"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 7.5H13.125"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 11.25H13.125"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4394_61744">
				<rect width="15" height="15" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default List;
