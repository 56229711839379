import React, { Component } from 'react';
import axios from 'axios';
import store from '../../store';
import './index.scss';
const BASE_CLASS = 'favorites';
import ArticleCard from '../atoms/ArticleCard';
import { getPublicArticles } from '../../store/closet';
import Back from '../../../public/icons/Back';
import history from '../../../client/history';
import { Link } from 'react-router-dom';

class Favorites extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			likedArticles: [],
		};

		this.toggleArticleLike = this.toggleArticleLike.bind(this);
		this.getLikedPublicRentals = this.getLikedPublicRentals.bind(this);
	}

	componentDidMount() {
		store.dispatch(getPublicArticles());
		this.getLikedPublicRentals();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.user.user.likedArticleIds !== this.state.user.user.likedArticleIds) {
			this.getLikedPublicRentals();
		}

		if (prevState.closet.publicArticles !== this.state.closet.publicArticles) {
			this.getLikedPublicRentals();
		}
	}

	getLikedPublicRentals() {
		const likedArticles = this.state.closet.publicArticles.filter(
			(article) =>
				this.state.user.user.likedArticleIds &&
				this.state.user.user.likedArticleIds.includes(article.id)
		);
		this.setState({
			likedArticles,
			likedArticleIds: this.state.closet.publicArticles
				.filter(
					(article) =>
						this.state.user.user.likedArticleIds &&
						this.state.user.user.likedArticleIds.includes(article.id)
				)
				.map((item) => item.id),
		});
	}

	async toggleArticleLike(articleId) {
		const userId = this.state.user.user.id;

		// if it's already liked, remove like from front & back end:
		if (this.state.likedArticleIds && this.state.likedArticleIds.includes(articleId)) {
			this.setState({
				likedArticles: this.state.likedArticles.filter((article) => article.id !== articleId),
				likedArticleIds: this.state.likedArticles
					.filter((article) => article.id !== articleId)
					.map((item) => item.id),
			});

			try {
				await axios.put(`/api/users/${userId}`, {
					likedId: articleId,
					addId: false,
				});
			} catch (err) {
				console.error(err);
			}
		}
	}

	render() {
		return (
			<div className={BASE_CLASS}>
				{!!this.state.likedArticles.length && (
					<>
						<div className={`${BASE_CLASS}__top`}>
							<button onClick={() => history.goBack()}>
								<Back />
							</button>
							<h3>Your favorites</h3>
						</div>

						<div className={`${BASE_CLASS}__row`}>
							{this.state.likedArticles.map((article) => (
								<ArticleCard
									article={article}
									toggleArticleLike={this.toggleArticleLike}
									likedArticleIds={this.state.likedArticleIds}
								/>
							))}
						</div>
					</>
				)}

				{!this.state.likedArticles.length && (
					<h3>
						You don’t have any favorites yet. <Link to="/rent"> Explore</Link> new styles to find
						something you like.
					</h3>
				)}
			</div>
		);
	}
}

export default Favorites;
