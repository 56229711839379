export const ORDERED_SIZES = [
	'XXS',
	'XS',
	'S',
	'M',
	'L',
	'XL',
	'XXL',
	'2X',
	'3X',
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
	'31',
	'32',
	'33',
	'34',
	'35',
	'36',
	'37',
	'38',
	'ONE SIZE',
	'OTHER',
];

export const POPULAR_FORMAL_IDS = [
	474,
	457,
	458,
	454,
	452,
	451,
	453,
	438,
	439,
	440,
	445,
	444,
	423,
	402,
	471,
	472,
	476,
	459,
	446,
	441,
	436,
	431,
	430,
	426,
	421,
	420,
	419,
	418,
	417,
	416,
	413,
	412,
	411,
	409,
	410,
	408,
	407,
	406,
	403,
	401,
	404,
	405,
	492,
	493,
	512,
	511,
	510,
	509,
	508,
	507,
	506,
];
