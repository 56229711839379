import React, { Component } from 'react';
import store from '../../store';
import './index.scss';
import Review from '../atoms/Review';
const BASE_CLASS = 'profile';
import { getDresses } from '../../store/closet';
import { getWardrobe } from '../../store/closets';
import axios from 'axios';
import { queueModal } from '../../store/modals';
import ArticleCard from '../atoms/ArticleCard';
import { Follow, Add, Instagram } from '../../../public/icons';
import getAverageUserReviews from '../../utils/getAverageUserReviews';

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			lenderReviewsAverage: null,
			lenderReviewsNumber: null,
			likedArticleIds: [],
			followerIds: [],
		};
		this.getLender = this.getLender.bind(this);
		this.handleFollow = this.handleFollow.bind(this);
		this.toggleArticleLike = this.toggleArticleLike.bind(this);
	}

	componentDidMount() {
		store.dispatch(getDresses());
		store.dispatch(getWardrobe(this.props.match.params.userId));
		this.getLender();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.closets.wardrobes !== this.state.closets.wardrobes) {
			let lenderWardrobe = this.state.closets.wardrobes.find((wardrobe) => {
				return wardrobe[0]?.userId == this.props.match.params.userId;
			});

			const [reviews, number] = getAverageUserReviews(lenderWardrobe);

			this.setState({
				lenderWardrobe: lenderWardrobe,
				lenderReviewsAverage: reviews,
				lenderReviewsNumber: number,
			});
		}
	}

	async handleFollow(lenderId) {
		const userId = this.state.user?.user?.id;

		if (!userId) {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to save your favorite rentals!'));
			scrollTo(0, 0);
		} else {
			// if the user is already following the lender, remove follow from front and back end:

			const isFollowing = this.state.followerIds?.includes(userId);

			if (isFollowing) {
				const first = axios.put(`/api/users/${userId}`, {
					followingId: lenderId,
					toRemove: true,
				});

				const second = axios.put(`/api/users/${lenderId}`, {
					followerId: userId,
					toRemove: true,
				});

				try {
					await Promise.all([first, second]);
				} catch (err) {
					console.error(err);
				}
				this.setState({
					followerIds: this.state.followerIds?.filter((id) => id !== userId),
				});
			} else {
				// otherwise add it to the front and back end:

				const first = axios.put(`/api/users/${userId}`, {
					followingId: lenderId,
				});

				const second = axios.put(`/api/users/${lenderId}`, {
					followerId: userId,
				});

				try {
					await Promise.all([first, second]);
				} catch (err) {
					console.error(err);
				}

				this.setState({
					followerIds: [...this.state.followerIds, userId],
				});
			}
		}
	}

	async getLender() {
		try {
			const lenderId = this.props.match.params.userId;
			const lender = await axios.get(`/api/users/${lenderId}`);

			this.setState({
				lender: lender?.data[0],
				followerIds: Array.from(new Set(lender?.data[0]?.followers || [])),
				followingIds: Array.from(new Set(lender?.data[0]?.following || [])),
			});
		} catch (err) {
			console.error(err);
		}
	}

	async toggleArticleLike(articleId) {
		const userId = this.state.user.user.id;

		if (!userId) {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to save your favorite rentals!'));
			scrollTo(0, 0);
		} else {
			// if it's already liked, remove like from front & back end:
			if (this.state.likedArticleIds && this.state.likedArticleIds.includes(articleId)) {
				this.setState({
					likedArticleIds: this.state.likedArticleIds?.filter((id) => id !== articleId),
				});

				try {
					await axios.put(`/api/users/${userId}`, {
						likedId: articleId,
						addId: false,
					});
				} catch (err) {
					console.error(err);
				}
			} else {
				// if it's not already liked, add like to front & back end:
				this.setState({
					likedArticleIds: this.state.likedArticleIds
						? [...this.state.likedArticleIds, articleId]
						: [articleId],
				});

				try {
					await axios.put(`/api/users/${userId}`, {
						likedId: articleId,
						addId: true,
					});
				} catch (err) {
					console.error(err);
				}
			}
		}
	}

	getHandle(handle) {
		const index = handle.lastIndexOf('instagram.com/');
		return index !== -1 ? handle.slice(index + 14) : handle;
	}

	render() {
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__topleft`}>
					{this.state?.lender && (
						<>
							{this.state.lender?.avatarUrl ? (
								<img src={this.state.lender?.avatarUrl} />
							) : (
								<img src="/images/Social-closet-logo.png" />
							)}
							<button type="button" onClick={() => this.handleFollow(this.state.lender.id)}>
								{this.state.followerIds?.includes(this.state.user?.user?.id) ? (
									<div className="unfollow">
										{/* <Follow /> */}
										<Add />
										<span>unfollow</span>
									</div>
								) : (
									<div className="add">
										<Add />
										<span>follow</span>
									</div>
								)}
							</button>
						</>
					)}
				</div>
				<div className={`${BASE_CLASS}__topright`}>
					<h3>{this.state.lender?.name}</h3>
					{this.state.lender?.instagramHandle && (
						<a
							href={`https://www.instagram.com/${this.getHandle(
								this.state.lender?.instagramHandle
							)}`}
							target="blank"
							rel="noreferrer">
							<Instagram />
							{this.getHandle(this.state.lender?.instagramHandle)}
						</a>
					)}

					{!!this.state.followerIds?.length && (
						<h5>
							<span>{this.state.followerIds?.length}</span> followers
						</h5>
					)}

					{!!this.state.followingIds?.length && (
						<h5>
							<span>{this.state.followingIds.length}</span> following
						</h5>
					)}
					<div className={`${BASE_CLASS}__topright__reviews`}>
						{this.state.lenderReviewsNumber ? (
							<>
								<Review reviewStarNumber={Math.round(this.state.lenderReviewsAverage)} />
								<span>({this.state.lenderReviewsNumber})</span>
							</>
						) : (
							<p>This lender has no reviews yet.</p>
						)}
					</div>

					{this.state.lender?.biography?.length && <h6>{this.state.lender.biography}</h6>}
				</div>
				<h3 className={`${BASE_CLASS}__subtitle`}>Articles for rent</h3>
				<div className={`${BASE_CLASS}__articles`}>
					{this.state.lenderWardrobe &&
						this.state.lenderWardrobe.map((article) => (
							<ArticleCard
								article={article}
								toggleArticleLike={this.toggleArticleLike}
								likedArticleIds={this.state.likedArticleIds}
							/>
						))}
				</div>
			</div>
		);
	}
}

export default Profile;
