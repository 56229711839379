import React from 'react';

const MoneyEarned = () => (
	<svg width="75" height="50" viewBox="0 0 75 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M63.1055 7.82278L63.4224 39.7055C63.438 41.8045 62.7495 43.8254 61.5073 45.3269C60.265 46.8284 58.5701 47.6884 56.7927 47.719L7.10531 48.4103C5.32775 48.4287 3.61622 47.6157 2.34463 46.1489C1.07304 44.6821 0.34473 42.6807 0.318797 40.5818L0.000231207 8.69918C-0.0144911 6.60088 0.674407 4.58094 1.91653 3.08034C3.15865 1.57966 4.85311 0.720235 6.63 0.689631L56.319 0.000289477C58.0956 -0.0176047 59.8061 0.794738 61.0774 2.26013C62.3488 3.72561 63.0778 5.72516 63.1055 7.82278Z"
			fill="#F8E5E5"
		/>
		<path
			d="M45.3494 13.4773C47.3612 13.4773 48.992 11.5516 48.992 9.1762C48.992 6.80071 47.3612 4.875 45.3494 4.875C43.3376 4.875 41.7068 6.80071 41.7068 9.1762C41.7068 11.5516 43.3376 13.4773 45.3494 13.4773Z"
			fill="#D1154B"
		/>
		<path
			d="M51.5223 13.3904C53.534 13.3904 55.1649 11.4647 55.1649 9.08928C55.1649 6.7138 53.534 4.78809 51.5223 4.78809C49.5105 4.78809 47.8796 6.7138 47.8796 9.08928C47.8796 11.4647 49.5105 13.3904 51.5223 13.3904Z"
			fill="#FFF5F5"
		/>
		<path
			d="M16.4587 33.8507L8.06769 33.9669C7.95984 33.9695 7.85263 33.9469 7.75214 33.9003C7.65172 33.8537 7.56009 33.784 7.48255 33.6955C7.40508 33.6069 7.34312 33.501 7.30041 33.3839C7.25771 33.267 7.23512 33.1411 7.23378 33.0137L7.22209 31.8892C7.2199 31.7618 7.23909 31.6352 7.27853 31.5165C7.31798 31.3979 7.37696 31.2897 7.45203 31.1983C7.52702 31.1067 7.61667 31.0336 7.71581 30.9832C7.81488 30.9328 7.92146 30.906 8.02938 30.9044L16.422 30.7883C16.53 30.7857 16.6372 30.8083 16.7376 30.8549C16.8381 30.9015 16.9297 30.9711 17.0072 31.0597C17.0847 31.1483 17.1466 31.2542 17.1893 31.3712C17.232 31.4882 17.2547 31.6141 17.256 31.7414L17.2676 32.866C17.2696 32.9934 17.2503 33.1201 17.2106 33.2388C17.171 33.3574 17.1118 33.4655 17.0367 33.557C16.9615 33.6486 16.8718 33.7217 16.7726 33.772C16.6734 33.8224 16.5667 33.8492 16.4587 33.8507ZM8.03936 31.2157C7.96664 31.2167 7.89483 31.2346 7.82798 31.2684C7.76113 31.3022 7.70059 31.3513 7.64974 31.4127C7.59897 31.4741 7.55889 31.5468 7.53191 31.6266C7.50486 31.7062 7.4914 31.7915 7.49232 31.8773L7.50401 33.0019C7.50486 33.0878 7.52001 33.1726 7.54869 33.2515C7.5773 33.3304 7.61887 33.4019 7.67084 33.462C7.72289 33.5219 7.78443 33.5692 7.85199 33.6011C7.91947 33.633 7.99171 33.6489 8.06436 33.6478L16.4554 33.5317C16.5281 33.5306 16.6 33.5127 16.6668 33.4789C16.7337 33.4451 16.7942 33.3961 16.845 33.3346C16.8958 33.2731 16.9359 33.2005 16.9629 33.1208C16.9899 33.041 17.0033 32.9558 17.0025 32.8699L16.9924 31.7454C16.9902 31.5722 16.9301 31.407 16.8251 31.286C16.7201 31.1649 16.5788 31.0978 16.4321 31.0994L8.03936 31.2157Z"
			fill="#FA255E"
		/>
		<path
			d="M36.2061 33.5791L20.1779 33.8016C19.897 33.8048 19.6265 33.6764 19.4255 33.4446C19.2246 33.2128 19.1095 32.8965 19.1055 32.5648V32.0114C19.1024 31.6796 19.211 31.3598 19.4073 31.1223C19.6037 30.8849 19.8719 30.7492 20.153 30.745L36.1794 30.5225C36.4605 30.5189 36.7313 30.647 36.9324 30.8789C37.1334 31.1108 37.2484 31.4274 37.2519 31.7593V32.3127C37.2546 32.6441 37.1461 32.9632 36.9502 33.2006C36.7542 33.4378 36.4867 33.5739 36.2061 33.5791ZM20.1513 31.0582C19.9404 31.0603 19.7388 31.161 19.5907 31.3381C19.4425 31.5152 19.3598 31.7546 19.3607 32.0036V32.557C19.3634 32.8059 19.4494 33.0435 19.6001 33.2178C19.7508 33.392 19.9537 33.4886 20.1646 33.4865L36.1928 33.2639C36.4037 33.2603 36.6047 33.1582 36.7519 32.9799C36.8992 32.8016 36.9806 32.5617 36.9783 32.3127V31.7593C36.9753 31.5103 36.8888 31.2729 36.7378 31.0991C36.5868 30.9252 36.3836 30.8291 36.1727 30.8318L20.1513 31.0582Z"
			fill="#FA255E"
		/>
		<path
			d="M56.7911 33.2909L40.7629 33.5135C40.482 33.5166 40.2115 33.3883 40.0104 33.1565C39.8094 32.9247 39.6944 32.6084 39.6905 32.2767V31.7232C39.6878 31.3915 39.7965 31.0721 39.9928 30.8348C40.1891 30.5975 40.457 30.4616 40.7379 30.4569L56.7661 30.2344C57.047 30.2313 57.3175 30.3596 57.5185 30.5914C57.7194 30.8232 57.8345 31.1395 57.8385 31.4712V32.0246C57.8412 32.3563 57.7325 32.6757 57.5362 32.9131C57.3399 33.1504 57.072 33.2862 56.7911 33.2909ZM40.7362 30.7701C40.5254 30.7732 40.3241 30.8749 40.1766 31.0527C40.029 31.2307 39.9472 31.4704 39.949 31.7193V32.2728C39.9516 32.522 40.0379 32.76 40.1889 32.9343C40.34 33.1085 40.5434 33.2049 40.7545 33.2023L56.7877 32.9876C56.9987 32.984 57.1999 32.8819 57.3474 32.7037C57.4949 32.5255 57.5767 32.2856 57.575 32.0364V31.483C57.5723 31.2342 57.4862 30.9968 57.3355 30.8229C57.1848 30.649 56.9818 30.5528 56.771 30.5554L40.7362 30.7701Z"
			fill="#FA255E"
		/>
		<path
			d="M15.3263 18.113L15.3646 21.975C15.3679 22.2972 15.3174 22.6169 15.216 22.916C15.1146 23.2151 14.9643 23.4877 14.7736 23.7182C14.5829 23.9486 14.3556 24.1324 14.1047 24.2591C13.8539 24.3858 13.5843 24.4528 13.3115 24.4564L6.40151 24.5529C6.12869 24.5569 5.85787 24.4972 5.60457 24.3775C5.35126 24.2578 5.12043 24.0802 4.92526 23.8551C4.73009 23.6299 4.57441 23.3616 4.46713 23.0653C4.35985 22.7691 4.30306 22.4508 4.30001 22.1286L4.26165 18.2667C4.25812 17.9444 4.30847 17.6245 4.40981 17.3253C4.51114 17.0261 4.66148 16.7534 4.8522 16.5229C5.04292 16.2924 5.27028 16.1086 5.52125 15.982C5.77223 15.8554 6.04189 15.7885 6.31479 15.7851L13.2248 15.6887C13.4976 15.6848 13.7684 15.7444 14.0217 15.8642C14.275 15.9839 14.5058 16.1614 14.701 16.3866C14.8961 16.6117 15.0519 16.8801 15.1591 17.1763C15.2664 17.4725 15.3232 17.7909 15.3263 18.113Z"
			fill="#FA255E"
		/>
		<path
			d="M6.7318 15.7793H6.31481C6.11339 15.7815 5.91334 15.8187 5.72105 15.8896H6.29814C6.55235 15.8864 6.7973 16.0024 6.97922 16.212C7.16115 16.4217 7.26531 16.708 7.26885 17.0082V17.536C7.27147 17.8362 7.17333 18.1254 6.99572 18.3402C6.81819 18.5551 6.57572 18.6781 6.32149 18.6822L4.26001 18.7098V18.9067L6.31982 18.8772C6.46908 18.8759 6.61658 18.839 6.75347 18.7689C6.95883 18.6641 7.13346 18.4902 7.2554 18.2688C7.37734 18.0473 7.44122 17.7883 7.43895 17.5241V16.9963C7.43506 16.734 7.36594 16.4788 7.24017 16.2624C7.11448 16.046 6.93766 15.878 6.7318 15.7793Z"
			fill="#F8E5E5"
		/>
		<path
			d="M6.7617 18.7763C6.62347 18.7102 6.47547 18.6774 6.32637 18.6798L4.26489 18.7074V18.9043L6.3247 18.8748C6.57879 18.8716 6.82352 18.9877 7.00523 19.1974C7.18687 19.4071 7.29068 19.6934 7.29373 19.9934V20.5212C7.29642 20.8218 7.19799 21.1112 7.02003 21.3261C6.84207 21.541 6.59925 21.6638 6.34471 21.6674L4.2849 21.697V21.8939L6.34471 21.8663C6.49459 21.8644 6.64266 21.8268 6.78004 21.756C6.98519 21.6512 7.15961 21.4772 7.28127 21.2557C7.40292 21.0343 7.46644 20.7754 7.46382 20.5114V19.9836C7.45894 19.7235 7.38989 19.4708 7.26519 19.2564C7.14049 19.042 6.9655 18.8751 6.7617 18.7763Z"
			fill="#F8E5E5"
		/>
		<path
			d="M6.79337 21.7743C6.6538 21.7091 6.50487 21.6764 6.35475 21.6778L4.29492 21.7074V21.9043L6.35475 21.8767C6.48058 21.8749 6.6055 21.9024 6.72234 21.9576C6.83926 22.0129 6.94569 22.0949 7.0357 22.1987C7.1257 22.3027 7.19751 22.4265 7.24694 22.5632C7.29637 22.6998 7.32243 22.8467 7.32377 22.9953V23.5212C7.32604 23.7308 7.27888 23.937 7.18752 24.1169C7.09624 24.2967 6.96439 24.4429 6.80675 24.5393H7.11692C7.23844 24.4121 7.33539 24.2555 7.40131 24.0802C7.46717 23.9048 7.50045 23.715 7.4989 23.5231V22.9973C7.49543 22.7344 7.42674 22.4783 7.30139 22.2609C7.17598 22.0436 6.99937 21.8744 6.79337 21.7743Z"
			fill="#F8E5E5"
		/>
		<path
			d="M13.0447 18.7919L15.333 18.7604V18.5635L13.0447 18.595C12.7441 18.5997 12.4572 18.7449 12.2471 18.999C12.037 19.2529 11.9208 19.5949 11.9239 19.9499V20.4758C11.9268 20.74 11.9956 20.9972 12.1216 21.2155C12.2477 21.4337 12.4255 21.6032 12.6327 21.7027C12.7718 21.77 12.9211 21.8029 13.0714 21.7992L15.358 21.7618V21.5648L13.068 21.5964C12.8138 21.599 12.5691 21.4826 12.3874 21.2725C12.2058 21.0625 12.1021 20.7759 12.099 20.4758V19.948C12.0966 19.7987 12.1191 19.6503 12.1654 19.5113C12.2117 19.3723 12.2807 19.2455 12.3685 19.1381C12.4564 19.0306 12.5614 18.9448 12.6774 18.8854C12.7934 18.826 12.9183 18.7943 13.0447 18.7919Z"
			fill="#F8E5E5"
		/>
		<path
			d="M11.4766 23.4854L8.20117 23.5306L8.20323 23.7374L11.4787 23.6921L11.4766 23.4854Z"
			fill="#FA255E"
		/>
		<path
			d="M11.4075 16.4971L8.13208 16.5423L8.13413 16.7491L11.4096 16.7039L11.4075 16.4971Z"
			fill="#F8E5E5"
		/>
		<path
			d="M13.0712 21.5851C12.9224 21.5869 12.7754 21.6234 12.6385 21.6924C12.5017 21.7614 12.3777 21.8616 12.2738 21.9873C12.1698 22.113 12.0878 22.2617 12.0327 22.4249C11.9774 22.588 11.95 22.7624 11.952 22.9381V23.4659C11.9541 23.6575 11.9912 23.8464 12.0605 24.0197C12.1298 24.193 12.23 24.3466 12.3539 24.4703H12.6659C12.5061 24.3788 12.371 24.2366 12.2758 24.0595C12.1807 23.8825 12.1292 23.6775 12.1271 23.4679V22.94C12.1245 22.6398 12.2227 22.3507 12.4003 22.1359C12.5778 21.921 12.8203 21.7981 13.0745 21.7939L15.3578 21.7623V21.5654L13.0712 21.5851Z"
			fill="#F8E5E5"
		/>
		<path
			d="M24.9381 42.4998L7.69907 42.7381C7.65531 42.7387 7.61176 42.7291 7.57111 42.71C7.53046 42.6907 7.49335 42.6623 7.46198 42.6262C7.43061 42.5901 7.40561 42.547 7.38834 42.4994C7.37106 42.4519 7.36185 42.4008 7.36136 42.3491C7.36079 42.2974 7.36886 42.2461 7.38515 42.198C7.40137 42.15 7.42551 42.1062 7.45611 42.0691C7.4867 42.0321 7.52317 42.0026 7.56339 41.9822C7.60368 41.9618 7.64695 41.951 7.69071 41.9503L24.9298 41.71C24.9736 41.7094 25.017 41.7189 25.0578 41.7381C25.0984 41.7573 25.1355 41.7858 25.1669 41.8219C25.1982 41.858 25.2233 41.9011 25.2405 41.9486C25.2578 41.9961 25.2669 42.0473 25.2675 42.099C25.2681 42.1507 25.26 42.2021 25.2437 42.2501C25.2274 42.2982 25.2034 42.3419 25.1728 42.3789C25.1422 42.416 25.1057 42.4455 25.0654 42.4659C25.0252 42.4863 24.9819 42.4972 24.9381 42.4977V42.4998Z"
			fill="#FA255E"
		/>
		<path
			d="M62.5017 49.9992C61.0723 49.9639 57.1796 47.1771 55.5434 44.9084C50.9234 38.5059 51.3654 26.3329 51.4771 26.3368C56.9177 26.4767 59.6246 25.0804 63.1872 20.3145C66.5696 25.2596 69.5584 26.8017 74.9972 26.9415C75.0439 26.9415 74.5588 40.1129 69.0497 45.9935C67.3952 47.7581 63.9294 50.0367 62.5017 49.9992Z"
			fill="#C39EA0"
		/>
		<path
			d="M61.1457 40.9644C61.0662 40.9637 60.9877 40.9423 60.9159 40.9019C60.8442 40.8614 60.7808 40.8027 60.7304 40.73L58.4237 37.4392C58.3754 37.374 58.3386 37.298 58.3156 37.2158C58.2925 37.1335 58.2837 37.0466 58.2896 36.9602C58.2955 36.8738 58.316 36.7898 58.3499 36.713C58.3838 36.6361 58.4305 36.568 58.487 36.5129C58.5436 36.4577 58.6089 36.4165 58.6792 36.3915C58.7496 36.3667 58.8233 36.3588 58.8963 36.3682C58.9692 36.3777 59.0398 36.4042 59.1039 36.4465C59.168 36.4887 59.2242 36.5456 59.2693 36.614L61.1257 39.2648L67.13 29.6148C67.216 29.476 67.3452 29.3834 67.4891 29.3572C67.6329 29.331 67.7797 29.3733 67.8972 29.4749C68.0146 29.5765 68.0931 29.729 68.1153 29.8989C68.1375 30.0688 68.1017 30.2421 68.0156 30.3809L61.601 40.7005C61.5517 40.78 61.4875 40.8453 61.4135 40.891C61.3394 40.9367 61.2576 40.9618 61.174 40.9644H61.1457Z"
			fill="#D1154B"
		/>
	</svg>
);

export default MoneyEarned;
