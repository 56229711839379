import axios from 'axios';

const initialState = {
	comments: [],
};

const GET_COMMENTS = 'GET_COMMENTS';
const GOT_UPDATED_COMMENT = 'GOT_UPDATED_COMMENT';

// action creators

const gotComments = (comments) => ({ type: GET_COMMENTS, comments });
const gotUpdatedComment = (comment) => ({ type: GOT_UPDATED_COMMENT, comment });

// thunk creators

export const getComments = (articleId) => {
	return async (dispatch, getState) => {
		try {
			const res = await axios.get(`/api/comment/${articleId}`);

			dispatch(gotComments(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

export const addComment = (articleId, comment, dress) => {

	
	return async (dispatch, getState) => {
		try {
			const res = await axios.post(`/api/comment/${articleId}`, { comment, ...dress });
			

			dispatch(gotUpdatedComment(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

// reducer

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_COMMENTS:
			return { ...state, comments: action.comments };
		case GOT_UPDATED_COMMENT:
			return { ...state, comment: action.comment, comments: [...state.comments, action.comment] };

		default:
			return state;
	}
}
