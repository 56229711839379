import React, { Fragment } from 'react';
import './index.scss';
const BASE_CLASS = 'remove-confirmation-modal';

class RemoveConfirmationModal extends React.Component {
	constructor() {
		super();
	}
	render() {
		return (
			<Fragment>
				<div className={`${BASE_CLASS}__container`} />
				<div className={`${BASE_CLASS}__inner`}>
					<h1>Are you sure?</h1>
					<p>Do you really want to remove this item?</p>
					<div className={`${BASE_CLASS}__inner__buttons`}>
						<button
							type="button"
							className={`${BASE_CLASS}__inner__buttons__button`}
							onClick={() => {
								this.props.handleAccept(this.props.dressId);
								window.location.href = '/closet';
							}}>
							yes
						</button>
						<button
							type="button"
							className={`${BASE_CLASS}__inner__buttons__button`}
							onClick={this.props.handleCancel}>
							cancel
						</button>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default RemoveConfirmationModal;
