import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import store from '../../store';
import { Heart, Face, ConfirmFace, Article, Checkmark } from '../../../public';
import { Search, Octagon, Close } from '../../../public/icons';
import { getFriends, getFriendsClosetsLikes, getFriendsClosets } from '../../store/friends';
import AddSuccess from '../modals/AddSuccessModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import Back from '../../../public/icons/Back';
import history from '../../../client/history';
import './index.scss';

const BASE_CLASS = 'friends';

class Friends extends Component {
	constructor() {
		super();
		this.state = {
			...store.getState(),
			friendSearchTerm: '',
			friendSearchResults: null,
			friendsPending: [],
			activeFriends: [],
			friendsToConfirm: [],
			friendsClosetsLikes: [],
			friendIds: [],
			friendRequestSent: false,
			friendRequestConfirmed: false,
			likedIds: [],
			showCancelFriendConfirmationModal: false,
			showDeclineFriendConfirmationModal: false,
			showAcceptFriendConfirmationModal: true,
			displayRequestsSent: false,
		};

		this.handleFriendSearchChange = this.handleFriendSearchChange.bind(this);
		this.handleFriendSearch = this.handleFriendSearch.bind(this);
		this.sendFriendRequest = this.sendFriendRequest.bind(this);
		this.sortFriends = this.sortFriends.bind(this);
		this.getLikedItems = this.getLikedItems.bind(this);
		this.handleFriendRequest = this.handleFriendRequest.bind(this);
		this.showConfirmationModal = this.showConfirmationModal.bind(this);
		this.toggleTabDisplay = this.toggleTabDisplay.bind(this);
	}

	componentDidMount() {
		store.dispatch(getFriends());
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.friends.friends !== this.state.friends.friends) {
			this.sortFriends();
			this.getLikedItems();
		}

		if (prevState.friends.friendsClosetsLikes !== this.state.friends.friendsClosetsLikes) {
			this.setState((state) => ({
				friendsClosetsLikes: state.friends.friendsClosetsLikes.flat(),
			}));
		}
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	toggleTabDisplay() {
		this.setState({
			displayRequestsSent: !this.state.displayRequestsSent,
		});
	}

	closeConfirmationModal(modal) {
		this.setState({
			[modal]: false,
			rentalIdBeingHandled: null,
			articleItemIdBeingHandled: null,
			friendBeingHandled: null,
		});
	}
	showConfirmationModal(modal, rentalId, articleId, rentalDates, friend) {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});

		this.setState({
			[modal]: true,
			rentalIdBeingHandled: rentalId,
			articleItemIdBeingHandled: articleId,
			rentalDatesBeingHandled: rentalDates,
			friendBeingHandled: friend,
		});
	}

	getLikedItems() {
		const likedItemsIdArr = [];
		const likedIds = [];
		const friendIds = [];

		this.state.friends.friends
			.filter((f) => f.requesterId === this.state.user.user.id)
			.map(
				(f) =>
					likedItemsIdArr.push({
						friendId: f.requesteeId,
						likedItems: f.articlesOfRequesteeLikedByRequester,
					}) &&
					friendIds.push(f.requesteeId) &&
					likedIds.push(f.articlesOfRequesteeLikedByRequester)
			);

		this.state.friends.friends
			.filter((f) => f.requesteeId === this.state.user.user.id)
			.map(
				(f) =>
					likedItemsIdArr.push({
						friendId: f.requesterId,
						likedItems: f.articlesOfRequesterLikedByRequestee,
					}) &&
					friendIds.push(f.requesterId) &&
					likedIds.push(f.articlesOfRequesterLikedByRequestee)
			);

		this.setState({
			friendIds: friendIds,
			likedIds: likedIds?.filter((l) => !!l).flat(),
		});

		store.dispatch(getFriendsClosets(Array.from(friendIds)));
		store.dispatch(getFriendsClosetsLikes(likedItemsIdArr));
	}

	async handleRejectFriend(friendHandled) {
		try {
			await axios.delete(`/api/friends/${friendHandled?.id}`);
		} catch (err) {
			console.error(err);
		}
	}

	async handleConfirmFriend(friend) {
		const {
			requesteeEmail,
			requesteeId,
			requesteeName,
			requesterEmail,
			requesterName,
			requesterId,
		} = friend;

		try {
			await axios
				.put(`/api/friends`, {
					requesterId,
					requesteeId,
					requesteeEmail,
					requesterEmail,
					requesterName,
					requesteeName,
				})
				.then(() =>
					this.setState({
						friendRequestConfirmed: true,
					})
				);
		} catch (err) {
			console.error(err);
		}
	}

	async handleFriendSearch(e) {
		e.preventDefault();
		let searchTerm = this.state.friendSearchTerm;

		try {
			const res = await axios.get(`/api/users`);
			const friends = res.data;
			const result = friends.filter(
				(friend) =>
					(friend.name.includes(searchTerm) || friend.email.includes(searchTerm)) &&
					!this.state.friendIds.includes(friend.id) &&
					friend.id !== this.state.user.user.id
			);
			this.setState({
				friendSearchResults: result,
			});
		} catch (err) {
			console.error(err);
		}
	}

	handleFriendSearchChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleFriendRequest(e, friend) {
		this.sendFriendRequest(friend);
		this.setState((state) => ({
			friendRequestSent: true,
		}));
	}

	sendFriendRequest(friend) {
		const user = this.state.user.user;

		try {
			const res = axios.post(`/api/friends`, { friend, user });
		} catch (err) {
			console.error(err);
		}
	}

	getActiveFriends() {
		let parsedActiveFriends = [];

		this.state.friends.friends
			.filter((f) => f.isActive && f.requesterId === this.state.user.user.id)
			.map((f) =>
				parsedActiveFriends.push({
					name: f.requesteeName,
					friendId: f.requesteeId,
					avatarUrl: f.requesteeAvatar,
				})
			);

		this.state.friends.friends
			.filter((f) => f.isActive && f.requesteeId === this.state.user.user.id)
			.map((f) =>
				parsedActiveFriends.push({
					name: f.requesterName,
					friendId: f.requesterId,
					avatarUrl: f.requesterAvatar,
				})
			);

		return parsedActiveFriends;
	}

	getIsRequester(friendId) {
		return Boolean(
			this.state.friends.friends.filter(
				(f) =>
					Number(f.requesterId) === Number(this.state.user.user.id) &&
					Number(f.requesteeId) === Number(friendId)
			).length
		);
	}

	async toggleLikeItem(dressId, friendId) {
		const userId = this.state.user.user.id;
		// if it's already liked, remove like from front & back end:
		if (this.state.likedIds && this.state.likedIds.includes(dressId)) {
			this.setState({
				likedIds: this.state.likedIds.filter((id) => id !== dressId),
			});

			try {
				await axios.put(`/api/friends/${friendId}`, {
					dressId: dressId,
					userId: this.state.user.user.id,
					isRequester: this.getIsRequester(friendId),
					remove: true,
					requesteeId: this.getIsRequester(friendId) ? friendId : userId,
					requesterId: this.getIsRequester(friendId) ? userId : friendId,
				});
			} catch (err) {
				next(err);
			}
		} else {
			// if it's not already liked, add like to front & back end:
			this.setState({
				likedIds: this.state.likedIds ? this.state.likedIds.concat(dressId) : [dressId],
			});

			try {
				await axios.put(`/api/friends/${friendId}`, {
					dressId: dressId,
					userId: this.state.user.user.id,
					isRequester: this.getIsRequester(friendId),
					remove: false,
					requesteeId: this.getIsRequester(friendId) ? friendId : userId,
					requesterId: this.getIsRequester(friendId) ? userId : friendId,
				});
			} catch (err) {
				console.error(err);
			}
		}
	}

	sortFriends() {
		const friendsPending = this.state.friends.friends.filter(
			(f) => !f.isActive && f.requesterId === this.state.user.user.id
		);
		const friendsToConfirm = this.state.friends.friends.filter(
			(f) => !f.isActive && f.requesterId !== this.state.user.user.id
		);

		this.setState((state) => ({
			friendsPending,
			friendsToConfirm,
			activeFriends: this.getActiveFriends(),
			displayRequestsSent: !friendsToConfirm.length,
		}));
	}

	render() {
		return this.state.friendRequestSent ? (
			<AddSuccess text="Friend request sent!" path="friends" />
		) : (
			<div className={BASE_CLASS}>
				{this.state.showDeclineFriendConfirmationModal && (
					<ConfirmationModal
						header="Are you sure?"
						icon="warning"
						text="By declining this request, you won't be able to view this friend's closet."
						handleAccept={() => {
							this.handleRejectFriend(this.state.friendBeingHandled);
							this.closeConfirmationModal('showDeclineFriendConfirmationModal');
						}}
						handleCancel={() => this.closeConfirmationModal('showDeclineFriendConfirmationModal')}
						continueCtaText="Agree and Decline"
						cancelCtaText="Cancel"
					/>
				)}

				{this.state.showCancelFriendConfirmationModal && (
					<ConfirmationModal
						icon="warning"
						text="By canceling this request, you won't be able to view this friend's closet."
						header="Are you sure?"
						handleAccept={() => {
							this.handleRejectFriend(this.state.friendBeingHandled);

							return setTimeout(() => {
								this.closeConfirmationModal('showCancelFriendConfirmationModal');
								window.location.reload();
							}, 500);
						}}
						handleCancel={() => this.closeConfirmationModal('showCancelFriendConfirmationModal')}
						continueCtaText="Agree and Cancel"
						cancelCtaText="Go back"
					/>
				)}

				{this.state.showConfirmFriendConfirmationModal && (
					<ConfirmationModal
						header="All good?"
						icon="flight-arrow"
						text="By confirming, you and this friend will be able to view each other's closets (* only the items you make visible to friends.)"
						handleAccept={() => {
							this.handleConfirmFriend(this.state.friendBeingHandled);

							return setTimeout(() => {
								this.closeConfirmationModal('showConfirmFriendConfirmationModal');
								window.location.reload();
							}, 500);
						}}
						handleCancel={() => this.closeConfirmationModal('showConfirmFriendConfirmationModal')}
						continueCtaText="Sounds good"
						cancelCtaText="Cancel"
					/>
				)}
				<div className={`${BASE_CLASS}__form`}>
					{/* <h3>Find users</h3> // TODO: add ability to follow friends ! *
					<p>
						Find a lender to follow, <br /> or add a friend to see their wardrobe
					</p> */}
					<button onClick={() => history.goBack()} className={`${BASE_CLASS}__form__back-btn`}>
						<Back />
					</button>
					<h1>Add a friend</h1>
					<p>Send a friend request to check out a friend's wardrobe</p>

					<form
						onSubmit={(e) => this.handleFriendSearch(e)}
						className={`${BASE_CLASS}__form__search`}>
						<input
							type="search"
							name="friendSearchTerm"
							autoCorrect="true"
							autoComplete="true"
							value={this.state.friendSearchTerm}
							onChange={this.handleFriendSearchChange}
							placeholder="Search for a user by name or email"
							id="searchbox-closet"
						/>
						<button
							type="submit"
							className={classnames(`${BASE_CLASS}__form__search__search-box`)}
							disabled={!this.state.friendSearchTerm}>
							<Search />
						</button>
					</form>
				</div>

				{this.state.friendSearchResults && (
					<div className={`${BASE_CLASS}__results`}>
						<button type="button" onClick={() => this.setState({ friendSearchResults: null })}>
							<Close />
						</button>
						{!!this.state.friendSearchResults.length &&
							this.state.friendSearchResults.map((friend, i) => (
								<div key={i} className={`${BASE_CLASS}__request-card`}>
									{friend.avatarUrl ? (
										<img src={friend.avatarUrl} />
									) : (
										<h2>{friend?.name?.slice(0, 1)}</h2>
									)}
									<p>{friend.name}</p>
									<button type="button" onClick={(e) => this.handleFriendRequest(e, friend)}>
										send friend request
									</button>
								</div>
							))}

						{this.state.friendSearchResults && !this.state.friendSearchResults.length && (
							<p>Sorry, we couldn't find any users that match your search.</p>
						)}
					</div>
				)}

				<div className={`${BASE_CLASS}__tabs`}>
					{!!this.state.friendsToConfirm.length && (
						<h5
							className={classnames({ tabinactive: this.state.displayRequestsSent })}
							onClick={() => this.toggleTabDisplay()}>
							pending friend requests
							<div />
						</h5>
					)}
					{!!this.state.friendsPending.length && (
						<h5
							className={classnames({ tabinactive: !this.state.displayRequestsSent })}
							onClick={() => this.toggleTabDisplay()}>
							friend requests sent
							<div />
						</h5>
					)}
				</div>

				{!!this.state.friendsToConfirm && !this.state.displayRequestsSent && (
					<div className={`${BASE_CLASS}__section`}>
						{this.state.friendsToConfirm.map((friend) => (
							<div className={`${BASE_CLASS}__request-card`} key={friend.requesterId}>
								{friend.requesterAvatar ? (
									<img src={friend.requesterAvatar} />
								) : (
									<h2>{friend?.requesterName?.slice(0, 1)}</h2>
								)}
								<p>{friend.requesterName}</p>

								<button
									type="button"
									className="black-btn"
									onClick={() =>
										this.showConfirmationModal(
											'showDeclineFriendConfirmationModal',
											null,
											null,
											null,
											friend
										)
									}>
									<Octagon />
									Decline
								</button>
								<button
									type="button"
									className="pink-text"
									onClick={() =>
										this.showConfirmationModal(
											'showConfirmFriendConfirmationModal',
											null,
											null,
											null,
											friend
										)
									}>
									<Checkmark />
									Confirm
								</button>
							</div>
						))}
					</div>
				)}

				{!!this.state.friendsPending.length && this.state.displayRequestsSent && (
					<div className={`${BASE_CLASS}__section`}>
						{this.state.friendsPending.map((friend) => (
							<div className={`${BASE_CLASS}__request-card`} key={friend.requesteeId}>
								{friend.requesteeAvatar ? (
									<img src={friend.requesteeAvatar} />
								) : (
									<h2>{friend?.requesteeName?.slice(0, 1)}</h2>
								)}
								<p>{friend.requesteeName}</p>
								<button
									type="button"
									onClick={() =>
										this.showConfirmationModal(
											'showCancelFriendConfirmationModal',
											null,
											null,
											null,
											friend
										)
									}>
									<Octagon />
									Cancel
								</button>
							</div>
						))}
					</div>
				)}
				<h5>
					your friends
					<div />
				</h5>
				{this.state.activeFriends.length ? (
					<div className={`${BASE_CLASS}__section`}>
						{this.state.activeFriends.map((friend) => (
							<a
								key={friend.friendId}
								className={`${BASE_CLASS}__active-friends__card`}
								href={`/friends/${friend.friendId}`}>
								{friend.avatarUrl ? (
									<img src={friend.avatarUrl} />
								) : (
									<h2>{friend?.name?.slice(0, 1)}</h2>
								)}
								<p>{friend.name}</p>
								<a>view closet</a>
							</a>
						))}
					</div>
				) : (
					<h3>Nothing here yet. Add a friend to get started!</h3>
				)}
				<h5>
					new in friends' closets
					<div />
				</h5>
				{this.state.friends?.friendsClosets.length ? (
					<div className={`${BASE_CLASS}__section`}>
						{this.state?.friends.friendsClosets.map((item) => (
							<div className={`${BASE_CLASS}__friend-card`} key={item.id}>
								<div>
									<span
										onClick={() => this.toggleLikeItem(item.id, item.userId)}
										className={classnames('heart', {
											liked: this.state.likedIds.includes(item.id),
										})}>
										<Heart />
									</span>

									<Link
										to={{
											pathname: `/friends/${item.userId}/${item.id}`,
										}}>
										{
											item.imageUrls ? <img src={item.imageUrls[0]} /> : null
											// <h2>{friend?.name?.slice(0, 1)}</h2>
										}
									</Link>
								</div>
								<Link
									to={{
										pathname: `/friends/${item.userId}/${item.id}`,
									}}>
									<h4>{item.name}</h4>
									<p>
										{item.brand} {item.size && item.brand && '|'} {item.size}
									</p>
								</Link>
							</div>
						))}
					</div>
				) : (
					<h3>Nothing here yet. Nudge your friends to upload their wardrobes!</h3>
				)}

				<h5>
					saved items
					<div />
				</h5>
				{this.state.friendsClosetsLikes.length ? (
					<div className={`${BASE_CLASS}__section`}>
						{this.state.friendsClosetsLikes.map((item) => (
							<div className={`${BASE_CLASS}__friend-card`} key={item.id}>
								<div>
									<span
										onClick={() => this.toggleLikeItem(item.id, item.userId)}
										className={classnames('heart', {
											liked: this.state.likedIds.includes(item.id),
										})}>
										<Heart />
									</span>
									<Link
										to={{
											pathname: `/friends/${item.userId}/${item.id}`,
										}}>
										{
											item.imageUrls ? <img src={item.imageUrls[0]} /> : null
											// <h2>{friend?.name?.slice(0, 1)}</h2>
										}
									</Link>
								</div>
								<Link
									to={{
										pathname: `/friends/${item.userId}/${item.id}`,
									}}>
									<h4>{item.name}</h4>
									<p>
										{item.brand} {item.size && item.brand && '|'} {item.size}
									</p>
								</Link>
							</div>
						))}
					</div>
				) : (
					<h3>
						Nothing here yet. Add friends and check out their virtual wardrobes to get started!
					</h3>
				)}
			</div>
		);
	}
}

export default Friends;
