import React from 'react';

const ShoppingCart = () => (
	<svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_3686_56417)">
			<path
				d="M8.25004 17.4167C8.7563 17.4167 9.16671 17.0623 9.16671 16.625C9.16671 16.1878 8.7563 15.8334 8.25004 15.8334C7.74378 15.8334 7.33337 16.1878 7.33337 16.625C7.33337 17.0623 7.74378 17.4167 8.25004 17.4167Z"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.3333 17.4167C18.8396 17.4167 19.25 17.0623 19.25 16.625C19.25 16.1878 18.8396 15.8334 18.3333 15.8334C17.827 15.8334 17.4166 16.1878 17.4166 16.625C17.4166 17.0623 17.827 17.4167 18.3333 17.4167Z"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M0.916626 0.791626H4.58329L7.03996 11.392C7.12378 11.7565 7.35338 12.0839 7.68854 12.3169C8.02371 12.55 8.4431 12.6737 8.87329 12.6666H17.7833C18.2135 12.6737 18.6329 12.55 18.968 12.3169C19.3032 12.0839 19.5328 11.7565 19.6166 11.392L21.0833 4.74996H5.49996"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3686_56417">
				<rect width="22" height="19" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default ShoppingCart;
