import React from 'react';

const Filter = () => (
	<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2201_8541)">
			<path d="M4.11523 21V14" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4.11523 10V3" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M11.6426 21V12" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M11.6426 8V3" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M19.168 21V16" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M19.168 12V3" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M1.29297 14H6.93799" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M8.81836 8H14.4634" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M16.3457 16H21.9907" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
		</g>
		<defs>
			<clipPath id="clip0_2201_8541">
				<rect width="22.5801" height="24" fill="white" transform="translate(0.351562)" />
			</clipPath>
		</defs>
	</svg>
);

export default Filter;
