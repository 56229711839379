import React from 'react';

const Aperture = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4394_61859)">
			<path
				d="M7.99967 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99967C14.6663 4.31778 11.6816 1.33301 7.99967 1.33301C4.31778 1.33301 1.33301 4.31778 1.33301 7.99967C1.33301 11.6816 4.31778 14.6663 7.99967 14.6663Z"
				stroke="#111111"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.54004 5.33301L13.3667 11.9597"
				stroke="#111111"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.45996 5.33301H14.1133"
				stroke="#111111"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.91992 7.99971L8.74659 1.37305"
				stroke="#111111"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.46046 10.6667L2.63379 4.04004"
				stroke="#111111"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.54005 10.667H1.88672"
				stroke="#111111"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.0796 8L7.25293 14.6267"
				stroke="#111111"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4394_61859">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default Aperture;
