import React from 'react';

const Grid = () => (
	<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.333 2.5H2.5v5.833h5.833V2.5zM17.5 2.5h-5.833v5.833H17.5V2.5zM17.5 11.666h-5.833v5.833H17.5v-5.833zM8.333 11.666H2.5v5.833h5.833v-5.833z"
			stroke="#111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Grid;
