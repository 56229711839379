import React from 'react';

const Pin = () => (
	<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 1 1 18 0z"
			stroke="#111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
			stroke="#111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Pin;
