import React from 'react';
import './index.scss';
const BASE_CLASS = 'howitworks';
import Faq from '../static/Faq';
import { Five, Six, Seven, Eight, Nine, Ten, Eleven, Twelve } from '../../../public/icons/index';
import Back from '../../../public/icons/Back';
import history from '../../../client/history';

const HowItWorks = () => {
	return (
		<div className="container-how-it-works">
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__top`}>
					<button onClick={() => history.goBack()} className="back">
						<Back />
					</button>
					<h1>How it works</h1>
				</div>
				<p>Social Closet is a place for people to rent and lend their wardrobes.</p>
				<div className={`${BASE_CLASS}__jumptos`}>
					<a href="#how-to-rent">Rent</a>
					<a href="#how-to-lend">Lend</a>
					<a href="#how-to-faqs">FAQs</a>
				</div>
				<h5>
					rent
					<div />
				</h5>
				<p>
					<b>As a renter,</b> you can find something unique to wear, save money and help the planet.{' '}
					<br /> Find something for an special event, a trip, or just to explore a new style.
					<br /> These are the steps:
				</p>
				<div className={`${BASE_CLASS}__cards`} id="how-to-rent">
					<div>
						<Five />
						<h3>find</h3>
						<p>something you love</p>
					</div>
					<div>
						<Six />
						<h3>rent</h3>
						<p>meet up locally, or have it delivered</p>
					</div>
					<div>
						<Seven />
						<h3>return</h3>
						<p>in great condition</p>
					</div>
					<div>
						<Eight />
						<h3>review</h3>
						<p>share your experience</p>
					</div>
				</div>
				<h5>
					lend
					<div />
				</h5>
				<p>
					<b>As a lender,</b> you can make extra cash from your wardrobe, on your own terms.
					<br /> These are the steps:
				</p>
				<div className={`${BASE_CLASS}__cards`} id="how-to-lend">
					<div>
						<Nine />
						<h3>upload</h3>
						<p>your wardrobe</p>
					</div>
					<div>
						<Ten />
						<h3>confirm</h3>
						<p>check your email for requests</p>
					</div>
					<div>
						<Eleven />
						<h3>deliver</h3>
						<p>meet up or opt for delivery</p>
					</div>
					<div>
						<Twelve />
						<h3>get paid</h3>
						<p>and leave a review for your borrower</p>
					</div>
				</div>
				<h5 id="how-to-faqs">
					FAQs
					<div />
				</h5>
				<Faq styleProps="howitworksfaq" />
			</div>
		</div>
	);
};

export default HowItWorks;
