import React, { useRef, useEffect } from 'react';

function VideoPlayer(props) {
	const vidRef = useRef(null);
	const handlePlayVideo = () => {
		// console.log('here - vidRef', vidRef)
		vidRef.current.play();
	};

	useEffect(() => {
		// console.log('firing')
		vidRef?.current.play();
	});
	return (
		<div>
			<video
				ref={vidRef}
				width="100%"
				height="100%"
				preload="auto"
				source
				src={props.src}
				type="video/mp4"
				webkit-playsinline="true"
				playsInline="true"
				autoPlay="true"
				muted="muted">
				{/* <source src={props.src} type="video/mp4" /> */}
			</video>
			{/* <button type="button"  onClick={handlePlayVideo}>Play!</button> */}
			{/* <div type="button" id='pauseButton' onClick={this.props.pauseVideo}>Pause!</div> */}
		</div>
	);
}

export default VideoPlayer;
