import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
const BASE_CLASS = 'promo';
import { Promo1, Promo2, Promo3 } from '../../../public/icons/index';

const Promo = () => {
	return (
		<div className={BASE_CLASS}>
			<div className={`${BASE_CLASS}__title`}>
				hey, <span>Columbia!</span>{' '}
			</div>
			<h3>Rent a gown for gala, for free.</h3>
			<div className={`${BASE_CLASS}__text`}>
				<h4>
					Social Closet is a place for New Yorkers to rent and lend their wardrobes.
					<p />
					We’re based out of the Columbia Startup Lab, so we’re having events on campus for Columbia
					students to rent dresses for upcoming galas for free.
					<p />
					Here is how it works:
				</h4>
				<img src="Columbia.jpg" />
			</div>
			<div className={`${BASE_CLASS}__cards`}>
				<div>
					<Promo1 />
					<h3>1. reserve now</h3>
					<p>
						find a dress you like and request to rent it. <br /> <br />
						(don’t worry about payment and meeting up -- just request your dress for a week so we
						can bring it to campus for you)
					</p>
					<Link to="/rent">Find a dress</Link>
				</div>
				<div>
					<Promo2 />
					<h3>2. pick up on campus</h3>
					<p>
						outside the gates on Broadway and 116th
						<br />
						you’ll Venmo us a $5 security deposit
					</p>
				</div>
				<div>
					<Promo1 />
					<h3>3. tag us</h3>
					<p>
						follow us on Instagram @mysocialcloset <br />
						and your rental is free!
					</p>
				</div>
				<div>
					<Promo3 />
					<h3>4. drop off on campus</h3>
					<p>
						outside the gates on Broadway and 116th <br />
						we’ll refund your $5 security deposit
					</p>
				</div>
			</div>
			<Link to="/rent">Find a dress</Link>
			<h4>Pick up and drop off your dress on one of these dates:</h4>

			<p>
				Saturday,<b>April 23:</b> Noon - 2pm <br />
				Wednesday,<b>April 27:</b> Noon - 1pm <br />
				Saturday,<b>April 30:</b> Noon - 2pm <br />
				Wednesday, <b>May 4:</b> Noon - 1pm <br />
				Saturday,<b>May 7:</b> Noon - 2pm
				<br />
				Saturday,<b>May 13:</b> Noon - 2pm <br />
				Wednesday, <b>May 17:</b> Noon - 1pm <br />
				<br />
				Meet us on 116th and Broadway in front of the gates.
			</p>
		</div>
	);
};

export default Promo;
