import axios from 'axios';

const initialState = {
	rentals: [],
};

const GET_ITEM_TO_LEND_RENTALS = 'GET_ITEM_TO_LEND_RENTALS';
const GOT_ALL_LENDER_RENTALS = 'GOT_ALL_LENDER_RENTALS';
const GOT_ALL_BORROWER_RENTALS = 'GOT_ALL_BORROWER_RENTALS';
const GOT_RENTAL = 'GOT_RENTAL';
const GOT_MESSAGES = 'GOT_MESSAGES';

// action creators
const gotRentals = (rentals) => ({ type: GET_ITEM_TO_LEND_RENTALS, rentals });
const gotAllLenderRentals = (rentals) => ({ type: GOT_ALL_LENDER_RENTALS, rentals });
const gotAllBorrowerRentals = (rentals) => ({ type: GOT_ALL_BORROWER_RENTALS, rentals });
const gotRental = (rental) => ({ type: GOT_RENTAL, rental });
const gotUpdatedMessages = (rentalWithUpdatedMessages) => ({
	type: GOT_MESSAGES,
	rentalWithUpdatedMessages,
});

// thunk creators

export const getAllBorrowerRentals = () => {
	return async (dispatch) => {
		try {
			const res = await axios.get(`/api/rentals`);
			dispatch(gotAllBorrowerRentals(res.data.borrowerRentals));
		} catch (err) {
			next(err);
		}
	};
};

export const getAllLenderRentals = () => {
	return async (dispatch) => {
		try {
			const res = await axios.get(`/api/rentals`);
			dispatch(gotAllLenderRentals(res.data.lenderRentals));
		} catch (err) {
			next(err);
		}
	};
};

export const getItemToLendRentals = (articleId) => {
	return async (dispatch) => {
		try {
			const res = await axios
				.get(`/api/rentals/${articleId}`)
				.then((res) => getBorrowerReviews(res.data));
			const rentals = res;

			dispatch(gotRentals(rentals));
		} catch (err) {
			console.error(err);
		}
	};
};

async function getBorrowerReviews(rentals) {
	let rentalArray = [];
	for (let i = 0; i < rentals.length; i++) {
		let otherBorrowerRentals = await axios.get(`/api/rentals/borrower/${rentals[i].borrowerId}`);

		let borrowerAverageConditionReview = otherBorrowerRentals.data
			.map((rental) => (rental.lenderConditionReview ? rental.lenderConditionReview : null))
			.reduce((prev, curr) => prev + curr, 0);

		let borrowerAverageDeliveryReview = otherBorrowerRentals.data
			.map((rental) => (rental.lenderDeliveryReview ? rental.lenderDeliveryReview : null))
			.reduce((prev, curr) => prev + curr, 0);

		let newRental = {
			...rentals[i],
			borrowerAverageConditionReview,
			borrowerAverageDeliveryReview,
		};

		rentalArray.push(newRental);
	}

	return rentalArray;
}

export const getRental = (rentalId) => {
	return async (dispatch) => {
		try {
			const res = await axios.get(`/api/rentals/rental/${rentalId}`);
			dispatch(gotRental(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

export const addMessage = (message, rentalId, articleId) => {
	return async (dispatch, getState) => {
		try {
			const res = await axios.put(`/api/rentals/${articleId}`, {
				...message,
				rentalId,
				articleId,
				action: 'sendMessage',
			});
			dispatch(gotUpdatedMessages(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

// reducer:
export default function (state = initialState, action) {
	switch (action.type) {
		case GET_ITEM_TO_LEND_RENTALS:
			return { ...state, rentals: action.rentals };
		case GOT_ALL_LENDER_RENTALS:
			return { ...state, lenderRentals: action.rentals };
		case GOT_ALL_BORROWER_RENTALS:
			return { ...state, borrowerRentals: action.rentals };
		case GOT_RENTAL:
			return { ...state, rental: action.rental };
		case GOT_MESSAGES:
			return { ...state, rental: action.rentalWithUpdatedMessages };
		default:
			return state;
	}
}
