import axios from 'axios';

/*
 * INITIAL STATE
 */

// todo: should initial state just be empty array to avoid ...
const initialState = {
	// defaultUser: {},
	dresses: [],
	dress: {},
	publicArticles: [],
	storedDresses: [],
};

/**
 * ACTION TYPES
 */
const GET_DRESSES = 'GET_DRESSES';
const GET_SINGLE_DRESS = 'GET_SINGLE_DRESS';
const ADDED_DRESS = 'ADDED_DRESS';
const UPDATED_DRESS = 'UPDATED DRESS';
const ADD_WEAR = 'ADD_WEAR';
const GET_PUBLIC_ARTICLES = 'GET_PUBLIC_ARTICLES';
const GET_STORED_DRESSES = 'GET_STORED_DRESSES';

/**
 * ACTION CREATORS
 */
const gotDresses = (dresses) => ({ type: GET_DRESSES, dresses });
const gotStoredDresses = (dresses) => ({ type: GET_STORED_DRESSES, dresses });
const gotAddedDress = (dress) => ({ type: ADDED_DRESS, dress });
const gotDress = (dress) => ({ type: GET_SINGLE_DRESS, dress });
const gotUpdatedDress = (dress) => ({ type: UPDATED_DRESS, dress });
const gotDressToIncrement = (dressId) => ({ type: ADD_WEAR, dressId });
const gotPublicArticles = (articles) => ({ type: GET_PUBLIC_ARTICLES, articles });

/**
 * THUNK CREATORS
 */

export const getDresses = () => {
	return async (dispatch, getState) => {
		try {
			const res = await axios.get(`/api/closet`);
			dispatch(gotDresses(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

export const getStoredDresses = () => {
	return async (dispatch, getState) => {
		try {
			const res = await axios.get(`/api/closet/stored`);
			dispatch(gotStoredDresses(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

export const getPublicArticles = () => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			// make diffrent api call depending on whether user is logged in or not
			let res = await axios.get(`/api/closets/unauth`);

			// TODO: was filtering out a user's own items -- changed to show all items based on lender wanting to check out her own listings
			// if (state.user && state.user.user && state.user.user.id) {
			// 	res = await axios.get(`/api/closets`);
			// 	// TODO: figure out if rental reviews are ever populated or needed?
			// 	// .then((res) => getRentals(res.data));
			// } else {
			// 	res = await axios.get(`/api/closets/unauth`);
			// 	// getRentals.then((res) => getRentals(res.data));
			// }

			dispatch(gotPublicArticles(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

async function getRentals(articles) {
	let arr = [];

	for (let i = 0; i < articles.length; i++) {
		let articleRentals = await axios.get(`/api/rentals/${articles[i].id}`);

		let articleOverallReview = articleRentals.data
			.map((rental) => (rental.borrowerOverallReview ? rental.borrowerOverallReview : null))
			.reduce((prev, curr) => prev + curr, 0);

		let articleDescriptionReview = articleRentals.data
			.map((rental) => (rental.borrowerDescriptionReview ? rental.borrowerDescriptionReview : null))
			.reduce((prev, curr) => prev + curr, 0);

		let articleDeliveryReview = articleRentals.data
			.map((rental) => (rental.borrowerDeliveryReview ? rental.borrowerDeliveryReview : null))
			.reduce((prev, curr) => prev + curr, 0);

		let newArticle = {
			...articles[i],
			borrowerOverallReview: articleOverallReview,
			borrowerDescriptionReview: articleDescriptionReview,
			borrowerDeliveryReview: articleDeliveryReview,
		};
		arr.push(newArticle);
	}

	return arr;
}

export const getSingleDress = (dressId) => {
	return async (dispatch, getState) => {
		try {
			const res = await axios.get(`/api/closet/${dressId}`);

			dispatch(gotDress(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

// utility

function randomIntFromInterval(min, max) {
	return Math.random() * (max - min) + min;
}

export const addDress = (dress) => {
	var getGeocode;
	return (dispatch, getState) => {
		// TODO: set location to random lat / lng between northeast / southwest bounds of lat / lng
		getGeocode = async function (zip) {
			let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=AIzaSyChKTCF8jV64Nj8pm4VJkoZuNz4NVLqbAA`;
			const response = await axios.get(url);
			const data = response.data.results[0];
			if (data) {
				const position = {
					lat: Number(data.geometry.location.lat.toPrecision(6)) + Number(Math.random() * 0.013),
					lng: Number(data.geometry.location.lng.toPrecision(6)) + Number(Math.random() * 0.013),
				};
				return [position.lat, position.lng];
			} else {
				return [40.74743, -73.98888];
			}
		};

		try {
			getGeocode(dress.lenderZipCode).then((coordsArr) => {
				const { data } = axios.post(`/api/closet`, { ...dress, lenderLatLng: coordsArr });
				dispatch(gotAddedDress(data));
			});
		} catch (err) {
			console.error(err);
		}
	};
};

export const updateDress = (dress, id) => {
	const dressId = id;
	var getGeocode;
	return async (dispatch, getState) => {
		// if there's a zip code, format it to be random:
		// if (dress.lenderZipCode) {
		// 	getGeocode = async function (zip) {
		// 		const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=AIzaSyChKTCF8jV64Nj8pm4VJkoZuNz4NVLqbAA`;
		// 		const response = await axios.get(url);
		// 		const data = response.data.results[0];

		// 		const position = {
		// 			lat: Number(data.geometry.location.lat.toPrecision(6)) + Number(Math.random() * 0.013),
		// 			lng: Number(data.geometry.location.lng.toPrecision(6)) + Number(Math.random() * 0.013),
		// 		};

		// 		return [position.lat, position.lng];
		// 	};

		// 	try {
		// 		getGeocode(dress.lenderZipCode).then((coordsArr) => {
		// 			const { data } = axios.put(`/api/closet/${dressId}/edit`, {
		// 				...dress,
		// 				lenderLatLng: coordsArr,
		// 			});
		// 			dispatch(gotUpdatedDress(data));
		// 		});
		// 	} catch (err) {
		// 		console.error(err);
		// 	}
		// } else {

		try {
			const { data } = await axios.put(`/api/closet/${dressId}/edit`, dress);

			dispatch(gotUpdatedDress(data));
		} catch (err) {
			console.error(err);
		}
		// }
	};
};

export const updateStorageList = (ids) => {
	return async (dispatch, getState) => {
		try {
			for (let i = 0; i < ids.length; i++) {
				const { data } = await axios.put(`/api/closet/${ids[i]}/edit`, { putItemsInStorage: true });

				// const itemsNotStored = data.filter((item) => item.list.indexOf('stored') === -1);
				// dispatch(gotDresses(itemsNotStored));
			}
		} catch (err) {
			console.error(err);
		}
	};
};

export const updateList = (ids, list) => {
	return async (dispatch, getState) => {
		try {
			for (let i = 0; i < ids.length; i++) {
				const { data } = await axios.put(`/api/closet/${ids[i]}/edit`, { list: list });

				// const itemsNotStored = data.filter((item) => item.list.indexOf('stored') === -1);
				// dispatch(gotDresses(itemsNotStored));
			}
		} catch (err) {
			console.error(err);
		}
	};
};

export const removeFromStorageList = (ids) => {
	return async (dispatch, getState) => {
		try {
			for (let i = 0; i < ids.length; i++) {
				const { data } = await axios.put(`/api/closet/${ids[i]}/edit`, {
					removeItemsFromStorage: true,
				});
			}
		} catch (err) {
			console.error(err);
		}
	};
};

export const addComment = (dress, id) => {
	const dressId = id;
	return async (dispatch, getState) => {
		try {
			const { data } = await axios.put(`/api/closet/${dressId}/comment`, dress);
			dispatch(gotUpdatedDress(data));
		} catch (err) {
			console.error(err);
		}
	};
};

export const addWear = (dressId) => {
	return async (dispatch, getState) => {
		try {
			const { data } = await axios.put(`/api/closet/${dressId}`, { dressId, addWear: true });
			dispatch(gotDressToIncrement(data));
		} catch (err) {
			console.error(err);
		}
	};
};

/**
 * REDUCER
 */
export default function (state = initialState, action) {
	switch (action.type) {
		case GET_DRESSES:
			return { ...state, dresses: action.dresses };
		case GET_STORED_DRESSES:
			return { ...state, storedDresses: action.dresses };
		case GET_SINGLE_DRESS:
			return { ...state, dress: action.dress };
		case ADDED_DRESS:
			return { ...state, dress: action.dress };
		case UPDATED_DRESS: {
			return { ...state, dress: action.dress };
		}
		case ADD_WEAR: {
			return { ...state, dressId: action.dressId };
		}
		case GET_PUBLIC_ARTICLES: {
			return { ...state, publicArticles: action.articles };
		}
		default:
			return state;
	}
}
