import React from 'react';
import reactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './history';
import store from './store';
import App from './app';
import ScrollToTop from './scrollToTop';

// establishes socket connection
import './socket';

reactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<ScrollToTop />
			<App />
		</Router>
	</Provider>,
	document.getElementById('app')
);
