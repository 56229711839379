import React from 'react';

const Arrows = () => (
	<svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.85171 10.1542C9.58506 10.5032 9.65177 11.0022 10.0007 11.2688C10.3496 11.5355 10.8486 11.4688 11.1153 11.1198L9.85171 10.1542ZM33.1761 13.6956L32.6019 4.53207L24.9531 9.61115L33.1761 13.6956ZM11.1153 11.1198C13.7571 7.66273 16.7288 4.54522 19.6977 3.45177C21.1421 2.91976 22.5569 2.87783 23.9546 3.49186C25.374 4.11545 26.8691 5.45674 28.3664 7.87546L29.7186 7.0384C28.1213 4.45812 26.4095 2.83339 24.5942 2.03589C22.7572 1.22884 20.9094 1.31075 19.1481 1.95948C15.7052 3.2275 12.4921 6.69903 9.85171 10.1542L11.1153 11.1198ZM28.3664 7.87546C28.4267 7.97287 28.4875 8.06903 28.5485 8.16405L29.8863 7.30425C29.8299 7.21643 29.774 7.12784 29.7186 7.0384L28.3664 7.87546Z"
			fill="white"
		/>
		<path
			d="M36.1564 16.4557C36.0225 16.0374 35.575 15.8069 35.1567 15.9407C34.7385 16.0745 34.5079 16.5221 34.6417 16.9403L36.1564 16.4557ZM19.9159 33.5676L27.7625 38.3354L27.9682 29.1561L19.9159 33.5676ZM34.6417 16.9403C35.9677 21.0843 36.8265 25.3048 36.0263 28.3657C35.637 29.855 34.8626 31.0397 33.5597 31.8354C32.2366 32.6434 30.2813 33.1033 27.4408 32.9489L27.3545 34.5369C30.3847 34.7016 32.6964 34.226 34.3886 33.1926C36.101 32.1468 37.0901 30.584 37.5649 28.768C38.4929 25.2183 37.4816 20.5974 36.1564 16.4557L34.6417 16.9403ZM27.4408 32.9489C27.3264 32.9427 27.2128 32.9376 27.1 32.9333L27.0405 34.5225C27.1449 34.5264 27.2495 34.5312 27.3545 34.5369L27.4408 32.9489Z"
			fill="white"
		/>
		<path
			d="M16.2793 35.6682C16.7127 35.739 17.1215 35.4451 17.1923 35.0117C17.2631 34.5783 16.9692 34.1696 16.5358 34.0987L16.2793 35.6682ZM8.41555 13.4257L0.602975 18.249L8.68634 22.6032L8.41555 13.4257ZM16.5358 34.0987C12.2418 33.397 8.09199 32.2442 5.72891 30.1405C4.57918 29.117 3.87573 27.8888 3.75895 26.3667C3.64036 24.8209 4.11917 22.8702 5.54736 20.41L4.17202 19.6116C2.64844 22.2361 2.02166 24.5114 2.17333 26.4883C2.32682 28.4889 3.26951 30.0802 4.67149 31.3283C7.41184 33.7679 11.9877 34.9668 16.2793 35.6682L16.5358 34.0987ZM5.54736 20.41C5.60487 20.3109 5.6611 20.2121 5.71615 20.1134L4.32751 19.3384C4.27663 19.4295 4.22484 19.5206 4.17202 19.6116L5.54736 20.41Z"
			fill="white"
		/>
	</svg>
);

export default Arrows;
