import React from 'react';
import { RadialChart } from 'react-vis';

function CategoriesPieChart(props) {
	const { data } = props;

	return (
		<RadialChart
			getLabel={(d) => d.name}
			data={data}
			colorType="literal"
			labelsRadiusMultiplier={0.9}
			labelsStyle={{
				fontSize: 12,
				fill: 'white',
				textTransform: 'uppercase',
				letterSpacing: '1.8',
				fontWeight: 600,
			}}
			width={300}
			height={300}
			animation={true}
			showLabels={true}
			style={{ marginBottom: 22 }}
		/>
	);
}

export default CategoriesPieChart;
