const categories = [
	'top',
	'bottom',
	'outerwear',
	'shoes',
	'dress',
	'accessories',
	'active',
	'layer',
	'bundle',
];

const deliveryOptions = ['ship only', 'pick up only', 'ship or pick up'];

const sizes = [
	'XXS',
	'XS',
	'S',
	'M',
	'L',
	'XL',
	'XXL',
	'0',
	'2',
	'4',
	'6',
	'8',
	'10',
	'12',
	'14',
	'16',
	'5',
	'5.5',
	'6',
	'6.5',
	'7',
	'7.5',
	'8',
	'8.5',
	'9',
	'9.5',
	'10',
];

const subcategoryConstants = {
	top: [
		'Blouse',
		'Tunic',
		'Short Sleeve',
		'Long Sleeve',
		'3/4 Sleeve',
		'Tank / Cami',
		'Off Shoulder',
		'Cropped',
		'T-shirt',
		'Strapless',
		'Bodysuit',
		'Turtleneck',
		'Halter',
	],
	bottom: [
		'All',
		'Pants',
		'Shorts',
		'Denim',
		'Skirt',
		'Wide leg',
		'Skinny',
		'Flared',
		'Boyfriend',
		'Corduroy',
		'Maxi',
		'Mini',
		'Knee-length',
		'High-rise',
		'Low-rise',
		'Medium-rise',
	],
	dress: [
		'Romper / Jumpsuit',
		'Mini',
		'Knee-length',
		'Long',
		'Strapless',
		'Sleeveless',
		'Short-sleeve',
		'Long-sleeve',
		'Plunge',
		'Halter',
		'Shift',
		'High-low',
		'Wrap',
	],

	outerwear: [
		'Blazer',
		'Fur / Faux Fur',
		'Leather',
		'Jacket',
		'Coat',
		'Denim',
		'Puffer',
		'Trench',
		'Vest',
		'Motorcycle',
		'Peacoat',
		'Windbreaker',
		'Parka',
	],
	shoes: ['All', 'Boots', 'Flats', 'Sandals', 'Heels', 'Athletic'],
	accessories: ['Handbag', 'Jewellry', 'Scarf', 'Sunglasses', 'Hat', 'Glove', 'Watch', 'Belt'],
	active: ['Top', 'Outerwear', 'Shoes', 'Sweatshirt', 'Shorts', 'Pants', 'Accessory'],
	layer: ['Pullover', 'Cardigan', 'Turtleneck', 'Cropped', 'Sweatshirt', 'Tunic'],
	bundle: ['Outfit', 'Bundle'],
};

const staticTags = [
	['summer', 'fall', 'winter', 'spring'],
	['wedding guest', 'party', 'formal', 'going out', 'cocktail', 'work', 'casual', 'vacation'],
	['animal print', 'floral', 'color block', 'plaid', 'polka dots', 'striped', 'solid', 'pattern'],
	[
		'lace',
		'sequin',
		'velvet',
		'cotton',
		'wool',
		'leather',
		'fur',
		'silk',
		'rayon',
		'linen',
		'nylon',
		'polyester',
		'cashmere',
		'spandex',
	],
];

const colors = [
	// 'Red',
	// 'Orange',
	// 'Yellow',
	// 'Green',
	// 'Blue',
	// 'Purple',
	// 'Black',
	// 'White',
	// 'Brown',
	// 'Neutrals',
	// 'Silver / Grey',
	// 'Gold',
	// 'Not Specified',
	// 'Other',
	// 'Pink',
	// 'Multicolor',
	'red',
	'pink',
	'orange',
	'yellow',
	'green',
	'blue',
	'purple',
	'brown',
	'neutrals',
	'silver',
	'gold',
	'black',
	'white',
	'multicolor',
	'other',
	'not specified',
];

const brands = [
	'& Other Stories',
	'1.State',
	'525 america',
	'7 For All Mankind',
	'A.L.C.',
	'A|X Armani Exchange',
	'Abercrombie & Fitch',
	'ABS by Allen Schwartz',
	'Acne Studios',
	'adidas',
	'Adrianna Papell',
	'Adrienne Vittadini',
	'Aerosoles',
	'AG - Adriano Goldschmied',
	'Akris',
	'Alberta Ferretti',
	'Alexander McQueen',
	'Alice + Olivia',
	'AllSaints ',
	'Alo Yoga',
	'American Apparel',
	'American Eagle Outfitters',
	'Andrew Marc',
	'Ann Taylor',
	'Anne Klein',
	'Anthropologie',
	'Apostrophe',
	'Apt 9',
	'Aqua',
	'Aritzia',
	'Asos',
	'Badgley Mischka',
	'Bailey 44',
	'Balenciaga',
	'Balmain',
	'Banana Republic',
	'Bandolino',
	'Bar III',
	'Bashian',
	'BB Dakota',
	'BCBGeneration',
	'BCBG Maxazria',
	'bebe',
	'Bershka',
	'Betsey Johnson',
	'Billabong',
	'BISOU BISOU',
	'Blank NYC',
	'Blue',
	'Bobbi Brown',
	'Boden',
	'BOSS',
	'Bottega Veneta',
	'Brahmin',
	'Brandy Melville',
	'Brooks Brothers',
	'Brunello Cucinelli',
	'Burberry',
	'Calvin Klein',
	'Calypso St. Barth',
	'Catherine Malandrino',
	'Chanel',
	'Chaps',
	'Charles by Charles David',
	'Chinese Laundry',
	'Chloé',
	'Clarks',
	'Club Monaco',
	'Cole Haan',
	'Columbia',
	'Comme Des Garçons',
	'Converse',
	'Cos',
	'Crocs',
	'Desigual',
	'Diane Von Furstenburg',
	'Dior',
	'Disney',
	'DKNY',
	'Dolce & Gabbana',
	'Dolce Vita',
	'Donald J Pliner',
	'Donna Morgan',
	'Dorothee Schumacher',
	'Dr. Martens',
	'Dr. Scholls',
	'Dress barn',
	'Dress The Population',
	'Dsquared2',
	'Easy Spirit',
	'Eileen Fisher',
	'Eleventy',
	'Elie Tahari',
	'Elie Tahari',
	'Eliza J',
	'elizabeth and james',
	'Elk Lighting',
	'Ella Moss',
	'Ellen Tracy',
	'Ellen Tracy',
	'Embellished',
	'Emilio Pucci',
	'Emporio Armani',
	'English Laundry',
	'Equipment',
	'Ermanno Scervino',
	'Ermenegildo Zegna',
	'Esprit',
	'Etro',
	'Eva Franco',
	'Fabletics',
	'Faded Glory',
	'Fame',
	'Fendi',
	'Fergie',
	'FitFlop',
	'Florsheim',
	'FLOS',
	'For Love & Lemons',
	'Forever 21',
	'Fossil',
	'Fox',
	'Foxcroft',
	'Frame Denim',
	"Francesca's",
	'Franco Sarto',
	'Free People',
	'French Connection',
	'Fresca',
	'Freya',
	'Fruit Of The Loom',
	'Frye',
	'Furla',
	'G-Star',
	'Gabby Skye',
	'Gabriela Hearst',
	'Gap',
	'Geox',
	'Giambattista Valli',
	'Giorgio Armani',
	'Giuseppe Zanotti',
	'Givenchy',
	'Glamorous',
	'Gloria Vanderbilt',
	'Golden Goose',
	'Gucci',
	'Guess',
	'Guidi',
	'H by Halston',
	'H+M',
	'Halogen',
	'Halston Heritage',
	'Hanes',
	'Harley-Davidson',
	'Haute Hippie',
	'Havaianas',
	'Helmut Lang',
	'Hermès',
	'Herschel',
	'Herve Leger',
	'Hobo',
	'Hogan',
	'House of Harlow',
	'Hudson',
	'HUGO BOSS',
	'Hurley',
	'INC International Concepts',
	'Intermix',
	'Isaac Mizrahi',
	'Isabel Marant',
	'Issey Miyake',
	'Ivanka Trump',
	'Izod',
	'J Brand',
	'J Kara',
	'J.Crew',
	'Jack & Jones',
	'Jason Wu',
	'Jay Godfrey',
	'Jean Paul Gaultier',
	'Jenny Too',
	'Jessica Howard',
	'Jessica Simpson',
	'Jil Sander',
	'Jimmy Choo London',
	'Joan Vass',
	'Jockey',
	'Joe Fresh',
	"Joe's",
	'John Hardy',
	'John Varvatos',
	'Joie',
	'Jonathan Simkhai',
	'Jones New York',
	'Josef Seibel',
	'Joseph',
	'Juicy Couture',
	'Just Cavalli',
	'Justice Design',
	'Karen Millen',
	'Karen Scott',
	'Karl Lagerfeld',
	'Kate Spade New York',
	'Keds',
	'Kendra Scott',
	'Kenneth Cole',
	'Kenneth Cole Reaction',
	'Kensie',
	'Kensie Girl',
	'Kimchi Blue',
	'Lacoste',
	'Lafayette 148 New York',
	'Lands End',
	'Lanvin',
	'Lark & Ro',
	'Laundry by Shelli Segal',
	'Laura Ashley',
	'Lauren Ralph Lauren',
	'Leifsdottir',
	'Lela Rose',
	"Levi's",
	'Likely',
	'Lilly Pulitzer',
	'Loft',
	'Lord and Taylor',
	'Lou and Grey',
	'Lucky Brand',
	'Madewell',
	'Maeve',
	'Mango',
	'Mara Hoffman',
	'Marc Jacobs',
	'Marc Joseph New York',
	'Marni',
	'Max Mara',
	'Michael Kors',
	'Michael Michael Kors',
	'Michael Stars',
	'Missguided',
	'Missoni',
	'Miu Miu',
	'ModCloth',
	'ModWay',
	'Nanette Lepore',
	'Natori',
	'Naturalizer',
	'Nautica',
	'New Balance',
	'New Look',
	'Nic+Zoe',
	'Nicole Miller',
	'Nike',
	'Nili Lotan',
	'Nine West',
	'Opening Ceremony',
	'Other',
	'Oscar De La Renta',
	'Paige',
	'Parker',
	'Patagonia',
	'Pink Rose',
	'Pinko',
	'Polo Ralph Lauren',
	'Prada',
	'Proenza Schouler',
	'Rachel Roy',
	'Rag & Bone',
	'Ralph Lauren',
	'Rampage',
	'Rebecca Minkoff',
	'Rebecca Taylor',
	'Reformation',
	'Reiss',
	'Roberto Cavalli',
	'Roxy',
	'Saint Laurent',
	'Salvatore Ferragamo',
	'Sam Edelman',
	'Skechers',
	'Sonia Rykiel',
	'Sperry Top-Sider',
	'Splendid',
	'Stella McCartney',
	'Steve Madden',
	'Stuart Weitzman',
	'Superdry',
	'SUPREME',
	'Tadashi Shoji',
	'Target',
	'Ted Baker',
	'The Limited',
	'The North Face',
	'Theory',
	'Tibi',
	'Timberland',
	"Tod's",
	'Tom Ford',
	'Tommy Bahama',
	'Tommy Hilfiger',
	'Toms',
	'Tory Burch',
	'Trina Turk',
	'True Religion',
	'U.S.Polo Association',
	'UGG',
	'Ulla Johnson',
	'Under Armour',
	'Unionbay',
	'Uniqlo',
	'Unique21',
	'Unlisted by Kenneth Cole',
	'Urban Outfitters',
	'Valentino',
	'Van Heusen',
	'Vans',
	'Velvet',
	'Vera Bradley',
	'Vera Wang',
	'Vero Moda',
	'Veronica Beard',
	'Versace',
	'VETEMENTS',
	'Via Spiga',
	'Vince',
	'Vince Camuto',
	'Vivienne Westwood',
	'Vogue',
	'Warehouse',
	'White House Black Market',
	'Wilifred',
	'Willow',
	'Young Fabulous & Broke',
	'Yumi Kim',
	'Zara',
	'Zac Posen',
	'Zadig & Voltaire',
	'Zimmermann',
	'Zuhair Murad',
];

export { brands, staticTags, sizes, categories, deliveryOptions, subcategoryConstants, colors };
