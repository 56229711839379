import React, { Component, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { addDress, getDresses } from '../../store/closet';
import { brands, staticTags, categories, sizes, subcategoryConstants, colors } from './utils/index';
import store from '../../store';
import AddSuccess from '../modals/AddSuccessModal';
import Calendar from 'react-calendar';
import Checkbox from '../atoms/Checkbox';
import './index.scss';

import ConfirmationModal from '../modals/ConfirmationModal';
import { Move, Trash } from '../../../public/icons';
import history from '../../../client/history';
import axios from 'axios';
import Back from '../../../public/icons/Back';

const BASE_CLASS = 'add-form';

// fake data generator
const getThumbnails = (urls) =>
	urls
		? Array.from({ length: urls?.length }, (v, k) => k).map((k) => ({
				id: `item-${k}`,
				url: urls[k],
		  }))
		: null;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

class AddContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			imageUrls: '',
			name: '',
			description: '',
			wearCount: '',
			cost: '',
			currentDailyRentalPrice: null,
			submitted: false,
			// submitted: true,
			category: '',
			subcategories: [],
			brand: '',
			error: '',
			thumbnailUrls: '',
			tags: [],
			items: [],
			size: '',
			public: false,
			rentable: false,
			sellable: false,
			comments: [],
			terms: false,

			availableForPickup: false,
			availableForShip: false,
			availableForShipping: false,
			hasRentalRequiredFieldError: false,
			hasRequiredFieldError: false,
			lenderZipCode: null,
			showStripeConfirmationModal: false,
			// showStripeCreateConnectedAccount: false,
			cleaningFeeAmount: null,
			colors: [],
			showPurchaseDatePicker: false,
			sellprice: null,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onInput = this.onInput.bind(this);
		this.getImageUrl = this.getImageUrl.bind(this);
		this.handleChangeArray = this.handleChangeArray.bind(this);
		this.isTagActive = this.isTagActive.bind(this);
		this.isCategoryActive = this.isCategoryActive.bind(this);
		this.toggleCheckbox = this.toggleCheckbox.bind(this);

		this.checkRentable = this.checkRentable.bind(this);
		this.handleConfirmAndCreateStripeAcct = this.handleConfirmAndCreateStripeAcct.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.handlePurchaseDateChange = this.handlePurchaseDateChange.bind(this);
	}

	componentDidMount() {
		store.dispatch(getDresses());
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	removeImage(e, item) {
		e.preventDefault();

		const urlToDelete = this.state.items.filter((i) => i.id === item.id)[0].url;
		const indexToRemove = this.state.thumbnailUrls.indexOf(urlToDelete);

		const items = this.state.items.filter((i) => i.id !== item.id);
		const firstHalf = this.state.thumbnailUrls.slice(0, indexToRemove);
		const secondHalf = this.state.thumbnailUrls.slice(indexToRemove + 1);
		const thumbnails = firstHalf.concat(secondHalf);

		const firstHalfImgs = this.state.imageUrls.slice(0, indexToRemove);
		const secondHalfImgs = this.state.imageUrls.slice(indexToRemove + 1);
		const newImages = firstHalfImgs.concat(secondHalfImgs);

		this.setState({
			items,
			thumbnailUrls: thumbnails,
			imageUrls: newImages,
		});
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			error: '',
			subCategoryOptions: subcategoryConstants[event.target.value],
		});
	}

	isTagActive(tag) {
		return this.state.tags.includes(tag);
	}

	isCategoryActive(tag) {
		return this.state.category === tag;
	}

	handleChangeArray(event) {
		// if an option is in the array, remove it; otherwise, add it
		return !this.state[event.target.name].includes(event.target.value)
			? this.setState({
					[event.target.name]: [...this.state[event.target.name], event.target.value],
			  })
			: this.setState({
					[event.target.name]: this.state[event.target.name].filter(
						(item) => item !== event.target.value
					),
			  });
	}

	onInput(id) {
		var val = document.getElementById(id).value;
		// TODO: add back for MVP v2
		// if (id === 'delivery') {
		// 	if (val === 'ship or pick up') {
		// 		this.setState({
		// 			availableForPickup: true,
		// 			availableForShip: true,
		// 		});
		// 	} else if (val === 'pick up only') {
		// 		this.setState({
		// 			availableForPickup: true,
		// 			availableForShip: false,
		// 		});
		// 	} else if (val === 'ship only') {
		// 		this.setState({
		// 			availableForPickup: false,
		// 			availableForShip: true,
		// 		});
		// 	}
		// }

		this.setState({
			[id]: val,
		});
	}

	toggleCheckbox(property) {
		if (property === 'rentable' && !this.state.user.user.stripeConnectedAccountId) {
			this.setState({
				showStripeConfirmationModal: true,
			});
		} else {
			this.setState({
				[property]: !this.state[property],
			});
		}
	}

	checkRentable(bool) {
		// if they agree to create a stripe acct in next step, we'll show connected account modal after item is submitted:
		this.setState({
			rentable: bool,
			showStripeConfirmationModal: false,
			// showStripeCreateConnectedAccount: bool,
		});
	}

	async handleConfirmAndCreateStripeAcct() {
		const userId = this.state.user.user.id;
		try {
			const accountLinks = await axios.put(`/api/users`, { createStripeAccount: true, userId });

			window.location.href = accountLinks.data;
		} catch (err) {
			next(err);
		}
	}

	handleSubmit(event) {
		event.preventDefault();

		if (
			!this.state.name ||
			!this.state.imageUrls ||
			!this.state.category ||
			!this.state.brand ||
			!this.state.size
		) {
			this.setState({
				error: 'Please enter required fields',
				hasRentalRequiredFieldError: true,
			});
		} else if (
			this.state.rentable &&
			(!this.state.terms || !this.state.lenderZipCode || !this.state.currentDailyRentalPrice)
		) {
			this.setState({
				error: 'Please accept the Terms and Conditions of lending on Social Closet',
				hasRentalRequiredFieldError: true,
			});
		} else if (
			!!this.state.rentable &&
			!(
				this.state.availableForPickup ||
				this.state.availableForShip ||
				this.state.availableForShipping
			)
		) {
			this.setState({
				error: 'Please choose at least one delivery option',
				hasRentalRequiredFieldError: true,
			});
		} else if (this.state.lenderZipCode && isNaN(this.state.lenderZipCode)) {
			this.setState({
				error: 'Please enter a number for your zip code (just digits)',
				hasRentalRequiredFieldError: false,
			});
		} else {
			const newDress = {
				// id: this.state.id,
				imageUrls: this.state.imageUrls || [],
				name: this.state.name,
				description: this.state.description,
				wearCount: Number(this.state.wearCount),
				cost: this.state.cost,
				// userId: req.session.passport.user,
				category: this.state.category,
				brand: this.state.brand,
				// latestWear: getWearDate(this.state.wearCount),
				tags: this.state.tags || [],
				size: this.state.size,
				public: this.state.public,
				rentable: this.state.rentable,
				currentDailyRentalPrice: Number(this.state.currentDailyRentalPrice),
				availableForPickup: this.state.availableForPickup,
				availableForShip: this.state.availableForShip,
				availableForShipping: this.state.availableForShipping,

				ownerEmail: this.state.user.user.email,
				lenderZipCode: this.state.lenderZipCode,
				thumbnailUrls: this.state.thumbnailUrls,
				wearCount: this.state.wearCount,
				// lenderLatLng: this.state.lenderLatLng,
				subcategories: this.state.subcategories || [],
				cleaningFeeAmount: this.state.cleaningFeeAmount,
				colors: this.state.colors?.toString(),
				sellable: this.state.sellable,
				datepurchased: this.state.datepurchased,
				sellprice: this.state.sellprice,
				// imageURL: this.state.video, // FIXME: this is video, not image
			};

			this.props.addDress(newDress);

			if (!this.state.user.user.stripeConnectedAccountId && this.state.rentable) {
				this.handleConfirmAndCreateStripeAcct();
			} else {
				// history.push('/lend');

				this.setState({
					imageUrls: '',
					name: '',
					description: '',
					wearCount: '',
					cost: '',
					submitted: true,
					lenderZipCode: '',
					// category: '',
					error: '',
					colors: [],
					hasRentalRequiredFieldError: false,
				});
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		}
	}

	showWidget = (widget) => {
		widget.open();
	};

	// checkUploadResult = (resultEvent) => {
	// 	if (resultEvent.event === 'success') {
	// 		// console.log(this.PaymentResponse.currentUser.id);
	// 		this.PaymentResponse.postPhoto({
	// 			user_id: this.props.currentUser.id,
	// 			caption: '',
	// 			url: resultEvent.info.secure_url,
	// 		}).then(this.props.history.push(`/profile`));
	// 	}
	// };

	getImageUrl(url) {
		let uploadIndex = url.indexOf('/upload');
		return url.slice(0, uploadIndex + 7) + '/a_ignore' + url.slice(uploadIndex + 7);
	}

	handlePurchaseDateChange(value, event) {
		this.setState({
			datepurchased: value,
		});
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(this.state.items, result.source.index, result.destination.index);
		const thumbnails = reorder(
			this.state.thumbnailUrls,
			result.source.index,
			result.destination.index
		);
		const images = reorder(this.state.imageUrls, result.source.index, result.destination.index);

		this.setState({
			items,
			thumbnailUrls: thumbnails,
			imageUrls: images,
		});
	}

	render() {
		let widget = window.cloudinary.createUploadWidget(
			{
				cloudName: 'micaelascloud',
				uploadPreset: 'ahb8abmz',
				showAdvancedOptions: true,
				maxImageHeight: 700,
				maxImageWidth: 700,
			},
			(error, result) => {
				if (!error && result && result.event === 'success') {
					// console.log('Done! Here is the image info: ', result, result.info);
					// TODO: if result includes movie ...
					// let image = result.info.video?.bit_rate ? null : result.info.url;
					// let thumbnail = result.info.thumbnail_url;
					// let video = result.info.video?.bit_rate ? result.info.url : null;
					let image = this.getImageUrl(result.info.url);
					let thumbnail = this.getImageUrl(result.info.thumbnail_url);
					this.setState({
						imageUrls: [...this.state.imageUrls, image],
						thumbnailUrls: [...this.state.thumbnailUrls, thumbnail],
						items: getThumbnails([
							...this.state.thumbnailUrls,
							this.getImageUrl(result.info.thumbnail_url),
						]),
						// video: video,
					});
				}
			}
		);

		return (
			<div className={BASE_CLASS}>
				{this.state.submitted && (
					<AddSuccess
						text="Article added."
						path="lend"
						// imageSrc={this.state.thumbnailUrls[0] || ''}
					/>
				)}

				{this.state.showStripeConfirmationModal ? (
					<ConfirmationModal
						header="Agree to get paid?"
						text="You'll be prompted to create an account with Stripe after uploading this item, so that you can get paid out when your item is rented out."
						handleAccept={() => this.checkRentable(true)}
						handleCancel={() => this.checkRentable(false)}
						cancelCtaText="Cancel, I won't make my item rentable for now"
						continueCtaText="Great, I'll create a Stripe account next"
					/>
				) : null}
				<button onClick={() => history.goBack()} className="back">
					<Back />
				</button>

				<h3>Add an item</h3>
				<div className={`${BASE_CLASS}__form`}>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="droppable" direction="horizontal">
							{(provided, snapshot) => (
								<div
									className={`${BASE_CLASS}__form__thumbnails`}
									ref={provided.innerRef}
									{...provided.droppableProps}>
									{this.state.items &&
										this.state.items.map((item, index) => (
											<Draggable key={item.id} draggableId={item.id} index={index}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}>
														<div className={`${BASE_CLASS}__form__thumbnails__container`}>
															<Move />
															<img src={item.url} />
															<button onClick={(e) => this.removeImage(e, item)}>
																<Trash />
															</button>
														</div>
													</div>
												)}
											</Draggable>
										))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

					{this.state.thumbnailUrls && this.state.thumbnailUrls.length ? (
						<div className={`${BASE_CLASS}__form__thumbnail`}>
							<img src={this.state.thumbnailUrls[0]} />
						</div>
					) : (
						<button
							type="button"
							className={classnames('upload', {
								'rental-required-field-error': this.state.hasRentalRequiredFieldError,
							})}
							onClick={() => this.showWidget(widget)}>
							{this.state.hasRentalRequiredFieldError
								? 'Please add at least one image *'
								: 'Upload images *'}
						</button>
					)}

					<h4>Description</h4>
					<small
						className={classnames({
							'rental-required-field-error': this.state.hasRentalRequiredFieldError,
						})}>
						ITEM NAME *
					</small>
					<input name="name" type="text" value={this.state.name} onChange={this.handleChange} />
					<small>DESCRIPTION</small>
					<input
						placeholder="add details about fit, sizing, flaws..."
						name="description"
						type="text"
						value={this.state.description}
						onChange={this.handleChange}
					/>

					<small
						className={classnames({
							'rental-required-field-error': this.state.hasRentalRequiredFieldError,
						})}>
						SIZE *
					</small>
					<input
						type="text"
						id="size"
						list="sizes"
						name="size"
						onChange={() => this.onInput('size')}
					/>
					<datalist id="sizes">
						{sizes.map((item, key) => (
							<option key={key} value={item} />
						))}
					</datalist>

					<small
						className={classnames({
							'rental-required-field-error': this.state.hasRentalRequiredFieldError,
						})}>
						BRAND *
					</small>
					<input
						placeholder="select or add your own"
						type="text"
						id="brand"
						list="datalist"
						name="brand"
						onChange={() => this.onInput('brand')}
					/>
					<datalist id="datalist">
						{brands.map((item, key) => (
							<option key={key} value={item} />
						))}
					</datalist>
					<small>ORIGINAL COST</small>
					<input
						name="cost"
						type="number"
						step="1"
						onChange={this.handleChange}
						value={this.state.cost}
					/>
					<small>DATE PURCHASED</small>
					<button
						type="button"
						className={`${BASE_CLASS}__form__tag-cta`}
						onClick={() => this.toggleCheckbox('showPurchaseDatePicker')}>
						{this.state.showPurchaseDatePicker ? 'Hide calendar' : 'Add date'}
					</button>

					{this.state.showPurchaseDatePicker && (
						<Calendar
							className={`${BASE_CLASS}__react-calendar-picker`}
							onClickDay={this.handlePurchaseDateChange}
							defaultActiveStartDate={this.state.today}

							// tileClassName={(activeStartDate, date, view) =>
							// 	this.displayPurchaseDateClass(activeStartDate, date, view)
							// }
						/>
					)}

					<small
						className={classnames({
							'rental-required-field-error': this.state.hasRentalRequiredFieldError,
						})}>
						{' '}
						CATEGORY *
					</small>
					{categories.map((item, key) => (
						<button
							key={key}
							value={item}
							name="category"
							type="button"
							className={classnames(`${BASE_CLASS}__form__category`, {
								[`${BASE_CLASS}__form__category--selected`]: this.isCategoryActive(item),
							})}
							onClick={this.handleChange}>
							{item}
						</button>
					))}
					{this.state.category ? (
						<>
							<small
								className={classnames({
									'rental-required-field-error': this.state.hasRentalRequiredFieldError,
								})}>
								SUBCATEGORY (pick all that apply)
							</small>

							{this.state.subCategoryOptions &&
								this.state.subCategoryOptions.map((item, key) => (
									<button
										key={key}
										value={item}
										name="subcategories"
										type="button"
										className={classnames(`${BASE_CLASS}__form__category`, {
											[`${BASE_CLASS}__form__category--selected`]:
												this.state.subcategories && this.state.subcategories.includes(item),
										})}
										onClick={this.handleChangeArray}>
										{item}
									</button>
								))}
						</>
					) : null}
					<small>COLOR *</small>
					{colors.map((color, key) => (
						<button
							key={key}
							value={color}
							name="colors"
							type="button"
							className={classnames(`${BASE_CLASS}__form__category`, 'colors', color, {
								[`colors--selected`]: this.state.colors?.includes(color),
							})}
							onClick={this.handleChangeArray}>
							{color}
						</button>
					))}
					<small>TAGS</small>
					{/* {prevTagArray.map((tag, key) => ( */}
					{staticTags.map((tagArr, key) => (
						<div className="border-line">
							{tagArr.map((tag, key) => (
								<button
									key={key}
									value={tag}
									name="tags"
									type="button"
									className={classnames(`${BASE_CLASS}__form__category`, {
										[`${BASE_CLASS}__form__category--selected`]: this.isTagActive(tag),
									})}
									onClick={this.handleChangeArray}>
									{tag}
								</button>
							))}
							<span />
						</div>
					))}

					<h4>Settings</h4>

					<Checkbox
						label="Make available to rent"
						onChange={() => this.toggleCheckbox('rentable')}
						checked={!!this.state.rentable}
						labelHasError={this.state.hasRentalRequiredFieldError}
					/>
					<Checkbox
						label="Open to offers to buy"
						onChange={() => this.toggleCheckbox('sellable')}
						checked={!!this.state.sellable}
					/>
					<Checkbox
						label="Make visible to friends?"
						onChange={() => this.toggleCheckbox('public')}
						checked={!!this.state.public}
						// labelHasError={this.state.hasRentalRequiredFieldError}
					/>

					<Checkbox
						onChange={() => this.toggleCheckbox('terms')}
						labelHasError={this.state.hasRentalRequiredFieldError}
						termsLink="/terms"
						checked={!!this.state.terms}
					/>

					<>
						<small
							className={classnames('lender', {
								'rental-required-field-error': this.state.hasRentalRequiredFieldError,
							})}>
							YOUR ZIP CODE *
						</small>
						<input
							type="number"
							id="lenderZipCode"
							name="lenderZipCode"
							step="1"
							placeholder="location of your rental"
							onChange={() => this.onInput('lenderZipCode')}
						/>
						<small
							className={classnames({
								'rental-required-field-error': this.state.hasRentalRequiredFieldError,
							})}>
							RENTAL FEE (cost per week) *
						</small>
						<input
							type="number"
							step="1"
							id="currentDailyRentalPrice"
							name="currentDailyRentalPrice"
							placeholder="fee for one-week rental"
							onChange={() => this.onInput('currentDailyRentalPrice')}
						/>{' '}
						<small
							className={classnames({
								'rental-required-field-error': this.state.hasRentalRequiredFieldError,
							})}>
							SELL PRICE (optional)
						</small>
						<input
							type="number"
							step="1"
							id="sellprice"
							name="sellprice"
							placeholder="optional selling price"
							onChange={() => this.onInput('sellprice')}
						/>
						<h4>Availability</h4>
						<p>
							Rentals begin Thursdays and end Mondays. Delivery and returns happen from Monday to
							Thursday, unless you agree with the borrower on another date.
						</p>
						<Checkbox
							label="Available for local pickup"
							onChange={() => this.toggleCheckbox('availableForPickup')}
							checked={!!this.state.availableForPickup}
							labelHasError={this.state.hasRentalRequiredFieldError}
						/>
						<p>The borrower meets you in your zip code.</p>
						<Checkbox
							label="Available for local delivery"
							onChange={() => this.toggleCheckbox('availableForShip')}
							checked={!!this.state.availableForShip}
							labelHasError={this.state.hasRentalRequiredFieldError}
						/>{' '}
						<p>The borrower pays for a delivery service to pick up and return the item.</p>
						<Checkbox
							label="Available for shipping"
							onChange={() => this.toggleCheckbox('availableForShipping')}
							checked={!!this.state.availableForShipping}
							labelHasError={this.state.hasRentalRequiredFieldError}
						/>
						<p>We'll send you a pre-paid shipping label.</p>
					</>

					{this.state.error && <div className={`${BASE_CLASS}__error`}>{this.state.error}</div>}
					<button
						className={`${BASE_CLASS}__form__submit`}
						type="button"
						onClick={this.handleSubmit}>
						submit
					</button>
				</div>
			</div>
		);
	}
}

const mapState = (state) => {
	return {
		dress: state.dress,
	};
};

const mapDispatch = (dispatch) => {
	return {
		addDress: (dress) => dispatch(addDress(dress)),
	};
};

export default connect(mapState, mapDispatch)(AddContainer);
