// import { useEffect } from 'react';
// import { withRouter } from 'react-router-dom';

// function ScrollToTop({ history }) {
// 	useEffect(() => {
// 		const unlisten = history.listen(() => {
// 			window.scrollTo({
// 				top: 0,
// 				behavior: 'smooth',
// 			});
// 		});
// 		return () => {
// 			unlisten();
// 		};
// 	}, []);

// 	return null;
// }

// export default withRouter(ScrollToTop);

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		// window.scrollTo(0, 0);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [pathname]);

	return null;
}
