import React, { useState, useEffect } from 'react';
import useUrlState from '@ahooksjs/use-url-state';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Loader from '../atoms/Loader';
import { subcategoryConstants } from '../Add/utils/index';
import {
	getDresses,
	getStoredDresses,
	addWear,
	updateStorageList,
	removeFromStorageList,
	updateList,
} from '../../store/closet';
import store from '../../store';
import { Close, Filter, Circle, CircleCheck } from '../../../public/icons';
import './index.scss';
import { me } from '../../store/user';

const BASE_CLASS = 'closet';

function Closet() {
	const closetItemsFromStore = useSelector((state) => state.closet.dresses);
	const storedItems = useSelector((state) => state.closet.storedDresses);
	const [filteredItems, setFilteredItems] = useState([]);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [subCategorySet, setSubcategorySet] = useState(null);
	const [closetItems, setClosetItems] = useState([]);
	const [storedArticles, setStoredArticles] = useState([]);
	const [sendToStorageIds, setSendToStorageIds] = useState([]);
	const [removeFromStorageIds, setRemoveFromStorageIds] = useState([]);
	const [isStorageOverlayVisible, setIsStorageOverlayVisible] = useState(false);
	const [isRemoveFromStorageOverlayVisible, setIsRemoveFromStorageOverlayVisible] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [showAddListOverlay, setShowAddListOverlay] = useState(false);
	const [addToListIds, setAddToListIds] = useState([]);
	const [listName, setListName] = useState('');
	const [userLists, setUserLists] = useState(null);
	const [showAddListModal, setShowAddListModal] = useState(false);

	const [filterState, setFilterState] = useUrlState({
		cate: '',
		searchTerm: '',
		subcat: '',
		sortOption: 'recent',
		list: '',
	});

	useEffect(() => {
		store.dispatch(getDresses());
		store.dispatch(me());
	}, []);

	useEffect(() => {
		setClosetItems(closetItemsFromStore);
	}, [closetItemsFromStore]);

	useEffect(() => {
		setStoredArticles(storedItems);
	}, [storedItems]);

	useEffect(() => {
		setCategoryOptions(Array.from(new Set(closetItems.map((item) => item.category))));

		setUserLists(
			Array.from(new Set(closetItems.map((item) => item.list).flat())).filter(
				(item) => item && item !== 'sellable' && item !== 'shippable'
			)
		);

		setSubcategorySet(
			filterState.cate
				? filterState.cate
						.split('-')
						.map((category) => subcategoryConstants[category])
						.flat()
				: null
		);
	}, [closetItems]);

	// FILTERING:
	useEffect(() => {
		const filteredArticles = closetItems
			.filter((item) => {
				return filterState.cate ? filterState.cate.split('-').includes(item.category) : item;
			})
			.filter((item) => {
				return filterState.subcat
					? filterState.subcat.split('*').some((cat) => {
							return !!item.subcategories && item.subcategories?.includes(cat);
					  })
					: item;
			})
			.filter((item) =>
				filterState.searchTerm
					? (item.name &&
							item.name.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1) ||
					  (item.brand &&
							item.brand?.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1) ||
					  (item.category &&
							item.category.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1) ||
					  (item.test &&
							item.test.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1) ||
					  (item.description &&
							item.description.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1)
					: item
			)
			.filter((item) => {
				return filterState.list
					? !!filterState.list.split('-').filter((i) => item.list && item.list.includes(i)).length
					: item;
			});

		if (!filterState.cate) {
			setFilterState({ subcat: undefined });
		}

		if (filterState.sortOption) {
			switch (filterState.sortOption) {
				case 'costHigh':
					filteredArticles.sort(function (a, b) {
						return b.cost - a.cost;
					});
					break;

				case 'costLow':
					filteredArticles.sort(function (a, b) {
						return a.cost - b.cost;
					});
					break;

				case 'leastWorn':
					filteredArticles.sort(function (a, b) {
						return a.wearCount - b.wearCount;
					});
					break;

				case 'mostWorn':
					filteredArticles.sort(function (a, b) {
						return b.wearCount - a.wearCount;
					});
					break;

				case 'firstAdded':
					filteredArticles.sort(function (a, b) {
						return b.wearCount - a.wearCount;
					});
					break;

				case 'recent':
					filteredArticles.sort(function (a, b) {
						return b.id - a.id;
					});
					break;
				default:
					filteredArticles.sort(function (a, b) {
						return b.id - a.id;
					});
			}
		}
		setFilteredItems(filteredArticles);
	}, [closetItems, filterState]);

	return (
		<div className={BASE_CLASS}>
			{showLoader && <Loader />}

			{!!showAddListModal ? (
				<>
					<div className={`${BASE_CLASS}__listname-container`} />
					<div className={`${BASE_CLASS}__listname-inner`}>
						<h3>What should we name this list?</h3>
						<p>“Spring break”, “date nights”, “casual fridays” ?</p>
						<div className="list-button-lockup">
							<input
								value={listName}
								onChange={(e) => setListName(e.target.value)}
								placeholder="List name"
							/>
							<button type="button" onClick={() => addToList(listName)}>
								Make list
							</button>
						</div>
						{!!userLists && (
							<>
								{' '}
								<h3>Or, add to your list:</h3>
								<div className="list-tags-lockup">
									{userLists.map(
										(l) =>
											!!l.length && (
												<button
													onClick={() => addToList(l)}
													className={classnames({
														selected: filterState?.list?.split('-')?.includes(l),
													})}>
													{l}
												</button>
											)
									)}
								</div>{' '}
							</>
						)}
					</div>
				</>
			) : null}

			<h2>Your Wardrobe</h2>

			<div className={`${BASE_CLASS}__body`}>
				<div className={classnames(`${BASE_CLASS}__menu`)}>
					<h4 className={`${BASE_CLASS}__hidden-on-mobile`}>Sort & Filter</h4>
					<div className={classnames(`${BASE_CLASS}__menu__top-mobile`)}>
						<select
							className={`${BASE_CLASS}__menu__button`}
							onChange={(e) => setFilterState({ sortOption: e.target.value })}>
							<option value="recent">Most recent</option>
							<option value="firstAdded">First added</option>
							<option value="costHigh">Cost: high to low</option>
							<option value="costLow">Cost: low to high</option>
							<option value="mostWorn">Most worn</option>
							<option value="leastWorn">Least worn</option>
						</select>
						<span
							onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
							className={`${BASE_CLASS}__hidden-on-desktop`}>
							<Filter />
						</span>
					</div>
					<input
						id="input-search"
						type="search"
						autoCorrect="true"
						autoComplete="true"
						value={filterState.searchTerm}
						onChange={(e) =>
							setFilterState({ searchTerm: e.target.value ? e.target.value : undefined })
						}
						placeholder="Search"
						className={`${BASE_CLASS}__hidden-on-mobile`}
					/>

					<div
						className={classnames(
							`${BASE_CLASS}__menu__filterbuttons`,
							`${BASE_CLASS}__hidden-on-mobile`
						)}>
						{!!categoryOptions.length &&
							categoryOptions.map(
								(category) =>
									!!category.length && (
										<button
											onClick={() => {
												setFilterState((s) => {
													let filtersArray = s.cate?.split('-');
													let filteredCategories = filtersArray.includes(category)
														? filtersArray.filter((cat) => cat !== category).join('-')
														: [...filtersArray, category].join('-');

													setSubcategorySet(
														filteredCategories
															.split('-')
															.map((category) => subcategoryConstants[category])
															.flat()
													);

													return {
														cate: filteredCategories ? filteredCategories : undefined,
													};
												});
											}}
											className={classnames({
												selected: filterState?.cate?.split('-')?.includes(category),
											})}>
											{category}
										</button>
									)
							)}
					</div>
					<div
						className={classnames(
							`${BASE_CLASS}__menu__filterbuttons`,
							`${BASE_CLASS}__hidden-on-mobile`
						)}>
						{!!filterState.cate &&
							subCategorySet?.map(
								(category) =>
									!!category && (
										<button
											onClick={() => {
												setFilterState((s) => {
													let filtersArray = s.subcat?.split('*');
													let filteredCategories = filtersArray.includes(category)
														? filtersArray.filter((cat) => cat !== category).join('*')
														: [...filtersArray, category].join('*');

													return {
														subcat: filteredCategories ? filteredCategories : undefined,
													};
												});
											}}
											className={classnames({
												selected: filterState.subcat.split('*').includes(category),
											})}>
											{category}
										</button>
									)
							)}
					</div>

					<div
						className={classnames(
							`${BASE_CLASS}__menu__filterbuttons`,
							`${BASE_CLASS}__hidden-on-mobile`
						)}>
						{!!filterState.cate &&
							subCategorySet?.map(
								(category) =>
									!!category && (
										<button
											onClick={() => {
												setFilterState((s) => {
													let filtersArray = s.subcat?.split('*');
													let filteredCategories = filtersArray.includes(category)
														? filtersArray.filter((cat) => cat !== category).join('*')
														: [...filtersArray, category].join('*');

													return {
														subcat: filteredCategories ? filteredCategories : undefined,
													};
												});
											}}
											className={classnames({
												selected: filterState.subcat.split('*').includes(category),
											})}>
											{category}
										</button>
									)
							)}
					</div>

					<h4 className={`${BASE_CLASS}__hidden-on-mobile`}>Organize</h4>
					{userLists && (
						<div
							className={classnames(
								`${BASE_CLASS}__menu__filterbuttons`,
								`${BASE_CLASS}__hidden-on-mobile`
							)}>
							{userLists.map(
								(l) =>
									!!l.length && (
										<button
											onClick={() => {
												setFilterState((s) => {
													let filtersArray = s.list?.split('-');
													let filteredCategories = filtersArray.includes(l)
														? filtersArray.filter((filter) => filter !== l).join('-')
														: [...filtersArray, l].join('-');

													return {
														list: filteredCategories ? filteredCategories : undefined,
													};
												});
											}}
											className={classnames({
												selected: filterState?.list?.split('-')?.includes(l),
											})}>
											{l}
										</button>
									)
							)}
						</div>
					)}
					{!!addToListIds.length ? (
						<button
							type="button"
							className={`${BASE_CLASS}__menu__button accent`}
							onClick={() => setShowAddListModal(!showAddListModal)}>
							Add to List
						</button>
					) : (
						<button
							type="button"
							className={`${BASE_CLASS}__menu__button black`}
							onClick={() => setShowAddListOverlay(!showAddListOverlay)}>
							Add to a List
						</button>
					)}

					{!!isFilterModalOpen && (
						<div
							className={classnames(`${BASE_CLASS}__menu__mobile-filter-menu`, {
								// 'mobile-filter-menu__visible': isFilterModalOpen,
							})}>
							<span onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}>
								<Close />
							</span>
							<h3>Filter & Search</h3>
							<input
								id="input-search"
								type="search"
								autoCorrect="true"
								autoComplete="true"
								value={filterState.searchTerm}
								onChange={(e) =>
									setFilterState({ searchTerm: e.target.value ? e.target.value : undefined })
								}
								placeholder="Search"
								// className={`${BASE_CLASS}__hidden-on-mobile`}
							/>
							{!!categoryOptions.length &&
								categoryOptions.map(
									(category) =>
										!!category.length && (
											<button
												onClick={() => {
													setFilterState((s) => {
														let filtersArray = s.cate?.split('-');
														let filteredCategories = filtersArray.includes(category)
															? filtersArray.filter((cat) => cat !== category).join('-')
															: [...filtersArray, category].join('-');

														setSubcategorySet(
															filteredCategories
																.split('-')
																.map((category) => subcategoryConstants[category])
																.flat()
														);

														return {
															cate: filteredCategories ? filteredCategories : undefined,
														};
													});
												}}
												className={classnames({
													selected: filterState?.cate?.split('-')?.includes(category),
												})}>
												{category}
											</button>
										)
								)}
							{userLists.map(
								(l) =>
									!!l.length && (
										<button
											onClick={() => {
												setFilterState((s) => {
													let filtersArray = s.list?.split('-');
													let filteredCategories = filtersArray.includes(l)
														? filtersArray.filter((filter) => filter !== l).join('-')
														: [...filtersArray, l].join('-');

													return {
														list: filteredCategories ? filteredCategories : undefined,
													};
												});
											}}
											className={classnames({
												selected: filterState?.list?.split('-')?.includes(l),
											})}>
											{l}
										</button>
									)
							)}

							<Link to="/add" className={classnames(`${BASE_CLASS}__menu__button`)}>
								Add item
							</Link>
							<Link to="/dashboard" className={classnames(`${BASE_CLASS}__menu__button accent`)}>
								Dashboard
							</Link>

							<div
								onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
								className={classnames(`${BASE_CLASS}__menu__button black`)}>
								apply filters
							</div>

							<button
								onClick={() =>
									setFilterState({
										cate: undefined,
										searchTerm: undefined,
										price: undefined,
										zip: undefined,
										color: undefined,
										brand: undefined,
										subcat: undefined,
										size: undefined,
										tags: undefined,
										deliveryOptions: undefined,
										dates: undefined,
									})
								}
								className={classnames(`${BASE_CLASS}__clear-button`)}>
								Clear all filters
							</button>
						</div>
					)}

					<h4 className={`${BASE_CLASS}__hidden-on-mobile`}>Store</h4>

					{!isRemoveFromStorageOverlayVisible && (
						<button
							type="button"
							className={`${BASE_CLASS}__menu__button`}
							onClick={() => {
								setSendToStorageIds([]);
								setIsStorageOverlayVisible(!isStorageOverlayVisible);
							}}>
							{sendToStorageIds.length ? 'Undo Storage' : 'Put in Storage'}
						</button>
					)}

					{!!sendToStorageIds.length && (
						<button
							type="button"
							className={`${BASE_CLASS}__menu__button accent`}
							onClick={() => putItemsInStorage()}>
							Send to Storage
						</button>
					)}

					{!isStorageOverlayVisible && (
						<button
							type="button"
							className={`${BASE_CLASS}__menu__button accent`}
							onClick={() => {
								getItemsFromStorage();
								setIsRemoveFromStorageOverlayVisible(!isRemoveFromStorageOverlayVisible);
							}}>
							{isRemoveFromStorageOverlayVisible ? 'Undo Storage' : 'Take from Storage'}
						</button>
					)}

					{!!removeFromStorageIds.length && (
						<button
							type="button"
							className={`${BASE_CLASS}__menu__button black`}
							onClick={() => {
								removeItemsFromStorage();
								setIsRemoveFromStorageOverlayVisible(!isRemoveFromStorageOverlayVisible);
							}}>
							Send Items Back to Closet
						</button>
					)}
					<h4 className={`${BASE_CLASS}__hidden-on-mobile`}>Upload & Track</h4>
					<Link
						to="/add"
						className={classnames(
							`${BASE_CLASS}__menu__button`,
							`${BASE_CLASS}__hidden-on-mobile`
						)}>
						Add item
					</Link>
					<Link
						to="/dashboard"
						className={classnames(
							`${BASE_CLASS}__menu__button accent`,
							`${BASE_CLASS}__hidden-on-mobile`
						)}>
						Dashboard
					</Link>

					<button
						onClick={() =>
							setFilterState({
								cate: undefined,
								searchTerm: undefined,
								price: undefined,
								zip: undefined,
								color: undefined,
								brand: undefined,
								subcat: undefined,
								size: undefined,
								tags: undefined,
								deliveryOptions: undefined,
								dates: undefined,
							})
						}
						className={classnames(
							`${BASE_CLASS}__clear-button`,
							`${BASE_CLASS}__hidden-on-mobile`
						)}>
						Clear all filters
					</button>
				</div>

				<div className={`${BASE_CLASS}__table`}>
					{storedArticles &&
						isRemoveFromStorageOverlayVisible &&
						storedArticles?.map((dress, i) => (
							<div className={`${BASE_CLASS}__item`} key={i}>
								<div>
									<button
										type="button"
										onClick={() => {
											return removeFromStorageIds.includes(dress.id)
												? setRemoveFromStorageIds(
														removeFromStorageIds.filter((id) => id !== dress.id)
												  )
												: setRemoveFromStorageIds(removeFromStorageIds.concat(dress.id));
										}}
										className={classnames(`${BASE_CLASS}__item__take-out`, {
											[`${BASE_CLASS}__item__take-out__selected`]: removeFromStorageIds?.includes(
												dress.id
											),
										})}>
										{removeFromStorageIds?.includes(dress.id) ? (
											<>
												<CircleCheck /> <p>Moving Back to Closet</p>
											</>
										) : (
											<>
												<Circle />
												<p>Take Out of Storage</p>
											</>
										)}
									</button>

									<Link to={`/closet/${dress.id}`}>
										<div className={`${BASE_CLASS}__item__img`}>
											{dress.imageUrls?.length ? (
												<img src={dress.imageUrls[0]} loading="lazy" />
											) : (
												<p>{dress.name}</p>
											)}
										</div>
									</Link>
								</div>
							</div>
						))}

					{filteredItems &&
						filteredItems?.map((dress, i) => (
							<div className={`${BASE_CLASS}__item`} key={i}>
								<div>
									{isStorageOverlayVisible && (
										<button
											type="button"
											onClick={() => {
												sendToStorageIds.includes(dress.id)
													? setSendToStorageIds(sendToStorageIds.filter((id) => id !== dress.id))
													: setSendToStorageIds(sendToStorageIds.concat(dress.id));
											}}
											className={classnames(`${BASE_CLASS}__item__put-away`, {
												[`${BASE_CLASS}__item__put-away__selected`]: sendToStorageIds?.includes(
													dress.id
												),
											})}>
											{sendToStorageIds?.includes(dress.id) ? (
												<>
													<CircleCheck /> <p>Moving to Storage</p>
												</>
											) : (
												<>
													<Circle />
													<p>Put away</p>
												</>
											)}
										</button>
									)}

									{!!showAddListOverlay && (
										<button
											type="button"
											onClick={() => {
												addToListIds.includes(dress.id)
													? setAddToListIds(addToListIds.filter((id) => id !== dress.id))
													: setAddToListIds(addToListIds.concat(dress.id));
											}}
											className={classnames(`${BASE_CLASS}__item__put-away`, {
												[`${BASE_CLASS}__item__put-away__selected`]: addToListIds?.includes(
													dress.id
												),
											})}>
											{addToListIds?.includes(dress.id) ? (
												<>
													<CircleCheck /> <p>Adding to a list</p>
												</>
											) : (
												<>
													<Circle />
													<p>Add to list</p>
												</>
											)}
										</button>
									)}

									{!isStorageOverlayVisible && (
										<button
											type="button"
											onClick={() => {
												return addItemWear(dress.id);
											}}
											className={classnames(`${BASE_CLASS}__item__add-wear`, {
												// [`${BASE_CLASS}__item__put-away__selected`]: this.state.storageIds?.includes(
												// 	dress.id
												// ),
											})}>
											Add wear
										</button>
									)}

									<Link to={`/closet/${dress.id}`}>
										<span>
											<p>{dress.wearCount}</p> <span>wears</span>
										</span>

										<div className={`${BASE_CLASS}__item__img`}>
											{dress.imageUrls?.length ? (
												<img src={dress.imageUrls[0]} loading="lazy" />
											) : (
												<p>{dress.name}</p>
											)}
										</div>
									</Link>
								</div>

								<Link
									to={{ pathname: `/closet/${dress.id}/edit`, state: dress }}
									className={`${BASE_CLASS}__item__edit`}>
									edit
								</Link>
							</div>
						))}
				</div>
			</div>
		</div>
	);

	function addItemWear(dressId) {
		let updatedClosetItems = closetItems.map((item) => {
			item.id === dressId ? (item.wearCount += 1) : null;
			return item;
		});

		setClosetItems(updatedClosetItems);
		return store.dispatch(addWear(dressId));
	}

	function putItemsInStorage() {
		setTimeout(() => setShowLoader(true), 100);
		store.dispatch(updateStorageList(sendToStorageIds)).then(() => store.dispatch(getDresses()));
		setSendToStorageIds([]);
		setIsStorageOverlayVisible(false);

		setTimeout(() => setShowLoader(false), 2000);
	}

	function removeItemsFromStorage() {
		setTimeout(() => setShowLoader(true), 100);

		store
			.dispatch(removeFromStorageList(removeFromStorageIds))
			.then(() => store.dispatch(getDresses()));

		setRemoveFromStorageIds([]);
		setIsRemoveFromStorageOverlayVisible(false);
		setTimeout(() => setShowLoader(false), 2000);
	}

	function addToList(list) {
		setShowAddListModal(false);
		setTimeout(() => setShowLoader(true), 100);
		store.dispatch(updateList(addToListIds, list)).then(() => store.dispatch(getDresses()));
		setAddToListIds([]);
		setIsStorageOverlayVisible(false);
		setListName('');
		setShowAddListOverlay(false);
		setTimeout(() => setShowLoader(false), 2000);
	}

	async function getItemsFromStorage() {
		store.dispatch(getStoredDresses());
	}
}

export default Closet;
