import React from 'react';

const Article = () => (
	<svg width="44" height="57" viewBox="0 0 44 57" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.7451 13.9877C14.6225 19.3832 15.6654 17.7416 14.7451 23.0466C13.7994 28.4987 14.085 34.0384 14.085 39.5507C14.085 44.5423 12.0693 51.3289 13.4615 56.2016C13.9764 58.004 37.1908 49.1198 37.1908 44.0252C37.1908 37.8245 36.705 31.6441 36.5306 25.5406C36.4719 23.4866 35.1666 17.5132 37.8509 19.9292C38.2556 20.2934 43.6034 24.4551 42.6188 22.2397C41.3178 19.3125 43.9303 17.4153 42.6188 14.4644C40.812 10.3992 36.0796 5.31504 32.7163 2.28807C29.6502 -0.471418 21.2702 4.31385 18.8528 1.6279C17.0437 -0.382291 13.0252 3.50539 10.8208 4.74535C8.87229 5.8414 0.257731 11.9599 1.02837 14.4644C1.8571 17.1578 3.53028 19.7999 4.14582 22.5698C4.81473 25.5799 6.61162 24.8804 9.46381 24.8804"
			stroke="#222222"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Article;
