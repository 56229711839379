import React from 'react';
import './index.scss';
const BASE_CLASS = 'activity-card';
import { Link } from 'react-router-dom';

export default function ActivityCard({ rental, onConfirm, onDecline, cardType }) {
	return (
		<div className={`${BASE_CLASS}`}>
			<Link to={`/rental/${rental.id}`}>
				<img src={rental.imageThumbnail} />
			</Link>
			<div className={`${BASE_CLASS}__right`}>
				{cardType === 'awaitingPaymentRentals' && (
					<>
						<p>Meet up on</p>
						<h5> {rental.firstDateOfRental}</h5>
						<h4>${rental.priceRented}</h4>
						<Link to={`/rental/${rental.id}`}>Pay now</Link>
					</>
				)}
				{(cardType === 'upcomingLenderRentals' || cardType === 'upcomingBorrowerRentals') && (
					<>
						<p>Meet up on</p>
						<h5>{rental.firstDateOfRental}</h5>
						<h4>${rental.priceRented}</h4>
						<Link to={`/rental/${rental.id}`}>See details</Link>
					</>
				)}
				{(cardType === 'endingLenderRentals' || cardType === 'endingBorrowerRentals') && (
					<>
						<p>Meet up on</p>
						<h5> {rental.lastDateOfRental}</h5>
						<h4>${rental.priceRented}</h4>
						<Link to={`/rental/${rental.id}`}>See details</Link>
					</>
				)}
				{cardType === 'pendingBorrowerRentals' && (
					<>
						<p>Pending meet up on</p>
						<h5> {rental.firstDateOfRental}</h5>
						<h4>${rental.priceRented}</h4>
						<Link to={`/rental/${rental.id}`}>See details</Link>
					</>
				)}

				{cardType === 'pendingLenderRentals' && (
					<>
						<p>Pending meet up on</p>
						<h5>{rental.firstDateOfRental}</h5>
						<h4>${rental.priceRented}</h4>
						<div className={`${BASE_CLASS}__right__links`}>
							<button onClick={() => onDecline()}>Decline</button>
							<button onClick={() => onConfirm()}>Confirm</button>
						</div>
					</>
				)}
				{(cardType === 'endedLenderRentals' || cardType === 'endedBorrowerRentals') && (
					<>
						<Link to={`/rental/${rental.id}`}>Leave a review</Link>
					</>
				)}
			</div>
		</div>
	);
}
