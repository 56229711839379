import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BlogPost2 } from '../../../../../public/icons/index';
import store from '../../../../store';
import './index.scss';
import axios from 'axios';
import classnames from 'classnames';
import { Heart } from '../../../../../public/index';

const BASE_CLASS = 'post-two';

class PostTwo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			comments: [],
			commentToAdd: '',
			liked: false,
		};

		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.getComments = this.getComments.bind(this);
		this.submitComment = this.submitComment.bind(this);
		this.postComment = this.postComment.bind(this);
		this.togglePostLike = this.togglePostLike.bind(this);
	}

	componentDidMount() {
		this.getComments();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	togglePostLike() {
		this.setState({
			liked: !this.state.liked,
		});

		this.postLike(!this.state.liked);
	}

	async postLike(isLiked) {
		try {
			const post = await axios.put(`/api/blogcomment/2`, { liked: isLiked.toString() });
		} catch (err) {
			console.err(err);
		}
	}

	async getComments() {
		try {
			const res = await axios.get(`/api/blogcomment/2`);
			const postData = res.data;

			if (!postData[0]) {
				// adding post for first time:
				const post = await axios.post(`/api/blogcomment/2`, {
					blogId: 1,
					topic: 'wardrobe',
					likeCount: 0,
				});
			} else {
				const comments = res.data[0]?.comment;
				const likeCount = res.data[0].likeCount;

				const commentMapped = comments ? comments.map((c) => JSON.parse(c)) : null;

				this.setState({
					comments: commentMapped,
					likeCount: likeCount,
				});
			}
		} catch (err) {
			console.error(err);
		}
	}

	async postComment(commentObj) {
		try {
			const post = await axios.put(`/api/blogcomment/2`, commentObj);
		} catch (err) {
			console.err(err);
		}
	}

	submitComment() {
		event.preventDefault();
		const commentObj = {
			commenter: this.state.user?.user.name || 'anonymous',
			comment: this.state.commentToAdd,
			userId: this.state.user?.user.id || null,
			date: Date.now(),
		};

		this.postComment(commentObj);

		this.setState({
			commentToAdd: '',
			comments: this.state.comments ? [...this.state.comments, commentObj] : [commentObj],
		});
	}

	handleCommentChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	render() {
		const localLikeCount = this.state.liked ? 1 : 0;
		let totalLocalLikeCount = (this.state.likeCount || 0) + localLikeCount;
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__top`}>
					<Link to="/fashion-fix">Back</Link>
					<h5>
						the fashion fix
						<div />
					</h5>
				</div>
				<h3>Intro to Tencel</h3>

				<p>
					<b>
						Lyocell, also known as Tencel, is a natural fabric that has been gaining popularity in
						the sustainable fashion industry in recent years.
					</b>
				</p>
				<h4>From eucalyptus pulp to bedding</h4>
				<p>
					In the 1970s, a group of scientists at Courtaulds Fibers (now known as Lenzing AG) began
					experimenting with new methods of producing cellulosic fibers. After years of research and
					development, they were able to create a new type of fiber made from wood pulp – most
					commonly eucalyptus – which they named Lyocell. Lyocell was first introduced to the market
					in the 1990s, under the brand name{' '}
					<b>Tencel (‘ten’ for tenacious, and ‘cel’ for cellulosic fiber)</b>. The fabric quickly
					gained popularity due to its unique combination of environmental sustainability and
					comfort.
				</p>
				<BlogPost2 />
				<h4>The benefits of Tencel</h4>
				<p>
					Eucalyptus trees, which are a fast-growing and renewable resource. Additionally, the
					production of lyocell under the Tencel brand uses less water and energy than traditional
					cotton production. In addition to its sustainable characteristics, lyocell is also known
					for:
					<ol>
						<li>
							<b>Comfort: </b> Lyocell fibers are smooth and soft, making it a comfortable choice
							for clothing. It is also highly absorbent, which helps to wick away moisture from the
							skin, keeping you feeling cool and dry.
						</li>
						<li>
							<b>Durability: </b> Lyocell is a strong and durable fabric, which means that clothes
							made from Lyocell can last for a long time. This makes it a great choice for everyday
							wear.
						</li>
						<li>
							<b>Hypoallergenic: </b> Lyocell fibers are naturally hypoallergenic, which makes it a
							great choice for people with sensitive skin.
						</li>
						<li>
							<b>Versatility: </b> Lyocell can be used for a wide range of clothing items, from
							everyday wear to high-end fashion. It is also used in home textiles such as bedding,
							towels, and upholstery.
						</li>
						<li>
							<b>Drapes well: </b>
							Lyocell has a natural drape and flow, it is lightweight and has a lustrous finish
							which makes it perfect for use in flowy, elegant clothing.
						</li>
						<li>
							<b>Moisture management: </b>
							Lyocell fibers are highly absorbent which makes it a great choice for clothing as it
							wicks away moisture from the skin, keeping the wearer feeling cool and dry.
						</li>
						<li>
							<b>Biodegradable: </b>
							Lyocell fibers will decompose when they are disposed of, unlike synthetic fibers which
							can take hundreds of years to break down.
						</li>
						<li>
							<b>Resistant to wrinkles: </b>
							Lyocell fibers are naturally wrinkle-resistant, which makes it a great choice for
							travel clothing and other items that may need to be packed and unpacked frequently.
						</li>
						<li>
							<b>Easy to dye: </b>
							Lyocell fibers are easy to dye and take up dye very well, this allows for a wide range
							of colors and shades to be achieved in the final product.
						</li>
					</ol>
				</p>

				<h4>Caring for Tencel</h4>
				<p>
					To care for lyocell, and ensure its lifecycle is extended as long as possible:
					<ol>
						<li>
							<b>Wash in cold water: </b>
							Tencel is a delicate fabric and should be washed in cold water to prevent shrinkage
							and damage to the fibers.
						</li>
						<li>
							<b>Use mild detergent: </b>
							Use a mild, low-sudsing detergent when washing Tencel clothing. Avoid using harsh
							detergents or bleach, as these can damage the fibers.
						</li>
						<li>
							<b>Don't over-dry: </b>
							Tencel should be air-dried or tumble-dried on a low heat setting to prevent shrinkage.
							Do not over-dry Tencel clothing as it can cause wrinkles and shrinkage.
						</li>
						<li>
							<b>Iron on low heat: </b>
							If you need to iron Tencel clothing, use a low heat setting and a pressing cloth to
							protect the fibers.
						</li>
						<li>
							<b>Avoid harsh chemicals: </b>
							Tencel is a delicate fabric and should be protected from harsh chemicals such as
							chlorine bleach or harsh stain removers.
						</li>
						<li>
							<b>Avoid over-washing: </b>
							Tencel is a highly absorbent fabric and does not need to be washed as often as other
							fabrics. Avoid over-washing Tencel clothing to prolong its lifespan.
						</li>
					</ol>
				</p>
				<h4>The takeaway</h4>
				<p>
					By following these tips, you can ensure that your Tencel clothing stays in great condition
					for a long time. Tencel is a sustainable and eco-friendly fabric, taking good care of it
					will prolong its lifespan and reduce environmental impact.
				</p>
				<p>
					Do you wear Tencel? Join the conversation and let us know your thoughts and your own tips.
					💚
				</p>

				<div className={`${BASE_CLASS}__likes`}>
					<span
						onClick={() => this.togglePostLike()}
						className={classnames('heart', {
							[`liked`]: this.state.liked,
						})}>
						<Heart />
					</span>
					<small>{totalLocalLikeCount || 0} people liked this post</small>
				</div>
				{/* <h4 className={`${BASE_CLASS}__comment-header`}>Further thoughts</h4> */}

				<div className={`${BASE_CLASS}__comments`}>
					{this.state.comments?.map((comm) => (
						<div className={`${BASE_CLASS}__comments__comment`} key={comm.text}>
							<div className={`${BASE_CLASS}__comments__comment__signature`}>
								<span>{comm.commenter}</span>
								<span>on {new Date(comm.date).toDateString()}</span>
							</div>
							<p>{comm.comment}</p>
						</div>
					))}
				</div>
				<form type="submit" onSubmit={() => this.submitComment()} className={`${BASE_CLASS}__form`}>
					<input
						type="text"
						name="commentToAdd"
						placeholder="add a comment"
						onChange={this.handleCommentChange}
						value={this.state.commentToAdd}
					/>
					<button type="submit">post</button>
				</form>
			</div>
		);
	}
}

export default PostTwo;
