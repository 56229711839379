import React from 'react';

const Logo = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19.0535 11.3037L17.1805 11.3815L18.2953 10.1626C18.4057 10.0571 18.5061 9.94414 18.6329 9.79726L18.9593 9.44075L18.9895 9.35162C19.651 8.41891 20 7.33806 20 6.21579C20 3.20801 17.5533 0.761364 14.5456 0.761364C12.65 0.761364 10.9767 1.73299 10 3.2055C9.02209 1.73425 7.34873 0.761364 5.45443 0.761364C2.44665 0.761364 0 3.20801 0 6.21579C0 7.28408 0.311322 8.31471 0.90133 9.20977L1.10972 9.48971C1.31434 9.76086 1.54783 10.0157 1.75119 10.1927L2.37886 10.8819L2.52699 14.442L4.34095 14.3667L4.2832 12.9695L10 19.2386L15.4557 13.2708L19.1313 13.1189L19.0535 11.3037ZM5.36781 11.4694L7.38765 11.6652L7.56339 9.85752L3.5413 9.46711L3.02912 8.90598C2.84333 8.73902 2.67888 8.55825 2.53954 8.36869L2.46422 8.27956C2.03866 7.66696 1.81521 6.95267 1.81521 6.21454C1.81521 4.20851 3.44715 2.57658 5.45318 2.57658C7.4592 2.57658 9.09113 4.20851 9.09113 6.21454H10.9076C10.9076 4.20851 12.5395 2.57658 14.5456 2.57658C16.5516 2.57658 18.1835 4.20851 18.1835 6.21454C18.1835 6.98908 17.9299 7.73851 17.4479 8.38125L17.2332 8.63859C17.1717 8.70889 17.1127 8.78044 16.9997 8.89091L15.949 10.0408V9.00389H14.1326V12.0267L10 16.5459L5.36781 11.4694Z"
			fill="#180A02"
		/>
	</svg>
);

export default Logo;
