import React, { Component } from 'react';
import store from '../../store';
import './index.scss';
import Review from '../atoms/Review';
const BASE_CLASS = 'myprofile';
import { getDresses } from '../../store/closet';
import { MoneyEarned, ItemsRented, ItemsBorrowed, Avatar, CreditIcon } from '../../../public/icons';
import { getAllBorrowerRentals, getAllLenderRentals } from '../../store/rentals';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Favorites from '../Favorites';

// TODO: this route is actually /profile/:id

class MyProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			lenderReviewsAverage: null,
			lenderReviewsNumber: null,
		};
		this.getLenderReviewAverage = this.getLenderReviewAverage.bind(this);
		this.getLenderData = this.getLenderData.bind(this);
		this.handleConfirmAndCreateStripeAcct = this.handleConfirmAndCreateStripeAcct.bind(this);
	}

	componentDidMount() {
		store.dispatch(getAllBorrowerRentals());
		store.dispatch(getDresses());
		store.dispatch(getAllLenderRentals());
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	getLenderData() {
		const numberOfRentalsBorrowed = this.state.rentals?.borrowerRentals?.length;
		const numberOfRentalsLent = this.state.rentals?.lenderRentals?.length;
		const totalEarningsFromRentals = this.state.rentals?.lenderRentals
			?.map((rental) => rental.priceRented)
			.reduce((a, b) => a + Number(b), 0);
		let stripeId = this.state.user.user.stripeConnectedAccountId;
		let lenderArticles = this.state.closet.dresses.filter((article) => article.rentable);

		this.setState({
			numberOfRentalsBorrowed,
			numberOfRentalsLent,
			totalEarningsFromRentals,
			userMissingStripeAccount: !!lenderArticles && !stripeId,
		});
	}

	getLenderReviewAverage() {
		let lenderArticles = this.state.closet.dresses;

		let borrowerDescriptionReviewsArray = lenderArticles
			.filter((article) => article.borrowerDescriptionReview)
			.map(
				(article) =>
					Number(article.borrowerDescriptionReview) *
					Number(article.borrowerDescriptionReviewNumber)
			);

		let sumOfBorrowerDescriptionReviews = borrowerDescriptionReviewsArray.reduce((total, num) => {
			return total + Math.round(num);
		}, 0);

		let borrowerDescriptionCount = lenderArticles
			.filter((article) => article.borrowerDescriptionReview)
			.map((article) => Number(article.borrowerDescriptionReviewNumber) || 1)
			.reduce((total, sum) => total + sum, 0);

		let borrowerDeliveryReviewsArray = lenderArticles
			.filter((article) => article.borrowerDeliveryReview)
			.map(
				(article) =>
					Number(article.borrowerDeliveryReview) * Number(article.borrowerDeliveryReviewNumber)
			);

		let borrowerDeliveryCount = lenderArticles
			.filter((article) => article.borrowerDeliveryReview)
			.map((article) => Number(article.borrowerDeliveryReviewNumber) || 1)
			.reduce((total, sum) => total + sum, 0);

		let sumOfBorrowerDeliveryReviews = borrowerDeliveryReviewsArray.reduce((total, num) => {
			return total + Math.round(num);
		}, 0);

		let borrowerOverallReviewsArray = lenderArticles
			.filter((article) => article.borrowerOverallReview)
			.map(
				(article) =>
					Number(article.borrowerOverallReview) * Number(article.borrowerOverallReviewNumber)
			);

		let borrowerOverallCount = lenderArticles
			.filter((article) => article.borrowerOverallReview)
			.map((article) => Number(article.borrowerOverallReviewNumber) || 1)
			.reduce((total, sum) => total + sum, 0);

		let sumOfBorrowerOverallReviews = borrowerOverallReviewsArray.reduce((total, num) => {
			return total + Math.round(num);
		}, 0);

		let lenderReviewsNumber =
			Math.round(borrowerDescriptionCount + borrowerDeliveryCount + borrowerOverallCount) / 3;

		let lenderReviewsAverage =
			(Number(sumOfBorrowerDescriptionReviews) +
				Number(sumOfBorrowerOverallReviews) +
				Number(sumOfBorrowerDeliveryReviews)) /
			(lenderReviewsNumber * 3);

		this.setState({
			lenderReviewsAverage,
			lenderReviewsNumber,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.closet.dresses !== this.state.closet.dresses) {
			this.getLenderReviewAverage();
		}
		if (
			prevState.rentals !== this.state.rentals &&
			prevState.rentals.lenderRentals !== this.state.rentals.lenderRentals
		) {
			this.getLenderData();
		}
	}

	async handleConfirmAndCreateStripeAcct() {
		const userId = this.state.user.user.id;

		try {
			const accountLinks = axios
				.put(`/api/users`, { createStripeAccount: true, userId })
				.then((response) => {
					const url = response.data;

					window.location.href = url;
				});
		} catch (err) {
			next(err);
		}
	}

	render() {
		let followerSet = new Set(this.state.user?.user?.followers || []);
		let followingSet = new Set(this.state.user?.user?.following || []);
		let followerArray = Array.from(followerSet);
		let followingArray = Array.from(followingSet);

		return (
			<div className="container-myprofile">
				<div className={BASE_CLASS}>
					<div className={`${BASE_CLASS}__user-info`}>
						<div className={`${BASE_CLASS}__user-info__img-container`}>
							{this.state.user?.user?.avatarUrl ? (
								<img src={this.state.user?.user?.avatarUrl} className={`${BASE_CLASS}__avatar`} />
							) : (
								<Avatar />
							)}
						</div>
						<div className={`${BASE_CLASS}__user-info__bio-info`}>
							<h3>{this.state.user?.user?.name}</h3>
							<div>
								<Review
									reviewStarNumber={Math.round(this.state.lenderReviewsAverage)}
									className="star-review-profile"
								/>
								<span>({this.state.lenderReviewsNumber || 'No reviews yet'})</span>
							</div>
							<h4>
								Followers:{' '}
								<b>{this.state.user?.user?.followers?.length ? `${followerArray.length}` : '0'}</b>
							</h4>
							<h4>
								Following:{' '}
								<b>{this.state.user?.user?.following?.length ? `${followingArray.length}` : '0'}</b>
							</h4>
						</div>
					</div>

					<div className={`${BASE_CLASS}__user-stats`}>
						<h5>
							<b>${this.state.totalEarningsFromRentals}</b> earned from renting{' '}
						</h5>
						<h5>
							<b>{this.state.numberOfRentalsLent}</b> rentals completed
						</h5>
						<h5>
							{' '}
							<b>{this.state.numberOfRentalsBorrowed}</b> items borrowed
						</h5>
						{/* {this.state.user.user.currentZipCode && ( */}
					</div>

					{this.state.user?.user?.currentZipCode && (
						<div className={`${BASE_CLASS}__credit`}>
							<CreditIcon />
							<h5>
								You have <b>${this.state.user?.user?.currentZipCode}</b> in credit
							</h5>
						</div>
					)}
					{this.state.user?.user?.biography?.length ? (
						<p>{this.state.user.user.biography}</p>
					) : (
						<p>
							You haven’t added a bio yet. <Link to="/account">Add one now</Link> to tell lenders
							and borrowers about yourself!
						</p>
					)}
					<div className={`${BASE_CLASS}__ctas`}>
						<Link to="/account">Edit Profile</Link>
						{this.state.userMissingStripeAccount && (
							<>
								{/* <h4>Looks like you're missing a Stripe account! It takes just a minute to set up: </h4> */}
								<button
									className={`${BASE_CLASS}__cta`}
									onClick={() => this.handleConfirmAndCreateStripeAcct()}>
									Connect Your Bank to Get Paid
								</button>
							</>
						)}
					</div>

					<h5>Favorites</h5>
					<Favorites />
				</div>
			</div>
		);
	}
}

export default MyProfile;
