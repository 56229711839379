import React, { Component } from 'react';
import { getDresses } from '../../store/closet';
import { Link } from 'react-router-dom';
import store from '../../store';
import './index.scss';
const BASE_CLASS = 'lending';
import axios from 'axios';

import { queueModal } from '../../store/modals';
import Review from '../atoms/Review';
import { Illustration7, Illustration5, Illustration6 } from '../../../public/icons/index';

class Lending extends Component {
	constructor() {
		super();
		this.state = {
			...store.getState(),
			rentableItems: [],
			newsletterName: '',
			newsletterEmail: '',
			submitted: false,
			isLoggedIn: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleNewsletterSignUp = this.handleNewsletterSignUp.bind(this);
		this.showLoginModal = this.showLoginModal.bind(this);
		this.handleGetStarted = this.handleGetStarted.bind(this);
	}

	componentDidMount() {
		store.dispatch(getDresses());
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));

		this.setState({
			isLoggedIn: !!this.state.user.user.id,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.closet.dresses !== this.state.closet.dresses) {
			this.setState({
				rentableItems: this.state.closet.dresses.filter((item) => item.rentable),
			});
		}
	}

	handleGetStarted() {
		const isLoggedIn = !!this.state.user.user.id;
		if (isLoggedIn) {
			history.push('/add');
		} else {
			this.showLoginModal();
		}
	}

	showLoginModal() {
		const isLoggedIn = !!this.state.user.user.id;
		if (!isLoggedIn) {
			store.dispatch(queueModal('LOGIN'));
			scrollTo(0, 0);
		}
	}

	handleNewsletterSignUp(e) {
		e.preventDefault();

		axios.post(`/api/newsletter`, {
			newsletterEmail: this.state.newsletterEmail,
			newsletterName: this.state.newsletterName,
		});

		this.setState({
			submitted: true,
		});
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	render() {
		return (
			<div className="container-lending">
				<div className={BASE_CLASS}>
					<span className={`${BASE_CLASS}__top`}>
						<div className={`${BASE_CLASS}__hero`}>
							<h1>
								Make money from your <br />
								wardrobe
							</h1>

							{this.state.user.user && !this.state.user.user.id && (
								<div className={`${BASE_CLASS}__hero__ctas`}>
									<Link to="/about">learn more</Link>
									<div onClick={() => this.showLoginModal()}>join social closet</div>
								</div>
							)}
						</div>
						<div className={`${BASE_CLASS}__hero-image-container`}>
							<img src="images/heroImageLend1.jpg" />
						</div>
					</span>
					<div className={`${BASE_CLASS}__howitworks-section`}>
						<div className={`${BASE_CLASS}__howitworks-section__subtitle`}>
							<h3>How it works</h3>
							<p>
								Lending your wardrobe on Social Closet allows you to make extra cash from your
								wardrobe, on your own terms.
							</p>
							{/* <p>
							<b>LIMITED TIME OFFER:</b>
							<br />
							Earn a $10 credit to use on Social Closet when you upload an item to lend. Make up to
							$50 in credits.
						</p> */}
							<button type="button" onClick={this.handleGetStarted}>
								upload an item to lend
							</button>
						</div>

						<div className={`${BASE_CLASS}__howitworks-section__cards`}>
							<div className={`${BASE_CLASS}__howitworks-section__cards__first`}>
								<Illustration5 />
								<h4>Upload</h4>
								<p>your wardrobe to get started</p>
							</div>
							<div className={`${BASE_CLASS}__howitworks-section__cards__second`}>
								<Illustration6 />
								<h4>Deliver</h4>
								<p>in a public place in your zip code, or through our delivery service</p>
							</div>
							<div className={`${BASE_CLASS}__howitworks-section__cards__third`}>
								<Illustration7 />
								<h4>Get paid</h4>
								<p>and leave a review for your borrower</p>
							</div>
						</div>
					</div>

					<div className={`${BASE_CLASS}__testimonials`}>
						<h3>What lenders are saying</h3>
						<p>
							Our lender guarantee and peer review system means you can make money off your wardrobe
							stress-free.
						</p>
						<div className={`${BASE_CLASS}__testimonials__cards`}>
							<div className={`${BASE_CLASS}__testimonials__cards__card`}>
								<img src="images/LenderTestimonial.jpg" />
								<div className={`${BASE_CLASS}__testimonials__cards__card__info`}>
									<Review reviewStarNumber="5" />
									<h3>Lili</h3>
									<p>
										I love sharing my clothes through Social Closet; it makes me so happy seeing my
										things being valued instead of sitting in the back of my closet!
									</p>
								</div>
							</div>
							<div className={`${BASE_CLASS}__testimonials__cards__card`}>
								<img src="images/LenderTestimonial2.jpg" />
								<div className={`${BASE_CLASS}__testimonials__cards__card__info`}>
									<Review reviewStarNumber={5} />
									<h3>Liv</h3>
									<p>
										I use Social Closet because I love the idea of helping the enviroment, through a
										little action like lending my clothes.
									</p>
								</div>
							</div>
						</div>
						{/* <Link to="/lenders">See more lenders</Link> */}
					</div>

					{!this.state.submitted && (
						<div className={`${BASE_CLASS}__newsletter`} id="waitlist">
							<div className={`${BASE_CLASS}__newsletter__title`}>
								<h3>Join the club</h3>
							</div>
							<p className={`${BASE_CLASS}__newsletter__tag`}>
								Join the movement to lend, rent, share more, and buy less.
							</p>
							<form
								onSubmit={(e) => this.handleNewsletterSignUp(e)}
								className={`${BASE_CLASS}__newsletter__form`}>
								<label>name</label>
								<input
									type="text"
									name="newsletterName"
									value={this.state.newsletterName}
									onChange={this.handleChange}
								/>
								<label>email</label>
								<input
									type="text"
									name="newsletterEmail"
									value={this.state.newsletterEmail}
									onChange={this.handleChange}
								/>
								<button type="submit">Sign Up</button>
							</form>
						</div>
					)}

					{!!this.state.submitted && (
						<div className={`${BASE_CLASS}__success`} id="waitlist">
							<div className={`${BASE_CLASS}__success__title`}>
								<Arrows />
								<h2>you're on the list</h2>
								<span>✨</span>
							</div>
							<p className={`${BASE_CLASS}__success__tag`}>
								Keep an eye on your email for exciting things to come.
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Lending;
