import React from 'react';

const ConfirmFace = () => (
	<svg
		width="149"
		height="159"
		viewBox="0 0 149 159"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M69.8554 51.0579C67.3979 63.6527 64.1729 75.2136 64.1729 87.9943C64.1729 91.497 62.699 97.9387 67.7245 97.9387C71.7496 97.9387 75.7747 97.9387 79.7998 97.9387"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M44.2842 151.923C55.3739 156.521 64.277 157.605 76.1695 157.605C81.8921 157.605 95.4573 156.124 98.2682 150.502"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1 14.8065C12.422 19.2639 36.6945 22.773 43.581 9"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M98 1C105.52 11.3401 111.775 17.6794 125.097 18.4195C132.523 18.8321 142.002 19.6443 148.323 16.484"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ConfirmFace;
