import React from 'react';

const Hashtag = () => (
	<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.7998 5.625H12.7998" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M2.7998 9.375H12.7998" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M6.5498 1.875L5.2998 13.125"
			stroke="#111111"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.2998 1.875L9.0498 13.125"
			stroke="#111111"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Hashtag;
