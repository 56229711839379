import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../../../public';

import './index.scss';

const BASE_CLASS = 'footer';

const Footer = () => {
	return (
		<div className={BASE_CLASS}>
			<Link to="/home" className={`${BASE_CLASS}__icon`}>
				<Icon />
				{/* <i>© 2021</i> */}
			</Link>
			<div className={`${BASE_CLASS}__links`}>
				<Link to="/about" className={`${BASE_CLASS}__cta`}>
					About
				</Link>
				<Link to="/fashion-fix" className={`${BASE_CLASS}__cta`}>
					The Fashion Fix
				</Link>
				{/* <Link to="/friends" className={`${BASE_CLASS}__cta`}>
					Friends
				</Link> */}
				<a href="mailto:hello@mysocialcloset.com">Contact</a>
				<Link to="/faq" className={`${BASE_CLASS}__cta`}>
					FAQ
				</Link>
				<Link to="/terms" className={`${BASE_CLASS}__cta`}>
					Terms
				</Link>
				<Link to="/privacy" className={`${BASE_CLASS}__cta`}>
					Privacy
				</Link>
			</div>
		</div>
	);
};

export default Footer;
