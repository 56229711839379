import React, { Component } from 'react';
import store from '../../store';
import './index.scss';
import { BlogPost1, BlogPost2 } from '../../../public/icons/index';

const BASE_CLASS = 'forum';

var wordpress = require('wordpress');
var client = wordpress.createClient({
	url: 'https://www.mysocialcloset.wordpress.com',
	username: 'socialcloset2023',
	password: 'Bishbosh45!',
	rejectUnauthorized: false,
});

import { Link } from 'react-router-dom';
// TODO: this route is actually /profile/:id

class Forum extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
		};
	}

	componentDidMount() {
		// client.getPosts(function (error, posts) {
		// 	console.log('Found ' + posts.length + ' posts!');
		// });
	}

	render() {
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__lockup`}>
					<h5>
						the fashion fix
						<div />
					</h5>
				</div>
				<div className={`${BASE_CLASS}__articles`}>
					<Link to="/buy-clothes-you-love" className={`${BASE_CLASS}__articles__card`}>
						<BlogPost1 />
						<h4>How to buy clothes you'll love</h4>
						<p>5 questions to ask yourself</p>
					</Link>
					<Link to="/intro-to-tencel" className={`${BASE_CLASS}__articles__card`}>
						<BlogPost2 />
						<h4>Intro to Tencel</h4>
						<p>A quick primer on this sustainable fabric</p>
					</Link>
					<Link to="/cleaner-color" className={`${BASE_CLASS}__articles__card`}>
						<img src="images/dyePollutionIndia.png" alt="dye pollution in India" />
						<h4>Cleaner Color</h4>
						<p>The cost of dyeing fabrics and a look at some solutions</p>
					</Link>
					<Link to="/fashion-tech" className={`${BASE_CLASS}__articles__card`}>
						<img src="images/techInFashion.jpeg" alt="technology transforming fashion" />
						<h4>A Better Fashion Ecosystem with Tech</h4>
						<p>How can emerging digital technologies help fashion?</p>
					</Link>
					<Link to="/clothing-rental-guide" className={`${BASE_CLASS}__articles__card`}>
						{/* <BlogPost5 /> */}
						<h4>Renting Fashion Online: Your Ultimate How-To Guide</h4>
						<p>Ten steps to discovering new fashion</p>
					</Link>
				</div>

				{/* <div className={`${BASE_CLASS}__resources`}>
					<h3>Resources</h3>
					<p>...coming soon!</p>
				</div> */}
			</div>
		);
	}
}

export default Forum;
