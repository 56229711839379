import React, { Component } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getRental, addMessage } from '../../store/rentals';
import store from '../../store';
import './index.scss';
import { fromUnixTime, sub, add, format } from 'date-fns';
const BASE_CLASS = 'rental-page';
import history from '../../../client/history';
import { Star } from '../../../public/index';
import classnames from 'classnames';
import axios from 'axios';
import ConfirmationModal from '../modals/ConfirmationModal';
import { Link, Route } from 'react-router-dom';
import getTimestamps from '../../utils/getTimestamps';
import Review from '../atoms/Review';
import getAverageUserReviews from '../../utils/getAverageUserReviews';

// const stripePromise = loadStripe(
// 	process.env.NODE_ENV === 'production'
// 		? 'pk_live_WRjK7m5baLqZnDW3bWusE53S'
// 		: 'pk_test_au8LjCq9Sg1b8JYe3JKEFxbm'
// );

const stripePromise = loadStripe('pk_live_WRjK7m5baLqZnDW3bWusE53S');
import CheckoutForm from '../CheckoutForm';

// render states / render states:
// PENDING: 'PENDING',
// 	CONFIRMED_PAID: 'CONFIRMED_PAID', // lender has confirmed, borrower has paid
// CONFIRMED_AWAITING_PAYMENT: // lender has confirmed, borrower has not paid
// 	CANCELLEDNORESPONSE: 'BLOCKED', // if lender doesn't respond to request within 24 hours ?? NEED TO CHECK THIS LOGIC
// 	ACTIVE: 'ACTIVE', // rental period has begun
// 	ACTIVECONFIRMING: 'ACTIVECONFIRMING', // one party has confirmed they met up
// 	ACTIVECONFIRMED: 'ACTIVECONFIRMED', // both parties have confirmed they met up
// 	COMPLETED: 'COMPLETED', // the rental period has passed
// 	DECLINED: 'BLOCKED',
// 	CANCELLED: 'BLOCKED',
// 	CANCELLEDCONFLICT: 'BLOCKED', // rental request was cancelled because of approved rental request in same period
// INCOMPLETE: 'BLOCKED' // NEED TO ADD THIS LOGIC ???? - INSTEAD OF COMPLETING ??

export default class RentalPage extends Component {
	constructor(props) {
		super(props);
		this.commentsEndRef = React.createRef();
		this.state = {
			...store.getState(),
			messageToAdd: '',
			rentalChat: null,
			rental: null,
			renderState: 'SUCCESS', // ??
			renterReviewOne: null,
			renterReviewTwo: null,
			renterReviewThree: null,
			renterOpenField: '',
			lenderReviewOne: null,
			lenderReviewTwo: null,
			lenderOpenField: '',
			lender: null,
			borrower: null,
			isBorrower: null,
			error: '',
			showCancelConfirmModal: false,
			showConfirmRentalConfirmationModal: false,
			showMeetUpBtn: false,
			showMeetupOptInModal: false,
			showCheckoutModal: false,
			// cancelPeriod: null,
			// showRefundOption: false,
			// showCancelOption: false,
			// borrowerClaimText: '',
			borrowerClaimPhoto: '',
			// lenderClaimText: '',
			// lenderClaimPhoto: [],
			showConfirmationModal: false,
		};

		this.submitMessage = this.submitMessage.bind(this);
		this.handleMessageChange = this.handleMessageChange.bind(this);
		this.getRenderState = this.getRenderState.bind(this);
		this.submitReview = this.submitReview.bind(this);
		// this.handleSelectRefundOption = this.handleSelectRefundOption.bind(this);
		this.handleCancelRental = this.handleCancelRental.bind(this);
		this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
		this.handleCloseCancelRental = this.handleCloseCancelRental.bind(this);
		this.handleMeetUpClick = this.handleMeetUpClick.bind(this);
		this.handleOptInMeetup = this.handleOptInMeetup.bind(this);
		this.getBorrower = this.getBorrower.bind(this);
		this.getLender = this.getLender.bind(this);
		this.getLenderAndBorrower = this.getLenderAndBorrower.bind(this);
		this.handleConfirmRental = this.handleConfirmRental.bind(this);
		this.submitRentalConfirmation = this.submitRentalConfirmation.bind(this);
		this.handleConfirmCancelRental = this.handleConfirmCancelRental.bind(this);
		this.handleshowMeetupOptInModal = this.handleshowMeetupOptInModal.bind(this);
		this.createStripePayment = this.createStripePayment.bind(this);
		this.handleStripePaymentCancel = this.handleStripePaymentCancel.bind(this);
		this.handleStripePaymentSuccess = this.handleStripePaymentSuccess.bind(this);
		this.getHandle = this.getHandle.bind(this);
		this.getLenderReviewAverage = this.getLenderReviewAverage.bind(this);
		this.openWidget = this.openWidget.bind(this);
		this.getConfirmLendingModalHtml = this.getConfirmLendingModalHtml.bind(this);
	}

	componentDidMount() {
		const rentalId = this.props.match.params.rentalId;
		store.dispatch(getRental(rentalId));

		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	handleStripePaymentCancel() {
		this.setState({
			showCheckoutModal: false,
		});
	}
	// FIXME: why have both ??
	getLenderAndBorrower() {
		const userId = this.state.user.user.id;
		const rental = this.state.rentals.rental;
		const isBorrower = rental.borrowerId === userId;

		if (isBorrower) {
			// if user is borrower
			this.getLender(rental.lenderId);
			this.getLenderReviewAverage(rental.lenderId);
			this.setState({
				isBorrower: true,
			});
		} else {
			this.getBorrower(rental.borrowerId);
			this.setState({
				isBorrower: false,
			});
		}
	}

	async getLender(lenderId) {
		try {
			// const lenderId = this.state.closet.dress.userId;
			const lender = await axios.get(`/api/users/${lenderId}`);

			this.setState({
				lender: lender?.data[0],
				borrower: this.state.user.user,
			});
		} catch (err) {
			console.error(err);
		}
	}

	async getLenderReviewAverage(lenderId) {
		const lenderArticles = await await axios.get(`/api/closet/user/${lenderId}`);

		const [reviews, number] = getAverageUserReviews(lenderArticles.data);

		this.setState({
			lenderReviewsAverage: reviews,
			lenderReviewsNumber: number,
		});
	}

	async getBorrower(borrowerId) {
		try {
			// const lenderId = this.state.closet.dress.userId;
			const borrower = await axios.get(`/api/users/${borrowerId}`);

			this.setState({
				lender: this.state.user.user,
				borrower: borrower?.data[0],
			});
		} catch (err) {
			console.error(err);
		}
	}

	handleCancelRental() {
		// const isBorrower = this.state.isBorrower;

		// const days = differenceInDays(Date.now(), fromUnixTime(this.state.rental.firstDateOfRental));
		// let text;
		// if (days > 7) {
		// 	text = isBorrower
		// 		? "since you're cancelling this rental over a week in advance, you won't be charged a fee for the rental. are you sure you want to cancel?"
		// 		: 'are you sure you want to cancel this rental?';
		// 	this.setState({
		// 		cancelPeriod: 'over-week',
		// 	});
		// } else if (days > 1) {
		// 	text = isBorrower
		// 		? "since you're cancelling this rental less than a week in advance, you'll receive a refund of 50% of the rental fee. are you sure you want to cancel?"
		// 		: "since you're cancelling this rental less than a week in advance, you'll be charged a $5 fee. are you sure you want to cancel?";
		// 	this.setState({
		// 		cancelPeriod: 'under-week',
		// 	});
		// } else {
		// 	text = isBorrower
		// 		? "since you're cancelling this rental less than 24 hours before the rental period begins, we're not able to refund you for this rental fee. are you sure you want to cancel?"
		// 		: "since you're cancelling this rental less than a 24 hours before the rental period, you'll be charged a $10 fee. are you sure you want to cancel?";
		// 	this.setState({
		// 		cancelPeriod: 'under-day',
		// 	});
		// }

		this.setState({
			showCancelConfirmModal: true,
		});
	}

	handleCloseCancelRental() {
		this.setState({
			showCancelConfirmModal: false,
		});
	}

	handleConfirmRental() {
		// window.scrollTo({
		// 	top: 0,
		// 	behavior: 'smooth',
		// });

		this.setState({
			showConfirmationModal: true,
		});
	}

	async handleConfirmCancelRental() {
		const articleId = this.state.rental.articleItemId;
		const firstDateOfRental = this.state.rental.firstDateOfRental;
		const daysOfSelectedWeek = getTimestamps(firstDateOfRental).weekTimestamps;

		const first = axios.put(`/api/rentals/${articleId}`, {
			// action: `cancelled-by-${this.state.isBorrower ? 'borrower' : 'lender'}-${
			//  this.state.cancelPeriod
			// }`
			action: 'cancel',
			rentalId: this.state.rental.id,
		});

		const second = axios.put(`/api/closet/${articleId}`, {
			action: 'availableDates',
			daysOfSelectedWeek,
		});

		try {
			// make dates available again on article

			await Promise.all([first, second]);
			// .then((response) => {
			// 	// this.closeConfirmationModal();
			// 	// window.location.reload(false);
			// });
		} catch (err) {
			next(err);
		}

		this.setState({
			renderState: 'CANCELLED-SUCCESS',
			showCancelConfirmModal: false,
		});
	}

	async submitRentalConfirmation() {
		const articleId = this.state.rental.articleItemId;
		const first = axios.put(`/api/rentals/${articleId}`, {
			action: 'confirm',
			rentalId: this.state.rental.id,
			articleId,
			...this.state.rental,
		});

		const second = axios.put(`/api/closet/${articleId}`, {
			unavailableDates: [this.state.rental.firstDateOfRental, this.state.rental.lastDateOfRental],
		});

		try {
			await Promise.all([first, second]).then((response) => {
				this.closeConfirmationModal();
				window.location.reload(false);
			});
		} catch (err) {
			console.error(err);
		}
	}

	// handleSelectRefundOption(e) {
	// 	if (e.target.value === 'inaccurate-listing') {
	// 		this.setState({
	// 			renderState: 'INACCURATE_LISTING',
	// 		});
	// 	} else if (e.target.value === 'didnt-fit') {
	// 		this.setState({
	// 			renderState: 'DIDNT_FIT',
	// 		});
	// 	} else if (e.target.value === 'lender-late') {
	// 		this.setState({
	// 			renderState: 'LENDER_LATE',
	// 		});
	// 	} else if (e.target.value === 'borrower-late') {
	// 		this.setState({
	// 			renderState: 'BORROWER_LATE',
	// 		});
	// 	}
	// }

	setStar(number, field) {
		this.setState({
			[field]: number,
		});
	}

	getRenderState() {
		const userId = this.state.user.user.id;
		const rental = this.state.rentals.rental;

		if (!rental) {
			this.setState({
				renderState: 'BLOCKED',
			});
		} else if (rental.status === 'PENDING') {
			this.setState({
				renderState: 'PENDING',
			});
		} else if (rental.status === 'CONFIRMED_AWAITING_PAYMENT') {
			this.setState({
				renderState: 'CONFIRMED_AWAITING_PAYMENT',
			});
		} else if (rental.status === 'CONFIRMED_PAID') {
			this.setState({
				renderState: 'CONFIRMED_PAID',
			});
		} else if (rental.status === 'ACTIVE') {
			this.setState({
				renderState: 'ACTIVE',
			});
		} else if (rental.status === 'COMPLETED') {
			// FIXME: fill out all conditions in IF statement
			if (userId === rental.borrowerId && !rental.borrowerOverallReview) {
				this.setState({
					renderState: 'BORROWER_REVIEW',
				});
			} else if (userId !== rental.borrowerId && !rental.lenderConditionReview) {
				this.setState({
					renderState: 'LENDER_REVIEW',
				});
			} else
				this.setState({
					renderState: 'SUCCESS',
				});
		}

		// userId === rental.borrowerId || userId === rental.lenderId
		else if (rental.status === 'ACTIVECONFIRMING') {
			this.setState({
				renderState: 'ACTIVECONFIRMING',
			});
		} else if (rental.status === 'ACTIVECONFIRMED') {
			this.setState({
				renderState: 'ACTIVECONFIRMED',

				// borrower view - so only show meet up btn if lender confirmed meetup
				// showConfirmMeetUpBtn: rental.lenderConfirmedMeetup,
			});
		}

		// else if (
		// 	rental.status === 'cancelled-inaccurate-listing' ||
		// 	rental.status === 'cancelled-didnt-fit' ||
		// 	rental.status === 'cancelled-lender-late'
		// ) {
		// 	this.setState({
		// 		renderState: 'CLAIM_PENDING',
		// 	});
		// }
		else {
			this.setState({
				renderState: 'BLOCKED',
			});
		}
	}

	closeConfirmationModal() {
		this.setState({
			showConfirmationModal: false,
			showMeetupOptInModal: false,
			showMeetupConfirmModal: false,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.state.showConfirmationModal && !this.state.showCancelConfirmModal) {
			this.commentsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
		if (prevState.rentals.rental !== this.state.rentals.rental) {
			const userId = this.state.user.user.id;
			const rental = this.state.rentals.rental;
			this.getRenderState();

			if (rental && (userId === rental.borrowerId || userId === rental.lenderId)) {
				this.parseChat();
				this.getLenderAndBorrower();
			} else {
				history.push('/rent');
			}
		}
	}

	parseChat() {
		this.setState({
			rentalChat:
				this.state.rentals.rental && this.state.rentals.rental.chat
					? this.state.rentals.rental.chat.map((msg) => JSON.parse(msg))
					: null,
			rental: this.state.rentals.rental,
		});
	}

	submitMessage(event) {
		event.preventDefault();
		if (this.state.messageToAdd) {
			const rentalId = this.props.match.params.rentalId;
			const articleId = this.state.rental.articleItemId;
			const messageObj = {
				message: this.state.messageToAdd,
				sender: this.state.user.user.name,
				userId: this.state.user.user.id,
			};

			store.dispatch(addMessage(messageObj, rentalId, articleId));
			this.setState({
				messageToAdd: '',
			});
		} else {
		}
	}

	async submitReview(e) {
		e.preventDefault();

		const articleId = this.state.rental.articleItemId;

		if (this.state.renderState === 'BORROWER_REVIEW') {
			const first = axios.put(`/api/rentals/${articleId}`, {
				borrowerOverallReview: this.state.borrowerReviewOne,
				borrowerDescriptionReview: this.state.borrowerReviewTwo,
				borrowerDeliveryReview: this.state.borrowerReviewThree,
				borrowerOpenReview: this.state.borrowerOpenField,
				borrowerClaimPhoto: [this.state.borrowerClaimPhoto],
				action: 'submit-borrower-review',
				rentalId: this.state.rental.id,
			});

			const second = axios.put(`/api/closet/${articleId}`, {
				borrowerOverallReview: this.state.borrowerReviewOne,
				borrowerDescriptionReview: this.state.borrowerReviewTwo,
				borrowerDeliveryReview: this.state.borrowerReviewThree,
				borrowerOpenReview: this.state.borrowerOpenField,
				borrowerPhotoReview: this.state.borrowerClaimPhoto,
			});

			try {
				// TODO: create review object from state - add success message - add new render state? 'submitted' - make different submit functions depending on borrower or lender
				await Promise.all([first, second]);
			} catch (err) {
				console.error(error);
			}
		} else if (this.state.renderState === 'LENDER_REVIEW') {
			const first = axios.put(`/api/rentals/${articleId}`, {
				lenderConditionReview: this.state.lenderReviewOne,
				lenderDeliveryReview: this.state.lenderReviewTwo,
				lenderOpenReview: this.state.lenderOpenField,
				action: 'submit-lender-review',
				rentalId: this.state.rental.id,
			});

			const second = axios.put(`/api/closet/${articleId}`, {
				lenderConditionReview: this.state.lenderReviewOne,
				lenderDeliveryReview: this.state.lenderReviewTwo,
				lenderOpenReview: this.state.lenderOpenField,
			});

			try {
				// TODO: create review object from state - add success message - add new render state? 'submitted' - make different submit functions depending on borrower or lender
				await Promise.all([first, second]);
			} catch (err) {
				console.error(error);
			}
		}

		this.setState({
			renderState: 'SUCCESS',
		});
	}

	getHandle(handle) {
		const index = handle.lastIndexOf('instagram.com/');
		return index !== -1 ? handle.slice(index + 14) : handle;
	}

	// async handleClaimFormSubmit(e, formType) {
	// 	e.preventDefault();

	// 	if (
	// 		formType === 'inaccurate-listing' &&
	// 		(!this.state.borrowerClaimText || !this.state.borrowerClaimPhoto.length)
	// 	) {
	// 		this.setState({
	// 			error:
	// 				'Please upload a photo and description of the item inaccuracy for us to process your claim.',
	// 		});
	// 	} else {
	// 		try {
	// 			await axios.put(`/api/rentals/${this.state.rental.articleItemId}`, {
	// 				action: formType,
	// 				rentalId: this.state.rental.id,
	// 				borrowerClaimPhoto: this.state.borrowerClaimPhoto,
	// 				borrowerClaimText: this.state.borrowerClaimText,
	// 			});
	// 		} catch (err) {
	// 			console.error(err);
	// 		}

	// 		this.setState({
	// 			renderState: 'CLAIM_PENDING',
	// 		});
	// 	}
	// }

	handleMessageChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	openWidget(widget) {
		widget.open(widget);
	}

	getImageUrl(url) {
		let uploadIndex = url.indexOf('/upload');
		return url.slice(0, uploadIndex + 7) + '/a_ignore' + url.slice(uploadIndex + 7);
	}

	handleshowMeetupOptInModal() {
		// removed b/c made confirmation modal sticky instead:
		// isMobileDevice &&
		// 	window.scrollTo({
		// 		top: 0,
		// 		behavior: 'smooth',
		// 	});

		this.setState({
			showMeetupOptInModal: true,
		});
	}

	async handleOptInMeetup() {
		// populate rental DB column with either borrowerConfirmedMeetup or lenderConfirmedMeetup
		try {
			await axios.put(`/api/rentals/${this.state.rental.articleItemId}`, {
				action: this.state.isBorrower ? 'borrower-opt-in-meetup' : 'lender-opt-in-meetup',
				rentalId: this.state.rental.id,
			});
		} catch (err) {
			console.error(err);
		}

		this.setState({
			showMeetupOptInModal: false,
			renderState: 'ACTIVECONFIRMING',
		});
	}

	handleMeetUpClick() {
		this.setState({
			showMeetupConfirmModal: true,
		});
	}

	async handleConfirmMeetup() {
		// populate rental DB column with either borrowerConfirmedMeetup or lenderConfirmedMeetup
		try {
			await axios.put(`/api/rentals/${this.state.rental.articleItemId}`, {
				action: this.state.isBorrower ? 'borrower-confirm-meetup' : 'lender-confirm-meetup',
				rentalId: this.state.rental.id,
			});
		} catch (err) {
			console.error(err);
		}

		this.setState({
			showMeetupConfirmModal: false,
			renderState: 'ACTIVECONFIRMED',
		});
	}

	async createStripePayment() {
		const rental = this.state.rental;
		try {
			const paymentIntent = await axios.get(`/api/payments/${rental.id}`).then((response) => {
				// console.log('response - from function', response);
				this.setState({
					secret: response.data.clientSecret,
					showCheckoutModal: true,
					rentalForCheckout: rental,
				});
				// return response;
			});
			// console.log('payment intent *', paymentIntent);
		} catch (err) {
			next(err);
		}
	}

	handleStripePaymentSuccess() {
		this.setState({
			showCheckoutModal: false,
		});
	}

	getConfirmLendingModalHtml() {
		const rental = this.state.rental;

		const delOpt = {
			pickup: Boolean(rental.availableForPickup),
			ship: Boolean(rental.availableForShip),
			deliver: Boolean(rental.deliveryAddress),
		};

		const option = Object.entries(delOpt).filter((i) => !!i[1])[0][0];
		const rentalFee = (Number(rental.priceRented) * 0.82).toFixed(2);

		function getDeliveryFee(opt) {
			switch (opt) {
				case 'ship':
					return [
						`You'll send this item by mail; we'll email you a pre-paid shipping label.`,
						Math.max(rentalFee, 0),
					];
				case 'pickup':
					return [
						"You'll coordinate with the borrower to find a public place and time to meet up in your zip code.",
						Math.max(rentalFee, 0),
					];
				case 'deliver':
					return [
						"The item will be delivered to the borrower via Doordash. We'll reach out to find which address the item should be picked up at.",
						Math.max(rentalFee, 0),
					];
				default:
					[
						"You'll coordinate with the lender to find a public place to meet up near the lender's zip code.",
						Math.max(rentalFee, 0),
					];
			}
		}

		const confirmModalHtml = {
			__html: `
				<p>${getDeliveryFee(option)?.[0]}</p>
				<p>You'll be paid $${getDeliveryFee(option)?.[1]} total, paid out through Stripe.</p>
				<p>By lending your item, you are agreeing to our <a href="/terms" target="_blank">terms and conditions</a>.</p>
			`,
		};

		return confirmModalHtml;
	}

	handleKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			// Check if Enter is pressed without the Shift key
			e.preventDefault(); // Prevent the default action to avoid a line break in textarea
			this.submitMessage(e); // Call the method to submit the form
		}
	};

	render() {
		// --------- add back when we have claim process: ------------
		let widget = window.cloudinary.createUploadWidget(
			{
				cloudName: 'micaelascloud',
				uploadPreset: 'ahb8abmz',
				showAdvancedOptions: true,
				maxImageHeight: 400,
				maxImageWidth: 400,
			},
			(error, result) => {
				if (!error && result && result.event === 'success') {
					// console.log('Done! Here is the image info: ', result, result.info);
					let image = this.getImageUrl(result.info.url);
					let thumbnail = this.getImageUrl(result.info.thumbnail_url);

					this.setState({
						borrowerClaimPhoto: image,
						thumbnailUrl: thumbnail,
					});
				}
			}
		);

		return (
			<div className="container-rental-page">
				<div className={`${BASE_CLASS}`}>
					{this.state.showCheckoutModal && (
						<Elements stripe={stripePromise}>
							{/* // TODO: disabled for pay now for now */}
							<CheckoutForm
								clientSecret={this.state.secret}
								rental={this.state.rentalForCheckout}
								onCancel={this.handleStripePaymentCancel}
								onSuccess={this.handleStripePaymentSuccess}
							/>
						</Elements>
					)}
					{this.state.showCancelConfirmModal && (
						<ConfirmationModal
							header="Are you sure?"
							icon="warning"
							text="Are you sure you want to cancel this rental?"
							handleAccept={this.handleConfirmCancelRental}
							handleCancel={this.handleCloseCancelRental}
							continueCtaText="agree and cancel"
							cancelCtaText="nevermind, don't cancel"
						/>
					)}
					{this.state.showConfirmationModal && (
						<ConfirmationModal
							header="All good?"
							icon="flight-arrow"
							html={this.getConfirmLendingModalHtml()}
							handleAccept={() => this.submitRentalConfirmation()}
							handleCancel={() => this.closeConfirmationModal()}
							continueCtaText="Sounds good"
							cancelCtaText="Cancel"
						/>
					)}
					{this.state.showMeetupOptInModal && (
						<ConfirmationModal
							html={{
								__html: `<p>You're confirming that you've met up and exchanged the item and payment. If anything looks off with your rental, please contact us: hello@mysocialcloset.com.</p>`,
							}}
							handleAccept={() => this.handleOptInMeetup()}
							handleCancel={() => this.closeConfirmationModal()}
							continueCtaText="All is good!"
							cancelCtaText="Cancel"
						/>
					)}
					{this.state.showMeetupConfirmModal && (
						<ConfirmationModal
							html={{
								__html: `<p>You're confirming that you've met up and exchanged the item and payment. If anything looks off with your rental, please contact us: hello@mysocialcloset.com.</p>`,
							}}
							handleAccept={() => this.handleConfirmMeetup()}
							handleCancel={() => this.closeConfirmationModal()}
							continueCtaText="All is good!"
							cancelCtaText="Cancel"
						/>
					)}
					{this.state.renderState === 'BLOCKED' && (
						<div className={`${BASE_CLASS}__centered-text`}>
							<h3>Sorry, we couldn’t find the rental you’re looking for.</h3>
							<Link to="/rent">Find something to rent.</Link>
						</div>
					)}

					{this.state.renderState === 'CANCELLED-SUCCESS' && (
						<div className={`${BASE_CLASS}__centered-text`}>
							<h3>Your rental has been successfully cancelled.</h3>
							<Link to="/rent">Find something new to rent</Link>
						</div>
					)}
					{(this.state.renderState === 'PENDING' ||
						this.state.renderState === 'CONFIRMED_PAID' ||
						this.state.renderState === 'CONFIRMED_AWAITING_PAYMENT' ||
						this.state.renderState === 'ACTIVE' ||
						this.state.renderState === 'ACTIVECONFIRMING' ||
						this.state.renderState === 'ACTIVECONFIRMED' ||
						this.state.renderState === 'LENDER_REVIEW' ||
						this.state.renderState === 'BORROWER_REVIEW') && (
						<>
							{/* // lender  */}
							{!this.state.isBorrower && (
								<div className={`${BASE_CLASS}__left`}>
									<h2>Your Rental Overview</h2>
									<p>Get all the details on your upcoming rental.</p>
									{this.state.rental.imageThumbnail && (
										<img src={this.state?.rental?.imageThumbnail} loading="lazy" />
									)}

									<a href={`/lender/${this.state?.borrower?.id}`}>
										<h3>Borrower Information: </h3>
										<p>
											<span>Name: </span>
											{this.state.borrower?.name}
										</p>
										<p>
											<span>Instagram: </span>
											{this.state.borrower?.instagramHandle
												? `${'@' + this.getHandle(this.state.borrower?.instagramHandle)}`
												: null}
										</p>{' '}
									</a>
									<h3>Rental Details:</h3>
									<p>
										<span>Rental price: </span> ${this.state.rental.priceRented}
									</p>
									<p>
										<span>Rental start date: </span>{' '}
										{fromUnixTime(this.state.rental.firstDateOfRental).toDateString()}
									</p>
									<p>
										<span>Rental end date: </span>
										{fromUnixTime(this.state.rental.lastDateOfRental).toDateString()}
									</p>
									<Link to={`/rent/${this.state.rental.articleItemId}`}>See listing</Link>
								</div>
							)}
							{/* // borrower */}
							{this.state.isBorrower && (
								<div className={`${BASE_CLASS}__left`}>
									<h2>Your Rental Overview</h2>
									<p>Get all the details on your upcoming rental.</p>
									{this.state.rental.imageThumbnail && (
										<img src={this.state?.rental?.imageThumbnail} loading="lazy" />
									)}

									<h3>Lender Information:</h3>
									<Link to={`/lender/${this.state.lender?.id}`}>
										<p>
											<span>Name: </span>
											{this.state.lender?.name}
										</p>
										<p>
											<span>Reviews: </span>

											{this.state.lenderReviewsNumber ? (
												<span>
													{' '}
													<span>
														<Review
															reviewStarNumber={Math.round(this.state.lenderReviewsAverage)}
														/>{' '}
													</span>
													<span>({this.state.lenderReviewsNumber})</span>
												</span>
											) : (
												<span>This lender has no reviews yet.</span>
											)}
										</p>
										<p>
											<span>Instagram: </span>
											{this.state.lender?.instagramHandle
												? `${'@' + this.getHandle(this.state.lender?.instagramHandle)}`
												: null}
										</p>
									</Link>

									<h3>Rental Details:</h3>
									<p>
										<span>Rental price: </span> ${this.state.rental.priceRented}
									</p>
									<p>
										<span>Rental start date: </span>
										{fromUnixTime(this.state.rental.firstDateOfRental).toDateString()}
									</p>
									<p>
										<span>Rental end date: </span>
										{fromUnixTime(this.state.rental.lastDateOfRental).toDateString()}
									</p>
									<Link to={`/rent/${this.state.rental.articleItemId}`}>see listing</Link>
								</div>
							)}

							{this.state.renderState !== 'LENDER_REVIEW' &&
								this.state.renderState !== 'BORROWER_REVIEW' && (
									<div className={`${BASE_CLASS}__right`}>
										<h2>Need help?</h2>
										<p>Here are some things you can do.</p>
										{this.state.renderState === 'CONFIRMED_AWAITING_PAYMENT' &&
											this.state.isBorrower && (
												<button onClick={() => this.createStripePayment()}>Pay Now</button>
											)}
										{this.state.renderState === 'ACTIVE' && (
											<button type="button" onClick={this.handleshowMeetupOptInModal}>
												Mark as {this.state.isBorrower && 'Received'}{' '}
												{!this.state.isBorrower && 'Delivered'}
											</button>
										)}

										{/* // if borrower confirmed meet up - show button for lender */}
										{this.state.renderState === 'ACTIVECONFIRMING' &&
											!!this.state.rental.borrowerConfirmedMeetup &&
											!this.state.isBorrower && (
												<button type="button" onClick={this.handleMeetUpClick}>
													Mark as Delivered
												</button>
											)}
										{/* // if lender confirmed meet up - show button for borrower */}
										{this.state.renderState === 'ACTIVECONFIRMING' &&
											!!this.state.rental.lenderConfirmedMeetup &&
											this.state.isBorrower && (
												<button type="button" onClick={this.handleMeetUpClick}>
													Mark as Received
												</button>
											)}
										{this.state.renderState === 'PENDING' && !this.state.isBorrower && (
											<button type="button" onClick={this.handleConfirmRental}>
												Confirm Request
											</button>
										)}

										<div className={`${BASE_CLASS}__comments`}>
											<div className={`${BASE_CLASS}__comment`}>
												{this.state.rentalChat &&
													!!this.state.rentalChat &&
													this.state.rentalChat.map(
														(comm) =>
															comm && (
																<div
																	className={`${BASE_CLASS}__comments__comment`}
																	key={comm?.message}>
																	<div className={`${BASE_CLASS}__comments__comment__signature`}>
																		<span>{comm?.senderName || comm?.sender}</span>
																		<span>on {fromUnixTime(comm?.timestamp).toDateString()}</span>
																	</div>
																	<p>{comm?.message}</p>
																</div>
															)
													)}
												<div ref={this.commentsEndRef} />
											</div>
										</div>

										<form onSubmit={this.submitMessage} className={`${BASE_CLASS}__form`}>
											<textarea
												type="text"
												name="messageToAdd"
												placeholder="Send a message (max 175 characters)"
												onChange={this.handleMessageChange}
												value={this.state.messageToAdd}
												maxLength={190}
												onKeyPress={this.handleKeyPress}
											/>
											<button type="submit">
												Send{' '}
												{this.state.isBorrower
													? this.state.lender?.name
													: this.state.borrower?.name}{' '}
												a Message
											</button>
										</form>
										{this.state.renderState === 'PENDING' && (
											<button type="button" onClick={this.handleCancelRental}>
												Cancel Request
											</button>
										)}

										<a href="mailto:hello@mysocialcloset.com">Contact Social Closet</a>
									</div>
								)}
						</>
					)}
					{this.state.renderState === 'BORROWER_REVIEW' && this.state.isBorrower ? (
						<div className={`${BASE_CLASS}__right`}>
							<h2>How was your experience?</h2>
							<form
								type="submit"
								onSubmit={this.submitReview}
								className={`${BASE_CLASS}__right__reviewform`}>
								<div>
									<p>How would you rate the item?</p>
									<div>
										<span
											onClick={() => this.setStar(1, 'borrowerReviewOne')}
											className={classnames({
												filled: this.state.borrowerReviewOne >= 1,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(2, 'borrowerReviewOne')}
											className={classnames({
												filled: this.state.borrowerReviewOne >= 2,
											})}>
											<Star />
										</span>

										<span
											onClick={() => this.setStar(3, 'borrowerReviewOne')}
											className={classnames({
												filled: this.state.borrowerReviewOne >= 3,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(4, 'borrowerReviewOne')}
											className={classnames({
												filled: this.state.borrowerReviewOne >= 4,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(5, 'borrowerReviewOne')}
											className={classnames({
												filled: this.state.borrowerReviewOne >= 5,
											})}>
											<Star />
										</span>
									</div>
								</div>
								<div>
									<p>How would you rate the accuracy of the description of this item?</p>
									<div>
										<span
											onClick={() => this.setStar(1, 'borrowerReviewTwo')}
											className={classnames({
												filled: this.state.borrowerReviewTwo >= 1,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(2, 'borrowerReviewTwo')}
											className={classnames({
												filled: this.state.borrowerReviewTwo >= 2,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(3, 'borrowerReviewTwo')}
											className={classnames({
												filled: this.state.borrowerReviewTwo >= 3,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(4, 'borrowerReviewTwo')}
											className={classnames({
												filled: this.state.borrowerReviewTwo >= 4,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(5, 'borrowerReviewTwo')}
											className={classnames({
												filled: this.state.borrowerReviewTwo >= 5,
											})}>
											<Star />
										</span>
									</div>
								</div>
								<div>
									<p>How would you rate the communication & delivery of this item?</p>
									<div>
										<span
											onClick={() => this.setStar(1, 'borrowerReviewThree')}
											className={classnames({
												filled: this.state.borrowerReviewThree >= 1,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(2, 'borrowerReviewThree')}
											className={classnames({
												filled: this.state.borrowerReviewThree >= 2,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(3, 'borrowerReviewThree')}
											className={classnames({
												filled: this.state.borrowerReviewThree >= 3,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(4, 'borrowerReviewThree')}
											className={classnames({
												filled: this.state.borrowerReviewThree >= 4,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(5, 'borrowerReviewThree')}
											className={classnames({
												filled: this.state.borrowerReviewThree >= 5,
											})}>
											<Star />
										</span>
									</div>
								</div>
								<div>
									<p>Do you want to add a public comment?</p>
									<input
										type="text"
										name="borrowerOpenField"
										placeholder="Add a public review"
										onChange={(e) => this.handleMessageChange(e)}
										value={this.state.borrowerOpenField}
									/>
								</div>
								{this.state.thumbnailUrl && this.state.thumbnailUrl.length ? (
									<div>
										<img src={this.state.thumbnailUrl} />
									</div>
								) : (
									<div>
										<p>Do you want to show others how you wore it?</p>
										<button type="button" onClick={() => this.openWidget(widget)}>
											add a photo
										</button>
									</div>
								)}
								<button type="submit">submit review</button>
							</form>
						</div>
					) : null}

					{this.state.renderState === 'LENDER_REVIEW' && !this.state.isBorrower && (
						<div className={`${BASE_CLASS}__right`}>
							<h2>How was your experience?</h2>

							<form
								type="submit"
								onSubmit={this.submitReview}
								className={`${BASE_CLASS}__right__reviewform`}>
								<div>
									<p>How would you rate the condition the item was returned in?</p>

									<div>
										<span
											onClick={() => this.setStar(1, 'lenderReviewOne')}
											className={classnames({
												filled: this.state.lenderReviewOne >= 1,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(2, 'lenderReviewOne')}
											className={classnames({
												filled: this.state.lenderReviewOne >= 2,
											})}>
											<Star />
										</span>

										<span
											onClick={() => this.setStar(3, 'lenderReviewOne')}
											className={classnames({
												filled: this.state.lenderReviewOne >= 3,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(4, 'lenderReviewOne')}
											className={classnames({
												filled: this.state.lenderReviewOne >= 4,
											})}>
											<Star />
										</span>

										<span
											onClick={() => this.setStar(5, 'lenderReviewOne')}
											className={classnames({
												filled: this.state.lenderReviewOne >= 5,
											})}>
											<Star />
										</span>
									</div>
								</div>
								<div>
									<p>How would you rate the communication & delivery of this item?</p>

									<div>
										<span
											onClick={() => this.setStar(1, 'lenderReviewTwo')}
											className={classnames({
												filled: this.state.lenderReviewTwo >= 1,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(2, 'lenderReviewTwo')}
											className={classnames({
												filled: this.state.lenderReviewTwo >= 2,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(3, 'lenderReviewTwo')}
											className={classnames({
												filled: this.state.lenderReviewTwo >= 3,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(4, 'lenderReviewTwo')}
											className={classnames({
												filled: this.state.lenderReviewTwo >= 4,
											})}>
											<Star />
										</span>
										<span
											onClick={() => this.setStar(5, 'lenderReviewTwo')}
											className={classnames({
												filled: this.state.lenderReviewTwo >= 5,
											})}>
											<Star />
										</span>
									</div>
								</div>

								<button type="submit" className={`${BASE_CLASS}__review-section__submit-button`}>
									submit review
								</button>
							</form>
						</div>
					)}
					{this.state.renderState === 'SUCCESS' && (
						<div className={`${BASE_CLASS}__centered-text`}>
							<h3>Thanks for submitting your review!</h3>
							<Link to="/rent">Browse Rentals</Link>
						</div>
					)}
				</div>
			</div>
		);
	}
}
