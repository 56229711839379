import React from 'react';

const MessageArrow = () => (
	<svg width="21" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1 8.126 20 1l-9 15.044L9 9.71 1 8.126z"
			stroke="#111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default MessageArrow;
