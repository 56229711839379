import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout, queueModal } from '../../../../store/index';
import { Link } from 'react-router-dom';
import Hamburger from './components/Hamburger';
import { Icon, UserIcon, Instagram, Tiktok } from '../../../../../public';
import './index.scss';

const BASE_CLASS = 'header-mobile';
const MobileHeader = ({ handleLogout, isLoggedIn, handleSignIn }) => {
	const [isNavOpen, setIsNavOpen] = useState(false);
	const [isSubNavOpen, setIsSubNavOpen] = useState(true);

	function handleBlur() {
		setIsNavOpen(!isNavOpen);
	}

	function handleSubNavClick() {
		setIsSubNavOpen(!isSubNavOpen);
	}

	function handleNavClick() {
		setIsNavOpen(!isNavOpen);

		const body = document.getElementsByTagName('body')[0];

		if (!isNavOpen) {
			body.setAttribute('style', 'overflow: hidden');
		} else {
			body.setAttribute('style', 'overflow: auto');
		}
	}

	return (
		<div className={BASE_CLASS} onMouseLeave={() => handleBlur()}>
			<Link to="/home" className={`${BASE_CLASS}__heart-icon`}>
				<Icon />
			</Link>
			{!isNavOpen ? (
				<Hamburger handleClick={handleNavClick} isActive={isNavOpen} />
			) : (
				<div>
					<Hamburger handleClick={() => handleNavClick()} isActive={isNavOpen} />

					{isLoggedIn && (
						<>
							<Link to="/rent" onClick={handleNavClick}>
								Rent
							</Link>
							<Link to="/lend" onClick={handleNavClick}>
								Lend
							</Link>
							<Link to="/activity" onClick={handleNavClick}>
								Activity
							</Link>

							<Link to="/howitworks" onClick={handleNavClick}>
								How it works
							</Link>

							<button onClick={handleSubNavClick}>
								<UserIcon />
							</button>
							{isSubNavOpen && (
								<div className="sub-nav">
									<Link to="/profile" onClick={handleNavClick}>
										Profile
									</Link>
									<Link to="/friends" onClick={handleNavClick}>
										Friends
									</Link>
									<Link to="/closet" onClick={handleNavClick}>
										Closet
									</Link>
								</div>
							)}

							<Link to="/add" className="add" onClick={handleNavClick}>
								List an Item
							</Link>
							<div className="socials">
								<a
									href="https://www.instagram.com/mysocialcloset/"
									target="_blank"
									rel="noreferrer noopener"
									onClick={handleNavClick}>
									<Instagram />
								</a>
								<a
									href="https://www.tiktok.com/@social.closet"
									target="_blank"
									rel="noreferrer noopener"
									onClick={handleNavClick}>
									<Tiktok />
								</a>
							</div>
							<a href="/home" onClick={handleLogout}>
								Log out
							</a>
						</>
					)}

					{!isLoggedIn && (
						<>
							<Link to="/rent" onClick={handleNavClick}>
								Renting
							</Link>
							<Link to="/lend" onClick={handleNavClick}>
								Lending
							</Link>
							<Link to="/howitworks" onClick={handleNavClick}>
								How it works
							</Link>
							<div className="socials">
								<a
									href="https://www.instagram.com/mysocialcloset/"
									target="_blank"
									rel="noreferrer noopener"
									onClick={handleNavClick}>
									<Instagram />
								</a>
								<a
									href="https://www.tiktok.com/@social.closet"
									target="_blank"
									rel="noreferrer noopener"
									onClick={handleNavClick}>
									<Tiktok />
								</a>
							</div>
							<a href="#" className="add" onClick={handleSignIn}>
								Log in - Sign up
							</a>
						</>
					)}
				</div>
			)}
		</div>
	);
};

/**
 * CONTAINER
 */
const mapState = (state) => {
	return {
		isLoggedIn: !!state.user.user.id,
	};
};

const mapDispatch = (dispatch) => {
	return {
		handleLogout() {
			dispatch(logout());
		},
		handleSignIn() {
			dispatch(queueModal('SIGNUP'));
		},
	};
};

export default connect(mapState, mapDispatch)(MobileHeader);

/**
 * PROP TYPES
 */
// MobileHeader.propTypes = {
// 	handleClick: PropTypes.func.isRequired,
// };
