import React, { Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import './index.scss';

const HtmlTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 320,
		fontSize: 16,
		fontWeight: 400,
		border: '1px solid #dadde9',
		fontFamily: 'futura-pt',
		padding: 20,
	},
}))(Tooltip);

const getToolTipHtml = (label) => {
	switch (label) {
		case 'Make available to rent':
			return `<p>
				Social Closet users can request to rent your item. <b>You can approve or reject</b> any rental request.
			</p>`;

		case 'Open to offers to buy':
			return `<p>
				Social Closet users can send you a bid to buy your item. <b>You can approve reject</b> any bid.
			</p>`;

		case 'Make visible to friends?':
			return `<React.Fragment>
			<p>
				If you’re not up for lending your wardrobe to strangers, you can still
				<strong fontWeight="400">share your items with friends.</strong> This means friends can
				see your item, save ones they like, and leave comments for you.
			</p>
			<p>
				<strong>To add friends,</strong> head to the friends page to make a request and confirm
				any friends requests you have.
			</p>
			<p>
				<strong>To see your closet,</strong> head to your closet page (also found under your
				account).
			</p>
		</React.Fragment>`;

		default:
			return null;
	}
};

function Checkbox({ label, checked, onChange, labelHasError, termsLink }) {
	return (
		<>
			{termsLink ? (
				<label className="checkbox">
					<span className="checkbox__input">
						<input type="checkbox" name="checkbox" checked={checked} onChange={onChange} />
						<span className="checkbox__control">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 28 28"
								aria-hidden="true"
								focusable="false">
								<path
									fill="none"
									stroke="#c39ea0"
									strokeWidth="3"
									d="M1.73 12.91l6.37 6.37L22.79 4.59"
								/>
							</svg>
						</span>
					</span>

					<span className="radio__label">
						I agree to the{' '}
						<a href={termsLink} target="_blank">
							terms of use
						</a>{' '}
						*
					</span>
				</label>
			) : getToolTipHtml(label) ? (
				<HtmlTooltip
					arrow
					placement="right"
					title={<div dangerouslySetInnerHTML={{ __html: getToolTipHtml(label) }} />}>
					<label className="checkbox">
						<span className="checkbox__input">
							<input
								type="checkbox"
								name="checkbox"
								checked={checked}
								// defaultChecked={checked}
								onChange={onChange}
							/>
							<span className="checkbox__control">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 28 28"
									aria-hidden="true"
									focusable="false">
									<path
										fill="none"
										stroke="#c39ea0"
										strokeWidth="3"
										d="M1.73 12.91l6.37 6.37L22.79 4.59"
									/>
								</svg>
							</span>
						</span>

						<span className="radio__label">{label}</span>
					</label>
				</HtmlTooltip>
			) : (
				<label className="checkbox">
					<span className="checkbox__input">
						<input
							type="checkbox"
							name="checkbox"
							checked={checked}
							// defaultChecked={checked}
							onChange={onChange}
						/>
						<span className="checkbox__control">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 28 28"
								aria-hidden="true"
								focusable="false">
								<path
									fill="none"
									stroke="#c39ea0"
									strokeWidth="3"
									d="M1.73 12.91l6.37 6.37L22.79 4.59"
								/>
							</svg>
						</span>
					</span>

					<span className="radio__label">{label}</span>
				</label>
			)}
		</>
	);
}

export default Checkbox;
