import React from 'react';

const MeetingArrows = () => (
	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.5 8.75H6.25V12.5" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M12.5 6.25H8.75V2.5" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M8.75 6.25L13.125 1.875"
			stroke="#111111"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.875 13.125L6.25 8.75"
			stroke="#111111"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default MeetingArrows;
