import React from 'react';

const Icon = () => (
	<svg width="129" height="59" viewBox="0 0 129 59" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.84952 11.0991C11.3419 11.7365 12.4649 12.5814 13.2238 13.6336C13.9826 14.6858 14.3569 15.9354 14.3569 17.3721C14.3569 19.4816 13.6841 21.1763 12.3435 22.4663C11.0029 23.7563 9.22729 24.3988 7.01153 24.3988C5.20553 24.3988 3.69294 23.9384 2.47376 23.0126C1.25459 22.0869 0.43 20.7766 0 19.0769L2.47376 17.8577C2.80765 19.2438 3.35906 20.2961 4.12294 21.0094C4.89188 21.7227 5.85306 22.0818 7.01153 22.0818C8.37741 22.0818 9.48529 21.6872 10.3301 20.8981C11.1749 20.1089 11.5999 19.087 11.5999 17.8223C11.5999 16.8004 11.3065 15.9101 10.7146 15.1512C10.1227 14.3975 9.23741 13.755 8.04858 13.2339L5.26117 12.0451C3.73847 11.4077 2.60529 10.5882 1.85659 9.58654C1.10788 8.58489 0.738584 7.38595 0.738584 5.98466C0.738584 4.35572 1.34564 3.01006 2.5547 1.94771C3.76376 0.885358 5.31176 0.349121 7.18858 0.349121C8.56458 0.349121 9.75847 0.642538 10.7652 1.2243C11.7719 1.81113 12.556 2.67112 13.1226 3.80935L10.8664 5.25112C10.3554 4.36583 9.7787 3.70312 9.14635 3.27312C8.50893 2.83806 7.79059 2.62559 6.98623 2.62559C5.96435 2.62559 5.12459 2.93925 4.472 3.56654C3.81941 4.19384 3.49564 4.99312 3.49564 5.95935C3.49564 6.77383 3.74859 7.477 4.24941 8.07394C4.75023 8.67088 5.52929 9.197 6.58153 9.65735L9.84952 11.0991Z"
			fill="black"
		/>
		<path
			d="M44.6394 12.4143C44.6394 14.0534 44.3358 15.5963 43.7288 17.0482C43.1217 18.5001 42.2415 19.785 41.083 20.9131C39.9245 22.0412 38.589 22.9063 37.0815 23.5184C35.5739 24.1305 34.0108 24.4341 32.3919 24.4341C30.7731 24.4341 29.215 24.1305 27.7176 23.5184C26.2202 22.9063 24.8897 22.0412 23.7312 20.9131C22.5626 19.7749 21.6723 18.4798 21.0602 17.033C20.4481 15.5811 20.1445 14.0432 20.1445 12.4143C20.1445 10.7752 20.4481 9.22724 21.0602 7.77535C21.6723 6.31841 22.5626 5.02335 23.7312 3.88512C24.8796 2.76712 26.205 1.90712 27.7075 1.30007C29.2099 0.693006 30.7731 0.394531 32.3919 0.394531C34.0209 0.394531 35.5891 0.698065 37.0966 1.30007C38.6042 1.90712 39.9347 2.76712 41.083 3.88512C42.2415 5.01323 43.1268 6.30324 43.7288 7.76018C44.3358 9.21206 44.6394 10.7651 44.6394 12.4143ZM32.3919 21.8692C33.6465 21.8692 34.8404 21.6315 35.9736 21.1559C37.1068 20.6804 38.1286 19.9924 39.0342 19.0818C39.9296 18.1864 40.6176 17.1645 41.0881 16.0314C41.5585 14.8931 41.7912 13.6891 41.7912 12.4143C41.7912 11.1294 41.5535 9.91019 41.0779 8.75172C40.6024 7.59325 39.9195 6.57135 39.0342 5.68606C38.1337 4.79064 37.1219 4.10265 35.9888 3.63724C34.8556 3.16677 33.6567 2.93407 32.3919 2.93407C31.1171 2.93407 29.9131 3.16677 28.7749 3.63724C27.6366 4.10771 26.6299 4.79064 25.7497 5.68606C24.8492 6.59159 24.1663 7.62359 23.6958 8.77194C23.2254 9.9203 22.9927 11.1344 22.9927 12.4194C22.9927 13.6942 23.2254 14.8931 23.6908 16.0212C24.1562 17.1494 24.8391 18.1712 25.7497 19.0869C26.6704 20.0076 27.6923 20.7007 28.8103 21.1711C29.9334 21.6315 31.1272 21.8692 32.3919 21.8692Z"
			fill="black"
		/>
		<path
			d="M70.8848 6.38932C69.787 5.2005 68.6184 4.32026 67.379 3.75368C66.1346 3.18709 64.7636 2.89873 63.2612 2.89873C62.0521 2.89873 60.8683 3.14661 59.7149 3.63225C58.5615 4.12296 57.5599 4.80591 56.715 5.68109C55.8399 6.58662 55.1822 7.60343 54.7421 8.72649C54.302 9.84955 54.0844 11.089 54.0844 12.4447C54.0844 13.5071 54.216 14.4885 54.474 15.389C54.737 16.2844 55.1266 17.109 55.6476 17.8627C56.5633 19.1679 57.6965 20.1695 59.037 20.8778C60.3776 21.581 61.8245 21.9351 63.3826 21.9351C64.8446 21.9351 66.2003 21.6316 67.4499 21.0245C68.7045 20.4174 69.8427 19.517 70.8797 18.318V21.9351C69.7415 22.7597 68.5173 23.3819 67.207 23.8068C65.8968 24.2267 64.5461 24.4392 63.1448 24.4392C61.9357 24.4392 60.757 24.2571 59.6087 23.8979C58.4603 23.5387 57.3879 23.0126 56.3862 22.3246C54.7168 21.1661 53.442 19.7395 52.5567 18.0499C51.6765 16.3603 51.2363 14.5037 51.2363 12.4751C51.2363 10.7096 51.5297 9.1059 52.1216 7.66414C52.7135 6.21731 53.614 4.90708 54.823 3.72837C55.9916 2.60025 57.2867 1.76049 58.7032 1.19896C60.1196 0.64249 61.6727 0.364258 63.3522 0.364258C64.6776 0.364258 65.9626 0.566612 67.202 0.966259C68.4464 1.36591 69.6707 1.99321 70.8797 2.83803V6.38932H70.8848Z"
			fill="black"
		/>
		<path d="M78.7705 23.8222V0.905762H81.4921V23.8222H78.7705Z" fill="black" />
		<path
			d="M92.4096 23.822H89.4502L99.841 0L110.267 23.822H107.308H92.4096ZM99.8461 6.53094L93.907 21.7074L106.261 21.667L99.8461 6.53094Z"
			fill="black"
		/>
		<path
			d="M99.2447 26.493L97.7979 25.117L100.095 22.6988L97.813 20.392L99.2295 18.9907L102.872 22.6685L99.2447 26.493Z"
			fill="black"
		/>
		<path d="M117.623 23.8725V0.956055H120.345V21.2723H129V23.8725H117.623Z" fill="black" />
		<path
			d="M19.6485 39.7422C18.5507 38.5534 17.3821 37.6731 16.1427 37.1065C14.8982 36.5399 13.5273 36.2516 12.0248 36.2516C10.8158 36.2516 9.632 36.4995 8.47859 36.9851C7.32517 37.4758 6.32858 38.1588 5.4787 39.0339C4.60352 39.9395 3.94588 40.9563 3.50576 42.0794C3.06564 43.2024 2.84811 44.4418 2.84811 45.7976C2.84811 46.8599 2.97964 47.8413 3.23764 48.7418C3.49564 49.6423 3.89023 50.4618 4.41129 51.2156C5.32693 52.5208 6.46012 53.5224 7.80071 54.2306C9.14129 54.9338 10.5881 55.2879 12.1462 55.2879C13.6082 55.2879 14.964 54.9844 16.2135 54.3774C17.4681 53.7754 18.6063 52.8698 19.6434 51.6709V55.2879C18.5052 56.1125 17.2809 56.7348 15.9707 57.1597C14.6605 57.5796 13.3098 57.7921 11.9085 57.7921C10.6994 57.7921 9.5207 57.6099 8.37235 57.2508C7.22399 56.8916 6.15153 56.3655 5.14988 55.6775C3.48047 54.519 2.20565 53.0924 1.32035 51.4028C0.440118 49.7131 0 47.8565 0 45.833C0 44.0675 0.293412 42.4638 0.885294 41.022C1.47718 39.5752 2.37765 38.265 3.5867 37.0863C4.75529 35.9582 6.05035 35.1184 7.46682 34.5569C8.88329 34.0004 10.4364 33.7222 12.1159 33.7222C13.4413 33.7222 14.7262 33.9245 15.9656 34.3242C17.2101 34.7238 18.4343 35.3511 19.6434 36.1959V39.7422H19.6485Z"
			fill="black"
		/>
		<path d="M26.7002 57.7514V34.835H29.4218V55.1512H38.0775V57.7514H26.7002Z" fill="black" />
		<path
			d="M82.4638 45.0083C83.9561 45.6457 85.0792 46.4905 85.838 47.5428C86.5969 48.595 86.9712 49.8445 86.9712 51.2813C86.9712 53.3908 86.2984 55.0855 84.9578 56.3755C83.6172 57.6655 81.8415 58.3079 79.6258 58.3079C77.8198 58.3079 76.3072 57.8476 75.088 56.9218C73.8689 56.0011 73.0443 54.6858 72.6143 52.9861L75.088 51.7669C75.4219 53.153 75.9733 54.2053 76.7372 54.9186C77.5061 55.6318 78.4673 55.991 79.6258 55.991C80.9917 55.991 82.0996 55.5964 82.9444 54.8072C83.7892 54.0181 84.2141 52.9962 84.2141 51.7315C84.2141 50.7096 83.9207 49.8192 83.3288 49.0604C82.737 48.3067 81.8517 47.6642 80.6628 47.1431L77.8754 45.9543C76.3527 45.3169 75.2196 44.4974 74.4709 43.4957C73.7221 42.4941 73.3528 41.2951 73.3528 39.8938C73.3528 38.2649 73.9599 36.9192 75.169 35.8569C76.378 34.7945 77.926 34.2583 79.8028 34.2583C81.1788 34.2583 82.3727 34.5517 83.3794 35.1335C84.3861 35.7203 85.1703 36.5803 85.7368 37.7185L83.4806 39.1603C82.9697 38.275 82.393 37.6123 81.7606 37.1823C81.1232 36.7473 80.4048 36.5348 79.6005 36.5348C78.5786 36.5348 77.7388 36.8484 77.0863 37.4757C76.4337 38.103 76.1099 38.9023 76.1099 39.8685C76.1099 40.683 76.3628 41.3862 76.8637 41.9831C77.3645 42.5801 78.1436 43.1062 79.1958 43.5665L82.4638 45.0083Z"
			fill="black"
		/>
		<path
			d="M94.1553 57.7514V34.835H106.241V37.3239H96.882V43.9004H106.241V46.4348H96.882V55.217H106.241V57.7514H94.1553Z"
			fill="black"
		/>
		<path
			d="M119.338 57.7514V37.4352H112.857V34.835H128.54V37.4352H122.09V57.7514H119.338Z"
			fill="black"
		/>
		<path
			d="M65.3853 48.0235L62.947 48.1246L64.3989 46.5362C64.5456 46.3996 64.6721 46.2529 64.839 46.0606L65.2639 45.5952L65.3044 45.4789C66.1644 44.2648 66.6197 42.8533 66.6197 41.3913C66.6197 37.4707 63.4326 34.2837 59.5121 34.2837C57.0433 34.2837 54.863 35.5484 53.5882 37.4657C52.3134 35.5484 50.133 34.2837 47.6643 34.2837C43.7437 34.2837 40.5566 37.4707 40.5566 41.3913C40.5566 42.7825 40.9613 44.1282 41.7303 45.2917L42.0035 45.6559C42.2716 46.0101 42.5751 46.3389 42.8382 46.5716L43.6577 47.467L43.8499 52.1059L46.2124 52.0098L46.1365 50.1886L53.5831 58.3536L60.6908 50.5782L65.4815 50.3809L65.3853 48.0235ZM47.553 48.241L50.1836 48.4939L50.4112 46.1416L45.1703 45.6357L44.5025 44.9022C44.2597 44.6846 44.0472 44.4469 43.8651 44.204L43.769 44.0877C43.2125 43.2884 42.9242 42.3576 42.9242 41.3964C42.9242 38.781 45.0489 36.6563 47.6643 36.6563C50.2797 36.6563 52.4044 38.781 52.4044 41.3964H54.7719C54.7719 38.781 56.8966 36.6563 59.5121 36.6563C62.1275 36.6563 64.2522 38.781 64.2522 41.3964C64.2522 42.4082 63.9233 43.3845 63.296 44.2192L63.0178 44.5531C62.9369 44.6442 62.861 44.7403 62.7143 44.8819L61.3433 46.3794V44.5379L58.9758 44.5683V48.9644L53.5932 54.8529L47.553 48.241Z"
			fill="black"
		/>
	</svg>
);

export default Icon;
