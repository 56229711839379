import React from 'react';

const Blocks = () => (
	<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g
			clipPath="url(#a)"
			stroke="#111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round">
			<path d="M16.667 1.666H3.333c-.92 0-1.666.746-1.666 1.667v3.333c0 .92.746 1.667 1.666 1.667h13.334c.92 0 1.666-.747 1.666-1.667V3.333c0-.92-.746-1.667-1.666-1.667zM16.667 11.666H3.333c-.92 0-1.666.746-1.666 1.667v3.333c0 .92.746 1.667 1.666 1.667h13.334c.92 0 1.666-.746 1.666-1.667v-3.333c0-.92-.746-1.667-1.666-1.667z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default Blocks;
