import React, { Component } from 'react';
import rentals, { getAllBorrowerRentals, getAllLenderRentals } from '../../store/rentals';
import store from '../../store';
import './index.scss';
const BASE_CLASS = 'inbox';

class Inbox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
		};
	}

	componentDidMount() {
		store.dispatch(getAllBorrowerRentals());
		store.dispatch(getAllLenderRentals());
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));

		this.setState({
			borrowerRentals: this.state.rentals.borrowerRentals,
			lenderRentals: this.state.rentals.lenderRentals,
		});
	}

	render() {
		const chatsAsLenderRaw = this.state.rentals.lenderRentals?.map((rental) => rental.chat);
		const chatsAsLenderNotEmpty = chatsAsLenderRaw?.map((msg) =>
			msg.filter((m) => m).map((m) => m)
		);
		// array of chat threads as lender from different rentals:
		const chatsAsLenderParsed = chatsAsLenderNotEmpty
			?.filter((chat) => chat.length)
			.map((thread) => thread?.map((chat) => JSON.parse(chat)));

		const chatAsBorrowerRaw = this.state.rentals.borrowerRentals?.map((rental) => rental.chat);
		const chatsAsBorrowerNotEmpty = chatAsBorrowerRaw?.map((msg) =>
			msg.filter((m) => m).map((m) => m)
		);
		// array of chat threads as borrower from different rentals:
		const chatsAsBorrowerParsed = chatsAsBorrowerNotEmpty
			?.filter((chat) => chat.length)
			.map((thread) => thread?.map((chat) => JSON.parse(chat)));

		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__spotlight`}>inbox</div>
				{/* {chatsAsBorrowerParsed?.map((chat) => (
					<p>{JSON.parse(chat)}</p>
				))} */}
			</div>
		);
	}
}

export default Inbox;
