const getAverageUserReviews = (lenderArticles) => {
	// let deliveryReviews = lenderArticles
	// 	.filter((article) => (article.borrowerDeliveryReview))
	// 	.map((article) => Number(article.borrowerDeliveryReview));

	// let descriptionReviews = lenderArticles
	// 	.filter((article) => (article.borrowerDescriptionReview))
	// 	.map((article) => Number(article.borrowerDescriptionReview));

	// let overallReviews = lenderArticles
	// 	.filter((article) => (article.borrowerOverallReview))
	// 	.map((article) => Number(article.borrowerOverallReview));

	// let sumOfDeliveryReviews = deliveryReviews?.reduce((total, num) => {
	// 	return total + Math.round(num);
	// }, 0);

	// let sumOfDescriptionReviews = descriptionReviews?.reduce((total, num) => {
	// 	return total + Math.round(num);
	// }, 0);

	// let sumOfOverallReviews = overallReviews?.reduce((total, num) => {
	// 	return total + Math.round(num);
	// }, 0);

	// let lenderReviewsAverage =
	// 	(sumOfDeliveryReviews + sumOfDescriptionReviews + sumOfOverallReviews) /
	// 	(deliveryReviews.length + descriptionReviews.length + overallReviews.length);

	// let lenderReviewsNumber = Math.round(
	// 	(deliveryReviews.length + descriptionReviews.length + overallReviews.length) / 3
	// );
	let borrowerDescriptionReviewsArray = lenderArticles
		?.filter((article) => article.borrowerDescriptionReview)
		?.map(
			(article) =>
				Number(article.borrowerDescriptionReview) * Number(article.borrowerDescriptionReviewNumber)
		);

	let sumOfBorrowerDescriptionReviews = borrowerDescriptionReviewsArray?.reduce((total, num) => {
		return total + Math.round(num);
	}, 0);

	let borrowerDescriptionCount = lenderArticles
		?.filter((article) => article.borrowerDescriptionReview)
		?.map((article) => Number(article.borrowerDescriptionReviewNumber) || 1)
		?.reduce((total, sum) => total + sum, 0);

	let borrowerDeliveryReviewsArray = lenderArticles
		?.filter((article) => article.borrowerDeliveryReview)
		?.map(
			(article) =>
				Number(article.borrowerDeliveryReview) * Number(article.borrowerDeliveryReviewNumber)
		);

	let borrowerDeliveryCount = lenderArticles
		?.filter((article) => article.borrowerDeliveryReview)
		?.map((article) => Number(article.borrowerDeliveryReviewNumber) || 1)
		?.reduce((total, sum) => total + sum, 0);

	let sumOfBorrowerDeliveryReviews = borrowerDeliveryReviewsArray?.reduce((total, num) => {
		return total + Math.round(num);
	}, 0);

	let borrowerOverallReviewsArray = lenderArticles
		?.filter((article) => article.borrowerOverallReview)
		?.map(
			(article) =>
				Number(article.borrowerOverallReview) * Number(article.borrowerOverallReviewNumber)
		);

	let borrowerOverallCount = lenderArticles
		?.filter((article) => article.borrowerOverallReview)
		?.map((article) => Number(article.borrowerOverallReviewNumber) || 1)
		?.reduce((total, sum) => total + sum, 0);

	let sumOfBorrowerOverallReviews = borrowerOverallReviewsArray?.reduce((total, num) => {
		return total + Math.round(num);
	}, 0);

	let lenderReviewsNumber =
		Math.round(borrowerDescriptionCount + borrowerDeliveryCount + borrowerOverallCount) / 3;

	let lenderReviewsAverage =
		(Number(sumOfBorrowerDescriptionReviews) +
			Number(sumOfBorrowerOverallReviews) +
			Number(sumOfBorrowerDeliveryReviews)) /
		(lenderReviewsNumber * 3);

	return [Math.round(lenderReviewsAverage), lenderReviewsNumber];
};

export default getAverageUserReviews;

// GET AVERAGE REVIEWS LEFT BY LENDER FOR BORROWER
// const getAverageUserReviews = (lenderArticles) => {
// 	let deliveryReviews = lenderArticles
// 		.filter((article) => Number(article.lenderDeliveryReview))
// 		.map((article) => Number(article.lenderDeliveryReview));

// 	let conditionReviews = lenderArticles
// 		.filter((article) => Number(article.lenderConditionReview))
// 		.map((article) => Number(article.lenderConditionReview));

// 	let sumOfDeliveryReviews = deliveryReviews?.reduce((total, num) => {
// 		return total + Math.round(num);
// 	}, 0);

// 	let sumOfConditionReviews = conditionReviews?.reduce((total, num) => {
// 		return total + Math.round(num);
// 	}, 0);

// 	let lenderReviewsAverage =
// 		(sumOfDeliveryReviews + sumOfConditionReviews) /
// 		(deliveryReviews.length + conditionReviews.length);

// 	let lenderReviewsNumber = Math.round((deliveryReviews.length + conditionReviews.length) / 2);

// 	return [lenderReviewsAverage, lenderReviewsNumber];
// };

// export default getAverageUserReviews;
