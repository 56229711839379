import React from 'react';

const Star = () => (
	<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.42885 1L12.0774 6.64218L18.0003 7.5525L13.7146 11.9419L14.726 18.1429L9.42885 15.2136L4.13171 18.1429L5.14314 11.9419L0.857422 7.5525L6.78028 6.64218L9.42885 1Z"
			fill="white"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Star;
