import React, { Component } from 'react';
import { getDresses } from '../../store/closet';
import { Link } from 'react-router-dom';
import store from '../../store';
import './index.scss';
const BASE_CLASS = 'lend-home';
import Back from '../../../public/icons/Back';
import history from '../../../client/history';

import ArticleCard from '../atoms/ArticleCard';

class LendHome extends Component {
	constructor() {
		super();
		this.state = {
			...store.getState(),
			rentableItems: [],
		};
	}

	componentDidMount() {
		store.dispatch(getDresses());
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.closet.dresses !== this.state.closet.dresses) {
			this.setState({
				rentableItems: this.state.closet.dresses.filter(
					(item) => !!item.rentable || !!item.public || !!item.sellprice
				),
			});
		}
	}

	render() {
		return (
			<div className="container-lend-home">
				<div className={BASE_CLASS}>
					<h1>Lend your wardrobe</h1>
					<Link to="/activity">See your rental activity</Link>

					{!!this.state.rentableItems.length ? (
						<p>Click an article to see details and make changes to an item.</p>
					) : (
						<h3>
							<Link to="/add">Add an item</Link> to start lending.
						</h3>
					)}

					<div className={`${BASE_CLASS}__table`}>
						{!!this.state.rentableItems.length &&
							this.state.rentableItems.map((article) => (
								<Link to={{ pathname: `/lend/${article.id}`, state: article }} key={article.id}>
									<ArticleCard article={article} cardType="lendItem" />
								</Link>
							))}
					</div>
				</div>
			</div>
		);
	}
}

export default LendHome;
