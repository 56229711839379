import React from 'react';

const Edit = () => (
	<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.333 2.5 17.5 6.667 6.667 17.5H2.5v-4.167L13.333 2.5z"
			stroke="#111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Edit;
