import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
const BASE_CLASS = 'about';
import { One, Two, Three, Four } from '../../../public/icons/index';

const About = () => {
	return (
		<div className={BASE_CLASS}>
			<div className={`${BASE_CLASS}__title`}>
				about <span>social closet</span>{' '}
			</div>
			<h4>Social Closet is a place for people to rent and lend their wardrobes.</h4>
			<p>At Social Closet, we care about our community and our earth. We're all about:</p>
			<div className={`${BASE_CLASS}__cards`}>
				<div>
					<One />
					<h3>discovery</h3>
					<p>explore and discover your style, without regret purchases</p>
				</div>
				<div>
					<Two />
					<h3>appreciation</h3>
					<p>we see clothing as an investment crafted by professionals, not disposable</p>
				</div>
				<div>
					<Three />
					<h3>responsible consumption</h3>
					<p>we hold ourselves accountable for keeping clothes out of landfills</p>
				</div>
				<div>
					<Four />
					<h3>respect</h3>
					<p>we respect our clothes, time, and our community</p>
				</div>
			</div>

			<h2>
				To learn more, check out our page for <Link to="/rent">Renting</Link>,{' '}
				<Link to="/lend">Lending</Link>, or our <Link to="/faq">Frequently Asked Questions.</Link>
			</h2>
		</div>
	);
};

export default About;
