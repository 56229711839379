import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { updateUser } from '../../store/user';
import store from '../../store';
import Loader from '../atoms/Loader';
import { Link } from 'react-router-dom';
import './index.scss';

const BASE_CLASS = 'account';

function Account() {
	const profile = useSelector((state) => state.user.user);
	const [isSubmitted, setIsSubmitted] = useState(false); // TODO: do we need error handling?
	const [isSuccessState, setIsSuccessState] = useState(false);
	const [profileData, setProfileData] = useState({
		userId: profile.id,
		avatarUrl: '',
		name: '',
		bio: '',
		username: '',
		instagramHandle: '',
		password: '',
	});

	const uploadImageWidget = () => {
		let myUploadWidget = openUploadWidget(
			{
				cloudName: 'micaelascloud',
				uploadPreset: 'ahb8abmz',
				showAdvancedOptions: true,
				maxImageHeight: 400,
				maxImageWidth: 400,
				sources: ['local', 'url', 'camera'],
			},
			function (error, result) {
				if (!error && result.event === 'success') {
					setProfileData({
						...profileData,
						avatarUrl: result.info.url,
					});
				}
			}
		);
		myUploadWidget.open();
	};

	return (
		<div className={BASE_CLASS}>
			{isSubmitted && <Loader />}

			<div className={`${BASE_CLASS}__name`}>
				hello,<span>{profile?.name}</span>
			</div>

			{!!isSuccessState && (
				<div className={`${BASE_CLASS}__success`}>
					<h4>Your profile details have been updated.</h4>
					<Link to="/profile">go to my profile</Link>
				</div>
			)}
			{!isSuccessState && (
				<>
					<img src={profile?.avatarUrl} className={`${BASE_CLASS}__avatar`} />
					<form onSubmit={(e) => updateProfileSubmit(e)}>
						<button
							type="button"
							onClick={uploadImageWidget}
							className={`${BASE_CLASS}__photo-cta`}>
							update profile photo
						</button>
						<div className={`${BASE_CLASS}__form-body`}>
							<div className={`${BASE_CLASS}__form-body__left`}>
								<p>name</p>
								<input
									value={profileData.name}
									placeholder={profile?.name}
									onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
								/>
								<p>username</p>
								<input
									value={profileData.username}
									placeholder={profile?.username}
									onChange={(e) => setProfileData({ ...profileData, username: e.target.value })}
								/>
								<p>instagram handle</p>
								<input
									value={profileData.instagramHandle}
									placeholder={profile?.instagramHandle || 'https://'}
									onChange={(e) =>
										setProfileData({ ...profileData, instagramHandle: e.target.value })
									}
								/>
								<p>set new password</p>
								<input
									value={profileData.password}
									placeholder="********"
									onChange={(e) => setProfileData({ ...profileData, password: e.target.value })}
								/>
							</div>
							<div className={`${BASE_CLASS}__form-body__right`}>
								<p>email</p>
								<div className={`${BASE_CLASS}__form-body__email`}>{profile?.email}</div>
								<p>bio</p>
								<input
									value={profileData.bio}
									className="bio"
									placeholder={profile?.biography}
									onChange={(e) => setProfileData({ ...profileData, bio: e.target.value })}
								/>
							</div>
						</div>

						<button type="submit" className={`${BASE_CLASS}__submit`}>
							save changes
						</button>
					</form>
				</>
			)}
		</div>
	);

	async function updateProfileSubmit(e) {
		e.preventDefault();

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});

		setIsSubmitted(true);

		const updatedProfile = await store.dispatch(updateUser(profileData));

		setIsSubmitted(true);
		setTimeout(() => setIsSubmitted(false), 1000);
		setTimeout(() => setIsSuccessState(true), 1000);
	}

	function openUploadWidget(options, callback) {
		return window.cloudinary.openUploadWidget(options, callback);
	}
}

export default Account;
