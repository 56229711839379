import React from 'react';

const Cross = () => (
	<svg width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.52173 8.24695C17.6083 5.8886 21.0045 9.79326 22.7611 22.0897C23.9767 30.5989 32.6151 38.2397 41.2823 38.2397"
			stroke="#5252D4"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.06104 46.3147C12.1911 33.5797 22.5047 22.1525 34.1046 10.8104C36.5117 8.45676 39.613 3.89054 42.4359 2.47913"
			stroke="#5252D4"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Cross;
