import React from 'react';

const Heart = () => (
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 125">
		<g>
			<path
				fill="white"
				d="M48.004,82.682L17.936,45.657c-6.123-7.539-4.975-18.607,2.564-24.73   c7.529-6.102,18.609-4.975,24.725,2.567l3.938,4.841c0,0,0.779,1.229,1.63,0.062c0.736-0.912,3.979-4.903,3.979-4.903   c6.123-7.536,17.191-8.675,24.729-2.567c7.537,6.123,8.688,17.191,2.565,24.73c0,0-28.289,35.3-30.275,37.286   C49.8,84.932,48.004,82.682,48.004,82.682"
			/>
		</g>
	</svg>
);

export default Heart;
