import React from 'react';
import './index.scss';
const BASE_CLASS = 'stars';
import { Star } from '../../../../public/index';
import classnames from 'classnames';

export default function Review({ reviewStarNumber, fillColor }) {
	return (
		<div className={`${BASE_CLASS}`}>
			<span
				className={classnames(`${BASE_CLASS}__star`, fillColor, {
					filled: Number(reviewStarNumber) >= 1,
				})}>
				<Star />
			</span>
			<span
				className={classnames(`${BASE_CLASS}__star`, fillColor, {
					filled: reviewStarNumber >= 2,
				})}>
				<Star />
			</span>
			<span
				className={classnames(`${BASE_CLASS}__star`, fillColor, {
					filled: reviewStarNumber >= 3,
				})}>
				<Star />
			</span>
			<span
				className={classnames(`${BASE_CLASS}__star`, fillColor, {
					filled: reviewStarNumber >= 4,
				})}>
				<Star />
			</span>
			<span
				className={classnames(`${BASE_CLASS}__star`, fillColor, {
					filled: reviewStarNumber >= 5,
				})}>
				<Star />
			</span>
		</div>
	);
}
