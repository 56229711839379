import React, { Component } from 'react';
import store from '../../store';
import axios from 'axios';
import './index.scss';
const BASE_CLASS = 'rental-item';
import { Link, withRouter } from 'react-router-dom';
import Calendar from 'react-calendar';
import getWeek from '../../utils/getWeekFromDay';
import getRecommendations from '../../utils/getRecommendations';
import getAverageUserReviews from '../../utils/getAverageUserReviews';
import { fromUnixTime, sub, add } from 'date-fns';
import ConfirmationModal from '../modals/ConfirmationModal';
import { getComments, addComment } from '../../store/comment';

import { startOfDay, getUnixTime, isThursday, nextThursday, format } from 'date-fns';
import classnames from 'classnames';
import { Heart } from '../../../public/index';
import Carousel from '../atoms/Carousel';
import { getSingleDress } from '../../store/closet';
import { queueModal } from '../../store/modals';
import { getWardrobe } from '../../store/closets';
import { getPublicArticles } from '../../store/closet';
import Review from '../atoms/Review';
// import VideoPlayer from '../atoms/VideoPlayer';
import Checkbox from '../atoms/Checkbox';
import ArticleCard from '../atoms/ArticleCard';
import {
	Clock,
	Add,
	AlertCircle,
	Dollar,
	CalendarIcon,
	MessageArrow,
	MessageCircle,
	MeetingArrows,
	ShoppingCart,
	Package,
	DeliveryTruck,
	Trash,
} from '../../../public/icons';
import Back from '../../../public/icons/Back';
import history from '../../../client/history';

const PROMOS = {
	FIRST10: 10,
	FIRST30: 30,
	FIRST50: 50,
	EARTHEVERYDAY: 15,
	LENDING10: 10,
	LENDING20: 20,
	LENDING30: 30,
	LENDING40: 40,
	LENDING50: 50,
};

class RentalItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			datesRequested: [],
			previousDatesRequested: [],
			today: new Date(),
			submitted: false,
			showAcceptTerms: false,
			error: '',
			likedArticleIds: [],
			messageToAdd: '',
			showCalendar: true,
			neighborhoodAreas: [],
			commentToAdd: '',
			deliverDelivery: false,
			shipDelivery: false,
			pickupDelivery: false,
			promoEntered: '',
		};

		this.handleRequestRental = this.handleRequestRental.bind(this);
		this.handleCalendarChange = this.handleCalendarChange.bind(this);
		this.handleSubmitRental = this.handleSubmitRental.bind(this);
		this.handleDeclineTerms = this.handleDeclineTerms.bind(this);
		this.displayItemAvailabilityClass = this.displayItemAvailabilityClass.bind(this);
		this.toggleArticleLike = this.toggleArticleLike.bind(this);
		this.handleCloseLogin = this.handleCloseLogin.bind(this);
		this.handleMessageChange = this.handleMessageChange.bind(this);
		this.getLender = this.getLender.bind(this);
		this.getReviews = this.getReviews.bind(this);
		this.handleFollow = this.handleFollow.bind(this);
		this.toggleShowCalendar = this.toggleShowCalendar.bind(this);
		this.setRecommendations = this.setRecommendations.bind(this);
		this.reloadPage = this.reloadPage.bind(this);
		this.getNeighborhoodFromZip = this.getNeighborhoodFromZip.bind(this);
		this.submitComment = this.submitComment.bind(this);
		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.getConfirmModalHtml = this.getConfirmModalHtml.bind(this);
		this.handleDeleteComment = this.handleDeleteComment.bind(this);
	}

	componentDidMount() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});

		const articleId = this.props.match.params.articleId;
		store.dispatch(getSingleDress(articleId));

		store.dispatch(getPublicArticles());
		store.dispatch(getComments(articleId));
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
		this.setState({
			// item: this.state.closet.dress,
			followerIds: this.state.user.user.following,
			previousDatesRequested: this.props.location.state?.selectedWeekDates,
			startAndEndDate: this.props.location.state?.datesSelected,
			showCalendar: true,
			// showCalendar: !this.props.location.state?.selectedWeekDates?.length,
		});

		// this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	handleValidatePromo() {
		const promoValue = PROMOS[this.state.promoEntered?.toUpperCase()];
		const message = Object.keys(PROMOS).includes(this.state.promoEntered.toUpperCase())
			? `Great! When the lender confirms your rental, we'll take ${promoValue}% off the final price.`
			: "Sorry, that code isn't valid.";

		this.setState({
			promoValidationMessage: message,
			promoValue: promoValue,
		});
	}

	async getLender() {
		try {
			const lenderId = this.state.closet.dress.userId;
			const lender = await axios.get(`/api/users/${lenderId}`);

			this.setState({
				lender: lender?.data[0],
			});
		} catch (err) {
			console.error(err);
		}
	}

	getWardrobe() {
		const lenderId = this.state.closet.dress?.userId;
		store.dispatch(getWardrobe(lenderId));
	}

	getReviews() {
		const lenderWardrobe = this.state.closets?.wardrobes[0];
		const [reviews, number] = getAverageUserReviews(lenderWardrobe);

		this.setState({
			userReview: reviews,
			userReviewsNumber: number,
		});
	}

	setRecommendations() {
		const recommendations = getRecommendations(
			this.state.closet.dress,
			this.state.closet.publicArticles
		);
		this.setState({
			recommendations,
		});
	}

	reloadPage() {
		window.location.reload();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.articleId !== this.props.match.params.articleId) {
			this.reloadPage();
		}
		if (prevState.closet.dress !== this.state.closet.dress) {
			this.getLender();
			this.getWardrobe();
			const itemColorArr = this.state.closet?.dress?.test
				? this.state.closet.dress.test.split(',')
				: [];

			const reviewPhotos = this.state.closet?.dress?.imageURL
				? this.state.closet.dress.imageURL.split('+++')
				: [];

			this.setState({
				item: { ...this.state.closet.dress, itemColorArr },
				likedArticleIds: this.state.user.user.likedArticleIds,
				reviewPhotos,

				// neighborhoodAreas: this.getNeighborhoodFromZip(this.state.closet.dress.lenderZipCode),
			});

			// this.getNeighborhoodFromZip(this.state.closet.dress.lenderZipCode);
		}

		if (prevState.user.user !== this.state.user.user) {
			this.setState({
				followerIds: this.state.user.user.following,
			});
		}

		if (prevState.closets !== this.state.closets) {
			this.getReviews();
		}

		if (prevState.closet.publicArticles !== this.state.closet.publicArticles) {
			this.setRecommendations();
		}
	}

	async handleFollow(lenderId) {
		const userId = this.state.user?.user?.id;

		if (!userId) {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to save your favorite rentals!'));
			scrollTo(0, 0);
		} else {
			// if the user is already following the lender, remove follow from front and back end:

			const isFollowing = this.state.followerIds?.includes(lenderId);

			if (isFollowing) {
				this.setState({
					followerIds: this.state.followerIds.filter((id) => id !== lenderId),
				});

				const first = axios.put(`/api/users/${userId}`, {
					followingId: lenderId,
					toRemove: true,
				});

				const second = axios.put(`/api/users/${lenderId}`, {
					followerId: userId,
					toRemove: true,
				});

				try {
					await Promise.all([first, second]);
				} catch (err) {
					console.error(err);
				}
			} else {
				// otherwise add it to the front and back end:

				const first = axios.put(`/api/users/${userId}`, {
					followingId: lenderId,
				});

				const second = axios.put(`/api/users/${lenderId}`, {
					followerId: userId,
				});

				try {
					await Promise.all([first, second]);
				} catch (err) {
					console.error(err);
				}

				this.setState({
					followerIds: [...this.state.followerIds, lenderId],
				});
			}
		}
	}

	handleMessageChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	handleCloseLogin() {
		this.setState({
			showLoginModal: false,
		});
	}

	async toggleArticleLike(articleId) {
		const userId = this.state.user.user.id;

		if (!userId) {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to save your favorite rentals!'));
			scrollTo(0, 0);
		} else {
			// if it's already liked, remove like from front & back end:
			if (this.state.likedArticleIds && this.state.likedArticleIds.includes(articleId)) {
				this.setState({
					likedArticleIds: this.state.likedArticleIds.filter((id) => id !== articleId),
				});

				try {
					await axios.put(`/api/users/${userId}`, {
						likedId: articleId,
						addId: false,
					});
				} catch (err) {
					console.error(err);
				}
			} else {
				// if it's not already liked, add like to front & back end:
				this.setState({
					likedArticleIds: this.state.likedArticleIds
						? [...this.state.likedArticleIds, articleId]
						: [articleId],
				});

				try {
					await axios.put(`/api/users/${userId}`, {
						likedId: articleId,
						addId: true,
					});
				} catch (err) {
					console.error(err);
				}
			}
		}
	}

	handleCalendarChange(value, event) {
		const week = getWeek(value).week;
		const weekTimestamps = getWeek(value).weekTimestamps;

		this.setState({
			datesRequested: weekTimestamps,
			previousDatesRequested: null,
			startAndEndDate: [format(week[0], 'MMM dd'), format(week[4], 'MMM dd')],
			error: '',
			showCalendar: false,
		});
	}

	getConfirmModalHtml(delOpt) {
		const option = Object.entries(delOpt).filter((i) => !!i[1])[0][0];
		const promoValue = this.state.promoValue | 0;
		const rentalFee = Number(this.state.item.currentDailyRentalPrice);

		function getDeliveryFee(opt) {
			switch (opt) {
				case 'ship':
					return [
						`You'll receive this item by mail. When it's time to return, you'll receive a pre-paid shipping label for return.`,
						Math.max(15.9 + rentalFee * (promoValue / 100), 0),
					];
				case 'pickup':
					return [
						"You'll coordinate with the lender to find a public place to meet up near the lender's zip code.",
						// Math.max(0 + rentalFee - promoValue, 0),
						Math.max(0 + rentalFee - rentalFee * (promoValue / 100), 0),
					];
				case 'deliver':
					return [
						"The item will be delivered to your location via Doordash. We'll reach out to find which address you want to use.",
						Math.max(10 + rentalFee - rentalFee * (promoValue / 100), 0),
					];
				default:
					[
						"You'll coordinate with the lender to find a public place to meet up near the lender's zip code.",
						Math.max(0 + rentalFee - promoValue, 0),
					];
			}
		}

		const confirmModalHtml = {
			__html: `
				<p>
					If this lender accepts your request, your rental will be confirmed.
				</p>
				<p>${getDeliveryFee(option)?.[0]}</p>
				<p>You'll be charged $${getDeliveryFee(option)?.[1]} total, and you'll pay securely via Stripe.</p>
				<p>By requesting to rent this item, you are agreeing to our <a href="/terms" target="_blank">terms and conditions</a>.</p>
			`,
		};

		return confirmModalHtml;
	}

	handleRequestRental() {
		const userId = this.state.user.user.id;

		if (!userId) {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to rent articles!'));
			scrollTo(0, 0);
		}
		// make sure user has selected a week
		else if (!this.state.datesRequested.length && !this.state.previousDatesRequested.length) {
			this.setState({
				error: "Please choose the week you'd like to rent this item.",
			});
		}
		// make sure user has selected a delivery option
		else if (
			!this.state.deliverDelivery &&
			!this.state.shipDelivery &&
			!this.state.pickupDelivery
		) {
			this.setState({
				error: 'Please choose a delivery option.',
			});
		} else {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});

			const deliveryOption = {
				deliver: this.state.deliverDelivery,
				pickup: this.state.pickupDelivery,
				ship: this.state.shipDelivery,
			};

			this.setState({
				showAcceptTerms: true,
				confirmHtml: this.getConfirmModalHtml(deliveryOption),
			});
		}
	}

	handleDeclineTerms() {
		this.setState({
			showAcceptTerms: false,
		});

		this.props.history.push('/rent');
	}

	async handleDeleteComment(comment) {
		await axios.delete(`/api/comment/${comment.id}`);
		this.reloadPage();
	}

	async handleSubmitRental() {
		const article = this.state.item;
		const user = this.state.user.user;
		const datesRequested = this.state.datesRequested;
		const messageToAdd = this.state.messageToAdd;
		const promoEntered = this.state.promoEntered;
		const promoValue = this.state.promoValue;
		const deliveryOption = {
			deliver: this.state.deliverDelivery,
			pickup: this.state.pickupDelivery,
			ship: this.state.shipDelivery,
		};

		const first = axios.post(`/api/rentals`, {
			article,
			datesRequested,
			user,
			messageToAdd,
			deliveryOption,
			promoEntered,
			promoValue,
		});

		this.setState({
			showAcceptTerms: false,
		});

		try {
			// TODO: have rental returned so we can have the rental ID and then route the user to the new rental page
			await Promise.all([first]).then((res) => {
				this.setState({
					submitted: true,
					// rentalId: '',
				});
			});
		} catch (err) {
			console.error(err);
		}
	}

	displayItemAvailabilityClass({ activeStartDate, date, view }) {
		const timeStampStartOfDay = getUnixTime(startOfDay(date));
		const availableDates = this.state.item?.calendarDatesAvailable;
		const timeStampStartOfToday = getUnixTime(startOfDay(Date.now()));
		const timeStampnextThursday = getUnixTime(nextThursday(Date.now()));
		const dates =
			this.state.closet.dress && this.state.closet.dress.calendarDatesAvailable
				? this.state.closet.dress.calendarDatesAvailable
				: [];

		if (Number(timeStampStartOfDay) < Number(timeStampStartOfToday)) {
			return 'past';
		} else if (
			!isThursday(Date.now()) &&
			Number(timeStampStartOfDay) < Number(timeStampnextThursday)
		) {
			return 'past';
		} else if (
			availableDates &&
			availableDates.includes(timeStampStartOfDay) &&
			this.state.datesRequested &&
			this.state.datesRequested.includes(timeStampStartOfDay)
		) {
			return 'requested';
		} else if (availableDates && availableDates.includes(timeStampStartOfDay)) {
			return 'available';
		}
	}

	toggleShowCalendar() {
		this.setState({
			showCalendar: !this.state.showCalendar,
		});
	}

	async getNeighborhoodFromZip(zip) {
		const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=AIzaSyChKTCF8jV64Nj8pm4VJkoZuNz4NVLqbAA`;
		const first = axios.get(url);

		try {
			await Promise.all([first]).then((response) => {
				const neighborhoodAreas = response[0].data.results[0].postcode_localities;

				this.setState({
					neighborhoodAreas,
				});
			});
		} catch (err) {
			console.error(err);
		}
	}

	submitComment(event) {
		event.preventDefault();

		if (this.state.commentToAdd) {
			const commentObj = {
				text: this.state.commentToAdd,
				commenter: this.state.user.user.name,
				closetId: this.state.item?.id,
				userId: this.state.user.user.id,
			};

			store.dispatch(addComment(this.state.item.id, commentObj, this.state.closet.dress));
			this.setState({
				commentToAdd: '',
			});
		}
	}

	handleCommentChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	toggleCheckbox(property) {
		// this.setState({
		// 	[property]: !this.state[property],
		// });

		if (property === 'deliverDelivery') {
			this.setState({
				deliverDelivery: true,
				shipDelivery: false,
				pickupDelivery: false,
			});
		} else if (property === 'shipDelivery') {
			this.setState({
				deliverDelivery: false,
				shipDelivery: true,
				pickupDelivery: false,
			});
		} else if (property === 'pickupDelivery') {
			this.setState({
				deliverDelivery: false,
				shipDelivery: false,
				pickupDelivery: true,
			});
		}
	}

	render() {
		return (
			<>
				{this.state.submitted ? (
					<div className="confirmation-modal-rental-item">
						<div className="confirmation-modal-rental-item__container" />
						<div className="confirmation-modal-rental-item__inner">
							<h2>great!</h2>
							<div>
								<MessageArrow />
								<p>
									We sent your request to rent this {this.state.item.name} to{' '}
									{this.state.lender?.name}.
								</p>
								<Clock />
								<p>
									{this.state.lender?.name} has 24 hours to respond, so keep an eye on your email
									for updates.
								</p>
								<MeetingArrows />
								<p>
									If {this.state.lender?.name} confirms, you'll receive this item between{' '}
									{format(
										sub(Number(this.state.datesRequested[0] * 1000), {
											days: 3,
										}),
										'MMM dd'
									)}{' '}
									- {format(fromUnixTime(this.state.datesRequested[0]), 'MMM dd')}
								</p>

								<MessageCircle />
								<p>
									You can chat with {this.state.lender?.name} on site. See your Activity page in
									your account for details on all your rentals.
								</p>
							</div>
							<div>
								<Link to="/rent">Back to browse</Link>
								<Link to="/activity">See my rentals</Link>
							</div>
						</div>
					</div>
				) : null}

				{this.state.showAcceptTerms ? (
					<ConfirmationModal
						html={this.state.confirmHtml}
						header="Looks good?"
						icon={null}
						handleAccept={() => this.handleSubmitRental()}
						handleCancel={() => this.handleDeclineTerms()}
						continueCtaText="Agree and Request"
						cancelCtaText="Cancel"
					/>
				) : null}
				{this.state.showLoginModal && (
					<AuthForm
						message={this.state.loginEyebrow}
						name="login"
						handleCloseLogin={this.handleCloseLogin}
						displayName="Sign in"
					/>
				)}

				{!!this.state.item?.rentable ||
				!!this.state.item?.public ||
				!!this.state.item?.sellprice ? (
					<div className={BASE_CLASS} key={this.state.item?.id}>
						<button onClick={() => history.goBack()} className="back">
							<Back />
						</button>
						<div className={`${BASE_CLASS}__left`}>
							{/* {this.state.item?.imageURL && 	<VideoPlayer src={this.state.item?.imageURL}/>} */}
							{this.state.item?.imageUrls && (
								// <Carousel
								// 	images={
								// 		this.state.item?.imageURL
								// 			? [this.state.item?.imageURL].concat(this.state.item.imageUrls)
								// 			: this.state.item?.imageUrls
								// 	}
								// />
								<Carousel images={this.state.item?.imageUrls} />
							)}
							<h4>Reviews</h4>
							{this.state.item?.borrowerOverallReview ? (
								<div className={`${BASE_CLASS}__left__review`}>
									<p>Overall review of item</p>
									<Review reviewStarNumber={this.state.item?.borrowerOverallReview} />
								</div>
							) : null}
							{this.state.item?.borrowerDescriptionReview ? (
								<div className={`${BASE_CLASS}__left__review`}>
									<p>Accuracy of item description</p>
									<Review reviewStarNumber={this.state.item?.borrowerDescriptionReview} />
								</div>
							) : null}
							{this.state.item?.borrowerDeliveryReview ? (
								<div className={`${BASE_CLASS}__left__review`}>
									<p>Lender communication & delivery</p>
									<Review reviewStarNumber={this.state.item?.borrowerDeliveryReview} />
								</div>
							) : null}
							{!this.state.item?.borrowerDeliveryReview &&
								!this.state.item?.borrowerDescriptionReview &&
								!this.state.item?.borrowerOverallReview && (
									<p>No one has reviewed this item yet. You could be the first!</p>
								)}

							{!!this.state.reviewPhotos.length && (
								<>
									<h4>How others wore it</h4>
									<Carousel images={this.state.reviewPhotos} />
								</>
							)}
							<h4>Comments & Questions</h4>

							<div className={`${BASE_CLASS}__left__comments`}>
								{this.state.comment?.comments.map((comm) => (
									<div className={`${BASE_CLASS}__left__comments__comment`} key={comm.text}>
										<div>
											<div className={`${BASE_CLASS}__left__comments__comment__signature`}>
												<span>{comm.commenter ? comm.commenter : 'Anonymous'}</span>
												<span>on {new Date(comm.date).toDateString()}</span>
											</div>
											<p>{comm.text}</p>
										</div>

										{this.state.user.user.id == comm.userId && (
											<div>
												<button onClick={() => this.handleDeleteComment(comm)}>
													<Trash />
												</button>
											</div>
										)}
									</div>
								))}
							</div>
							<form onSubmit={this.submitComment} className={`${BASE_CLASS}__left__form`}>
								<input
									type="text"
									name="commentToAdd"
									placeholder="Add a public comment"
									onChange={this.handleCommentChange}
									value={this.state.commentToAdd}
								/>
								<button type="submit">submit</button>
							</form>
						</div>
						<div className={`${BASE_CLASS}__right`}>
							<h1>
								${this.state.item?.currentDailyRentalPrice} / week to rent{' '}
								<span
									onClick={() => this.toggleArticleLike(this.state.item?.id)}
									className={classnames(`${BASE_CLASS}__right__icon`, {
										[`${BASE_CLASS}__right__icon__liked`]:
											this.state.likedArticleIds &&
											this.state.likedArticleIds?.includes(this.state.item?.id),
									})}>
									<Heart />
								</span>
							</h1>
							{!!this.state.item?.sellprice && <h2>${this.state.item?.sellprice} to buy </h2>}
							{this.state.lender && (
								<h1 className="lender-name">
									Renting from{' '}
									<Link to={`/lender/${this.state.lender?.id}`}>{this.state.lender?.name}</Link>
								</h1>
							)}

							<p>
								<span>{this.state.item?.brand}</span>
								<span>Size {this.state.item?.size?.toUpperCase()}</span>
							</p>
							<p>{this.state.item?.description}</p>
							<div className={`${BASE_CLASS}__right__tags`}>
								{!!this.state.item?.tags?.length &&
									this.state.item?.tags.map((tag) => <h6>#{tag}</h6>)}
								{!!this.state.item?.itemColorArr?.length &&
									this.state.item?.itemColorArr.map((color) => <h6>#{color}</h6>)}
							</div>

							<h5 className="h5-grid">
								<AlertCircle />
								When {this.state.lender?.name} confirms your request
								{this.state.item?.availableForPickup && this.state.item?.availableForShip
									? `, include your delivery preference in your message:`
									: ':'}
								{/* {this.state.neighborhoodAreas && (
								<>
									<span>, which is around:</span>
									{this.state.neighborhoodAreas?.map((area) => (
										<p>{area}</p>
									))}
								</>
							)} */}
							</h5>

							{this.state.item?.availableForPickup && (
								<h5 className="h5-grid indent">
									{/* <MeetingArrows /> */}
									<Checkbox
										label=""
										onChange={() => this.toggleCheckbox('pickupDelivery')}
										checked={!!this.state.pickupDelivery}
										labelHasError={this.state.hasRentalRequiredFieldError}
									/>
									<p>
										{' '}
										You can pick the item up in {this.state.lender?.name}'s zip code,{' '}
										{this.state.item?.lenderZipCode}. You'll message {this.state.lender?.name} to
										meet up in a public place.
									</p>
								</h5>
							)}
							{this.state.item?.availableForShip && (
								<h5 className="h5-grid indent">
									{/* <Package /> */}
									<Checkbox
										label=""
										onChange={() => this.toggleCheckbox('deliverDelivery')}
										checked={!!this.state.deliverDelivery}
										labelHasError={this.state.hasRentalRequiredFieldError}
									/>
									{this.state.item?.availableForPickup && this.state.item?.availableForShip
										? 'Or y'
										: 'Y'}
									ou can have this delivered for $5 each way. This delivery fee will be charged to
									your card when your rental is confirmed, and our team will reach out to you for a
									drop-off address.
								</h5>
							)}

							{this.state.item?.list?.includes('shippable') && (
								<h5 className="h5-grid indent">
									{/* <DeliveryTruck /> */}
									<Checkbox
										label=""
										onChange={() => this.toggleCheckbox('shipDelivery')}
										labelHasError={this.state.hasRentalRequiredFieldError}
										checked={!!this.state.shipDelivery}
									/>
									{(this.state.item?.availableForShip || this.state.item?.availableForPickup) &&
									this.state.item?.list?.includes('shippable')
										? 'Or y'
										: 'Y'}
									ou can have this shipped for $7.95 each way. This shipping fee will be charged to
									your card when your rental is confirmed, and our team will reach out to you for a
									shipping address.
								</h5>
							)}

							<h5 className="h5-grid">
								<Dollar />
								You'll pay through Stripe.
							</h5>
							{this.state.item?.list?.includes('sellable') && (
								<h5 className="h5-grid">
									<ShoppingCart />
									Message the lender with an offer if you'd like to buy this item.
								</h5>
							)}

							{!!this.state.startAndEndDate?.length && (
								<h5>
									Renting {this.state.startAndEndDate[0]} - {this.state.startAndEndDate[1]}
								</h5>
							)}
							{this.state.error && <p className={`${BASE_CLASS}__error`}>{this.state.error}</p>}
							{this.state.showCalendar ? (
								<>
									<span>Pick a week to rent:</span>
									{/* <p className={`${BASE_CLASS}__right__calendar-open`}>
										<span>Pick a week to rent:</span>
										<span onClick={this.toggleShowCalendar}>Hide calendar</span>
									</p> */}

									<Calendar
										onClickDay={this.handleCalendarChange}
										defaultActiveStartDate={this.state.today}
										tileClassName={(activeStartDate, date, view) =>
											this.displayItemAvailabilityClass(activeStartDate, date, view)
										}
									/>
								</>
							) : (
								<button
									className={`${BASE_CLASS}__right__change-date`}
									onClick={this.toggleShowCalendar}>
									{this.state.startAndEndDate?.length ? 'Change dates' : 'See availability'}
								</button>
							)}

							<div className={`${BASE_CLASS}__right__promo-lockup`}>
								<input
									className={`${BASE_CLASS}__right__promo-note`}
									type="textarea"
									name="promoEntered"
									placeholder="Promo code"
									onChange={this.handleMessageChange}
									value={this.state.promoEntered}
								/>

								<button
									className={`${BASE_CLASS}__right__apply`}
									type="submit"
									onClick={() => this.handleValidatePromo()}>
									apply
								</button>
							</div>

							<p className={`${BASE_CLASS}__right__promo-error`}>
								{this.state.promoValidationMessage}
							</p>

							<textarea
								className={`${BASE_CLASS}__right__note`}
								name="messageToAdd"
								placeholder="Send an optional note (max 175 characters)"
								onChange={this.handleMessageChange}
								value={this.state.messageToAdd}
								maxLength={190}
							/>

							<button
								className={`${BASE_CLASS}__right__submit`}
								type="submit"
								onClick={() => this.handleRequestRental(this.state.item)}>
								request to rent
							</button>

							<h5 className="h5-grid">
								<Clock />
								{this.state.lender?.name} has 24 hours to confirm or decline your request.
							</h5>
						</div>

						<div className={`${BASE_CLASS}__bottom`}>
							<div className={`${BASE_CLASS}__bottom__lender`}>
								{this.state.lender && (
									<>
										<Link
											to={`/lender/${this.state.lender.id}`}
											className={`${BASE_CLASS}__bottom__lender__image`}>
											{this.state.lender.avatarUrl ? (
												<img src={this.state.lender.avatarUrl} />
											) : (
												<img src="/images/Social-closet-logo.png" />
											)}
										</Link>

										<div className={`${BASE_CLASS}__bottom__lender__info`}>
											<Link to={`/lender/${this.state.lender.id}`}>{this.state.lender.name}</Link>
											<div>
												<Review reviewStarNumber={this.state.userReview} />
												<span>({this.state.userReviewsNumber})</span>
											</div>
											<button type="button" onClick={() => this.handleFollow(this.state.lender.id)}>
												{this.state.followerIds?.includes(this.state.lender.id) ? (
													<div className="unfollow">
														<Add />
														<span>unfollow</span>
													</div>
												) : (
													<div className="add">
														<Add />
														<span>follow</span>
													</div>
												)}
											</button>
										</div>
										{this.state.lender.bio && (
											<div className={`${BASE_CLASS}__bottom__lender__bio`}>
												<p>{this.state.lender.bio}</p>
											</div>
										)}
									</>
								)}
							</div>

							{this.state.closets.wardrobe && (
								<>
									<div className={`${BASE_CLASS}__bottom__subtitle`}>
										<span>more from this lender</span>
										<Link to={`/lender/${this.state.closet?.dress?.userId}`}>See all</Link>
									</div>
									<div className={`${BASE_CLASS}__bottom__wardrobe`}>
										{this.state.closets.wardrobe.slice(0, 5).map((article) => (
											<ArticleCard
												article={article}
												toggleArticleLike={this.toggleArticleLike}
												likedArticleIds={this.state.likedArticleIds}
											/>
										))}
									</div>
								</>
							)}

							{this.state.recommendations && (
								<>
									<div className={`${BASE_CLASS}__bottom__subtitle`}>
										<span>You might also like:</span>
									</div>
									<div className={`${BASE_CLASS}__bottom__wardrobe`}>
										{this.state.recommendations.slice(0, 5).map((article) => (
											<ArticleCard
												article={article}
												toggleArticleLike={this.toggleArticleLike}
												likedArticleIds={this.state.likedArticleIds}
											/>
										))}
									</div>
								</>
							)}
						</div>
					</div>
				) : (
					<p>Sorry no article found</p>
				)}
			</>
		);
	}
}

export default withRouter(RentalItem);
