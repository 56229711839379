import React from 'react';

const Map = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1787_4060)">
			<path
				d="M0.75 4.5V16.5L6 13.5L12 16.5L17.25 13.5V1.5L12 4.5L6 1.5L0.75 4.5Z"
				stroke="#180A02"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6 1.5V13.5"
				stroke="#180A02"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 4.5V16.5"
				stroke="#180A02"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1787_4060">
				<rect width="18" height="18" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default Map;
