import { createStore, combineReducers, applyMiddleware } from 'redux';
import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import user from './user';
import closet from './closet';
import utils from './utils';
import outfit from './outfit';
import friends from './friends';
import comment from './comment';
import rentals from './rentals';
import modals from './modals';
import closets from './closets';

const reducer = combineReducers({
	user,
	closet,
	utils,
	outfit,
	friends,
	comment,
	rentals,
	modals,
	closets,
});
const middleware = composeWithDevTools(
	applyMiddleware(thunkMiddleware)
	// applyMiddleware(thunkMiddleware, createLogger({ collapsed: true }))
);
const store = createStore(reducer, middleware);

export default store;
export * from './user';
export * from './closet';
export * from './outfit';
export * from './utils';
export * from './friends';
export * from './comment';
export * from './rentals';
export * from './modals';
export * from './closets';
