import React, { Component } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';

import { getPublicArticles } from '../../store/closet';
import { queueModal } from '../../store/modals';
import store from '../../store';
import history from '../../../client/history';
import classnames from 'classnames';
import {
	Illustration1,
	Illustration2,
	Illustration3,
	Illustration4,
} from '../../../public/icons/index';

import axios from 'axios';
import { ArticleCard, MapContainer } from '../index';
import { Arrows } from '../../../public';

import { InView } from 'react-intersection-observer';

const BASE_CLASS = 'home-unauth';

export default class HomeUnauth extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			publicArticles: [],
			newsletterName: '',
			newsletterEmail: '',
			submitted: false,
			collection: null,
		};
		this.getUsers = this.getUsers.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNewsletterSignUp = this.handleNewsletterSignUp.bind(this);
		this.toggleArticleLike = this.toggleArticleLike.bind(this);
		this.handleGetStarted = this.handleGetStarted.bind(this);
		this.getUrl = this.getUrl.bind(this);
	}

	componentDidMount() {
		store.dispatch(getPublicArticles());
		this.getUsers();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
		this.getUrl();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.closet.publicArticles !== this.state.closet.publicArticles) {
			this.setState({
				publicArticles: this.state.closet.publicArticles,
			});
		}
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	getUrl() {
		let urlPath = window?.location?.href;
		if (urlPath) {
			if (urlPath.includes('login')) {
				store.dispatch(queueModal('LOGIN'));
				window.history.replaceState({}, document.title, '/');
				// console.log('document', document);
			} else if (urlPath.includes('signup')) {
				store.dispatch(queueModal('SIGNUP'));
				window.history.replaceState({}, document.title, '/');
			}
		}
	}

	async getUsers() {
		try {
			const res = await axios.get(`/api/users`);
			const lenders = res.data;

			this.setState({
				lenders: lenders,
			});
		} catch (err) {
			console.error(err);
		}
	}

	// openCollection(collection) {
	// 	this.setState({
	// 		collection: collection,
	// 	});
	// }

	handleNewsletterSignUp(e) {
		e.preventDefault();
		axios.post(`/api/newsletter`, {
			newsletterEmail: this.state.newsletterEmail,
			newsletterName: this.state.newsletterName,
		});

		this.setState({
			submitted: true,
		});
	}

	handleGetStarted() {
		const isLoggedIn = !!this.state.user.user.id;
		if (isLoggedIn) {
			history.push('/add');
		} else {
			this.showSignupModal();
		}
	}

	showSignupModal() {
		const isLoggedIn = !!this.state.user.user.id;
		if (!isLoggedIn) {
			store.dispatch(queueModal('SIGNUP'));
			scrollTo(0, 0);
		}
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleLinkClick(page) {
		const isLoggedIn = !!this.state.user.user.id;
		if (!isLoggedIn && page === 'closet') {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to begin tracking your wardrobe'));
			scrollTo(0, 0);
		} else if (!isLoggedIn && page === 'friends') {
			store.dispatch(queueModal('LOGIN', `sign up or log in to check out friends' closets`));
			scrollTo(0, 0);
		} else if (isLoggedIn) {
			history.pushState(page);
		}
	}

	toggleArticleLike(articleId) {
		const isLoggedIn = !!this.state.user.user.id;
		if (!isLoggedIn) {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to save your favorite rentals!'));
			scrollTo(0, 0);
		}
	}

	render() {
		return (
			<div className="container-home-unauth">
				<div className={`${BASE_CLASS}`}>
					<InView triggerOnce="true" threshold=".3">
						{({ inView, ref, entry }) => (
							<div ref={ref}>
								<span className={classnames(`${BASE_CLASS}__top`, { inView: inView })}>
									<div className={classnames(`${BASE_CLASS}__hero`)}>
										<h1>Rent fashion in</h1>
										<h1>your city</h1>

										{this.state.user.user && !this.state.user?.user?.id && (
											<div className={`${BASE_CLASS}__hero__ctas`}>
												<div onClick={() => this.showSignupModal()}>join social closet</div>
												{/* <Link to="/about">learn more</Link> */}
											</div>
										)}
									</div>
									<div className={`${BASE_CLASS}__hero-image-container`}>
										<img src="images/homepage-hero-desktop.jpg" />
										{/* <img className="desktop" src="images/homepage-hero-desktop.jpg" /> */}
										{/* <img className="desktop" src="images/HeroNew.png" /> */}
									</div>
								</span>
							</div>
						)}
					</InView>

					<InView triggerOnce="true" threshold=".2">
						{({ inView, ref, entry }) => (
							<div ref={ref}>
								{!!this.state.publicArticles.length && (
									<div className={classnames(`${BASE_CLASS}__article-section`, { inView: inView })}>
										<h3>Here comes the guest</h3>

										{!!this.state.publicArticles.length && (
											<>
												<div className={`${BASE_CLASS}__article-section__articles`}>
													{this.state.closet.publicArticles
														// .filter((item) => [15, 6, 25, 28, 101, 58, 48, 31].includes(item.id))
														.filter((item) =>
															[1486, 1485, 1058, 1100, 813, 1164, 999, 1487].includes(item.id)
														)
														.map((article) => (
															<ArticleCard
																article={article}
																toggleArticleLike={this.toggleArticleLike}
																cardType="homepage"
															/>
														))}
												</div>

												<Link to="/rent" className="cta-white">
													browse all dresses
												</Link>
											</>
										)}
									</div>
								)}
							</div>
						)}
					</InView>
					<InView triggerOnce="true" threshold=".3">
						{({ inView, ref, entry }) => (
							<div ref={ref}>
								<div
									className={classnames(`${BASE_CLASS}__howitworks-section`, { inView: inView })}>
									<h3>How it works</h3>
									<p className={`${BASE_CLASS}__howitworks-section__subtitle`}>
										Social Closet is a community of people lending, renting and sharing clothes for
										life’s special moments.
									</p>

									<Link to="/rent" className={`${BASE_CLASS}__howitworks-section__cards`}>
										<div>
											<Illustration1 />
											<h3>Find</h3>
											<p>something you love</p>
										</div>
										<div>
											<Illustration2 />
											<h3>Rent</h3>
											<p>in person or with delivery</p>
										</div>
										<div>
											<Illustration3 />
											<h3>Return</h3>
											<p>in great condition</p>
										</div>
										<div>
											<Illustration4 />
											<h3>Review</h3>
											<p>share your experience</p>
										</div>
									</Link>
								</div>
							</div>
						)}
					</InView>

					{/* <InView triggerOnce="true" threshold=".3">
						{({ inView, ref, entry }) => (
							<div ref={ref}>
								<div className={classnames(`${BASE_CLASS}__promo-bo`, { inView: inView })}>
									<div className={`${BASE_CLASS}__promo-bo-img`}></div>
									<div>
										<small>lender spotlight</small>
										<h3>Kissed by Bo</h3>
										<p>
											<b>Braisha Owens</b> is a fashion designer, model, stylist and speaker from
											Columbus, Ohio. We sat down to ask her about the fashion industry, making
											wearable art, and getting dressed.
										</p>

										<div>
											<Link to={`/lender/${835}`}>Rent Kissed By Bo</Link>
											<Link to="/lenders">Read Interview</Link>
										</div>
									</div>
								</div>
							</div>
						)}
					</InView> */}

					<InView triggerOnce="true" threshold=".2">
						{({ inView, ref, entry }) => (
							<div ref={ref} className={`${BASE_CLASS}__article-section-container-two`}>
								{!!this.state.publicArticles.length && (
									<div className={classnames(`${BASE_CLASS}__article-section`, { inView: inView })}>
										<h3>Rent, rock, return</h3>

										{!!this.state.publicArticles.length && (
											<>
												<div className={`${BASE_CLASS}__article-section__articles`}>
													{this.state.closet.publicArticles
														.filter((item) => [844, 778, 845, 1034].includes(item.id))
														// .filter((item) => [38, 41, 66, 30].includes(item.id))
														.map((article) => (
															<ArticleCard
																article={article}
																toggleArticleLike={this.toggleArticleLike}
																cardType="homepage"
															/>
														))}
												</div>
												<Link to="/rent">browse all accessories</Link>
											</>
										)}
									</div>
								)}
							</div>
						)}
					</InView>

					{/* <InView triggerOnce="true" threshold=".3">
					{({ inView, ref, entry }) => (
						<div ref={ref}>
							<div className={classnames(`${BASE_CLASS}__promo-card`, { inView: inView })}>
								<h3>
									make sustainable fashion
									<br /> your side hustle
								</h3>
								<button type="button" onClick={() => this.handleGetStarted()}>
									get started
								</button>
							</div>
						</div>
					)}
				</InView> */}

					{/* <InView triggerOnce="true" threshold=".2">
					{({ inView, ref, entry }) => (
						<div ref={ref} className={`${BASE_CLASS}__article-section-container-three`}>
							{!!this.state.publicArticles.length && (
								<div className={classnames(`${BASE_CLASS}__article-section`, { inView: inView })}>
									<h3>Vintage revivals</h3>
									{!!this.state.publicArticles.length && (
										<>
											<div className={`${BASE_CLASS}__article-section__articles`}>
												{this.state.closet.publicArticles
													.filter((item) => [813, 841, 854, 772].includes(item.id))
													.map((article) => (
														<ArticleCard
															article={article}
															toggleArticleLike={this.toggleArticleLike}
															cardType="homepage"
														/>
													))}
											</div>
											<Link to="/rent">browse all rentals</Link>
										</>
									)}
								</div>
							)}
						</div>
					)}
				</InView> */}

					{/* <InView triggerOnce="true" threshold=".2">
					{({ inView, ref, entry }) => (
						<div ref={ref}>
							<div className={classnames(`${BASE_CLASS}__map-section`, { inView: inView })}>
								<h3>Find something beautiful</h3>
								<p>Just around the corner</p>
								<div className={`${BASE_CLASS}__map-section__map`}>
									<MapContainer articles={this.state.publicArticles} />
								</div>
								<Link to="/rent">browse all rentals</Link>
							</div>
						</div>
					)}
				</InView> */}

					{!this.state.submitted && (
						<div className={`${BASE_CLASS}__newsletter`} id="waitlist">
							<h3>Join the club</h3>
							<p>Join the movement to lend, rent, share more, and buy less.</p>
							<form
								onSubmit={(e) => this.handleNewsletterSignUp(e)}
								className={`${BASE_CLASS}__newsletter__form`}>
								<label>name</label>
								<input
									type="text"
									name="newsletterName"
									value={this.state.newsletterName}
									onChange={this.handleChange}
								/>
								<label>email</label>
								<input
									type="text"
									name="newsletterEmail"
									value={this.state.newsletterEmail}
									onChange={this.handleChange}
								/>
								<button type="submit">Sign up</button>
							</form>
						</div>
					)}

					{!!this.state.submitted && (
						<div className={`${BASE_CLASS}__success`} id="waitlist">
							<div className={`${BASE_CLASS}__success__title`}>
								<Arrows />
								<h2>you're on the list</h2>
								<span>✨</span>
							</div>
							<p className={`${BASE_CLASS}__success__tag`}>
								Keep an eye on your email for exciting things to come.
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}
}
