import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import './index.scss';

const Modal = ({ modal, items, handleModalFalse, currentIndex, featured }) => {
	return (
		<div className={modal ? 'modal' : 'hide'} onClick={handleModalFalse}>
			<img src={featured} />
		</div>
	);
};

class Carousel extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentIndex: 0,
			items: this.props.images,
			modal: false,
			featured: undefined,
			itemNumber: this.props.images ? this.props.images.length : 0,
		};
	}

	componentDidMount() {
		this.setState({ featured: this.state.items[0] });
	}

	responsive = {
		0: { items: 1 },
		1024: { items: 3 },
	};

	slideTo = (i) => this.setState({ currentIndex: i });

	// Do I need this method at all?
	onSlideChanged = (e) => this.setState({ currentIndex: e.item });
	slideNext = () =>
		this.setState({
			currentIndex: items[this.state.currentIndex + 1]
				? this.state.currentIndex + 1
				: this.state.currentIndex,
		});
	slidePrev = () => {
		this.setState({
			currentIndex: items[this.state.currentIndex - 1]
				? this.state.currentIndex - 1
				: this.state.currentIndex,
		});
	};

	toggleModal() {
		this.setState({ modal: !this.state.modal });
	}

	render() {
		return (
			<div className="carousel">
				{this.state.itemNumber > 1 && (
					<RenderThumbs
						items={this.state.items}
						slideNext={this.slideNext}
						slidePrev={this.slidePrev}
						slideTo={this.slideTo}
						responsive={this.responsive}
					/>
				)}

				<Modal
					modal={this.state.modal}
					items={this.state.items}
					handleModalFalse={() => this.toggleModal()}
					currentIndex={this.state.currentIndex}
					featured={this.state.items[this.state.currentIndex]}
				/>
				<div onClick={() => this.toggleModal()}>
					<RenderGallery
						items={this.state.items}
						responsive={this.responsive}
						onSlideChanged={this.onSlideChanged}
						currentIndex={this.state.currentIndex}
						onClick={() => this.toggleModal()}
					/>
				</div>
			</div>
		);
	}
}

const RenderGallery = ({ currentIndex, items, onSlideChanged, responsive }) => {
	return (
		<AliceCarousel
			// dotsDisabled={true}
			disableDotsControls={true}
			buttonsDisabled={true}
			activeIndex={items[0]}
			slideToIndex={currentIndex}
			onSlideChanged={onSlideChanged}
			responsive={responsive}>
			<div className="wrapper">
				<img className="wrapper__img" src={items[currentIndex]} />
			</div>
		</AliceCarousel>
	);
};

// Thumbnails
const RenderThumbs = ({ items, slideNext, slidePrev, slideTo }) => (
	<ul className="thumb-list">
		{items.map((item, i) => (
			<li className="thumb-list__thumb" key={i} onClick={() => slideTo(i)}>
				<img className="thumb-list__thumb__img" src={item} />
			</li>
		))}
	</ul>
);

export default Carousel;
