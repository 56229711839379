import startOfMonth from 'date-fns/startOfMonth';
import isWithinInterval from 'date-fns/isWithinInterval';
import parseISO from 'date-fns/parseISO';
import subMonths from 'date-fns/subMonths';
import endOfMonth from 'date-fns/endOfMonth';

function getPercentageOfWardrobeWorn(dresses) {
	let numOfItemsTotal = dresses.length;

	let itemsWorn = dresses.filter((dress) => dress.wearCount);
	return (itemsWorn.length / numOfItemsTotal) * 100;
}

function getAverageWears(dresses) {
	let itemsWorn = dresses?.filter((dress) => dress.wearCount)?.length;
	let allWears = dresses?.map((dress) => dress.wearCount)?.reduce((a, b) => a + b, 0) / itemsWorn;

	return allWears;
}

function getAvgCostPerWear(dresses) {
	let costPerWears = [];
	dresses?.map((dress) => {
		if (!!dress?.wearCount && !!dress?.cost) {
			costPerWears?.push(dress?.cost / dress?.wearCount);
		}
	});

	let avgCostPerWear = costPerWears?.reduce((a, b) => a + b, 0) / costPerWears?.length;

	return avgCostPerWear;
}

function getMoneySpent(dresses) {
	let monthlySpend = 0;
	let yearlySpend = 0;
	let currDate = new Date();
	let currYear = currDate?.getFullYear();
	let currMonth = currDate?.getMonth() + 1;
	let itemsPurchasedThisYear = 0;

	dresses.map((dress) => {
		if (dress.cost) {
			let yearCreated = dress?.createdAt?.slice(0, 4);
			let monthCreated = dress?.createdAt?.slice(5, 7);

			if (Number(yearCreated) === currYear) {
				yearlySpend += dress?.cost;
				itemsPurchasedThisYear += 1;
			}
			if (Number(yearCreated) === currYear && Number(monthCreated) === currMonth) {
				monthlySpend += dress?.cost;
			}
		}
	});

	if (!!monthlySpend && !!yearlySpend) {
		return { monthlySpend, yearlySpend, itemsPurchasedThisYear };
	} else return { monthlySpend: 0, yearlySpend: 0, itemsPurchasedThisYear: 0 };
}

function getPurchasesThisMonth(articles) {
	let currDate = new Date();
	let currYear = currDate?.getFullYear();
	let currMonth = currDate?.getMonth() + 1;
	let itemsPurchasedThisYear = 0;
	let itemsPurchasedThisMonth = 0;
	let itemsPurchasedOneMonthAgo = 0;

	// const monthStart = startOfMonth(Date.now());

	articles.map((dress) => {
		// TODO: for historical data, use Date fns I think to account for previous months that are in a different year
		let yearPurchased = dress?.datepurchased?.slice(0, 4);
		let monthPurchased = dress?.datepurchased?.slice(5, 7);

		if (Number(yearPurchased) === currYear) {
			itemsPurchasedThisYear += 1;
		}
		if (Number(yearPurchased) === currYear && Number(monthPurchased) === currMonth) {
			itemsPurchasedThisMonth += 1;
		}

		if (Number(yearPurchased) === currYear && Number(monthPurchased) === currMonth) {
			itemsPurchasedOneMonthAgo += 1;
		}
	});

	// console.log({ itemsPurchasedThisMonth, itemsPurchasedThisYear, itemsPurchasedOneMonthAgo });

	return { itemsPurchasedThisYear, itemsPurchasedThisMonth };
}

function getPurchases(articles) {
	// let currDate = new Date();
	// let currYear = currDate?.getFullYear();
	// let currMonth = currDate?.getMonth() + 1;
	let itemsPurchasedThisYear = 0;
	let itemsPurchasedThisMonth = 0;
	let itemsPurchasedOneMonthAgo = 0;
	let itemsPurchasedTwoMonthsAgo = 0;
	let itemsPurchasedThreeMonthsAgo = 0;
	let itemsPurchasedFourMonthsAgo = 0;
	let itemsPurchasedFiveMonthsAgo = 0;

	articles.map((dress) => {
		let datePurchased = parseISO(dress.datepurchased);
		let monthStart = new Date(startOfMonth(Date.now()));
		let oneMonthAgo = subMonths(monthStart, 1);
		let twoMonthsAgo = subMonths(monthStart, 2);
		let threeMonthsAgo = subMonths(monthStart, 3);
		let fourMonthsAgo = subMonths(monthStart, 4);
		let fiveMonthsAgo = subMonths(monthStart, 5);

		let monthEnd = new Date(endOfMonth(Date.now()));
		let oneMonthAgoEnd = subMonths(monthEnd, 1);
		let twoMonthsAgoEnd = subMonths(monthEnd, 2);
		let threeMonthsAgoEnd = subMonths(monthEnd, 3);
		let fourMonthsAgoEnd = subMonths(monthEnd, 4);
		let fiveMonthsAgoEnd = subMonths(monthEnd, 5);

		const isThisMonth = isWithinInterval(datePurchased, {
			start: monthStart,
			end: new Date(Date.now()),
		});

		const isOneMonthAgo = isWithinInterval(datePurchased, {
			start: oneMonthAgo,
			end: oneMonthAgoEnd,
		});

		const isTwoMonthsAgo = isWithinInterval(datePurchased, {
			start: twoMonthsAgo,
			end: twoMonthsAgoEnd,
		});
		const isThreeMonthsAgo = isWithinInterval(datePurchased, {
			start: threeMonthsAgo,
			end: threeMonthsAgoEnd,
		});
		const isFourMonthsAgo = isWithinInterval(datePurchased, {
			start: fourMonthsAgo,
			end: fourMonthsAgoEnd,
		});
		const isFiveMonthsAgo = isWithinInterval(datePurchased, {
			start: fiveMonthsAgo,
			end: fiveMonthsAgoEnd,
		});

		if (isThisMonth) {
			itemsPurchasedThisMonth += 1;
		} else if (isOneMonthAgo) {
			itemsPurchasedOneMonthAgo += 1;
		} else if (isTwoMonthsAgo) {
			itemsPurchasedTwoMonthsAgo += 1;
		} else if (isThreeMonthsAgo) {
			itemsPurchasedThreeMonthsAgo += 1;
		} else if (isFourMonthsAgo) {
			itemsPurchasedFourMonthsAgo += 1;
		} else if (isFiveMonthsAgo) {
			itemsPurchasedFiveMonthsAgo += 1;
		}
	});

	return [
		// {
		// 	x: itemsPurchasedThisYear,
		// 	y: itemsPurchasedThisYear,
		// 	label: itemsPurchasedThisYear.toString(),
		// },
		{
			x: 'itemsPurchasedThisMonth',
			y: itemsPurchasedThisMonth,
			label: itemsPurchasedThisMonth.toString(),
		},
		{
			x: 'itemsPurchasedOneMonthAgo',
			y: itemsPurchasedOneMonthAgo,
			label: itemsPurchasedOneMonthAgo.toString(),
		},
		{
			x: 'itemsPurchasedTwoMonthsAgo',
			y: itemsPurchasedTwoMonthsAgo,
			label: itemsPurchasedTwoMonthsAgo.toString(),
		},
		{
			x: 'itemsPurchasedThreeMonthsAgo',
			y: itemsPurchasedThreeMonthsAgo,
			label: itemsPurchasedThreeMonthsAgo.toString(),
		},
		{
			x: 'itemsPurchasedFourMonthsAgo',
			y: itemsPurchasedFourMonthsAgo,
			label: itemsPurchasedFourMonthsAgo.toString(),
		},
		// {
		// 	x: itemsPurchasedFiveMonthsAgo,
		// 	y: itemsPurchasedFiveMonthsAgo,
		// 	label: itemsPurchasedFiveMonthsAgo.toString(),
		// },
		// 1: itemsPurchasedThisYear,
		// 2: itemsPurchasedThisMonth,
		// 3: itemsPurchasedOneMonthAgo,
		// 4: itemsPurchasedTwoMonthsAgo,
		// 5: itemsPurchasedThreeMonthsAgo,
		// 6: itemsPurchasedFourMonthsAgo,
		// 7: itemsPurchasedFiveMonthsAgo,
	];
}

export {
	getAvgCostPerWear,
	getPercentageOfWardrobeWorn,
	getAverageWears,
	getMoneySpent,
	getPurchasesThisMonth,
	getPurchases,
};
