import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import store from '../../../../store';
import './index.scss';
import axios from 'axios';
import classnames from 'classnames';
import { Heart } from '../../../../../public/index';

const BASE_CLASS = 'post-four';

class PostFour extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			comments: [],
			commentToAdd: '',
			liked: false,
		};

		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.getComments = this.getComments.bind(this);
		this.submitComment = this.submitComment.bind(this);
		this.postComment = this.postComment.bind(this);
		this.togglePostLike = this.togglePostLike.bind(this);
	}

	componentDidMount() {
		this.getComments();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	togglePostLike() {
		this.setState({
			liked: !this.state.liked,
		});

		this.postLike(!this.state.liked);
	}

	async postLike(isLiked) {
		try {
			const post = await axios.put(`/api/blogcomment/4`, { liked: isLiked.toString() });
		} catch (err) {
			console.err(err);
		}
	}

	async getComments() {
		try {
			const res = await axios.get(`/api/blogcomment/4`);
			const postData = res.data;

			// if (!postData[0]) {
			// 	// adding post for first time:
			// 	await axios.post(`/api/blogcomment/4`, {
			// 		blogId: 4,
			// 		topic: 'technology',

			// 		likeCount: 0,
			// 	});
			// } else {
			const comments = res.data[0]?.comment;
			const likeCount = res.data[0].likeCount;

			const commentMapped = comments ? comments.map((c) => JSON.parse(c)) : null;

			this.setState({
				comments: commentMapped,
				likeCount: likeCount,
			});
			// }
		} catch (err) {
			console.error(err);
		}
	}

	async postComment(commentObj) {
		try {
			const post = await axios.put(`/api/blogcomment/4`, commentObj);
		} catch (err) {
			console.err(err);
		}
	}

	submitComment() {
		event.preventDefault();
		const commentObj = {
			commenter: this.state.user?.user.name || 'anonymous',
			comment: this.state.commentToAdd,
			userId: this.state.user?.user.id || null,
			date: Date.now(),
		};

		this.postComment(commentObj);

		this.setState({
			commentToAdd: '',
			comments: this.state.comments ? [...this.state.comments, commentObj] : [commentObj],
		});
	}

	handleCommentChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	render() {
		const localLikeCount = this.state.liked ? 1 : 0;
		let totalLocalLikeCount = (this.state.likeCount || 0) + localLikeCount;
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__top`}>
					<Link to="/fashion-fix">Back</Link>
					<h5>
						the fashion fix
						<div />
					</h5>
				</div>
				<a
					className={`${BASE_CLASS}__top__lockup`}
					href="https://www.linkedin.com/in/vibha-arvind-a21342126"
					target="_blank"
					rel="noreferrer noopener">
					<img src="images/VibhaArvind.jpeg" alt="Vibha Arvind" />
					<small>Vibha Arvind</small>
				</a>
				<h3>
					The Role of Digital Technology in Shaping a Bigger, Better and Bolder Fashion Ecosystem
				</h3>

				<p>
					<b>The fashion industry is at a tipping point of extraordinary change and development:</b>{' '}
					Coming out of a harsh couple of years through disruptions in global forces, the fashion
					ecosystem is reinventing itself like never before. A retail apocalypse, rise of digital
					fashion, demand for transparency of the supply chain have all posed challenges, thereby
					providing opportunities to reshape the industry. Large problems like these require
					solutions that can deliver and create impact at scale.
				</p>
				<img src="images/techInFashion.jpeg" alt="technology transforming the fashion industry" />

				<p>
					To achieve a repeatable and scalable solution, the use of modern digital technology in key
					areas is paramount. Mastering emerging technology and integrating it with rapid
					transformation can make fashion more profitable and sustainable. Technology can augment
					the growth of fashion by not being limited as efficient task performing agents but rather
					by serving as an integral part of running and maintaining the ecosystem. Fashion
					businesses today can either be leaders and changemakers or be left behind in the race for
					relevance. Enabling digitization that impacts all stakeholders in the value chain can very
					well be the difference.
				</p>

				<p>
					Every product goes through an immensely complex life cycle before it is floor-ready,
					making the process almost impossible to trace. At every stage of the supply chain, value
					is created and a footprint is left behind in large forms of unstructured data. Newer
					innovations such as product passports capture and synthesize complex data into a simple,
					readable format that enables digital connectivity of products and processes in the supply
					chain. This essentially means that the product life cycle can be traced end-to-end with
					data containing manufacturing information, value added at every stage, carbon footprint,
					human labor involved, and more. This breakthrough technology not only allows businesses to
					take accountability for merchandise produced, but also empowers consumers to make better
					and more informed purchase decisions.
				</p>

				<p>
					<b>
						Digital infrastructure in fashion also enables small businesses and community-level
						suppliers to integrate into the mainstream.
					</b>{' '}
					With the ability to hold digitally identifiable product information in a globally
					standardized format, a small manufacturer from any corner of the world can gain
					credibility to participate in fashion’s global playing field. For producer communities
					deprived of market linkage and funding, aggregated data of previously-produced products
					can be held as assets for access to loans and other benefits.
				</p>

				<p>
					While innovations like PLM (Product Lifecycle Management) and RFID (Radio Frequency
					Identification Tags) have tackled large scale inventory-related challenges, there has been
					a tendency to follow linear economic models and overlook today’s demand for circularity.
					Further developing these technologies by creating digital identity for products beyond
					sale and through to post consumer life can make fashion truly circular. Data, for where a
					product ends up after use, results in better business decisions for subsequent seasons and
					cycles of production as well.
				</p>

				<p>
					Fashion is an industry that functions predominantly due to expert guidance from
					forecasting and trend analyses. With change in seasons and the overall cyclical evolution
					of trends, forecasting products that can drive business in the market is key. Today, with
					the potential of big data being accessible not just in large volumes but also in
					real-time, insights on micro trends and consumer tastes can be leveraged to produce truly
					profitable merchandise. Inefficiency and inaccuracy of forecasting has resulted in large
					amounts of deadstock fabrics, adding to the negative environmental impact of fashion.
					Surplus/ overstock from production not only generates tons of untreatable waste but also
					drains a company’s resources. Real-time, hyperlocal data and sharper forecasting insights
					have the potential to produce greater sell through percents and to also make fashion
					businesses more sustainable.
				</p>

				<p>
					With rapid globalization of the industry, it is more important now than ever to digitize
					and technologically enable products and processes making it easier to create impact at
					scale. Dynamic and ever-evolving fashion businesses can truly benefit from making use of
					smarter technology to bring home larger profits as well as to uphold greater ethical
					standards.{' '}
					<b>
						Power to synthesize data into actionable intelligence can transform the fashion
						ecosystem to be bigger, better and bolder.
					</b>
				</p>

				<div className={`${BASE_CLASS}__likes`}>
					<span
						onClick={() => this.togglePostLike()}
						className={classnames('heart', {
							[`liked`]: this.state.liked,
						})}>
						<Heart />
					</span>
					<small>{totalLocalLikeCount || 0} people liked this post</small>
				</div>
				{/* <h4 className={`${BASE_CLASS}__comment-header`}>Further thoughts</h4> */}

				<div className={`${BASE_CLASS}__comments`}>
					{this.state.comments?.map((comm) => (
						<div className={`${BASE_CLASS}__comments__comment`} key={comm.text}>
							<div className={`${BASE_CLASS}__comments__comment__signature`}>
								<span>{comm.commenter}</span>
								<span>on {new Date(comm.date).toDateString()}</span>
							</div>
							<p>{comm.comment}</p>
						</div>
					))}
				</div>
				<form type="submit" onSubmit={() => this.submitComment()} className={`${BASE_CLASS}__form`}>
					<input
						type="text"
						name="commentToAdd"
						placeholder="add a comment"
						onChange={this.handleCommentChange}
						value={this.state.commentToAdd}
					/>
					<button type="submit">post</button>
				</form>
			</div>
		);
	}
}

export default PostFour;
