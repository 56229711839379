import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import store from '../../store';
import Carousel from '../atoms/Carousel';
import { getComments, addComment } from '../../store/comment';
import { getSingleDress } from '../../store/closet';
import { getFriendCloset, getFriends } from '../../store/friends';
import { Heart, Article } from '../../../public/index';
import Back from '../../../public/icons/Back';
import history from '../../../client/history';
import { useSelector } from 'react-redux';
import './index.scss';

const BASE_CLASS = 'friend-article';

function FriendArticle(props) {
	const user = useSelector((state) => state.user.user);
	const storeState = useSelector((state) => state);
	const friendId = props.match.params.friendId;
	const comments = useSelector((state) => state.comment.comments);

	const [likedIds, setLikedIds] = useState([]);
	const [isUserRequester, setIsUserRequester] = useState(null);
	const [friendAccount, setFriendAccount] = useState({});
	const [item, setItem] = useState(null);
	const [commentText, setCommentText] = useState('');

	useEffect(() => {
		const dressId = props.match.params.dressId;
		const friendId = props.match.params.friendId;
		store.dispatch(getFriends());
		store.dispatch(getSingleDress(dressId));
		store.dispatch(getComments(dressId));
		store.dispatch(getFriendCloset(friendId));
	}, []);

	useEffect(() => {
		setFriendAccount(storeState.friends.friendAccount);
		setItem(storeState.closet.dress);

		const friendId = props.match.params.friendId;
		const friend = storeState.friends.friends.filter(
			(f) => Number(friendId) === f.requesteeId || Number(friendId) === f.requesterId
		)?.[0];

		const isRequesterBoolean = Boolean(
			Number(friend?.requesterId) == Number(user.id) &&
				Number(friend?.requesteeId) == Number(friendId)
		);

		setIsUserRequester(isRequesterBoolean);

		const friendsArticlesUserLikes = isRequesterBoolean
			? friend?.articlesOfRequesteeLikedByRequester
			: friend?.articlesOfRequesterLikedByRequestee;
		setLikedIds(friendsArticlesUserLikes);
	}, [storeState]);

	const toggleLikeItem = async (dressId) => {
		const friendId = props.match.params.friendId;
		const userId = user.id;
		// if it's already liked, remove like from front & back end:
		if (likedIds && likedIds.includes(dressId)) {
			setLikedIds(likedIds.filter((id) => id !== dressId));

			try {
				await axios.put(`/api/friends/${friendId}`, {
					dressId: dressId,
					userId: user.id,
					isRequester: isUserRequester,
					remove: true,
					requesteeId: Number(isUserRequester ? friendId : userId),
					requesterId: Number(isUserRequester ? userId : friendId),
				});
			} catch (err) {
				next(err);
			}
		} else {
			// if it's not already liked, add like to front & back end:

			setLikedIds(likedIds ? likedIds.concat(dressId) : [dressId]);

			try {
				await axios.put(`/api/friends/${friendId}`, {
					dressId: dressId,
					userId: user.id,
					isRequester: isUserRequester,
					remove: false,
					requesteeId: Number(isUserRequester ? friendId : userId),
					requesterId: Number(isUserRequester ? userId : friendId),
				});
			} catch (err) {
				console.error(err);
			}
		}
	};

	const handleSubmitComment = (e) => {
		e.preventDefault();

		const commentObj = {
			text: commentText,
			commenter: user.name,
			closetId: item.id,
			userId: user.id,
		};

		store.dispatch(addComment(item.id, commentObj, item));
		setCommentText('');
	};

	return (
		<div className={BASE_CLASS}>
			<button onClick={() => history.goBack()} className={`${BASE_CLASS}__back-btn`}>
				<Back />
			</button>
			<div className={`${BASE_CLASS}__friend-info`}>
				{friendAccount && <img src={friendAccount.avatarUrl} />}

				<h4>
					{friendAccount?.username ||
						(friendAccount?.instagramHandle &&
							`${'@' + getHandle(friendAccount?.instagramHandle)}`)}
				</h4>
			</div>

			<div className={`${BASE_CLASS}__item`}>
				<div
					onClick={() => toggleLikeItem(item?.id)}
					className={classnames('heart', {
						liked: likedIds && likedIds.includes(item?.id),
					})}>
					<Heart />
				</div>
				{item?.imageUrls && <Carousel images={item?.imageUrls} />}
				<div className={`${BASE_CLASS}__item__details`}>
					<h1>{item?.name}</h1>
					<h3>{item?.brand}</h3>
					<h3>Size {item?.size?.toUpperCase()}</h3>
					<p>{item?.description}</p>
					<div className={`${BASE_CLASS}__item__tags`}>
						{!!item?.tags?.length && item?.tags.map((tag) => <h6>{tag}</h6>)}
						{!!item?.itemColorArr?.length && item?.itemColorArr.map((color) => <h6>#{color}</h6>)}
					</div>
				</div>
			</div>

			<div className={`${BASE_CLASS}__comment-section`}>
				<h5>
					leave a comment
					<div />
				</h5>

				{!!comments && (
					<div>
						{comments.map((comm) => (
							<div key={comm.text}>
								<h6>
									<span>{comm.commenter}</span>
									<span>on {new Date(comm.date).toDateString()}</span>
								</h6>
								<p>{comm.text}</p>
							</div>
						))}
					</div>
				)}
				<form type="submit" onSubmit={handleSubmitComment} className={`${BASE_CLASS}__inner__form`}>
					<input
						placeholder="add a comment"
						onChange={(e) => setCommentText(e.target.value)}
						value={commentText}
					/>
					<button type="submit">Comment</button>
				</form>
			</div>
		</div>
	);

	function getHandle(handle) {
		const index = handle.lastIndexOf('instagram.com/');
		return index !== -1 ? handle.slice(index + 14) : handle;
	}
}

export default FriendArticle;
