export const POPULAR_FORMAL_IDS = [
	841,
	999,
	854,
	833,
	1164,
	1108,
	813,
	1034,
	845,
	844,
	778,
	1045,
	1050,
	418,
	958,
	1108,
	863,
	1061,
	606,
	1037,
	864,
	1109,
	1055,
	797,
	1052,
	784,
	1051,
	657,
	1058,
	656,
	1053,
	680,
	1221,
	1486,
	1485,
	1099,
	1213,
	1102,
	1475,
	1488,
	1494,
	417,
	1423,
	1323,
	1487,
	1484,
	1182,
	1499,
	1106,
	769,
	1212,
	1255,
	1103,
	1296,
	408,
	463,
	// 1167,
	// 1166,
	// 1165,
	// 1164,
	// 1163,
	// 1118,
	// 1117,
	// 1115,
	// 1114,
	// 1113,
	// 1112,
	// 1111,
	// 1110,
	// 1109,
	// 1108,
	// 1107,
	// 1106,
	// 418,
	// 473,
	// 477,
	// 492,
	// 493,
	// 506,
	// 509,
	// 512,
	// 583,
	// 584,
	// 604,
	// 605,
	// 606,
	// 607,
	// 609,
	// 611,
	// 612,
	// 613,
	// 614,
	// 617,
	// 618,
	// 620,
	// 621,
	// 640,
	// 641,
	// 656,
	// 657,
	// 659,
	// 660,
	// 661,
	// 662,
	// 674,
	// 680,
	// 681,
	// 688,
	// 689,
	// 709,
	// 714,
	// 717,
	// 718,
	// 719,
	// 720,
	// 762,
	// 763,
	// 764,
	// 765,
	// 766,
	// 767,
	// 768,
	// 769,

	// 771,
	// 772,

	// 777,
	// 778,
	// 784,
	// 787,
	// 795,
	// 797,
	// 800,
	// 813,
	// 824,
	// 832,
	// 833,
	// 834,
	// 837,
	// 839,
	// 840,
	// 841,
	// 842,
	// 843,
	// 844,
	// 845,
	// 846,
	// 852,
	// 854,
	// 850,
	// 852,
	// 854,
	// 855,
	// 860,
	// 862,
	// 863,
	// 864,
	// 866,
	// 867,
	// 868,
	// 870,
	// 948,
	// 958,
	// 959,
	// 996,
	// 999,
	// 1000,
	// 1013,
	// 1014,
	// 1015,
	// 1016,
	// 1017,
	// 1018,
	// 1019,
	// 1020,
	// 1021,
	// 1022,
	// 1023,
	// 1024,
	// 1025,
	// 1026,
	// 1027,
	// 1028,
	// 1029,
	// 1030,
	// 1031,
	// 1032,
	// 1033,
	// 1034,
	// 1037,
	// 1038,
	// 1044,
	// 1045,
	// 1046,
	// 1061,
	// 1050,
	// 1051,
	// 1052,
	// 1053,
	// 1054,
	// 1055,
	// 1056,
	// 1057,
	// 1058,
];

function shuffleArray(array) {
	// TODO: THIS SEPARATES OUT RECOMMENDED FAVORITES TO APPEAR AT TOP OF LIST
	let favorites = array.filter((item) => POPULAR_FORMAL_IDS.includes(item.id));
	let nonFavorites = array.filter((item) => POPULAR_FORMAL_IDS.indexOf(item.id) === -1);

	for (let i = favorites.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[favorites[i], favorites[j]] = [favorites[j], favorites[i]];
	}

	for (let i = nonFavorites.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[nonFavorites[i], nonFavorites[j]] = [nonFavorites[j], nonFavorites[i]];
	}

	return favorites.concat(nonFavorites);

	// for (let i = array.length - 1; i > 0; i--) {
	// 	const j = Math.floor(Math.random() * (i + 1));
	// 	[array[i], array[j]] = [array[j], array[i]];
	// }

	// return array;
}

export default shuffleArray;
