import React from 'react';
import './index.scss';
const BASE_CLASS = 'friend-article-modal';
import { Article } from '../../../../public/index';

export default function FriendArticleModal({
	article,
	handleCloseModal,
	submitComment,
	handleCommentChange,
	commentToAdd,
	comments,
}) {
	return (
		<div className={`${BASE_CLASS}`}>
			<div className={`${BASE_CLASS}__container`} />
			<div className={`${BASE_CLASS}__inner`}>
				<div className={`${BASE_CLASS}__inner__title`}>
					<span>{article.name}</span>
					<span>
						{article.brand} {article.size && article.brand && '|'} {article.size}{' '}
					</span>
				</div>

				<div className={`${BASE_CLASS}__inner__image`}>
					{article.imageUrls ? (
						<img className="" src={article.imageUrls[0]} loading="lazy" />
					) : (
						<div className="">
							<Article />
						</div>
					)}
				</div>
				<div className={`${BASE_CLASS}__inner__comments`}>
					{comments.comments.map((comm) => (
						<div className={`${BASE_CLASS}__inner__comments__comment`} key={comm.text}>
							<div className={`${BASE_CLASS}__inner__comments__comment__signature`}>
								<span>{comm.commenter}</span>
								<span>on {new Date(comm.date).toDateString()}</span>
							</div>
							<p>{comm.text}</p>
						</div>
					))}
				</div>
				<form
					type="submit"
					onSubmit={() => submitComment(commentToAdd, article.id)}
					className={`${BASE_CLASS}__inner__form`}>
					<input
						type="text"
						name="commentToAdd"
						placeholder="add a comment"
						onChange={(e) => handleCommentChange(e)}
						value={commentToAdd}
					/>
					<button type="submit">comment</button>
				</form>
				<button type="button" onClick={handleCloseModal}>
					close
				</button>
			</div>
		</div>
	);
}
