import React, { useState, useEffect, useRef } from 'react';
import store from '../../store';
import { getDresses } from '../../store/closet';
import {
	BrandsWornBarGraph,
	BrandsPurchasedBarGraph,
	UsageBarChart,
} from './utils/BrandsPurchasedAndWornBarChart';
import CategoriesPieChart from './utils/CategoriesPieChart';
import { useSelector } from 'react-redux';
import { me } from '../../store/user';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './index.scss';
const BASE_CLASS = 'dashboard';
import { Close } from '../../../public/icons';
import Back from '../../../public/icons/Back';
import history from '../../../client/history';

import {
	getAvgCostPerWear,
	getPercentageOfWardrobeWorn,
	getAverageWears,
	getMoneySpent,
	getPurchasesThisMonth,
	getPurchases,
} from './utils/calculations.js';
import HangersFalling from '../../../public/images/hangersFalling';
import SingleLeaf from '../../../public/images/SingleLeaf';
import Logo from '../../../public/icons/Logo';

function Dashboard() {
	const closetItemsFromStore = useSelector((state) => state.closet.dresses);
	const [closetItems, setClosetItems] = useState([]);
	const [mostWornDress, setMostWornDress] = useState([]);
	const [totalCost, setTotalCost] = useState(0);
	const [topBrandsPurchased, setTopBrandsPurchased] = useState([]);
	const [topBrandsWorn, setTopBrandsWorn] = useState([]);
	const [moneySpentObj, setMoneySpentObj] = useState(null);
	const [itemsPurchasedObj, setItemsPurchasedObj] = useState(null);
	const [articlesOwnedObj, setArticlesOwnedObj] = useState(null);
	const [averageWearsObj, setAverageWearsObj] = useState(null);
	const [categoryObj, setCategoryObj] = useState({});
	const [averageCostPerWear, setAverageCostPerWear] = useState(null);
	const [showShareCards, setShowShareCards] = useState(false);
	const [purchaseHistory, setPurchaseHistory] = useState([]);

	useEffect(() => {
		store.dispatch(getDresses());
		store.dispatch(me());
	}, []);

	useEffect(() => {
		setClosetItems(closetItemsFromStore);
		setMostWornDress(closetItemsFromStore.sort((a, b) => b.wearCount - a.wearCount).slice(0, 6));
		setTotalCost(
			closetItemsFromStore.reduce((a, b) => {
				return a + b.cost;
			}, 0)
		);

		// getStats();
	}, [closetItemsFromStore]);

	useEffect(() => {
		getStats();
	}, [closetItems]);

	const handleShowShareCards = () => {
		setShowShareCards(true);

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const handleCloseShareCards = () => {
		setShowShareCards(false);
	};

	const getStats = () => {
		let dressBrandArray = closetItems.map((dress) => dress.brand);

		const avgCostPerWr = getAvgCostPerWear(closetItems);
		// const percentageOfWardrobeWorn = getPercentageOfWardrobeWorn(closetItems);
		const averageWears = getAverageWears(closetItems);
		const moneySpent = getMoneySpentObj(getMoneySpent(closetItems).yearlySpend);
		const itemsPurchased = getItemsPurchasedObj(getMoneySpent(closetItems).itemsPurchasedThisYear);
		const topWorn = topNWorn(closetItems, 5);
		const topBrandsPurchased = topNPurchased(dressBrandArray, 5);
		setAverageCostPerWear(avgCostPerWr);
		setCategoryObj(getCategories(closetItems));
		setArticlesOwnedObj(getTotalItemsOwnedObj);
		setAverageWearsObj(getAverageWearsObj(Math.round(averageWears)));
		setMoneySpentObj(moneySpent);
		setItemsPurchasedObj(itemsPurchased);
		setTopBrandsWorn(topWorn);
		setTopBrandsPurchased(topBrandsPurchased);
		getPurchasesThisMonth(closetItems);
		setPurchaseHistory(getPurchases(closetItems));
	};

	return (
		<div className={`${BASE_CLASS}`}>
			<button onClick={() => history.goBack()} className={`${BASE_CLASS}__back-btn`}>
				<Back />
			</button>
			<h5>
				your wardrobe,
				<br /> by the numbers
				<div />
			</h5>

			{showShareCards && (
				<div className={`${BASE_CLASS}__share-cards`}>
					<div className={`${BASE_CLASS}__share-card`}>
						<div>
							<h3>
								<Logo />
							</h3>
							<small>
								{new Date(Date.now()).toLocaleDateString('en-US', {
									month: 'numeric',
									day: 'numeric',
									year: '2-digit',
								})}
							</small>
							<h4>
								{isNaN(getAverageWears(closetItems))
									? 'tbd'
									: Number.parseFloat(getAverageWears(closetItems).toFixed())}
							</h4>
							<h5>Average wears</h5>
						</div>
					</div>

					<div className={`${BASE_CLASS}__share-card`}>
						<div>
							<h3>
								<Logo />
							</h3>
							<small>
								{new Date(Date.now()).toLocaleDateString('en-US', {
									month: 'numeric',
									day: 'numeric',
									year: '2-digit',
								})}
							</small>
							<h4>
								<span>$</span>
								{isNaN(Number.parseFloat(averageCostPerWear))
									? 'tbd'
									: Number.parseFloat(averageCostPerWear).toFixed(1)}
							</h4>
							<h5>Cost per wear</h5>
						</div>
					</div>
					<span onClick={handleCloseShareCards}>
						<Close />
					</span>
				</div>
			)}

			<div className={`${BASE_CLASS}__cards`}>
				<div className={`${BASE_CLASS}__card`}>
					<h6>
						<small>your closet,</small> in categories
						<div />
					</h6>
					{categoryObj.length && <CategoriesPieChart data={categoryObj} />}
					{categoryObj.length &&
						categoryObj.map(
							(c) =>
								!!c && (
									<p>
										<span>{c.percent}%</span> {c.name || c.subLabel}
									</p>
								)
						)}
				</div>

				<div className={`${BASE_CLASS}__card`}>
					<h6>
						<small>your closet,</small> in usage
						<div />
					</h6>
					<p>Your most-loved items: </p>
					<div className={`${BASE_CLASS}__card__photo-grid`}>
						{mostWornDress.map((dress) => (
							<img src={dress.imageUrls ? dress.imageUrls[0] : ''} />
						))}
					</div>
					<p>
						Average wears <br />
						per article
					</p>
					<h4>
						{isNaN(getAverageWears(closetItems))
							? 'tbd'
							: Number.parseFloat(getAverageWears(closetItems).toFixed())}
					</h4>
					<p className="small-par">
						By wearing your clothes more, you're contributing to reducing landfill waste.
					</p>
				</div>

				<div className={`${BASE_CLASS}__card`}>
					<HangersFalling />
					<h6>
						<small>your closet,</small> in dollars
						<div />
					</h6>
					<h2>
						{closetItems.length}
						<p>Items</p>
					</h2>
					<p>Total value:</p>
					<h4>${totalCost}</h4>
					<p>Average cost per item:</p>
					<h4>
						{isNaN(Number.parseFloat(totalCost / closetItems.length).toFixed(2))
							? 'tbd'
							: '	$' + Number.parseFloat(totalCost / closetItems.length).toFixed(2)}
					</h4>
					<p>Average cost per wear:</p>
					<h4>
						{isNaN(Number.parseFloat(averageCostPerWear))
							? 'tbd'
							: '$' + Number.parseFloat(averageCostPerWear).toFixed(2)}
					</h4>
				</div>

				<div className={`${BASE_CLASS}__card`}>
					<h6>
						<small>brands you</small> wear
						<div />
					</h6>
					<BrandsWornBarGraph data={topBrandsWorn} />
					{topBrandsWorn.length && topBrandsWorn.map((c) => <p>{c.x}</p>)}
				</div>
				<div className={`${BASE_CLASS}__empty-card`}>
					<SingleLeaf />
					<p>
						Want to learn more about sustainability? Check out some learning materials{' '}
						<Link to="/fashion-fix">here</Link>.
					</p>
					<button className="button" onClick={handleShowShareCards}>
						Share your progress
					</button>
				</div>
				<div className={`${BASE_CLASS}__card`}>
					<h6>
						<small>brands you</small> buy
						<div />
					</h6>
					<BrandsPurchasedBarGraph data={topBrandsPurchased} />
					{topBrandsPurchased.length && topBrandsPurchased.map((c) => <p>{c.x}</p>)}
				</div>

				{purchaseHistory && (
					<div className={`${BASE_CLASS}__card`}>
						<h6>
							<small>your purchase</small> trends
							<div />
						</h6>
						<BrandsPurchasedBarGraph data={purchaseHistory} />
						{/* {purchaseHistory.length && purchaseHistory.map((c) => <p>{c.x}</p>)} */}
					</div>
				)}

				<div className={`${BASE_CLASS}__card`}>
					<h6>
						<small>how you compare in</small> usage
						<div />
					</h6>
					<p>Articles in your closet:</p>
					<UsageBarChart data={articlesOwnedObj} className={articlesOwnedObj?.[0]?.color} />
					<p>Average wears per article:</p>
					<UsageBarChart data={averageWearsObj} className={averageWearsObj?.[0]?.color} />
				</div>
				<div className={`${BASE_CLASS}__card`}>
					<h6>
						<small>how you compare in</small> shopping
						<div />
					</h6>
					<p>Items bought this year:</p>
					<UsageBarChart data={itemsPurchasedObj} className={itemsPurchasedObj?.[0]?.color} />
					<p>Money spent on clothing this year:</p>
					<UsageBarChart data={moneySpentObj} className={itemsPurchasedObj?.[0]?.color} />
				</div>
			</div>
		</div>
	);

	function getCategories(items) {
		let hash = {};

		for (let item of items) {
			if (!hash[item.category]) hash[item.category] = 0;
			hash[item.category]++;
		}

		const data = [
			!!hash.bottom && {
				angle: hash.bottom,
				color: '#DBAA56',
				name: '',
				subLabel: 'Bottoms',
				percent: Math.round((hash.bottom / items.length) * 100),
			},
			!!hash.dress && {
				angle: hash.dress,
				color: '#8C6D37',
				name: 'Dresses',
				subLabel: '',
				percent: Math.round((hash.dress / items.length) * 100),
			},
			!!hash.top && {
				angle: hash.top,
				color: '#4D3B1E',
				name: '',
				subLabel: 'Tops',
				percent: Math.round((hash.top / items.length) * 100),
			},
			!!hash.accessories && {
				angle: hash.accessories,
				color: '#CC9E50',
				name: 'Accessories',
				subLabel: '',
				percent: Math.round((hash.accessories / items.length) * 100),
			},
			!!hash.shoes && {
				angle: hash.shoes,
				color: '#2F4D3A',
				name: '',
				subLabel: 'Shoes',
				percent: Math.round((hash.shoes / items.length) * 100),
			},
			!!hash.outerwear && {
				angle: hash.outerwear,
				color: '#477559',
				name: 'Outerwear',
				subLabel: '',
				percent: Math.round((hash.outerwear / items.length) * 100),
			},
			!!hash.layer && {
				angle: hash.layer,
				color: '#3E664D',
				name: '',
				subLabel: 'Layer',
				percent: Math.round((hash.layer / items.length) * 100),
			},
			!!hash.active && {
				angle: hash.active,
				color: '#B38B46',
				name: 'Active',
				subLabel: '',
				percent: Math.round((hash.active / items.length) * 100),
			},
		];

		return data.filter((i) => !!i);
	}

	function topNWorn(arr, n) {
		let hash = {};

		for (let item of arr) {
			if (!hash[item.brand]) {
				// first item in array is cumulative items, second is cumulative wears, third is average
				hash[item.brand] = [1, item.wearCount, item.wearCount / 1];
			} else {
				hash[item.brand] = [
					hash[item.brand][0] + 1,
					hash[item.brand][1] + item.wearCount,
					(hash[item.brand][1] + item.wearCount) / (hash[item.brand][0] + 1),
				];
			}
		}

		const wornItemsArray = Object.entries(hash);

		const sortedWornItemsArray = wornItemsArray
			.sort(
				(function (index) {
					return function (a, b) {
						return a[1]?.[2] === b[1]?.[2] ? 0 : b[1]?.[2] < a[1]?.[2] ? -1 : 1;
					};
				})()
			)
			.slice(0, n);

		const sortedWornItemsObj = sortedWornItemsArray.map((item) => {
			return (item = {
				x: item[0],
				y: Math.round(item[1][2]),
				label: Math.round(item[1][2]),
			});
		});

		return sortedWornItemsObj;
	}

	function topNPurchased(nums, n) {
		let hash = {};

		for (let num of nums) {
			if (!hash[num]) hash[num] = 0;
			hash[num]++;
		}

		const hashToArray = Object.entries(hash);
		const sortedArray = hashToArray
			.sort((a, b) => b[1] - a[1])
			.slice(0, n)
			.reverse();

		const sortedObj = sortedArray.map((item) => {
			return (item = {
				x: item[0],
				y: item[1],
				label: item[1],
			});
		});

		return sortedObj;
	}

	function getMoneySpentObj(amount) {
		return [
			{
				y: 'US Avg',
				x: 230, // TODO: get real number - calculate it depending on current month (ie yearly / what month we're in )
				label: 'US Avg',
				color: amount > 1434 ? 'user-yellow' : 'user-green',
			},
			{
				y: 'You',
				x: Math.round(amount),
				label: 'You',
			},
		];
	}

	function getAverageWearsObj(num) {
		return [
			{
				y: 'US Avg',
				x: 7,
				label: 'US Avg',
				color: num < 7 ? 'user-yellow' : 'user-green',
			},
			{
				y: 'You',
				x: num,
				label: 'You',
			},
		];
	}

	function getTotalItemsOwnedObj(amount) {
		return [
			{
				y: 'US Avg',
				x: 138,
				label: 'US Avg',
				color: closetItems.length > 138 ? 'user-yellow' : 'user-green',
			},
			{
				y: 'You',
				x: closetItems.length,
				label: 'You',
			},
		];
	}

	function getItemsPurchasedObj(amount) {
		return [
			{
				y: 'US Avg',
				x: 70, // TODO: get real number - calculate it depending on current month (ie yearly / what month we're in )
				label: 'US Avg',
				color: amount > 70 ? 'user-yellow' : 'user-green',
			},
			{
				y: 'You',
				x: amount,
				label: 'You',
			},
		];
	}
}

export default Dashboard;
