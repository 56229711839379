import React from 'react';

const BlogPost2 = () => (
	<svg
		width="2254"
		height="2061"
		viewBox="0 0 2254 2061"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 930.178C0.074915 930.215 0.0374575 930.178 0 930.178Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M629.358 807.259C734.581 839.523 693.597 679.201 619.507 717.703C574.197 734.721 593.687 788.906 629.358 807.259Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M872.547 1011.35C914.037 977.49 955.34 1032 967.676 973.682C952.993 913.431 742.531 977.915 872.547 1011.35Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1132.79 1485.5C1143.48 1488.31 1167.72 1494.63 1146.35 1503.48C1066.04 1593.87 1286.95 1567.1 1169.93 1493.33C1231.3 1368.36 1035.12 1335.97 1132.79 1485.5Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1947.36 549.546C1907.21 551.615 1903.98 657.787 1951.88 648.712C1984.67 633.631 1992.94 544.984 1947.36 549.546Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M316.33 1137.42C322.722 1095.08 314.925 1017.41 285.728 1011.35C236.044 1020.17 250.187 1193.93 316.33 1137.42Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1142.48 1153.97C1170.08 1155.75 1195.83 1205.22 1232.55 1177.99C1279.27 1088.04 1144.08 1037.81 1142.48 1153.97Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1167.45 1396.81C1175.17 1416.17 1173.73 1438.35 1187.09 1454.54C1266.41 1500.41 1220.94 1276.79 1167.45 1396.81Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M836.788 571.07C809.697 617.144 874.058 677.411 914.836 634.667C967.165 595.282 872.048 509.221 836.788 571.07Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1024.45 280.229C974.681 262.797 944.69 289.338 930.219 320.34C972.758 349.895 1051.91 342.708 1024.45 280.229Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1243.53 1106.39C1228.14 1276.55 1372.98 1125.5 1263.63 1099.6C1355.44 1077.44 1259.67 993.585 1243.53 1106.39Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M618.989 1526.72C589.827 1555.43 571.837 1652.74 640.954 1614.29C670.016 1603.13 651.946 1503.69 618.989 1526.72Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1471.8 849.036C1524.39 840.682 1507.25 724.92 1465.47 753.751C1432.19 770.652 1434.71 845.542 1471.8 849.036Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1160.55 374.575C1210.94 367.188 1226.45 264.625 1164.18 275.494C1085.64 282.4 1086.19 365.027 1160.55 374.575Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1410.64 910.845C1376.23 999.74 1501.98 1016.57 1504.57 926.776C1485.81 886.524 1438.52 868.332 1410.64 910.845Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M493.304 1464.15C490.784 1524.01 542.894 1548.94 554.686 1488.46C564.131 1424.26 500.825 1392.78 493.304 1464.15Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1149.02 699.822C1178.35 664.841 1173.41 600.576 1128.91 613.496C1084.81 650.481 1107.83 744.705 1149.02 699.822Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M730.2 597.047C675.799 552.542 644.261 578.978 630.559 628.932C680.75 628.714 734.537 660.699 730.2 597.047Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1641.6 1201.49C1648.1 1156.8 1552.34 1146.75 1542.02 1184.13C1545.99 1221.03 1628.66 1242.16 1641.6 1201.49Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M604.219 642.869C622.34 609.163 629.038 546.825 584.017 549.458C536.084 558.59 552.956 677.81 604.219 642.869Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2213.47 948.923C2318.9 938.372 2187.64 849.726 2154.39 903.892C2148.59 922.72 2188.7 945.052 2213.47 948.923Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1547.23 1048.77C1601.54 1051.03 1580.81 931.215 1532.72 951.17C1499.25 976.679 1526.75 1027.36 1547.23 1048.77Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1836.98 1460.57C1893.54 1458.94 1891.04 1408.46 1842.36 1398.41C1774.46 1400.97 1745.58 1465.52 1836.98 1460.57Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1005.16 915.244C1023.94 950.746 1112.47 956.689 1104.52 940.058C1109.84 895.15 1003.15 858.946 1005.16 915.244Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M734.971 1150.88C678.814 1133.61 684.373 1223.74 720.088 1248.58C765.083 1243.21 763.719 1180.61 734.971 1150.88Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1470.67 628.348C1449.69 603.188 1389.42 607.665 1379.71 635.421C1396.31 688.497 1512.18 688.081 1470.67 628.348Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1169.61 489.959C1156.94 528.204 1203.03 552.667 1229.09 525.085C1236.68 495.441 1187.34 450.466 1169.61 489.959Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1891.65 1853C1890.38 1883.32 1940.87 1919.65 1953.56 1881.33C1959.46 1845.87 1910.96 1814.67 1891.65 1853Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1538.9 1061.38C1521.91 993.185 1466.83 1027.55 1482.3 1087.74C1494.48 1151.97 1556.5 1122.16 1538.9 1061.38Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M379.816 1286.04C345.752 1294.39 352.653 1346.36 371.945 1375.6C427.153 1424.16 437.03 1285.67 379.816 1286.04Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1204.91 218.25C1203.85 182.527 1159.28 151.774 1142.48 198.602C1144.78 229.84 1194.07 256.691 1204.91 218.25Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1210.01 1123.72C1249.83 1108.5 1220.02 1053.6 1204.78 1023.84C1144.51 1048.26 1165.33 1093.01 1210.01 1123.72Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M932.042 774.121C987.679 768.535 973.37 631.792 922.166 687.588C893.448 711.941 904.348 765.432 932.042 774.121Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M773.85 459.673C758.978 487.352 774.292 518.575 794.548 536.889C862.683 537.45 820.843 379.593 773.85 459.673Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1030.11 815.358C1011.68 768.822 964.018 790.143 931.093 801.266C920.567 855.01 1008.06 868.457 1030.11 815.358Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M530.673 1350.57C531.317 1387.54 575.855 1400.55 592.225 1364.99C601.25 1321.42 537.763 1305.29 530.673 1350.57Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1460.73 1133.44C1410.1 1089.63 1402.89 1207.32 1442.84 1223.29C1484.71 1228.62 1490.99 1166.85 1460.73 1133.44Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1113.86 1280.03C1156.29 1256.04 1105.16 1200.56 1073.56 1234.11C1052.67 1257.24 1091.32 1302.3 1113.86 1280.03Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1189.6 511.919C1214.83 480.793 1252.48 518.472 1266.45 491.126C1281.13 414.907 1107.49 456.325 1189.6 511.919Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1304.79 1445.73C1306.6 1476.09 1310.35 1538.37 1344.89 1519.91C1398.29 1485.15 1344.2 1377.12 1304.79 1445.73Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M525.767 1698.07C577.765 1692.52 554.569 1623.16 511.875 1637.63C479.185 1650.15 493.034 1696.77 525.767 1698.07Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1026.68 711.692C982.435 729.897 1013.86 796.819 1053.43 766.227C1085.7 744.393 1056.63 715.146 1026.68 711.692Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1155.29 1655.02C1204.12 1681.12 1228.59 1608.05 1173.28 1598.22C1146.4 1596.7 1129.46 1637.7 1155.29 1655.02Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M864.581 1560.73C967.239 1402.93 747.263 1461.48 867.228 1536.49C877.054 1537.98 857.739 1550.51 864.581 1560.73Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1679.37 881.639C1671.44 844.035 1625.65 834.515 1616.94 876.891C1617.73 917.704 1672.46 926.183 1679.37 881.639Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1086.33 1385.93C1169.97 1381.32 1118.92 1297.71 1054.37 1331.53C993.073 1353.2 1064.63 1370.97 1086.33 1385.93Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1479.72 1123.72C1502.43 1157.16 1505.24 1175.62 1479.6 1201.47C1551.43 1279.95 1573.46 1124.62 1479.72 1123.72Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M874.02 1788.58C900.465 1811.42 941.069 1789.76 927.522 1753.52C913.188 1710.57 847.525 1753.53 874.02 1788.58Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M693.199 1632.45C698.749 1687.47 781.126 1651.86 747.287 1610.73C730.345 1582.42 689.712 1607.45 693.199 1632.45Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M444.543 1028.73C407.03 1070 454.06 1109 492.21 1070.47C499.379 1048.51 466.584 1010.25 444.543 1028.73Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M937.373 1947.11C954.467 1984.26 1006.71 1933.29 989.052 1910.79C962.857 1876.79 911.316 1917.15 937.373 1947.11Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1070.24 549.376C1050.64 553.261 1035.35 577.376 1046.19 595.92C1095.14 648.333 1137.32 554.712 1070.24 549.376Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M583.651 1760.6C541.714 1765.63 549.655 1823.28 589.021 1822.93C632.267 1816.45 624.53 1757.8 583.651 1760.6Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M992.586 1638.47C995.545 1566.1 895.433 1603.77 943.117 1653.72C953.842 1666.21 993.535 1662.09 992.586 1638.47Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M754.506 2033.49C763.331 1994 705.448 1985.8 694.559 2016.02C681.3 2059.76 756.396 2080.3 754.506 2033.49Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1067.76 1863.8C1064.19 1894.02 1110.1 1909.56 1125.43 1887.38C1148.66 1848.26 1075.97 1805.91 1067.76 1863.8Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M372.885 1166.95C337.489 1143.25 295.286 1198.95 333.536 1219.28C366.912 1237.31 395.194 1195.04 372.885 1166.95Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1785.04 511.919C1766.21 516.751 1737.78 552.226 1766.53 568.804C1811.69 596.087 1843.27 513.913 1785.04 511.919Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M830.332 445.813C850.023 489.617 901.851 482.8 891.376 428.029C861.197 400.88 842.331 409.258 830.332 445.813Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M677.548 1372.84C661.74 1382.32 663.995 1435.67 696.493 1420.77C759.75 1411.55 723.551 1331.21 677.548 1372.84Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1304.79 950.346C1307.29 989.677 1350.7 1001.63 1366.6 961.721C1374.36 925.548 1307.72 904.105 1304.79 950.346Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M969.175 1343.71C912.626 1311.11 881.424 1316.21 927.46 1371.76C983.658 1429.04 1046.31 1290.59 969.175 1343.71Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1479.6 1692.48C1484.7 1764.7 1585.39 1690.11 1520.05 1661.06C1501.63 1657.63 1480.02 1674.2 1479.6 1692.48Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1617.09 1051.57C1613.13 1102.71 1686.07 1093.12 1678.87 1050.11C1670.9 1011.31 1623.39 1018.63 1617.09 1051.57Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M418.22 1836.1C420.335 1804.33 380.281 1785.66 356.74 1807.26C349.37 1845.73 390.539 1888.33 418.22 1836.1Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M555.644 1079.89C560.676 1140.99 643.802 1101.14 610.023 1058.86C588.247 1033.64 567.028 1061.68 555.644 1079.89Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1580.49 324.867C1573.03 355.487 1608.3 388.542 1639.24 368.415C1652.59 328.456 1613.28 292.085 1580.49 324.867Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1030.11 1556.77C1030.64 1518.5 973.345 1509.1 967.839 1549.29C964.068 1594 1025.75 1598.75 1030.11 1556.77Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1161.02 829.21C1125.28 798.629 1091.69 808.21 1110.35 854.99C1121.15 896.626 1188.19 861.566 1161.02 829.21Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M834.715 1286.04C855.104 1252.49 890.988 1222.49 846.501 1186.15C804.789 1187.8 785.075 1279.5 834.715 1286.04Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M730.446 972.983C708.31 930.535 666.874 951.171 668.04 981.786C694.335 1023.74 711.225 1021.25 730.446 972.983Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1336.01 1735.53C1290.09 1739.03 1299.08 1791.55 1335.52 1797.96C1378.52 1792.53 1376.89 1738.7 1336.01 1735.53Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M740.693 658.065C718.626 674.452 734.446 711.542 766.733 711.692C821.043 702.994 779.97 623.101 740.693 658.065Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1979 1433.47C1980.76 1369.5 1889.82 1404.14 1924.49 1449.6C1939.31 1473.94 1979.67 1454.2 1979 1433.47Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1446.7 1323.5C1405.09 1324.87 1410.74 1385.5 1443.19 1385.93C1491.86 1385.99 1490.42 1325.72 1446.7 1323.5Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2094.27 986.667C2082.5 1013.3 2109.22 1049.88 2133.07 1048.78C2185.74 1018.81 2125.25 982.759 2094.27 986.667Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1376.67 275.22C1298.25 266.207 1374.28 375.531 1403.83 322.563C1408.84 302.343 1390.67 287.77 1376.67 275.22Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1819.38 1114.27C1800.36 1162.07 1889.48 1158.01 1878.14 1114.96C1879.93 1080.02 1807.94 1073.77 1819.38 1114.27Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1354.91 1090.15C1378.34 1120.95 1407.66 1120.2 1404.43 1073.26C1396.16 1017.3 1310.6 1072.48 1354.91 1090.15Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M926.024 834.98C952.381 779.954 844.716 769.213 872.372 816.566C902.401 811.324 909.905 876.852 926.024 834.98Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M830.332 966.99C829.696 935.465 790.202 901.802 772.363 943.155C750.524 979.164 815.198 1006.45 830.332 966.99Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1077.69 1423.38C1121.04 1406.14 1110.19 1372.04 1063.69 1360.96C1038.83 1391.87 1027.63 1412.93 1077.69 1423.38Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M867.79 647.95C844.167 635.471 837.424 587.864 813.59 626.875C779.13 660.69 863.782 702.377 867.79 647.95Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1368.88 811.561C1424.08 808.749 1410.7 740.389 1361.02 750.092C1338.28 764.294 1331.09 812.639 1368.88 811.561Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1716.81 1463.25C1716.13 1370.36 1599.25 1466.27 1687.11 1485.81C1695.94 1472.73 1717.45 1481.32 1716.81 1463.25Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1406.34 910.053C1428.45 895.678 1459.59 871.834 1480.73 911.394C1568.11 915.123 1385.59 770.16 1406.34 910.053Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1442.11 1495.85C1442.38 1429.12 1349.61 1470.12 1389.81 1516.74C1404.14 1533.02 1443.35 1516.13 1442.11 1495.85Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1403.69 1286.8C1376.97 1221.76 1316.07 1297.38 1354.79 1323.45C1382.9 1324.61 1410.25 1305.69 1403.69 1286.8Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1307.7 948.923C1330.1 919.908 1373.65 898.862 1305.89 886.494C1274.27 895.9 1267.49 945.04 1307.7 948.923Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1008.63 1540.08C1022.23 1571.73 1057.67 1564.35 1067.34 1535.86C1072.44 1483.59 987.079 1486.69 1008.63 1540.08Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1042.07 811.579C1027.32 818.132 1025.57 847.909 1005.13 849.733C1034.3 916.377 1105.43 826.241 1042.07 811.579Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1713.34 1659.92C1760.45 1663.46 1743.73 1583.91 1702.01 1600.45C1666.03 1604.39 1675.25 1667.92 1713.34 1659.92Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2179.96 1123.8C2133.55 1131.34 2155.25 1200.54 2196.13 1183.44C2233.28 1178.61 2213.77 1121.45 2179.96 1123.8Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1005.48 1957.15C1000.59 1997.51 1048.28 2014.48 1065.44 1976.48C1079.19 1937.14 1022.3 1917.14 1005.48 1957.15Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1168.81 2020.28C1157.54 2064.01 1219.98 2074.18 1229.64 2038.76C1234.24 1999.51 1171.59 1979.27 1168.81 2020.28Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M118.64 1316.61C110.971 1288.45 87.9901 1232.58 60.3377 1278.64C40.1257 1303.21 99.733 1338.56 118.64 1316.61Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M867.79 1435.83C867.79 1470.17 920.418 1465.27 929.994 1444.37C934.302 1391.5 876.018 1378.57 867.79 1435.83Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1817.22 1494.28C1812.53 1521.92 1841.36 1526.88 1859.81 1521.17C1919.21 1492.33 1823.31 1417.89 1817.22 1494.28Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1105.02 730.112C1139.47 729.704 1127.28 772.959 1151.18 774.121C1197.05 734.683 1134.37 683.819 1105.02 730.112Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M892.761 325.7C845.59 298.827 811.154 311.548 842.194 367.121C872.484 394.064 882.061 340.41 892.761 325.7Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M948.511 1161.18C943.379 1184.8 926.373 1191.31 905.247 1201.26C921.192 1260.83 1004.63 1185.12 948.511 1161.18Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1380.16 1657.06C1434.6 1633.97 1387.24 1572.78 1345.92 1609.84C1334.52 1627.2 1350.74 1673.76 1380.16 1657.06Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M418.3 1633.95C417.249 1579.73 358.619 1595.33 355.871 1626.98C356.516 1669.24 410.177 1671.81 418.3 1633.95Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M615.6 1522.07C646.131 1529.35 647.107 1501.92 655.531 1482.07C635.732 1423.9 554.227 1501.53 615.6 1522.07Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M738.287 588.35C722.155 623.135 730.385 646.294 767.916 632.952C822.978 619.203 775.003 543.604 738.287 588.35Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1105.02 1115.62C1065.96 1090.75 1063.63 1064.32 1042.78 1115.75C1038.92 1159.38 1096.33 1160.02 1105.02 1115.62Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M495.989 299.65C517.761 281.501 531.254 253.907 498.866 237.233C447.125 236.778 436.991 300.563 495.989 299.65Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1095.39 1004.67C1124.25 918.457 1082.52 968.263 1045.53 948.923C1032.1 974.544 1067.55 1030.58 1095.39 1004.67Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M708.853 1108.18C751.848 1123.37 760.362 1078.35 753.104 1052.12C724.072 1034.73 664.552 1090.67 708.853 1108.18Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1542.69 238.602C1534.77 291.041 1599.93 282.19 1604.45 238.602C1596.28 201.093 1557.02 205.946 1542.69 238.602Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M720.534 1111.08C694.093 1100.11 708.308 1082.11 730.446 1072.18C725.599 1000.79 599.116 1116.2 720.534 1111.08Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1915.84 1186.4C1902.5 1201.09 1889.02 1204.13 1892.06 1227.64C1918.97 1294.24 2002.2 1180.12 1915.84 1186.4Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1204.91 895.402C1210.46 914.541 1245.8 926.879 1238.99 948.748C1300.76 953.468 1248.66 857.42 1204.91 895.402Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M888.454 449.49C897.356 471.034 846.989 498.854 878.003 506.866C937.798 532.764 952.681 450.737 888.454 449.49Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M456.091 1903.4C459.016 1951.01 529.373 1942.32 516.65 1897.3C514.309 1861.61 450.62 1866.21 456.091 1903.4Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1688.91 1797.96C1754.27 1792.33 1688.67 1708.36 1656.21 1744.56C1647.34 1769.07 1673.09 1795.49 1688.91 1797.96Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M957.763 1735.89C937.274 1738.71 922.703 1758.02 934.377 1781.4C967.701 1838.65 1033.23 1728.48 957.763 1735.89Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M755.417 386.794C706.84 337.395 656.159 455.097 730.911 434.576C749.874 423.562 733.344 379.999 755.417 386.794Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M828.072 1185.97C861.959 1188.68 860.536 1160.96 867.79 1136.19C829.558 1094.82 773.506 1168.19 828.072 1185.97Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M318.351 1356.26C321.25 1283.69 222.187 1349.81 268.191 1381.34C289.058 1394.32 312.525 1377.77 318.351 1356.26Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1014.55 1510.19C1045.66 1497.31 1025.96 1450.78 991.924 1462.81C946.163 1474.8 971.697 1553.99 1014.55 1510.19Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2153.82 1357.11C2154.54 1312.8 2079.59 1310.39 2093.01 1361.34C2091.68 1402.78 2155.07 1385.23 2153.82 1357.11Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1784.82 237.231C1862.72 227.838 1775.96 134.525 1755.63 195.13C1748.23 207.593 1773.1 231.012 1784.82 237.231Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1163.29 20.4756C1144.64 -20.8025 1110.64 8.39932 1105.02 37.4314C1115.26 80.711 1184.97 63.0723 1163.29 20.4756Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1873.92 1698.07C1960.04 1693.3 1895.84 1597.37 1855.18 1652.87C1849.74 1662.97 1867.72 1685.16 1873.92 1698.07Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2191.29 1073.66C2191.5 1027.68 2104.62 1054.03 2135.49 1084.27C2153.95 1130.32 2177.8 1111.09 2191.29 1073.66Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M355.871 1448.72C347.147 1408.64 293.524 1419.48 293.442 1457.73C311.789 1495.71 352.791 1497.49 355.871 1448.72Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M359.692 1041.79C381.846 1056.65 412.598 1047.31 418.3 1019.59C402.016 962.994 340.004 984.283 359.692 1041.79Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1311.72 1335.77C1282.83 1383.73 1352.81 1408.89 1366.22 1357.68C1371.82 1333.61 1353.85 1307.54 1311.72 1335.77Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M431.034 1033.71C444.638 1025.2 460.4 1029.89 472.347 1048.81C538.781 1045.49 424.796 920.509 431.034 1033.71Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1164.25 1785.56C1151.94 1722.08 1063.31 1796.06 1129.07 1822.53C1148.83 1825.98 1176.99 1806.71 1164.25 1785.56Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M962.083 1097.74C985.381 1055.25 929.932 1043.35 905.247 1050.96C943.616 1053.45 924.088 1144.93 962.083 1097.74Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M866.554 1675.22C875.806 1620.64 829.808 1626.58 841.632 1668.28C852.657 1684.64 833.104 1671.07 830.332 1677.75C836.038 1693.88 868.639 1715.18 866.554 1675.22Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M882.948 1578.47C913.875 1526.58 863.295 1566.78 846.776 1523.72C806.75 1515.94 848.062 1612.31 882.948 1578.47Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1149.46 1111.24C1175.23 1090 1177.08 1032.78 1129.99 1053.12C1137.85 1072.4 1118.68 1108.89 1149.46 1111.24Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1760.93 711.692C1794.45 699.547 1778.38 621.944 1741.8 659.382C1745.44 677.449 1742.52 702.204 1760.93 711.692Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1007.47 612.276C994.409 607.441 945.714 641.307 979.251 649.263C985.144 607.277 1069.45 652.343 1007.47 612.276Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M534.878 1360.96C544.05 1338.84 566.642 1327.56 593.102 1342.44C579.703 1307.03 513.533 1325.92 534.878 1360.96Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M892.761 1315.09C882.061 1291.91 848.824 1297.02 830.782 1286.04C825.663 1307.38 865.992 1338.66 892.761 1315.09Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1542.62 237.231C1553.37 221.779 1592.7 190.6 1598.24 235.081C1629.26 204.752 1533.63 172.773 1542.62 237.231Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M602.708 1560.73C605.876 1545.88 625.524 1544.43 630.559 1531.57C614.506 1505.14 574.975 1549.24 602.708 1560.73Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2049.24 1122.71C2069.46 1101.55 2018.02 1063.88 2016.49 1103.92C2031.49 1104.96 2026.51 1128.86 2049.24 1122.71Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M454.429 1822.93C428.859 1855.23 405.801 1870.34 395.713 1824.97C377.747 1875.28 467.627 1869.63 454.429 1822.93Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2091.4 972.109C2111.28 980.874 2132.58 989.964 2153.83 984.957C2141.12 963.943 2093.62 923.006 2091.4 972.109Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1893.18 1635.64C1927.5 1663.5 1908.62 1679.92 1879.14 1696.22C1915.21 1710.5 1934.78 1637.68 1893.18 1635.64Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1019.47 1972.76C1052.28 1957.73 1030.63 1965.56 1031.57 1950.17C1040.93 1910.27 989.577 1962.15 1019.47 1972.76Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1279.82 948.923C1258.17 954.492 1248.88 960.297 1242.36 986.28C1256.81 988.128 1274.88 964.692 1279.82 948.923Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1854.17 1698.07C1841.96 1684.99 1855.43 1664.74 1822.76 1660.62C1803.7 1670.7 1834.03 1695.81 1854.17 1698.07Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1108.52 37.4576C1122.01 35.8407 1122.45 24.044 1119.53 13.1402C1185.63 15.6511 1085.57 -30.8499 1108.52 37.4576Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1657.81 337.117C1633.35 338.362 1665.26 344.214 1653.65 353.128C1640.2 399.816 1699.65 358.93 1657.81 337.117Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2087.44 1097.33C2085.49 1078.04 2065.26 1089.49 2054.64 1091.14C2046.86 1123.24 2107.07 1141.93 2087.44 1097.33Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2086.98 1086.27C2088.59 1118.63 2083.1 1097.27 2053.94 1099.68C2083.14 1135.56 2099.92 1131.44 2086.98 1086.27Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1130.8 1286.04C1125.22 1301.39 1149.68 1340.03 1166.63 1315.61C1173.67 1308.09 1133.26 1299.28 1130.8 1286.04Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1455.72 611.806C1444.71 628.587 1452.65 636.475 1455.57 649.263C1469.36 639.047 1483.48 620.478 1455.72 611.806Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1654.4 381.676C1642.26 394.037 1627.45 380.59 1616.94 388.381C1624.67 413.215 1652.46 409.394 1654.4 381.676Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1167.45 1374.32C1167.87 1351.8 1114.68 1352.96 1134.36 1376.15C1146.19 1358.32 1154.83 1383.23 1167.45 1374.32Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1165.41 1339.94C1169.61 1321.74 1134.1 1358.48 1138.48 1336.72C1116.05 1340.62 1155.45 1361.97 1165.41 1339.94Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1827.73 1654.8C1843.91 1658.09 1842.56 1638.43 1843.16 1628.61C1828.02 1631.46 1827.76 1643.07 1827.73 1654.8Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M801.003 1217.17C811.192 1211.7 821.38 1206.16 822.204 1192.6C807.483 1194.21 801.49 1203.27 801.003 1217.17Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1147.31 12.1362C1141.43 16.9308 1141.88 19.403 1149.78 25.3213C1156.3 21.3133 1155.51 17.2304 1147.31 12.1362Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2065.6 1065.35C2066.39 1071.42 2070.77 1074.72 2075.83 1073.48C2086.13 1066.21 2073.77 1055.39 2065.6 1065.35Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M537.756 1973.83C534.422 1977.17 525.882 1980.24 537.344 1984.17C546.671 1981.1 540.378 1977.39 537.756 1973.83Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M193.415 1103.51C189.707 1114.26 207.724 1111.83 206.638 1101.64C201.619 1097.37 197.348 1100.25 193.415 1103.51Z"
			fill="#7C984A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1458.26 623.867C1453.61 631.883 1459.79 634.318 1465.11 637.202C1464.59 631.846 1465.3 625.852 1458.26 623.867Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1465.79 653.004C1457.02 648.097 1454.77 651.655 1456.46 660.233C1460.88 659.521 1464.44 657.686 1465.79 653.004Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2172.4 1911.82C2167.11 1915.56 2171.68 1918.3 2173.37 1921.33C2175.99 1917.85 2175.92 1914.66 2172.4 1911.82Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1182.64 1501.22C1182.49 1506.09 1184.56 1508.6 1189.72 1507.7C1189.12 1503.62 1187.14 1501.03 1182.64 1501.22Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M413.55 1026.42C410.142 1027.73 408.868 1030.22 409.58 1033.74C412.989 1032.43 416.659 1031.25 413.55 1026.42Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1360.14 18.0347C1362.73 19.8326 1362.58 19.8701 1359.96 18.2219L1360.14 18.0347Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1147.25 15.4512C1147.32 17.9983 1147.43 20.6203 1150.17 22.0062C1150.13 19.3842 1150.84 16.4625 1147.25 15.4512Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1049.5 1841.51L1048.19 1841.32C1048.07 1842.56 1048.93 1841.73 1049.5 1841.51Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1121.89 1170.66C1126.95 1169.87 1126.98 1163.65 1121.81 1164.22C1122.15 1166.31 1121.78 1168.52 1121.89 1170.66Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1025.26 1131.72C1025.45 1129.66 1024.32 1128.63 1022.45 1128.22C1023.39 1129.38 1024.32 1130.55 1025.26 1131.72Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1123.94 1167.94C1123.26 1166.66 1123.26 1166.55 1124.05 1167.82L1123.94 1167.94Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1385.34 1166.75C1385.79 1167.16 1387.18 1169.11 1386.39 1167.42C1386.13 1167.09 1385.04 1167.13 1385.34 1166.75Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1898.01 1205.08L1897.67 1204.68C1897.67 1204.72 1898.23 1205.01 1898.01 1205.08Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1359.17 1239.87C1359.47 1242.19 1360.17 1244.14 1362.79 1244.81C1362.72 1242.34 1361.38 1240.77 1359.17 1239.87Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M189.594 911.466C271.796 856.493 152.32 751.222 156.19 856.674C183.997 849.6 187.779 887.013 189.594 911.466Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M179.999 918.233C184.382 891.936 175.644 854.032 147.418 849.036C131.733 869.589 168.891 1009.69 179.999 918.233Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M199.562 1049.31C162.554 1040.38 194.056 1154.61 214.845 1092.82C221.475 1080.74 212.935 1062.58 199.562 1049.31Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M838.536 298.454C831.519 277.443 846.726 272.493 867.79 270.991C819.557 175.778 686.763 313.803 838.536 298.454Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M263.674 758.649C243.052 794.802 267.182 850.187 297.357 849.018C346.72 826.168 298.363 716.652 263.674 758.649Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M515.069 874.893C474.21 815.382 381.945 869.36 433.623 928.445C466.828 981.224 533.33 920.555 515.069 874.893Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M418.143 820.355C420.37 790.695 398.539 760.95 382.07 749.149C318.822 775.392 385.877 905.887 418.143 820.355Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M755.417 294.491C753.19 267.432 758.197 246.07 731.463 240.592C642.176 208.028 733.273 426.062 755.417 294.491Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M560.224 411.759C594.122 486.673 653.162 372.469 590.476 357.327C573.385 301.753 544.378 374.461 560.224 411.759Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M332.925 749.15C369.493 732.286 358.121 670.472 328.385 649.544C276.24 643.24 286.377 745.252 332.925 749.15Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M493.215 609.684C476.945 508.995 370.87 657.946 478.27 634.163C485.379 638.787 491.763 616.177 493.215 609.684Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M351.043 887.982C293.141 873.192 321.339 974.219 365.726 942.842C396.341 933.091 374.782 891.248 351.043 887.982Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M297.303 611.718C370.689 562.994 226.362 511.495 261.635 589.399C278.059 589.029 277.218 613.445 297.303 611.718Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M393.491 710.251C388.966 774.252 480.843 751.093 449.058 699.687C426.93 683.212 400.106 678.225 393.491 710.251Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M159.131 616.404C162.985 649.452 222.5 639.165 218.316 607.918C212.393 557.682 140.655 566.555 159.131 616.404Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M397.704 495.86C375.827 536.439 442.584 554.373 452.73 514.635C470.422 475.096 405.119 457.427 397.704 495.86Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M217.964 470.998C209.093 432.023 147.738 451.362 157.058 486.587C167.479 532.789 224.791 508.654 217.964 470.998Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M595.057 986.381C632.211 973.72 623.16 921.67 576.724 924.029C548.467 931.653 543.196 985.119 595.057 986.381Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M642.507 476.171C608.14 465.887 580.867 504.432 598.758 527.242C645.899 557.415 673.248 510.231 642.507 476.171Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M820.481 811.635C789.656 810.076 752.935 841.064 774.244 870.305C813.746 887.443 847.788 841.183 820.481 811.635Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M363.841 434.18C412.97 449.407 439.76 399.067 396.772 378.192C343.667 360.768 353.962 410.699 363.841 434.18Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M537.366 866.559C534.52 866.072 536.992 871.241 537.778 867.833C537.891 867.458 537.516 867.009 537.366 866.559Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M436.621 830.435C437.594 829.873 437.744 829.873 436.808 830.585L436.621 830.435Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M929.682 574.348C937.386 526.456 859.612 524.102 868.502 569.521C889.515 550.985 910.242 557.258 929.682 574.348Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M850.16 557.623C850.197 556.312 850.235 554.964 850.272 553.615C849.074 554.926 845.553 556.162 850.16 557.623Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M795.993 1087.22C781.586 1127.37 821.28 1135.27 830.332 1101.86C817.544 1105.68 807.363 1092.61 795.993 1087.22Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M986.468 1047.42C1033.91 1060.72 1048.75 973.383 1000.14 988.041C964.568 992.923 955.615 1041.74 986.468 1047.42Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M988.166 936.095C930.057 886.354 903.899 1007.04 965.928 983.234C995.208 977.416 996.581 953.618 988.166 936.095Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M988.503 1068.94C988.84 1067.06 988.016 1065.68 986.181 1065.83C981.985 1067.06 985.394 1070.25 988.503 1068.94Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M221.069 948.923C184.198 963.756 190.16 1016.9 205.615 1045.25C263.722 1070.62 275.083 952.019 221.069 948.923Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M351.891 994.633C324.088 981.186 282.845 978.427 295.944 1031.73C318.03 1067.89 370.685 1042.42 351.891 994.633Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M220.87 939.271C247.482 964.056 290.847 937.049 278.927 904.134C255.467 861.904 207.387 904.323 220.87 939.271Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M311.596 948.611C336.403 953.043 281.942 908.73 318.414 900.773C307.034 854.948 244.383 932.68 311.596 948.611Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1145.54 1308.38C1149.43 1308.49 1153.67 1305.23 1151.16 1301.15C1147.45 1301.97 1147.04 1305.87 1145.54 1308.38Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M867.79 1223.61C854.542 1224.56 825.226 1243.29 831.094 1261.07C845.178 1251.13 862.958 1246.19 867.79 1223.61Z"
			fill="#F8FB9B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M511.831 691.934C510.895 692.758 510.97 693.357 512.056 693.994C512.993 693.132 512.918 692.533 511.831 691.934Z"
			fill="#7C984A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M986.118 866.736C985.631 869.209 985.818 869.583 986.942 867.148L986.118 866.736Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M986.181 1341.7C986.855 1343.16 986.855 1343.05 986.181 1341.7Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1635.13 356.104C1636.59 355.354 1636.55 355.392 1635.13 356.104Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1636.22 355.567C1634.72 356.354 1634.76 356.354 1636.22 355.567Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1835.98 1104.73C1834.52 1105.48 1834.59 1105.48 1835.98 1104.73Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1834.93 1105.27C1836.32 1104.48 1836.28 1104.48 1834.93 1105.27Z"
			fill="#BCE378"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1048.41 1542.64C1049.83 1541.03 1049.8 1540.95 1048.22 1542.41L1048.41 1542.64Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M673.72 1105.31C675.144 1104.48 675.219 1104.48 673.72 1105.31Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M885.769 1642.62C886.294 1642.1 886.743 1641.65 887.268 1641.16C886.331 1641.09 885.919 1641.84 885.769 1642.62Z"
			fill="#BCDF48"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M957.5 948.923C965.754 923.655 1001.64 932.153 990.538 903.352C969.262 851.723 888.654 933.74 957.5 948.923Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M514.229 1340.89C512.73 1341.3 511.195 1341.68 509.659 1342.05C512.318 1345.57 512.955 1341.94 514.229 1340.89Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2041.92 1098.05C2033.75 1098.95 2029.52 1103.67 2028.51 1111.61C2039.75 1113.82 2038.7 1103.82 2041.92 1098.05Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1360.77 1366.26C1361.18 1366.45 1361.55 1366.67 1360.77 1366.26C1360.95 1369.22 1360.66 1368.28 1360.77 1366.26Z"
			fill="#BFE050"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M811.747 592.293C810.324 594.128 810.549 594.353 812.384 592.967L811.747 592.293Z"
			fill="#7C984A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M208.778 759.214C164.111 723.888 134 791.379 176.667 810.833C201.245 816.647 235.569 787.445 208.778 759.214Z"
			fill="#FCFEA6"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M162.947 1005.76C162.498 1005.35 161.187 1003.44 161.936 1005.16C162.161 1005.5 163.135 1005.46 162.947 1005.76Z"
			fill="#B4D741"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M298.243 294.486C299.291 294.71 300.303 294.448 301.127 293.737C299.329 290.703 298.917 293.1 298.243 294.486Z"
			fill="#7C984A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M375.9 420.223C378.746 415.279 374.251 417.002 372.378 416.328C373.577 417.639 374.738 418.912 375.9 420.223Z"
			fill="#7C984A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M711.067 867.506C711.291 867.132 711.553 866.72 711.067 867.506C713.876 866.757 711.291 870.016 711.067 867.506Z"
			fill="#7C984A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M374.404 868.417C374.029 868.117 373.692 867.78 374.404 868.417C374.216 865.682 376.314 868.042 374.404 868.417Z"
			fill="#7C984A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M337.849 929.62C337.437 930.032 335.377 931.38 337.137 930.631C337.512 930.406 337.474 929.208 337.849 929.62Z"
			fill="#F8FB9B"
		/>
	</svg>
);

export default BlogPost2;
