import React from 'react';
import store, { pwreset } from '../../store/user';
import './index.scss';

const BASE_CLASS = 'forgot-password';

export default function ForgotPassword() {
	const handleSubmit = (e) => {
		pwreset(e.target.email.value);
	};

	return (
		<div className={BASE_CLASS}>
			<div className={`${BASE_CLASS}__title`}>forgot your password?</div>
			<div className={`${BASE_CLASS}__text`}>
				please enter your email and we'll send you a link to reset it.
			</div>

			<form onSubmit={(e) => handleSubmit(e)}>
				<input
					name="email"
					type="email"
					placeholder="email"
					className={`${BASE_CLASS}__form__input`}
				/>
				<button type="submit" className={`${BASE_CLASS}__form__submit`}>
					send me a link
				</button>
			</form>
		</div>
	);
}
