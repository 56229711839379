import React from 'react';

const Dollar = () => (
	<svg width="18" height="18" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1715_5434)">
			<path
				d="M10.5 1.375V20.625"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.875 4.875H8.3125C7.50027 4.875 6.72132 5.19766 6.14699 5.77199C5.57266 6.34632 5.25 7.12527 5.25 7.9375C5.25 8.74973 5.57266 9.52868 6.14699 10.103C6.72132 10.6773 7.50027 11 8.3125 11H12.6875C13.4997 11 14.2787 11.3227 14.853 11.897C15.4273 12.4713 15.75 13.2503 15.75 14.0625C15.75 14.8747 15.4273 15.6537 14.853 16.228C14.2787 16.8023 13.4997 17.125 12.6875 17.125H5.25"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1715_5434">
				<rect width="21" height="21" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);

export default Dollar;
