import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Friends from '../../Friends';
import classnames from 'classnames';
import './index.scss';
import { Star } from '../../../../public';

const BASE_CLASS = 'add-success';

class AddSuccessModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalDisplayed: true,
		};

		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		return setTimeout(() => {
			this.setState({
				isModalDisplayed: false,
			});
		}, 2500);
	}

	closeModal() {
		this.setState({
			isModalDisplayed: false,
		});
	}

	render() {
		return this.state.isModalDisplayed ? (
			<div
				className={classnames(`${BASE_CLASS}`, {
					[`${BASE_CLASS}__hidden`]: !this.state.isModalDisplayed,
				})}>
				<div className={`${BASE_CLASS}__inner`}>
					<h1>Success!</h1>
					<Star />
					<p>{this.props.text}</p>
					{/* {this.props.imageSrc && <img src={this.props.imageSrc} alt="new item added" />} */}
				</div>
			</div>
		) : this.props.path === 'friends' ? (
			<Friends />
		) : (
			<Redirect push to={`/${this.props.path}`} />
		);
	}
}

export default AddSuccessModal;
