import React from 'react';

const Package = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.89 1.45015L20.89 5.45015C21.2233 5.61573 21.5037 5.87097 21.6998 6.18719C21.8959 6.50341 21.9999 6.86807 22 7.24015V16.7702C21.9999 17.1422 21.8959 17.5069 21.6998 17.8231C21.5037 18.1393 21.2233 18.3946 20.89 18.5602L12.89 22.5602C12.6122 22.6992 12.3058 22.7716 11.995 22.7716C11.6843 22.7716 11.3779 22.6992 11.1 22.5602L3.10005 18.5602C2.76718 18.3924 2.4878 18.135 2.29344 17.817C2.09907 17.4989 1.99745 17.1329 2.00005 16.7601V7.24015C2.00025 6.86807 2.10424 6.50341 2.30033 6.18719C2.49642 5.87097 2.77684 5.61573 3.11005 5.45015L11.11 1.45015C11.3866 1.31273 11.6912 1.24121 12 1.24121C12.3089 1.24121 12.6135 1.31273 12.89 1.45015V1.45015Z"
			stroke="#111111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.31982 6.16016L11.9998 11.0002L21.6798 6.16016"
			stroke="#111111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 22.76V11"
			stroke="#111111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7 3.5L17 8.5"
			stroke="#111111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Package;
