import React, { Fragment } from 'react';
import './index.scss';
import classnames from 'classnames';

export default function DisplayRandomOutfit({
	top,
	bottom,
	shoes,
	dress,
	handleCloseModalClick,
	handleShuffleAgainClick,
	handleSaveOutfit,
	randomOutfitSaved,
	BASE_CLASS,
}) {
	return (
		<Fragment>
			<div className={classnames(`${BASE_CLASS}__container`)} />
			<div className={`${BASE_CLASS}__inner`}>
				<div className={`${BASE_CLASS}__inner__title`}>here's an idea</div>
				{dress && dress.imageUrls && (
					<img src={dress.imageUrls[0]} className={`${BASE_CLASS}__inner__dress`} loading="lazy" />
				)}
				{shoes && shoes.imageUrls && (
					<img src={shoes.imageUrls[0]} className={`${BASE_CLASS}__inner__shoes`} loading="lazy" />
				)}
				{top && top.imageUrls && (
					<img src={top.imageUrls[0]} className={`${BASE_CLASS}__inner__top`} loading="lazy" />
				)}
				{bottom && bottom.imageUrls && (
					<img
						src={bottom.imageUrls[0]}
						className={`${BASE_CLASS}__inner__bottom`}
						loading="lazy"
					/>
				)}
				<div className={`${BASE_CLASS}__inner__menu`}>
					<button
						type="button"
						onClick={handleShuffleAgainClick}
						className={`${BASE_CLASS}__inner__menu__button`}>
						show me another
					</button>
					{randomOutfitSaved ? (
						<button
							type="button"
							className={classnames(
								`${BASE_CLASS}__inner__menu__button`,
								`${BASE_CLASS}__inner__menu__button__saved`
							)}>
							✅ saved!
						</button>
					) : (
						<button
							type="button"
							onClick={() => handleSaveOutfit(top, bottom, shoes, dress)}
							className={classnames(
								`${BASE_CLASS}__inner__menu__button`,
								`${BASE_CLASS}__inner__menu__button__save`
							)}>
							save outfit
						</button>
					)}
					<button
						type="button"
						onClick={handleCloseModalClick}
						className={classnames(
							`${BASE_CLASS}__inner__menu__button`,
							`${BASE_CLASS}__inner__menu__button__close`
						)}>
						close
					</button>
				</div>
			</div>
		</Fragment>
	);
}
