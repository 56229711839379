import React from 'react';

const QuoteUp = () => (
	<svg width="32" height="56" viewBox="0 0 32 56" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.1844 55.4C20.1177 55.4 24.1177 53.6667 27.1844 50.2C30.1177 46.6 31.5844 41.9333 31.5844 36.2C31.5844 28.7333 29.3844 21.8 24.9844 15.4C20.5844 8.86666 15.1177 4 8.58437 0.799999L7.38437 3.19999C10.1844 5.33333 12.651 8.06666 14.7844 11.4C16.9177 14.7333 18.451 18.8667 19.3844 23.8L15.1844 24.8C10.5177 25.8667 6.91771 27.8667 4.38437 30.8C1.71771 33.6 0.384372 37 0.384372 41C0.384372 45.2667 1.85104 48.7333 4.78437 51.4C7.58437 54.0667 11.051 55.4 15.1844 55.4Z"
			fill="#fa255e"
		/>
	</svg>
);

export default QuoteUp;
