import React from 'react';

import './index.scss';

const BASE_CLASS = 'terms';

const Terms = () => {
	return (
		<div className={BASE_CLASS}>
			<h1 className={`${BASE_CLASS}__title`}>Terms of Use</h1>
			<p>Welcome to Social Closet!</p>
			<h3>Acceptance of Terms of Use</h3>
			<p>
				Social Closet ("we" or "us" or "our”) is excited to have you as a user. The following terms
				and conditions (collectively, these "Terms of Use") apply to your use of
				www.mysocialcloset.com (the “Website). These Terms of Use also cover any content,
				functionality, products, and services offered on or in connection with the Website (the
				“Services”). The Terms of Use also include our Privacy Policy, which you can review{' '}
				<a href="privacy">here.</a>
			</p>
			<p>
				Please read the Terms of Use carefully before you start using the Website because it
				represents a binding agreement between us and you. We thank you for your use of Social
				Closet!
			</p>
			<h3>Changes to the Website and Terms of Use</h3>
			<p>
				The Website is a work in progress, meaning that things could change in the near future. We
				reserve the right to update the Website from time to time in our discretion and without
				notice to you. If we update these Terms of Use, we will notify existing users and provide
				them with an opportunity to accept the update Terms of Use. After an update to the Terms of
				Use, users with User Accounts that were created prior to the update will not be permitted to
				continue using their User Accounts unless they accept the updated Terms of Use.
			</p>
			<h3>Types of Users</h3>
			<p>
				The Website accommodates two types of Users: Borrowers and Lenders. Borrowers are Users who
				rent items of clothing and Lenders are Users who list items of clothing for rent. You may
				visit the Website without being a User. However, you will not be able to make any
				transactions through the Website or use the Services until you become a User by creating a
				User Account.
			</p>
			<h3>Account Registration</h3>
			<p>
				The Website is intended for individuals at least 13 years of age or older. By registering
				for a User Account, you represent that you are 13 years of age or older and that you are
				authorized to use the chosen payment method (including credit cards, debit cards, bank
				accounts, etc.) for the purpose of renting the items as described in these Terms of Use.
				Items rented through Social Closet may be used and worn by individuals under the age of 13,
				but rentals can only be made by persons 13 or over.
			</p>
			<p>
				In order to access certain features of the Website, and to book an item for rent or create a
				Listing, you must become a User by registering and creating a User Account.
			</p>
			{/* <p>

		 You can register
			or join by logging into your account with certain third party social networking sites,
			currently limited to Facebook and Google (a “Third Party Account”). As part of the
			functionality of the Website, you may link your User Account with Third Party Accounts, by
			either: (i) providing your Third Party Account login information to Social Closet through the
			Website; or (ii) allowing Social Closet to access your Third Party Account, as is permitted
			under the applicable terms and conditions that govern your use of each Third Party Account.
			You represent that you are entitled to disclose your Third Party Account login information to
			Social Closet and/or grant Social Closet access to your Third Party Account, without breach by
			you of any of the terms and conditions that govern your use of the applicable Third Party
			Account and without obligating Social Closet to pay any fees or making Social Closet subject
			to any usage limitations imposed by such third party social networking sites. 
                </p> */}
			<p>
				A User Account and a User profile page for your use of the Website will be created upon
				registration based upon the personal information you provide to us or that we obtain via a
				third party social networking site, as described above.
			</p>
			<p>When creating a User Account:</p>
			<ul>
				<li>
					A Borrower will provide only their contact information (name, address, phone number,
					website user name, and optional profile picture). Only the website username, profile
					picture, and the city where the borrower is located will be visible to other Users of the
					Website.
				</li>
				<li>
					A Lender will also be required to provide their contact information as described above as
					well as information about the bank account where payments will be deposited. Only the
					website username, profile picture, and the city where the Lender is located will be
					visible to other Users of the Website.
				</li>
				<li>
					Stripe, our third party payment processor, will also require Lenders to create a Stripe
					merchant account; so in addition to the foregoing, Lenders will need to upload some proof
					of identity—which could be the last 4 digits of their social security number or a clear
					photo of a government-issued ID.
				</li>
				<li>
					You may not have more than one (1) active User Account. You agree to provide accurate,
					current, and complete information during the registration process and to update such
					information to keep it accurate, current, and complete.
				</li>
				<li>
					Social Closet reserves the right to suspend or terminate your User Account and your access
					to the Website or Services for any reason, including, without limitation, if you create
					more than one (1) User Account or if any information provided during the registration
					process or thereafter proves to be inaccurate, not current, or incomplete.
				</li>
				<li>
					You are responsible for safeguarding your password. You agree that you will not disclose
					your password to any third party and that you are solely responsible for any activities or
					actions under your User Account, whether or not you have authorized such activities or
					actions.
				</li>
				<li>
					You will immediately notify Social Closet of any unauthorized use of your User Account.
				</li>
			</ul>
			<h3>Creating a Listing</h3>
			<p>
				Lenders may submit listings for items for rent. Lenders will be asked a variety of questions
				about the listed items, including, but not limited to, the location, size, brand, retail and
				rental price, availability and description.
			</p>
			<p>
				Lenders must submit high-resolution photos of each item listed for rent. If the items will
				be shipped, the Lender will also provide information about who will be responsible for
				shipping costs. If a Lender ships an item, the Lender must provide tracking information for
				the shipment on the My Transaction page on the Website.
			</p>
			<p>
				Listings will be made publicly available via the Website. Other Users will be able to rent
				your listed items on the Website, based upon the information provided in your listing.
			</p>
			<p>
				You understand and agree that after a User requests to rent an item, the price for such item
				may not be altered.
			</p>
			<p>
				Lenders are required to only rent clean clothing! This is a requirement of New York State
				law. Lenders are also responsible for post-rental cleaning of items rented on the Website.
			</p>
			<h3> Renting an Item</h3>
			<p>
				Borrowers can peruse the Website to add items to their wishlist, if they aren’t ready to
				rent yet. Once a Borrower decides to rent an item, he or she can make a request to do so to
				the Lender.
			</p>
			{/* <p>
      The Borrower and Lender can communicate with each other through the Website messaging
			function at any time, including before a formal rental request has been made.
      </p> */}
			<p>
				The Borrower and Lender can communicate with each other through the Website commenting
				feature at any time, including before a formal rental request has been made.
			</p>
			<p>
				Borrowers are responsible for carefully reading item listings, including, without
				limitation, the description of the item and any policies or instructions posted by the
				Lender.
			</p>
			<p>
				If a Borrower requests to book an item, and if such request is confirmed by the Lender and
				Social Closet, the Borrower agrees to pay Social Closet the rental price for such item. Once
				you reserve an item and the Lender accepts you will receive a confirmation email summarizing
				your confirmed rental transaction. In order to initiate a rental transaction, you understand
				and agree that Social Closet, on behalf of the Lender, reserves the right, in its sole
				discretion, to obtain a pre-authorization via your credit card for the amount of the rental
				price. Social Closet will collect the rental price in accordance with these Terms of Use and
				the pricing terms set forth in the applicable listing. Please note that Social Closet cannot
				control any fees that may be charged to a Borrower by his or her own bank related to Social
				Closet’s collection of the rental price, and Social Closet disclaims all liability in this
				respect.
			</p>
			<p>
				As a Borrower, you acknowledge and agree that, by requesting an item for rent, you are
				agreeing to enter into an agreement with the Lender whose item you have requested to rent.
				To enter into this agreement, you agree to accept any terms, conditions, rules and
				restrictions imposed by the Lender in connection with any item for rent. You also
				acknowledge and agree that you, and not Social Closet, will be responsible for performing
				the obligations of any such agreements, that Social Closet is not a party to such
				agreements, and that, with the exception of its payment obligations hereunder, Social Closet
				disclaims all liability arising from or related to any such agreements.
			</p>
			<h3>Payment Processing</h3>
			<p>
				In connection with your requested rental transaction, you will be asked to provide customary
				billing information such as name, billing address, credit or debit card information to
				Social Closet’s third party payment processor, which is currently “Stripe” (www.stripe.com).
				You agree to pay Social Closet for any confirmed rental transactions made in connection with
				your User Account in accordance with these Terms of Use by credit or debit card. You
				authorize the collection of such amounts by charging the credit or debit card provided as
				part of the rental transaction, either directly by Social Closet or indirectly, via our
				third party online payment processor. You also authorize Social Closet to charge your credit
				or debit card in the event of damage caused to an item for rent as described under “Damage
				to Item for Rent” section below.
			</p>
			<p>
				If you are directed to Social Closet’s third party payment processor, you will be subject to
				terms and conditions governing use of that third party’s service and that third party’s
				personal information collection practices. Stripe collects payment immediately from the
				Borrower on behalf of the Lender. The amount received by the Lender will be the agreed-upon
				rental price minus the Service Fee charged by Social Closet.
			</p>
			<p>
				The Service Fee is equal to eighteen percent (18%) of the Rental Price before any applicable
				delivery and/or insurance fees are included. Each Lender hereby permits Social Closet to
				collect payments made by Borrowers on behalf of the Lender. Each Lender agrees that payment
				of a rental price made by a Borrower to Social Closet shall be considered the same as a
				payment made directly to the Lender and the Lender will make the item for rent available to
				the Borrower in the agreed upon manner as if the Lender had directly received payment of the
				rental price.
			</p>
			<p>
				Social Closet assumes no liability for any acts or omissions of the Lender. Lenders are
				solely responsible for honoring any confirmed rental transactions and making available any
				item for rent reserved through the Website or in connection with the Services.
			</p>
			<p>
				Upon a Borrower’s payment of the rental price (and shipping fee, as applicable) to Social
				Closet, Borrower’s payment obligation to the Lender for such amounts is fulfilled. Social
				Closet is responsible for remitting such amounts, less the applicable Service Fee, to the
				Lender. In the event that Social Closet does not remit any such amounts to a Lender, such
				Lender will have recourse only against Social Closet.
			</p>
			<h3>Use of Rented or Borrowed Items</h3>
			<p>
				As a Borrower, you agree to treat the items with the highest standard of care, as if the
				item were your own. Borrowers are responsible for loss, destruction or damage to the items,
				whether due to theft, mysterious disappearance, fire, major stains or any other cause.
			</p>
			<p>
				If a Borrower or Lender claims that he or she received damaged clothing, he or she will have
				24 hours from when the item was received to notify Social Closet of the damage and submit
				photographic evidence of the claim. The other party will be notified that there is a claim
				against them but will not be given an opportunity to dispute the claim. Social Closet will
				review the evidence and make a determination within 72 hours. If Social Closet determines
				that the Borrower has caused minor damage to an item, the Borrower will be charged the cost
				of repair to the item, or a maximum of 1.5 times the rental fee. Examples of minor damage
				include a minor stain, a loose thread, or missing button.
			</p>

			<p>
				{' '}
				If Social Closet determines that the Borrower has caused major damage to an item, the
				Borrower will be charged the cost of repair to the item, or a maximum of 4 times the rental
				fee. Examples of major damage include a large stain or a tear in fabric.
			</p>
			<p>
				If an article is lost, stolen, or not returned to the Lender, the Borrower will be charged a
				fine of 5 times the rental fee. Social Closet reserves the right to remove a User at any
				time for suspected misuse of the platform.
			</p>

			{/* <h3>
      Insurance
      </h3>
      <p>
      Borrowers may purchase
			insurance for $3.00 (United States Dollars) for each item rented. Insurance will cover minor
			stains, rips, missing beads, stuck zippers, snagged threads, and other similar damage within
			Social Closet’s sole discretion. If a Borrower does not purchase insurance and an item of
			clothing sustains minor damage while in the Borrower’s possession, the Borrower shall be
			liable to the Lender for up to 1.5 times the rental price. Claim Liability and Payouts
			Borrower Lender Minor damage (i.e., a small tear, snagged thread, removable stain, or similar
			damage in the sole discretion of Social Closet) - If the Borrower bought $3 insurance and
			completed payment for the rental price, the Borrower has no further liability to the lender. -
			If the Borrower did not buy $3 insurance, the borrower is liable for up to 1.5 times the
			rental price in addition to the original rental price. Regardless of whether the Borrower
			purchased insurance, the Lender can expect to receive up to 1.5 times the rental price in
			addition to the original rental price. Major damage or total loss (including failure to return
			a rented item) - If the Borrower bought $3 insurance, the Borrower is responsible for up to 3
			times the rental price in addition to the original rental price. - If the Borrower did not buy
			$3 insurance, the borrower is responsible for up to 5.5 times the rental price in addition to
			the original rental price. Regardless of whether the Borrower purchased insurance, the Lender
			can expect to receive up to 5.5 times the rental price in addition to the original rental
			price.
      </p> */}
			<h3>Complete Rentals</h3>
			<p>
				Once an item is marked “complete,” Social Closet considers the rental transaction to have
				been completed because all of the following events have occurred: the Borrower has made
				payment to the Lender and the Lender has received payment; the Lender has sent the item to
				the Borrower and the Borrower has received the item undamaged; the Borrower has returned the
				item and the Lender has received the returned item undamaged.
			</p>
			<p>
				Items which are rented in person will automatically be marked complete on the final day on
				the rental period identified in the Lender’s Listing. For items that are returned by
				shipping, the item will be marked as complete on the final day of the rental period, and
				Borrowers will submit tracking information on the site to prove they’ve shipped the item by
				this date. If Lenders claim a late return, Social Closet will (manually) use tracking
				information submitted by the Borrower to verify this.
			</p>
			<h3>Cancellations and Refunds</h3>
			<p>
				Borrowers and Lenders may cancel a rental transaction within 12 hours of confirming the
				transaction, up to 24 hours before the start of the rental period. Social Closet will cancel
				any pre-authorization to the Borrower’s credit card and/or refund any amounts charged to the
				Borrower’s credit card in connection with the requested rental transaction within a
				commercially reasonable time.
			</p>
			<p>
				After a transaction has been completed, a Borrower may request a refund only if the item
				received is different from the item described in the Lender listing, only within 12 hours of
				receiving the item, and only with photographic proof. If Social Closet finds that the item
				was falsely described in the listing or that the Lender rented a dirty or otherwise damaged
				item, Social Closet may withhold payment to the Lender and refund the Borrower.
			</p>
			<h3>User Conduct</h3>
			<p>
				You understand and agree that you are solely responsible for compliance with any and all
				laws, rules, regulations, and tax obligations that may apply to your use of the Website and
				the Services. In connection with your use of the Social Closet Website and Services, you may
				not and you agree that you will not:
			</p>
			<ul>
				<li>Harass any user of the Website or Services</li>
				<li>
					Collect, store, sell, disseminate or provide to any other party any personally
					identifiable information about Users other than for purposes of transacting as a Social
					Closet User;
				</li>
				<li>
					Offer, as a Lender, any item for rent that you do not yourself own or have permission to
					rent;
				</li>
				<li>
					Register for more than one User Account or register for a User Account on behalf of an
					individual other than yourself;
				</li>
				<li>
					Contact a Lender for any purpose other than asking a question related to an item that is
					being offered by the Lender for rent or the booking process and instructions for such
					item(s);
				</li>
				<li>
					Impersonate any person or entity, or falsify or otherwise misrepresent yourself or your
					affiliation with any person or entity;
				</li>
				<li>
					Use the Website or Services to find a Lender or Borrower and then complete a rental
					transaction independent of the Website or Services in order to circumvent the obligation
					to pay any Service Fees;
				</li>
				<li>
					As a Lender, submit any listing with false or misleading information, or submit any
					listing with a rental price that you do not intend to honor;
				</li>
				<li>
					As a Lender, fail to deliver an item after you have confirmed a requested rental
					transaction for such item;
				</li>
				<li>
					Post, upload, publish, submit or transmit any Content that: (i) infringes, misappropriates
					or violates a third party’s patent, copyright, trademark, trade secret, moral rights or
					other intellectual property rights, or rights of publicity or privacy; (ii) violates, or
					encourages any conduct that would violate, any applicable law or regulation or would give
					rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is
					defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination,
					bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is
					violent or threatening or promotes violence or actions that are threatening to any other
					person; or (vii) promotes illegal or harmful activities or substances;
				</li>
				<li>
					Systematically retrieve data or other content from our Website or Services to create or
					compile, directly or indirectly, in single or multiple downloads, a collection,
					compilation, database, directory, whether by manual methods, through the use of bots,
					crawlers, or spiders, or otherwise; and
				</li>
				<li>
					Use, display, mirror or frame the Website or any individual element within the Website,
					Social Closet’s name, any Social Closet trademark, logo or other proprietary information,
					or the layout and design of any page or form contained on a page, without Social Closet’s
					express written consent.
				</li>
			</ul>
			<h3>Feedback & Comments</h3>
			<p>
				We welcome and encourage you to provide feedback, comments, and suggestions for improvements
				to the Website and Services. Please submit feedback by emailing us at
				hello@mysocialcloset.com.
			</p>
			<p>
				You acknowledge and agree that all feedback will be the sole and exclusive property of
				Social Closet, and you hereby irrevocably assign to Social Closet all of your right, title,
				and interest in all feedback, including, without limitation, all worldwide patent,
				copyright, trade secret, moral, and other proprietary or intellectual property rights
				therein.
			</p>
			<p>
				At Social Closet’s request and expense, you agree to execute documents and take such further
				acts as Social Closet may reasonably request to assist Social Closet to acquire, perfect,
				and maintain its intellectual property rights and other legal protections for the feedback.
			</p>
			<h3>Termination and Social Closet Account Cancellation</h3>
			<p>
				We may, in our discretion and without liability to you, with or without cause, with or
				without prior notice and at any time: (a) limit, suspend or terminate your access to your
				User Account, our Website and Services, and (b) deactivate or cancel your User Account. Upon
				termination, we will promptly pay you any amounts we reasonably determine we owe you and
				which we are legally obligated to pay you.
			</p>
			<p>
				In the event Social Closet terminates these Terms of Use or your access to our Website and
				Services or deactivates or cancels your User Account, you will remain liable for all amounts
				due hereunder. You may cancel your User Account at anytime via the “Cancel Account” feature
				of the Website or by sending an email to hello@mysocialcloset.com. Please note that if your
				User Account is cancelled, we do not have an obligation to delete or return to you any
				content you have posted to the Website or provided in connection with the Services,
				including, but not limited to, any reviews or feedback.
			</p>
			<h3>User Content License</h3>
			<p>
				We permit Users to post, upload, publish, submit or transmit content on the Website or in
				connection with the Services (“User Content.”) You hereby grant to Social Closet a
				worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with
				the right to sublicense, use, view, copy, adapt, modify, distribute, license, sell,
				transfer, publicly display, publicly perform, transmit, stream, broadcast, access, view, and
				otherwise exploit such User Content on, through, or by means of the Website and Services.
			</p>
			<p>
				Nothing in these Terms of Use will be deemed to restrict any rights that you may have to use
				and exploit any such User Content. You acknowledge and agree that you are responsible for
				all content that you make available through the Website or in connection with the Services.
				Accordingly, you represent and warrant that: (i) you either are the sole and exclusive
				lender of all User Content that you make available through the Website or in connection with
				the Services or you have all rights, licenses, consents and releases that are necessary to
				grant to Social Closet the rights in such User Content, as contemplated under these Terms;
				and (ii) neither the User Content nor your posting, uploading, publication, submission or
				transmittal of the User Content or Social Closet’s use of the User Content (or any portion
				thereof) on, through, or by means of the Website and the Services will infringe,
				misappropriate, or violate a third party’s patent, copyright, trademark, trade secret, moral
				rights or other proprietary or intellectual property rights, or rights of publicity or
				privacy, or result in the violation of any applicable law or regulation.
			</p>
			<h3>Intellectual Property</h3>
			<p>
				The Website and its entire contents are owned by Social Closet LLC and are protected by
				United States copyright, trademark, and other intellectual property or proprietary rights
				laws. No permission is granted to you to print, copy, reproduce, distribute, transmit,
				upload, download, store, display in public, alter, or modify any content which appears on
				the Website.
			</p>
			<p>
				Any partial or total reproduction of the Website or its content is subject to our prior and
				express written authorization. Access to the Website We work hard to ensure that the Website
				is always up and available, but we can't guarantee that the Website will not have downtime
				for any reason.
			</p>
			<p>
				We reserve the right to close the Website for short periods of time for regular updating of
				products and for general maintenance, but will attempt to keep this to a minimum. We will
				not be liable if for any reason all or any part of the Website is unavailable at any time,
				for any length of time.
			</p>
			<h3>Disclaimer of Warranties, Limitations of Liability, and Indemnification</h3>
			<p>
				Your use of the Website and Services is at your sole risk. The Website and Services are
				provided "as is" and "as available." We disclaim all warranties of any kind, express or
				implied, including, without limitation, the warranties of merchantability, fitness for a
				particular purpose, and non-infringement.
			</p>
			<p>
				We are not liable for damages, direct or consequential, resulting from your use of the
				Website or the Services, and you agree to defend, indemnify, and hold us harmless from any
				claims, losses, liability, and costs and expenses (including but not limited to attorney's
				fees) arising from your violation of any third party's rights. In no circumstance will
				Social Closet’s liability to any user in connection with the Website or Services exceed $100
				United States Dollars.
			</p>
			<p>
				You acknowledge that you have only a limited, non-exclusive, nontransferable license to use
				the Website. Because the Website may not be error- or bug-free, you agree that you will use
				it carefully and avoid using it in ways which might result in any loss of your or any third
				party's property or information.
			</p>
			<h3>Third Party Websites</h3>
			<p>
				The Website may contain links to third-party websites (“Third Party Websites”). Your use of
				all such links is at your own risk. We do not monitor or have any control over and make no
				claim or representation regarding Third Party Websites. If we provide links to Third Party
				Websites, they are provided only as a convenience and do not imply our endorsement of or
				affiliation with such Third Party Website.
			</p>
			<p>
				We accept no responsibility for the quality, content, policies, nature, or reliability of
				Third Party Websites or of websites linking to http://www.mysocialcloset.com. You should
				review the applicable terms and policies, including privacy and data gathering practices, of
				any Third Party Website, and should make whatever inquiry you feel necessary or appropriate
				before proceeding with any transaction with any Third Party Website.
			</p>
			<h3>Governing Law and Jurisdiction</h3>
			<p>
				These Terms of Use and any dispute or claim arising out of, or related to them, shall be
				governed by and construed in accordance with the laws of the State of New York without
				giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or
				proceeding arising out of, or related to, these Terms of Use or the Website or Services
				shall be brought in the courts of the State of New York.
			</p>
			<h3>Dispute Resolution</h3>
			<p>
				Any controversy or claim arising out of or relating to this contract, or the breach thereof,
				shall be settled by arbitration administered by the American Arbitration Association in
				accordance with its Commercial Arbitration Rules and judgment on the award rendered by the
				arbitrator(s) may be entered in any court having jurisdiction thereof.
			</p>
			<h3>Waiver, Entire Agreement, and Severability</h3>
			<p>
				Our failure to exercise or enforce any right or provision of the Terms of Use is not a
				waiver of such right or provision. The Terms of Use constitute our entire agreement with you
				and govern your use of the Website, superseding any prior agreements. If any provision of
				these Terms of Use is held by a court of competent jurisdiction to be invalid or
				unenforceable, only that particular provision so found, and not the rest of the Terms of
				Use, will be inoperative.
			</p>
		</div>
	);
};

export default Terms;
