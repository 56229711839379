import Add from './Add';
import AddToCart from './AddToCart';
import Back from './Back';
import Cart from './Cart';
import Close from './Close';
import DrawerOpen from './DrawerOpen';
import Favorite from './Favorite';
import Filter from './Filter';
import Instagram from './Instagram';
import Menu from './Menu';
import Payment from './Payment';
import Profile from './Profile';
import PromoCode from './PromoCode';
import Remove from './Remove';
import Shipping from './Shipping';
import DrawerClosed from './DrawerClosed';
import SingleFilter from './SingleFilter';
import Map from './Map';
import Tag from './Tag';
import Logo from './Logo';
import Dollar from './Dollar';
import CalendarIcon from './CalendarIcon';
import QuoteDown from './Quotedown';
import QuoteUp from './Quoteup';
import Edit from './Edit';
import Follow from './Follow';
import Hashtag from './Hashtag';
import Sliders from './Sliders';
import Grid from './Grid';
import AlertCircle from './AlertCircle';
import Illustration1 from './Illustrations/Illustration1';
import Illustration2 from './Illustrations/Illustration2';
import Illustration3 from './Illustrations/Illustration3';
import Illustration4 from './Illustrations/Illustration4';
import Illustration5 from './Illustrations/Illustration5';
import Illustration6 from './Illustrations/Illustration6';
import Illustration7 from './Illustrations/Illustration7';
import One from './Illustrations/1';
import Two from './Illustrations/2';
import Three from './Illustrations/3';
import Four from './Illustrations/4';
import Five from './Illustrations/5';
import Six from './Illustrations/6';
import Seven from './Illustrations/7';
import Eight from './Illustrations/8';
import Nine from './Illustrations/9';
import Ten from './Illustrations/10';
import Eleven from './Illustrations/11';
import Twelve from './Illustrations/12';
import Clock from './Clock';
import MessageCircle from './MessageCircle';
import MessageArrow from './MessageArrow';
import MeetingArrows from './MeetingArrows';
import Search from './Search';
import Pin from './Pin';
import Blocks from './Blocks';
import Promo1 from './Promo1';
import Promo2 from './Promo2';
import Promo3 from './Promo3';
import BlogPost1 from './BlogPost1';
import BlogPost2 from './BlogPost2';
import Trash from './Trash';
import Move from './Move';
import Avatar from './Avatar';
import ItemsBorrowed from './ItemsBorrowed';
import ItemsRented from './ItemsRented';
import MoneyEarned from './MoneyEarned';
import CreditIcon from './CreditIcon';
import ShoppingCart from './ShoppingCart';
import Package from './Package';
import DeliveryTruck from './DeliveryTruck';
import Octagon from './OctagonX';
import WarningTriangle from './WarningTriangle';
import Circle from './Circle';
import CircleCheck from './Circle-Check';
import List from './List';
import Subcategory from './Subcategory';
import Aperture from './Aperture';

export {
	Add,
	AddToCart,
	Back,
	Cart,
	Close,
	DrawerOpen,
	Favorite,
	Filter,
	Instagram,
	Payment,
	Menu,
	PromoCode,
	Profile,
	Remove,
	Shipping,
	DrawerClosed,
	SingleFilter,
	Map,
	Tag,
	Logo,
	Dollar,
	CalendarIcon,
	QuoteUp,
	QuoteDown,
	Edit,
	Follow,
	Hashtag,
	Sliders,
	Grid,
	AlertCircle,
	Clock,
	MessageCircle,
	MessageArrow,
	MeetingArrows,
	Search,
	Pin,
	Blocks,
	Illustration1,
	Illustration2,
	Illustration3,
	Illustration4,
	Illustration5,
	Illustration6,
	Illustration7,
	One,
	Two,
	Three,
	Four,
	Five,
	Six,
	Seven,
	Eight,
	Nine,
	Ten,
	Eleven,
	Twelve,
	Promo1,
	Promo2,
	Promo3,
	BlogPost1,
	BlogPost2,
	Trash,
	Move,
	Avatar,
	MoneyEarned,
	ItemsBorrowed,
	ItemsRented,
	CreditIcon,
	ShoppingCart,
	DeliveryTruck,
	Package,
	Octagon,
	WarningTriangle,
	Circle,
	CircleCheck,
	List,
	Subcategory,
	Aperture,
};
