import React, { Component } from 'react';
import store, { resetPassword } from '../../store/user';
import './index.scss';

const BASE_CLASS = 'reset-password';

export default class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit = (e) => {
		resetPassword(e.target.password.value, e.target.email.value);
	};
	render() {
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__title`}>reset your password</div>
				<div className={`${BASE_CLASS}__text`}>please enter a new one.</div>
				<form onSubmit={(e) => this.handleSubmit(e)}>
					<input
						name="email"
						type="email"
						placeholder="email"
						className={`${BASE_CLASS}__form__input`}
					/>
					<input
						name="password"
						type="password"
						placeholder="password"
						className={`${BASE_CLASS}__form__input`}
					/>
					<button type="submit" className={`${BASE_CLASS}__form__submit`}>
						reset
					</button>
				</form>
			</div>
		);
	}
}
