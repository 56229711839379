import React from 'react';

const Clock = () => (
	<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 18.333a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667z"
			stroke="#111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 5v5l3.333 1.667"
			stroke="#111"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Clock;
