import React from 'react';

import './index.scss';

const BASE_CLASS = 'privacy';

const Privacy = () => {
	return (
		<div className={BASE_CLASS}>
			<h1 className={`${BASE_CLASS}__title`}>Privacy Policy </h1>
			<p>
				The purpose of this Privacy Policy is to explain how Social Closet LLC (“we,” “us,” or
				“our”) collects and uses information from you when you visit or complete a rental
				transaction through our Website, http://www.mysocialcloset.com. By signing up for our
				services, you consent to the collection and use of your information as described in this
				Privacy Policy.
			</p>
			<h3>Types of Information We Collect:</h3>
			<p>
				Information collected on our site can be broken down into two categories: Personal
				Information and Non-Personal Information.
			</p>
			<ul>
				Personal Information refers to data that can be used to identify or contact you. This
				includes:
				<li>
					Your username; Your profile picture; Your postal zip code; Your telephone number; Your
					password, and email address you input when you register for a Social Closet account;
				</li>
				<li>
					Your Internet Protocol (IP) address; o User Content, including but not limited to photos,
					comments, and other materials that you post on the Website;
				</li>
				<li>
					Communications between you and Social Closet: For example, you may be sent service-related
					emails (e.g., account verification, changes/updates to features of the service, technical
					and security notices). Note that you may not opt out of service-related e-mails unless
					your account is terminated;
				</li>
				<li>
					Communication between you and Social Closet users: For example, we may review messages
					sent between you and another user to resolve a dispute about damage to a clothing item)
				</li>
				<li>
					Public posts on the Website; and  When you post messages on our Website and other public
					message areas or leave feedback for other users, we collect the information you post. This
					information is helpful to resolve disputes, provide customer support, and troubleshoot
					problems as permitted by law.{' '}
				</li>
				{/* <li>
        The last four digits of your social security number or a
				clear photo of a government-issued ID—only applicable to Lenders.
        </li> */}
			</ul>
			<p>
				Non-Personal Information refers to data that is anonymized and cannot be used to identify
				you. This includes the type of information collected by cookies and similar technology. o
				Cookies are small pieces of information that are stored by your browser on your computer’s
				hard drive. A "session cookie" stores temporary information that is erased when you close
				your web browser or turn off your computer. A "persistent cookie" enables our site to
				recognize you when you return to it and remains stored on your computer until you delete it.
				Your web browser is usually set up to initially accept cookies, but you can change this
				feature in your browser settings. Please note that some aspects of the site, such as the
				shopping cart, will not function correctly if you disable cookies.
			</p>
			<h3>How We Collect Your Information: </h3>
			<p>
				{' '}
				We only collect the Personal Information that you submitted or uploaded voluntarily through
				your interactions with the Website. We may collect Non-Personal Information automatically
				through cookies and similar technology.
			</p>
			<h3>How We Use Your Information:</h3>
			<h4>Personal Information - We may use Personal Information to:</h4>

			<ul>
				<li>Respond to requests that you make;</li>
				<li>
					Promote lender's listings on Social Media (you may request out of this by contacting our
					team);
				</li>
				<li>Communicate with you about additional products and services that we offer;</li>
				<li>Customize, analyze, adjust, and improve our website to better meet your needs;</li>
				<li>Enforce our agreements with you;</li>
				<li> Help you efficiently access your information after you sign in;</li>
				<li>
					Remember information so you will not have to re-enter it during your visit or the next
					time you visit the Website;
				</li>

				<li>
					Provide personalized content and information to you and others, which may include online
					ads or other forms of marketing;
				</li>
				<li>
					Develop and test new products and features; • Monitor metrics such as total number of
					visitors, traffic, and demographic patterns;
				</li>
				<li>Diagnose and/or fix technology problems;</li>
				<li>
					Provide you with important administrative information regarding our services, such as
					changes to this Privacy Policy, our Terms of Use, and other policies;
				</li>
				<li>Prevent fraud and other prohibited or illegal activities;</li>
				<li>
					Comply with court orders, subpoenas, warrants, or other requests as required by law; and
				</li>
				<li>
					Any other use that Social Closet views as relevant to improving the Website and user
					experience.
				</li>
			</ul>
			<p>
				We do not sell, trade, or rent your Personal Information to others. We may provide summary
				statistics about our customers’ interests and preferences, products sold, traffic patterns,
				and related site information to a reputable third party. These statistics do not include
				Personal Information. They are used only to enhance the design of our site and to customize
				new product offerings to our customers. We do not use or share Personal Information provided
				to us online in ways unrelated to the ones described in this Policy.{' '}
			</p>
			<h4>Non-Personal Information </h4>
			<p>
				We may use cookies and similar technology to personalize or improve the site, keep track of
				your shopping cart, and other similar uses. Social Closet may run contests, special offers,
				or other events or activities (“Events”) on the Website. You may opt out of these
				communications at any time.
			</p>

			<p>
				{/* How Your Personal Information Is Protected: Secure Sockets
				Layer (SSL) server software is used to prevent unauthorized access to the information you
				submit in connection with a rental transaction. When you initiate payment for a rental
				transaction, SSL encrypts all information you input before it is sent to our third-party
				service providers. You can identify the secure connection by looking at the URL, which will
				begin with “https:” (instead of “http:”).  */}
				<h3>Managing Your Information</h3>
				<p>
					Your account information and profile/privacy settings may be managed by you as follows:
				</p>
				<ul>
					<li>Update your account at any time by logging in and changing your profile settings.</li>
					<li>
						Unsubscribe from email communications from Social Closet by clicking on the “unsubscribe
						link” provided in such communications. As noted above, you may not opt out of
						service-related communications, including but not limited to account verification,
						billing confirmations and reminders, changes/updates to features of the Website,
						technical and security notices, unless your Social Closet account is terminated.
					</li>
				</ul>
				<h3>Third-Party Services</h3>

				<p>
					We are not responsible for the practices employed by any websites or services linked to or
					from our Website, including the information or content contained within them. Please
					remember that when you use a link to go from a Social Closet-owned domain to another
					website or service, Social Closets’ Privacy Policy does not apply to those third-party
					websites or services. Your browsing and interaction on any third-party website or service,
					including those that have a link on our website, are subject to that third party’s own
					rules and policies. In addition, you agree that we are not responsible and do not have
					control over any third parties that you authorize to access your user content. If you are
					using a third-party website or service and you allow them to access your Personal
					Information, you do so at your own risk.
				</p>
				<h3>Data and User Content Retention Policy</h3>
				<p>
					Following termination or deactivation of your account, Social Closet, or its third party
					service providers may retain information (including your profile information) and user
					content for an indefinite but reasonable time for backup, archival, and/or audit purposes.
					Children’s Online Privacy Protection Act We do not intend that any portion of our website
					will be accessed or used by children under the age of 13, and such use is prohibited. By
					using our website, you represent that you are at least 13 years old and understand that
					you must be at least 13 years old in order to send us Personal Information and purchase
					the products that we advertise. We will promptly delete the Personal Information of any
					user of our site when we obtain actual knowledge that such users are under the age of 13.
				</p>
				<h3>Changes in Ownership</h3>
				<p>
					If part or all of Social Closet LLC is in the process of a potential transaction, bought,
					sold, or otherwise transferred, customer information will likely be shared for evaluation
					purposes and included among the transferred business assets. You acknowledge that such
					transfers may occur, and that any acquirer of Social Closet LLC or its assets may continue
					to use your Personal Information as set forth in this Privacy Policy. Changes to the
					Privacy Policy Social Closet reserves the right to modify or update this Privacy Policy
					from time to time without notifying users, so please review it periodically. We may
					provide you additional forms of notice of modifications or updates as appropriate under
					certain circumstances. Your continued use of the Website constitutes acceptance to any
					updated versions of the Privacy Policy.
				</p>
				<h3>Contacting Us</h3>
				<p>
					If you have questions about this Privacy Policy or Social Closet generally, please contact
					us at hello@mysocialcloset.com.
				</p>
			</p>
		</div>
	);
};

export default Privacy;
