import React from 'react';

const Octagon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.13581 1.28926C7.32286 1.10221 7.57654 0.997131 7.84107 0.997131H16.0994C16.3639 0.997131 16.6176 1.10221 16.8046 1.28926L22.6493 7.13392C22.8364 7.32096 22.9414 7.57465 22.9414 7.83917V16.0975C22.9414 16.362 22.8364 16.6157 22.6493 16.8028L16.8046 22.6474C16.6176 22.8345 16.3639 22.9395 16.0994 22.9395H7.84107C7.57654 22.9395 7.32286 22.8345 7.13581 22.6474L1.29115 16.8028C1.1041 16.6157 0.999023 16.362 0.999023 16.0975V7.83917C0.999023 7.57465 1.1041 7.32096 1.29115 7.13392L7.13581 1.28926ZM8.2542 2.9919L2.99379 8.2523V15.6844L8.2542 20.9448H15.6863L20.9467 15.6844V8.2523L15.6863 2.9919H8.2542Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.6674 8.27119C16.0569 8.66069 16.0569 9.2922 15.6674 9.6817L9.68311 15.666C9.2936 16.0555 8.6621 16.0555 8.2726 15.666C7.88309 15.2765 7.88309 14.645 8.2726 14.2555L14.2569 8.27119C14.6464 7.88169 15.2779 7.88169 15.6674 8.27119Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.2726 8.27119C8.6621 7.88169 9.2936 7.88169 9.68311 8.27119L15.6674 14.2555C16.0569 14.645 16.0569 15.2765 15.6674 15.666C15.2779 16.0555 14.6464 16.0555 14.2569 15.666L8.2726 9.6817C7.88309 9.2922 7.88309 8.66069 8.2726 8.27119Z"
			fill="black"
		/>
	</svg>
);

export default Octagon;
