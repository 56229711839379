import React from 'react';

const CircleCheck = () => (
	<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20.1666 10.4792V11.3225C20.1655 13.2993 19.5254 15.2227 18.3418 16.8059C17.1582 18.3891 15.4946 19.5473 13.599 20.1078C11.7034 20.6683 9.67741 20.601 7.82318 19.9159C5.96896 19.2309 4.38585 17.9648 3.30997 16.3065C2.23408 14.6482 1.72306 12.6866 1.85312 10.7142C1.98318 8.74173 2.74736 6.86418 4.03168 5.36153C5.31599 3.85888 7.05164 2.81165 8.97976 2.37602C10.9079 1.94038 12.9252 2.13969 14.7308 2.94422"
			fill="white"
		/>
		<path
			d="M20.1666 10.4792V11.3225C20.1655 13.2993 19.5254 15.2227 18.3418 16.8059C17.1582 18.3891 15.4946 19.5473 13.599 20.1078C11.7034 20.6683 9.67741 20.601 7.82318 19.9159C5.96896 19.2309 4.38585 17.9648 3.30997 16.3065C2.23408 14.6482 1.72306 12.6866 1.85312 10.7142C1.98318 8.74173 2.74736 6.86418 4.03168 5.36153C5.31599 3.85888 7.05164 2.81165 8.97976 2.37602C10.9079 1.94038 12.9252 2.13969 14.7308 2.94422"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.1667 3.98926L11 13.1651L8.25 10.4151"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default CircleCheck;
