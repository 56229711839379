import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout, queueModal } from '../../../../store/index';
import { Link } from 'react-router-dom';
import './index.scss';
import { Icon, UserIcon, Instagram, Tiktok } from '../../../../../public';

const BASE_CLASS = 'desktop-header';

const DesktopHeader = ({ handleClick, isLoggedIn, handleSignUp, handleLogIn }) => {
	const [isSubNavOpen, setIsSubNavOpen] = useState(false);

	function handleSubNavClick() {
		setIsSubNavOpen(!isSubNavOpen);
	}

	return (
		<div className={BASE_CLASS}>
			<Link to="/home" className={`${BASE_CLASS}__logo`}>
				<Icon />
			</Link>
			{isLoggedIn && (
				<>
					<div>
						<Link to="/howitworks">How it works</Link>
						<Link to="/rent">Rent</Link>
						<Link to="/lend">Lend</Link>
						<Link to="/activity">Activity</Link>

						<a
							href="https://www.instagram.com/mysocialcloset/"
							target="_blank"
							rel="noreferrer noopener"
							className="social-icon-one">
							<Instagram />
						</a>
						<a
							href="https://www.tiktok.com/@social.closet"
							target="_blank"
							rel="noreferrer noopener"
							className="social-icon-two">
							<Tiktok />
						</a>
						<div className="sub-nav__container">
							<button onMouseEnter={handleSubNavClick}>
								<UserIcon />
							</button>
							{isSubNavOpen && (
								<div className="sub-nav" onMouseLeave={handleSubNavClick}>
									{/* <Link to="/inbox">Inbox</Link> */}
									<Link to="/profile">Profile</Link>
									<Link to="/closet">Closet</Link>
									<Link to="/friends">Friends</Link>
								</div>
							)}
						</div>

						<Link to="/add" className={`${BASE_CLASS}__login`}>
							List an Item
						</Link>
						<a href="/home" onClick={handleClick} className={`${BASE_CLASS}__logout`}>
							Log out
						</a>
					</div>
				</>
			)}

			{!isLoggedIn && (
				<>
					<div>
						<Link to="/howitworks">How it works</Link>
						<Link to="/rent">Rent</Link>
						<Link to="/lend">Lend</Link>
						<a
							href="https://www.instagram.com/mysocialcloset/"
							target="_blank"
							rel="noreferrer noopener"
							className="social-icon-one">
							<Instagram />
						</a>
						<a
							href="https://www.tiktok.com/@social.closet"
							target="_blank"
							rel="noreferrer noopener"
							className="social-icon-two">
							<Tiktok />
						</a>
					</div>
					<a href="#" onClick={handleLogIn} className={`${BASE_CLASS}__login`}>
						Log in
					</a>
					<a href="#" onClick={handleSignUp} className={`${BASE_CLASS}__logout`}>
						Sign up
					</a>
				</>
			)}
		</div>
	);
};

const mapState = (state) => {
	return {
		isLoggedIn: !!state.user.user.id,
	};
};

const mapDispatch = (dispatch) => {
	return {
		handleClick() {
			dispatch(logout());
		},
		handleSignUp() {
			dispatch(queueModal('SIGNUP'));
		},
		handleLogIn() {
			dispatch(queueModal('LOGIN'));
		},
	};
};

export default connect(mapState, mapDispatch)(DesktopHeader);

DesktopHeader.propTypes = {
	handleClick: PropTypes.func.isRequired,
};
