import React from 'react';
import { XYPlot, LabelSeries, VerticalBarSeries, HorizontalBarSeries } from 'react-vis';

function BrandsPurchasedBarGraph(props) {
	const { data } = props;

	return (
		<XYPlot xType="ordinal" height={310} width={300} className="brands-purchased-barchart">
			<VerticalBarSeries data={data} />
			<LabelSeries
				animation
				allowOffsetToBeReversed
				labelAnchorY="end"
				data={data}
				style={{ fontSize: 14, fontWeight: 500 }}
			/>
		</XYPlot>
	);
}

function BrandsWornBarGraph(props) {
	const { data } = props;

	return (
		<XYPlot xType="ordinal" height={310} width={300} className="brands-worn-barchart">
			<VerticalBarSeries data={data} />
			<LabelSeries
				animation={true}
				allowOffsetToBeReversed
				labelAnchorY="end"
				data={data}
				style={{ fontSize: 14, fontWeight: 500 }}
			/>
		</XYPlot>
	);
}

function UsageBarChart(props) {
	const { data } = props;

	return (
		<XYPlot yType="ordinal" height={150} width={320} className={props.className}>
			<HorizontalBarSeries data={data} />
			<LabelSeries
				animation={true}
				labelAnchorX="center"
				// allowOffsetToBeReversed
				data={
					!!data &&
					data.map((d) => {
						return { ...d, yOffset: 0, xOffset: 0 };
					})
				}
				style={{ fontSize: 14, fontWeight: 500 }}
			/>
		</XYPlot>
	);
}

export { BrandsPurchasedBarGraph, BrandsWornBarGraph, UsageBarChart };
