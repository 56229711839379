import axios from 'axios';
import history from '../history';

/**
 * ACTION TYPES
 */
const GET_USER = 'GET_USER';
const REMOVE_USER = 'REMOVE_USER';
const GOT_USERS = 'GOT_USERS';
const GOT_UPDATED_USER = 'GOT_UPDATED_USER';
const GOT_LENDER_FOR_REQUEST = 'GOT_LENDER_FOR_REQUEST';

/**
 * INITIAL STATE
 */
const initialState = {
	user: {},
	users: [],
	lender: [],
};
//const defaultUser = {}

/**
 * ACTION CREATORS
 */
const getUser = (user) => ({ type: GET_USER, user });
const removeUser = () => ({ type: REMOVE_USER });
const gotUsers = (users) => ({ type: GOT_USERS, users });
const gotUpdatedUser = (user) => ({ type: GOT_UPDATED_USER, user });
const gotLenderForRequest = (lender) => ({ type: GOT_LENDER_FOR_REQUEST, lender });

/**
 * THUNK CREATORS
 */
export const me = () => async (dispatch) => {
	try {
		const res = await axios.get('/auth/me');
		dispatch(getUser(res.data || initialState.user));
	} catch (err) {
		console.error(err);
	}
};

export const getUsers = () => async (dispatch) => {
	try {
		const res = await axios.get('/api/users');
		dispatch(gotUsers(res.data));
	} catch (err) {
		console.error(err);
	}
};

export const auth = (name, email, password, method) => async (dispatch) => {
	let res;

	try {
		res = await axios.post(`/auth/${method}`, { name, email, password });
	} catch (authError) {
		return dispatch(getUser({ error: authError }));
	}

	try {
		dispatch(getUser(res.data));

		// history.push('/home');
	} catch (dispatchOrHistoryErr) {
		console.error(dispatchOrHistoryErr);
	}
};

export const logout = () => async (dispatch) => {
	try {
		await axios.post('/auth/logout');
		dispatch(removeUser());
		// history.push('/login');
		history.push('/home');
	} catch (err) {
		console.error(err);
	}
};

export const pwreset = async (email) => {
	try {
		let res = await axios.post(`/api/forgot`, { email });
	} catch (authError) {
		console.log(authError);
	}
};

export const resetPassword = async (password, email) => {
	let res;
	try {
		res = await axios.put(`/api/reset`, { password, email });
	} catch (authError) {
		console.log(authError);
	}
};

// TODO: not being used?
export const getLenderForRequest = (article, dates, borrower) => async (dispatch) => {
	const userId = article.userId;
	try {
		const lender = await axios.get(`/api/users/${userId}`).then((lender) => {
			dispatch(gotLenderForRequest(lender.data));
		});
	} catch (err) {
		console.error(err);
	}
};

//TODO: need to update FRIEND here too

export const updateUser = (updatedUser) => async (dispatch) => {
	try {
		const res = await axios.put('/api/users', updatedUser);

		dispatch(gotUpdatedUser(res.data));
	} catch (err) {
		console.error(err);
	}
};

/**
 * REDUCER
 */
export default function (state = initialState, action) {
	switch (action.type) {
		case GET_USER:
			return { ...initialState, user: action.user };
		case REMOVE_USER:
			return { ...initialState, user: {} };
		case GOT_USERS:
			return { ...initialState, users: action.users };
		case GOT_UPDATED_USER:
			return { ...initialState, user: action.user };
		case GOT_LENDER_FOR_REQUEST:
			return { ...initialState, lender: action.lender };
		default:
			return state;
	}
}
