import React from 'react';

const DrawerClosed = () => (
	<svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_1704_5461"
			// style="mask-type:alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="4"
			width="22"
			height="16">
			<path d="M0 4H13V6L14.5 8V11H16.5L18 13V16H20L21.5 18V20H0V4Z" fill="#C4C4C4" />
		</mask>
		<g mask="url(#mask0_1704_5461)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 8V6H24V8H3ZM3 13H24V11H3V13ZM3 18H24V16H3V18Z"
				fill="#442C2E"
			/>
		</g>
		<path
			d="M38.9115 12.1236L36.7576 12.2132L38.0395 10.8114C38.1666 10.6901 38.2821 10.5602 38.4279 10.3913L38.8032 9.98129L38.8379 9.8788C39.5987 8.80618 40 7.56321 40 6.2726C40 2.81365 37.1864 0 33.7274 0C31.5475 0 29.6231 1.11737 28.5 2.81076C27.3754 1.11882 25.451 0 23.2726 0C19.8136 0 17 2.81365 17 6.2726C17 7.50113 17.358 8.68635 18.0365 9.71567L18.2762 10.0376C18.5115 10.3494 18.78 10.6425 19.0139 10.846L19.7357 11.6386L19.906 15.7327L21.9921 15.6461L21.9257 14.0394L28.5 21.2489L34.774 14.3858L39.001 14.2111L38.9115 12.1236ZM23.173 12.3142L25.4958 12.5394L25.6979 10.4606L21.0725 10.0116L20.4835 9.3663C20.2698 9.1743 20.0807 8.96642 19.9205 8.74843L19.8339 8.64593C19.3445 7.94144 19.0875 7.12001 19.0875 6.27115C19.0875 3.96422 20.9642 2.0875 23.2712 2.0875C25.5781 2.0875 27.4548 3.96422 27.4548 6.27115H29.5437C29.5437 3.96422 31.4205 2.0875 33.7274 2.0875C36.0343 2.0875 37.9111 3.96422 37.9111 6.27115C37.9111 7.16187 37.6194 8.02372 37.0651 8.76287L36.8182 9.05881C36.7475 9.13966 36.6796 9.22194 36.5497 9.34898L35.3414 10.6714V9.47891H33.2524V12.9552L28.5 18.1523L23.173 12.3142Z"
			fill="#442C2E"
		/>
	</svg>
);

export default DrawerClosed;
