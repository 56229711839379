import React from 'react';

const Subcategory = () => (
	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4394_61909)">
			<path
				d="M1.08056 1.65519H13.9191C14.2148 1.65519 14.488 1.4974 14.6358 1.24136C14.7836 0.985307 14.7836 0.669814 14.6358 0.413764C14.488 0.157714 14.2148 0 13.9191 0H1.08056C0.784825 0 0.511611 0.157701 0.363797 0.413764C0.215974 0.669814 0.215974 0.985307 0.363797 1.24136C0.51162 1.49741 0.784825 1.65519 1.08056 1.65519ZM3.42549 5.79304C3.42549 5.46384 3.29466 5.14809 3.06187 4.91531C2.82909 4.68244 2.51325 4.55169 2.18405 4.55169C1.85484 4.55169 1.53909 4.68243 1.30631 4.91531C1.07345 5.14809 0.94269 5.46384 0.94269 5.79304C0.94269 6.12234 1.07344 6.43809 1.30631 6.67086C1.53909 6.90364 1.85484 7.03449 2.18405 7.03449C2.51325 7.03449 2.82909 6.90365 3.06187 6.67086C3.29465 6.43809 3.42549 6.12234 3.42549 5.79304ZM13.9083 6.62064C14.204 6.62064 14.4772 6.46293 14.625 6.20687C14.7728 5.95081 14.7728 5.63533 14.625 5.37928C14.4772 5.12323 14.204 4.96545 13.9083 4.96545H6.59799C6.30234 4.96545 6.02913 5.12324 5.88131 5.37928C5.73349 5.63533 5.73349 5.95082 5.88131 6.20687C6.02913 6.46292 6.30234 6.62064 6.59799 6.62064H13.9083ZM13.9083 11.5861V11.5861C14.204 11.5861 14.4772 11.4284 14.625 11.1723C14.7728 10.9163 14.7728 10.6008 14.625 10.3447C14.4772 10.0887 14.204 9.93096 13.9083 9.93096H6.59799C6.30234 9.93096 6.02913 10.0887 5.88131 10.3447C5.73349 10.6008 5.73349 10.9163 5.88131 11.1723C6.02913 11.4284 6.30234 11.5861 6.59799 11.5861L13.9083 11.5861ZM3.42549 10.7586C3.42549 10.4293 3.29466 10.1135 3.06187 9.88073C2.82908 9.64796 2.51325 9.51711 2.18405 9.51711C1.85484 9.51711 1.53909 9.64794 1.30631 9.88073C1.07345 10.1135 0.94269 10.4293 0.94269 10.7586C0.94269 11.0878 1.07344 11.4035 1.30631 11.6363C1.53909 11.8692 1.85484 11.9999 2.18405 11.9999C2.51325 11.9999 2.82909 11.8692 3.06187 11.6363C3.29465 11.4035 3.42549 11.0878 3.42549 10.7586Z"
				fill="#221F20"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4394_61909">
				<rect width="15" height="15" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default Subcategory;
