import React from 'react';

const Face = () => (
	<svg
		width="169"
		height="189"
		viewBox="0 0 169 189"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.655273 20.4102C11.9748 15.0038 27.0508 10.158 39.8 10.158C44.0603 10.158 53.7803 12.7488 53.7803 18.5461"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M124.614 18.5462C129.405 13.7545 168.419 -11.4217 168.419 9.22599"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M86.5107 81.2633C84.0532 93.8582 80.8282 105.419 80.8282 118.2C80.8282 121.702 79.3543 128.144 84.3797 128.144C88.4049 128.144 92.43 128.144 96.4551 128.144"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M60.9395 182.128C72.0291 186.726 80.9323 187.811 92.8248 187.811C98.5474 187.811 112.113 186.33 114.923 180.708"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Face;
