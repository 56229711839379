import React, { useState, useEffect } from 'react';
import useUrlState from '@ahooksjs/use-url-state';
// import { startOfDay, getUnixTime, isThursday, nextThursday, format } from 'date-fns';
// import Calendar from 'react-calendar';
import classnames from 'classnames';
import axios from 'axios';
import { queueModal } from '../../store/modals';
// import { MapContainer } from '../index';
import { InView } from 'react-intersection-observer';
import history from '../../history';
import Checkbox from '../atoms/Checkbox';
import Loader from '../atoms/Loader';
import {
	Close,
	Filter,
	List,
	Subcategory,
	Aperture,
	SingleFilter,
	Tag,
	Hashtag,
	MeetingArrows,
	Search,
} from '../../../public/icons';
// import getWeek from '../../utils/getWeekFromDay';
import { staticTags, subcategoryConstants, colors } from '../Add/utils/index';
import { getPublicArticles } from '../../store/closet';
import store from '../../store';
import getDistanceFromLatLng from '../../utils/getDistance';
import shuffleArray from '../../utils/shuffleArray';
import './index.scss';
import { me } from '../../store/user';
import ArticleCard from '../atoms/ArticleCard';
import { ORDERED_SIZES } from './constants/index';
import { useSelector } from 'react-redux';

const BASE_CLASS = 'rent-home';

function RentHome() {
	const user = useSelector((state) => state.user.user);
	const isLoggedIn = useSelector((state) => !!state.user.user.id);
	const publicArticles = useSelector(
		(state) => state.closet.publicArticles
		// isLoggedIn
		// 	? state.closet.publicArticles?.filter((article) => article.userId !== user.id)
		// 	: state.closet.publicArticles // TODO: restore this but need it to not be circular b/t is logged in and public articles
	);

	const [filteredItems, setFilteredItems] = useState([]);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	// const [isMapExpanded, setIsMapExpanded] = useState(false);
	const [priceRange, setPriceRange] = useState(null);
	const [isPriceFilterOpen, setIsPriceFilterOpen] = useState(false);
	const [isColorFilterOpen, setIsColorFilterOpen] = useState(false);
	const [isBrandFilterOpen, setIsBrandFilterOpen] = useState(false);
	const [isSizeFilterOpen, setIsSizeFilterOpen] = useState(false);
	const [isTagFilterOpen, setIsTagFilterOpen] = useState(false);
	const [isCategoryFilterOpen, setIsCategoryFilterOpen] = useState(true);
	const [isSubcategoryFilterOpen, setIsSubcategoryFilterOpen] = useState(false);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [colorSet, setColorSet] = useState([]);
	// const [isCalendarFilterOpen, setIsCalendarFilterOpen] = useState(false);
	const [brandSet, setBrandSet] = useState(null);
	const [tagSet, setTagSet] = useState(null);
	// const [allAvailableDates, setAllAvailableDates] = useState(null);
	const [sizeSet, setSizeSet] = useState(null);
	const [subCategorySet, setSubcategorySet] = useState(null);
	const [likedArticleIds, setLikedArticleIds] = useState([]);
	// const [startAndEndDates, setStartAndEndDates] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchInput, setSearchInput] = useState('');

	const handleInputChange = (e) => {
		if (!e.target.value) {
			setSearchInput('');
			window.history.replaceState({}, '', '');
			performSearch();
			setFilterState({ searchTerm: '' });
		} else {
			setSearchInput(e.target.value);
		}
	};

	// Function to handle Enter key press
	const handleEnterPress = (e) => {
		if (e.key === 'Enter') {
			performSearch();
			// TODO: send search event here
		}
	};

	const [filterState, setFilterState] = useUrlState({
		cate: '',
		search: '',
		price: null,
		zip: null,
		color: '',
		brand: '',
		subcat: '',
		size: '',
		tags: '',
		deliveryOptions: '',
		dates: '',
	});

	useEffect(() => {
		setIsLoading(true); // Start loading
		store.dispatch(getPublicArticles()).finally(() => {
			setIsLoading(false); // Stop loading when the articles are fetched
		});
		store.dispatch(me());
	}, []);

	useEffect(() => {
		console.log({ publicArticles });
		setTagSet(
			Array.from(
				new Set(
					publicArticles
						.map((item) => item.tags)
						.reduce((a, b) => a.concat(b), [])
						.filter((tag) => staticTags.flat().includes(tag.toLowerCase()))
				)
			)
		);

		setColorSet(
			Array.from(
				new Set(
					publicArticles
						.map((item) => (item.test ? item.test.toLowerCase().split(',') : null))
						.reduce((a, b) => a.concat(b), [])
						.filter((color) => color && colors.includes(color.toLowerCase()))
				)
			)
		);

		setCategoryOptions(Array.from(new Set(publicArticles.map((item) => item.category))));

		setSubcategorySet(
			filterState.cate
				? filterState.cate
						.split('-')
						.map((category) => subcategoryConstants[category])
						.flat()
				: null
		);

		setSizeSet(
			Array.from(
				new Set(
					publicArticles
						.map((item) => item.size?.toUpperCase())
						.sort((a, b) => {
							return ORDERED_SIZES.indexOf(a) < ORDERED_SIZES.indexOf(b) ? -1 : 1;
						})
						.filter((size) => {
							return ORDERED_SIZES.includes(size);
						})
				)
			)
		);

		setBrandSet(Array.from(new Set(publicArticles.map((item) => item.brand).sort())));

		setPriceRange(
			Array.from(
				new Set(
					publicArticles
						.map((item) => Number(item.currentDailyRentalPrice))
						.sort((a, b) => {
							return a - b;
						})
				)
			)
		);
		console.log({ publicArticles });
	}, [publicArticles]);

	// FILTERING:
	useEffect(() => {
		console.log('filter state price', filterState.price);
		const filteredArticles = publicArticles
			.filter((item) => {
				return filterState.cate ? filterState.cate.split('-').includes(item.category) : item;
			})
			.filter((item) => {
				return filterState.color
					? filterState.color
							.split('-')
							.map((b) => b.toLowerCase())
							.some(
								(val) =>
									item.test &&
									item.test
										.split(',')
										.map((b) => b.toLowerCase())
										.indexOf(val) !== -1
							)
					: item;
			})
			.filter((i) => {
				return filterState.brand
					? filterState.brand
							.split('-')
							.map((b) => b.toLowerCase().replace(/\s+/g, ''))
							.includes(i.brand?.toLowerCase().replace(/\s+/g, ''))
					: i;
			})
			.filter((item) =>
				filterState.price ? Number(item.currentDailyRentalPrice) <= Number(filterState.price) : item
			)
			.filter((item) =>
				filterState.size ? filterState.size.split('-').includes(item.size?.toUpperCase()) : item
			)
			.filter((item) =>
				filterState.tags
					? filterState.tags.split('-').some((val) => item.tags.indexOf(val) !== -1)
					: item
			)
			.filter((item) => {
				return filterState.deliveryOptions
					? filterState.deliveryOptions
							.split('-')
							.some(
								(opt) =>
									item[opt] ||
									!!item.list?.includes('shippable') ||
									!!item.list?.includes('sellable')
							)
					: item;
			})
			.filter((item) => {
				return filterState.subcat
					? filterState.subcat
							.split('-')
							.filter((val) => !!val)
							.some(function checkSubcategory(subcat) {
								return !!item.subcategories && subcat.length && item.subcategories.includes(subcat);
							})
					: item;
			})
			.filter((item) =>
				filterState.searchTerm
					? (item.name &&
							item.name.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1) ||
					  (item.brand &&
							item.brand?.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1) ||
					  (item.category &&
							item.category.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1) ||
					  (item.test &&
							item.test.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1) ||
					  (item.description &&
							item.description.toLowerCase().indexOf(filterState.searchTerm.toLowerCase()) !== -1)
					: item
			)
			.filter((item) => {
				return filterState.dates
					? item.calendarDatesAvailable?.includes(Number(filterState.dates.split('-')[2]))
					: item;
			});

		if (!filterState.cate) {
			setFilterState({ subcat: undefined });
		}

		if (filterState.zip && filterState.zip.length > 4) {
			handleZipSort(filterState.zip, filteredArticles);
		} else {
			setFilteredItems(filteredArticles);
		}
	}, [publicArticles.length, filterState]); // add back filterstate

	const performSearch = () => {
		window.history.pushState({}, '', `?search=${searchInput}`);
		setFilterState({ searchTerm: searchInput });
	};

	const handleFavoritesClick = () => {
		if (!isLoggedIn) {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to start adding favorites'));
			scrollTo(0, 0);
		} else if (isLoggedIn) {
			history.push('/favorites');
		}
	};

	const toggleArticleLike = async (articleId) => {
		// if user isn't logged in, prompt them to log in:
		if (!isLoggedIn) {
			store.dispatch(queueModal('LOGIN', 'sign up or log in to save your favorite rentals!'));
			scrollTo(0, 0);
		} else {
			const userId = user.id;

			// if it's already liked, remove like from front & back end:
			if (likedArticleIds && likedArticleIds.includes(articleId)) {
				setLikedArticleIds([...likedArticleIds.filter((id) => id !== articleId)]);

				try {
					await axios.put(`/api/users/${userId}`, {
						likedId: articleId,
						addId: false,
					});
				} catch (err) {
					console.error(err);
				}
			} else {
				setLikedArticleIds(likedArticleIds ? [...likedArticleIds, articleId] : [articleId]);

				try {
					await axios.put(`/api/users/${userId}`, {
						likedId: articleId,
						addId: true,
					});
				} catch (err) {
					console.error(err);
				}
			}
		}
	};

	// const handleCalendarChange = (value) => {
	// 	const daysOfSelectedWeek = getWeek(value).weekTimestamps;
	// 	const week = getWeek(value).week;
	// 	const startDateSelected = format(week[0], 'MMM dd');
	// 	const endDateSelected = format(week[4], 'MMM dd');

	// 	setFilterState({ dates: [...daysOfSelectedWeek].join('-') });
	// 	setStartAndEndDates([startDateSelected, endDateSelected]);
	// };

	return (
		<div className="container-rent-home">
			{isLoading ? (
				<div className="loader">
					<Loader />
				</div>
			) : (
				<div className={BASE_CLASS}>
					<InView triggerOnce="true" threshold=".3">
						{({ inView, ref, entry }) => (
							<div ref={ref} className={classnames(`${BASE_CLASS}__hero`, { inView: inView })}>
								<div id="containeranimation">
									<h2>Find something</h2>
									<div id="flip">
										<div>
											<h3>beautiful</h3>
										</div>
										<div>
											<h3>wallet-friendly</h3>
										</div>
										<div>
											<h3>sustainable</h3>
										</div>
									</div>
								</div>
								<div className={classnames(`${BASE_CLASS}__hero__ctas`)}>
									<a onClick={handleFavoritesClick}>Favorites</a>
									<span
										onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
										className="hidden-icon-on-desktop">
										Filter
										<Filter />
									</span>
								</div>
							</div>
						)}
					</InView>

					{/* <InView triggerOnce="true" threshold=".3">
				{({ inView, ref, entry }) => (
					<div ref={ref}>
						<div
							// ref={ref}
							className={classnames(
								`${BASE_CLASS}__map`,
								{ inView: inView },
								{ expanded: isMapExpanded }
							)}>
							<MapContainer articles={shuffleArray(filteredItems)} />
						</div>
						<div
							className={classnames(`${BASE_CLASS}__map__toggle`, { inView: inView })}
							onClick={() => setIsMapExpanded(!isMapExpanded)}>
							<span>{isMapExpanded ? 'Minimize' : 'Expand'} map</span>
						</div>
					</div>
				)}
			</InView> */}

					<InView triggerOnce="true" threshold=".1">
						{({ inView, ref, entry }) => (
							<div
								className={classnames(
									`${BASE_CLASS}__menu`,
									// { inView: inView },
									{ visibleOnMobile: isFilterModalOpen }
								)}
								ref={ref}>
								<span
									onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
									className="hidden-on-desktop">
									<Close />
								</span>

								<input
									id="input-search"
									type="search"
									autoCorrect="true"
									autoComplete="true"
									value={searchInput}
									onChange={handleInputChange}
									onKeyDown={handleEnterPress}
									placeholder="Search"
									className={`${BASE_CLASS}__menu__zip`}
								/>
								<button
									className={`${BASE_CLASS}__menu__button`}
									type="submit"
									onClick={() => performSearch()}>
									search
								</button>

								<h3 onClick={() => setIsCategoryFilterOpen(!isCategoryFilterOpen)}>
									<List />
									Article Type
								</h3>
								<div className={`${BASE_CLASS}__menu__options`}>
									{isCategoryFilterOpen
										? !!categoryOptions.length &&
										  categoryOptions.map(
												(category) =>
													!!category.length && (
														<button
															onClick={() => {
																setFilterState((s) => {
																	let filtersArray = s.cate?.split('-');
																	let filteredCategories = filtersArray.includes(category)
																		? filtersArray.filter((cat) => cat !== category).join('-')
																		: [...filtersArray, category].join('-');

																	setSubcategorySet(
																		filteredCategories
																			.split('-')
																			.map((category) => subcategoryConstants[category])
																			.flat()
																	);

																	return {
																		cate: filteredCategories ? filteredCategories : undefined,
																	};
																});
															}}
															className={classnames({
																selected: filterState?.cate?.split('-')?.includes(category),
															})}>
															{category}
														</button>
													)
										  )
										: null}
								</div>
								{!!filterState.cate && (
									<>
										<h3 onClick={() => setIsSubcategoryFilterOpen(!isSubcategoryFilterOpen)}>
											<Subcategory />
											Subcategory
										</h3>
										{isSubcategoryFilterOpen && (
											<div className={`${BASE_CLASS}__menu__options`}>
												{subCategorySet?.map(
													(category) =>
														!!category && (
															<button
																onClick={() => {
																	setFilterState((s) => {
																		let filtersArray = s.subcat?.split('-');
																		let filteredCategories = filtersArray.includes(category)
																			? filtersArray.filter((cat) => cat !== category).join('-')
																			: [...filtersArray, category].join('-');

																		return {
																			subcat: filteredCategories ? filteredCategories : undefined,
																		};
																	});
																}}
																className={classnames({
																	selected: filterState.subcat.split('-').includes(category),
																})}>
																{category}
															</button>
														)
												)}
											</div>
										)}
									</>
								)}

								<h3 onClick={() => setIsSizeFilterOpen(!isSizeFilterOpen)}>
									<SingleFilter />
									Size
								</h3>
								<div className={`${BASE_CLASS}__menu__options`}>
									{isSizeFilterOpen
										? !!sizeSet.length &&
										  sizeSet.map(
												(size) =>
													!!size && (
														<button
															onClick={() => {
																setFilterState((s) => {
																	let filtersArray = s.size?.split('-');
																	let filteredCategories = filtersArray.includes(size)
																		? filtersArray.filter((cat) => cat !== size).join('-')
																		: [...filtersArray, size].join('-');

																	return {
																		size: filteredCategories ? filteredCategories : undefined,
																	};
																});
															}}
															className={classnames({
																selected: filterState.size.split('-').includes(size),
															})}>
															{size}
														</button>
													)
										  )
										: null}
								</div>
								<h3 onClick={() => setIsBrandFilterOpen(!isBrandFilterOpen)}>
									<Tag />
									Brand
								</h3>
								<div className={`${BASE_CLASS}__menu__options`}>
									{isBrandFilterOpen
										? !!brandSet.length &&
										  brandSet.map(
												(brand) =>
													!!brand.length && (
														<button
															onClick={() => {
																setFilterState((s) => {
																	let filtersArray = s.brand
																		?.split('-')
																		.map((b) => b.replace(/\s+/g, ''));
																	let filteredBrands = filtersArray.includes(
																		brand.replace(/\s+/g, '')
																	)
																		? filtersArray
																				.filter((b) => b !== brand.replace(/\s+/g, ''))
																				.join('-')
																		: [...filtersArray, brand].join('-');

																	return {
																		brand: filteredBrands ? filteredBrands : undefined,
																	};
																});
															}}
															className={classnames({
																selected: filterState.brand
																	.split('-')
																	.map((b) => b.replace(/\s+/g, ''))
																	.includes(brand.replace(/\s+/g, '')),
															})}>
															{brand}
														</button>
													)
										  )
										: null}
								</div>

								{/* <button
							onClick={() => setIsCalendarFilterOpen(!isCalendarFilterOpen)}
							className={`${BASE_CLASS}__menu__button`}>
							<CalendarIcon />
							{startAndEndDates?.length ? (
								<span>
									{startAndEndDates?.[0]} - {startAndEndDates?.[1]}
								</span>
							) : (
								'Date'
							)}
						</button> */}
								{/* {isCalendarFilterOpen ? (
							<div className={`${BASE_CLASS}__calendar`}>
								<span onClick={() => setIsCalendarFilterOpen(false)}>
									<Close />
								</span>
								<Calendar
									onClickDay={handleCalendarChange}
									tileClassName={(activeStartDate, date, view) =>
										displayItemAvailabilityClass(activeStartDate, date, view)
									}
								/>
							</div>
						) : null} */}
								<h3>
									<MeetingArrows />
									Delivery Option
								</h3>
								<div className={`${BASE_CLASS}__menu__options__delivery`}>
									<Checkbox
										label="Local pickup"
										onChange={() => {
											setFilterState((s) => {
												let filtersArray = s.deliveryOptions?.split('-');
												let filteredCategories = filtersArray.includes('availableForPickup')
													? filtersArray.filter((cat) => cat !== 'availableForPickup').join('-')
													: [...filtersArray, 'availableForPickup'].join('-');

												return {
													deliveryOptions: filteredCategories ? filteredCategories : undefined,
												};
											});
										}}
										checked={filterState.deliveryOptions.includes('availableForPickup')}
										labelHasError={false}
									/>

									<Checkbox
										label="Delivery"
										onChange={() => {
											setFilterState((s) => {
												let filtersArray = s.deliveryOptions?.split('-');
												let filteredCategories = filtersArray.includes('availableForShip')
													? filtersArray.filter((cat) => cat !== 'availableForShip').join('-')
													: [...filtersArray, 'availableForShip'].join('-');

												return {
													deliveryOptions: filteredCategories ? filteredCategories : undefined,
												};
											});
										}}
										checked={filterState.deliveryOptions.includes('availableForShip')}
										labelHasError={false}
									/>

									<Checkbox
										label="Shippable"
										onChange={() => {
											setFilterState((s) => {
												let filtersArray = s.deliveryOptions?.split('-');
												let filteredCategories = filtersArray.includes('shippable')
													? filtersArray.filter((cat) => cat !== 'shippable').join('-')
													: [...filtersArray, 'shippable'].join('-');

												return {
													deliveryOptions: filteredCategories ? filteredCategories : undefined,
												};
											});
										}}
										checked={filterState.deliveryOptions.includes('shippable')}
										labelHasError={false}
									/>

									<Checkbox
										label="For sale"
										onChange={() => {
											setFilterState((s) => {
												let filtersArray = s.deliveryOptions?.split('-');
												let filteredCategories = filtersArray.includes('sellable')
													? filtersArray.filter((cat) => cat !== 'sellable').join('-')
													: [...filtersArray, 'sellable'].join('-');

												return {
													deliveryOptions: filteredCategories ? filteredCategories : undefined,
												};
											});
										}}
										checked={filterState.deliveryOptions.includes('sellable')}
										labelHasError={false}
									/>
								</div>

								<h3>
									<Search />
									Location & Price
								</h3>

								<input
									placeholder="Search by zip code"
									value={filterState.zip}
									onChange={(e) =>
										setFilterState({ zip: e.target.value ? e.target.value : undefined })
									}
									className={`${BASE_CLASS}__menu__zip`}
								/>
								<button
									onClick={() => setIsPriceFilterOpen(!isPriceFilterOpen)}
									className={`${BASE_CLASS}__menu__button`}>
									{/* <Dollar /> */}
									{filterState?.price ? `$ ${filterState?.price} or less` : '$ Price'}
								</button>

								{isPriceFilterOpen ? (
									<div className={`${BASE_CLASS}__slider`}>
										<p>${priceRange[0]}</p>
										<input
											id="typeinp"
											type="range"
											min={priceRange?.[0]}
											max={priceRange?.[priceRange.length - 1]}
											value={filterState?.price}
											onChange={(e) =>
												setFilterState({ price: e.target.value ? e.target.value : undefined })
											}
											step="1"
											onBlur={() => setIsPriceFilterOpen(!isPriceFilterOpen)}
										/>
										<p>${priceRange?.[priceRange.length - 1]}</p>
										<span>${filterState.price}</span>
									</div>
								) : null}

								<h3 onClick={() => setIsTagFilterOpen(!isTagFilterOpen)}>
									<Hashtag />
									Tag
								</h3>
								<div className={`${BASE_CLASS}__menu__options`}>
									{isTagFilterOpen
										? !!tagSet.length &&
										  tagSet.map(
												(tag) =>
													!!tag.length && (
														<button
															onClick={() => {
																setFilterState((s) => {
																	let filtersArray = s.tags?.split('-');
																	let filteredCategories = filtersArray.includes(tag)
																		? filtersArray.filter((cat) => cat !== tag).join('-')
																		: [...filtersArray, tag].join('-');

																	return {
																		tags: filteredCategories ? filteredCategories : undefined,
																	};
																});
															}}
															className={classnames({
																selected: filterState.tags.includes(tag),
															})}>
															{tag}
														</button>
													)
										  )
										: null}
								</div>
								<h3 onClick={() => setIsColorFilterOpen(!isColorFilterOpen)}>
									<Aperture />
									Color
								</h3>
								<div className={`${BASE_CLASS}__menu__options`}>
									{isColorFilterOpen
										? !!colorSet.length &&
										  colorSet.map(
												(color, key) =>
													!!color.length && (
														<button
															key={key}
															onClick={() => {
																setFilterState((s) => {
																	let filtersArray = s.color?.split('-');
																	let filteredCategories = filtersArray.includes(color)
																		? filtersArray.filter((cat) => cat !== color).join('-')
																		: [...filtersArray, color].join('-');

																	return {
																		color: filteredCategories ? filteredCategories : undefined,
																	};
																});
															}}
															className={classnames({
																selected: filterState?.color?.split('-')?.includes(color),
															})}>
															{color}
														</button>
													)
										  )
										: null}
								</div>

								<h4
									onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
									className="hidden-on-desktop">
									apply filters
								</h4>
								<div className={`${BASE_CLASS}__menu__options`}>
									<button
										onClick={() =>
											setFilterState({
												cate: undefined,
												searchTerm: undefined,
												price: undefined,
												zip: undefined,
												color: undefined,
												brand: undefined,
												subcat: undefined,
												size: undefined,
												tags: undefined,
												deliveryOptions: undefined,
												dates: undefined,
											})
										}
										className="clear">
										Clear all filters
									</button>
								</div>
							</div>
						)}
					</InView>

					{filteredItems.length ? (
						<div className={`${BASE_CLASS}__table`}>
							{shuffleArray(
								filteredItems.map((article) => (
									<ArticleCard
										article={article}
										toggleArticleLike={toggleArticleLike}
										likedArticleIds={likedArticleIds}
										selectedWeekDates={filterState.dates}
										// datesSelected={startAndEndDates}
									/>
								))
							)}
						</div>
					) : (
						<h4 className={`${BASE_CLASS}__no-results`}>
							Sorry, we couldn't find any results for you. Try removing some filters for better
							luck!{' '}
						</h4>
					)}
				</div>
			)}{' '}
		</div>
	);

	function handleZipSort(zip, articles) {
		async function getGeocode(zipToLookup) {
			let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipToLookup}&key=AIzaSyChKTCF8jV64Nj8pm4VJkoZuNz4NVLqbAA`;
			const response = await axios.get(url);
			const data = response.data.results[0];
			const position = {
				lat: data.geometry.location.lat,
				lng: data.geometry.location.lng,
			};

			return [position.lat, position.lng];
		}
		// should take articles and return articles sorted by zip:
		try {
			getGeocode(zip).then((coordsArr) => {
				const sortedArticles = articles
					.map((item) => ({
						...item,
						distanceFromInput: getDistanceFromLatLng(coordsArr, item.lenderLatLng),
					}))
					.sort((a, b) => (a.distanceFromInput > b.distanceFromInput ? 1 : -1));

				setFilteredItems(sortedArticles);
			});
		} catch (err) {
			console.error(err);
		}
	}

	// function getAvailableDates(itemDates) {
	// 	let allDates = [];
	// 	for (let i = 0; i < itemDates.length; i++) {
	// 		allDates = allDates.concat(itemDates[i]);
	// 	}
	// 	return Array.from(new Set(allDates));
	// }

	// function displayItemAvailabilityClass({ activeStartDate, date, view }) {
	// 	const timeStampStartOfDay = getUnixTime(startOfDay(date));
	// 	const availableDates = allAvailableDates;
	// 	const timeStampStartOfToday = getUnixTime(startOfDay(Date.now()));
	// 	const timeStampnextThursday = getUnixTime(nextThursday(Date.now()));

	// 	if (Number(timeStampStartOfDay) < Number(timeStampStartOfToday)) {
	// 		return 'past';
	// 	} else if (
	// 		!isThursday(Date.now()) &&
	// 		Number(timeStampStartOfDay) < Number(timeStampnextThursday)
	// 	) {
	// 		return 'past';
	// 	} else if (
	// 		availableDates &&
	// 		availableDates.includes(timeStampStartOfDay) &&
	// 		filterState.dates &&
	// 		filterState.dates.includes(timeStampStartOfDay)
	// 	) {
	// 		return 'requested';
	// 	} else if (availableDates && availableDates.includes(timeStampStartOfDay)) {
	// 		return 'available';
	// 	}
	// }
}

export default RentHome;
