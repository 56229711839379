import React from 'react';

const Checkmark = () => (
	<svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.6527 1.27906C19.0422 1.66857 19.0422 2.30007 18.6527 2.68958L7.68149 13.6608C7.29199 14.0503 6.66048 14.0503 6.27098 13.6608L1.28407 8.67387C0.894568 8.28437 0.894568 7.65286 1.28407 7.26336C1.67357 6.87386 2.30508 6.87386 2.69458 7.26336L6.97624 11.545L17.2422 1.27906C17.6317 0.889563 18.2632 0.889563 18.6527 1.27906Z"
			fill="#FA255E"
		/>
	</svg>
);

export default Checkmark;
