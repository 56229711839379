import React from 'react';

const CreditIcon = () => (
	<svg
		width="158"
		height="129"
		viewBox="0 0 158 129"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M141.774 53.4382L40.884 73.6262L52.7251 124.731L153.615 104.543L141.774 53.4382Z"
			fill="#CDFFEC"
			fillOpacity="0.2"
		/>
		<path
			d="M119.336 84.4244C121.953 95.7709 114.249 106.982 101.894 109.414C89.6839 111.845 77.6195 104.685 75.0033 93.2037C72.3871 81.8572 80.0904 70.6457 92.4455 68.2144C104.655 65.7831 116.72 73.077 119.336 84.4244Z"
			fill="white"
		/>
		<path
			d="M102.62 87.2609C101.603 86.7204 100.15 86.4502 98.4048 86.1799L97.2425 86.0448C96.9517 86.0448 96.8063 86.0448 96.6609 85.9097C95.7886 85.7746 95.0624 85.5044 94.4809 85.2341C93.8993 84.8288 93.6085 84.4242 93.3177 83.7486C93.1723 83.0731 93.1723 82.3975 93.6085 81.857C93.8993 81.3174 94.4809 80.912 95.207 80.7769C95.207 80.7769 95.207 80.7769 95.3524 80.7769C96.0794 80.6418 96.8063 80.7769 97.3879 81.0471C97.9695 81.3174 98.4048 81.857 98.6956 82.6677L103.493 81.7219C103.057 80.5067 102.475 79.4257 101.603 78.4807C100.731 77.5349 99.7134 76.9944 98.4048 76.5891C97.8241 76.454 97.2425 76.3189 96.5155 76.3189L95.6432 72.267L93.4631 72.6723L91.5738 73.212L92.4454 77.2647C91.8646 77.5349 91.4284 77.8051 90.8469 78.2105C89.8291 79.0204 89.1021 79.9662 88.6668 81.1822C88.2306 82.3975 88.2306 83.4784 88.376 84.6937C88.6668 85.7746 89.1021 86.8555 89.9745 87.6654C90.8469 88.4761 91.7192 89.1517 92.8815 89.557C93.4631 89.6922 94.1901 89.9624 95.207 90.0975C95.3524 90.0975 95.6432 90.0975 96.2247 90.2326C96.5155 90.2326 96.8063 90.367 97.0971 90.367C98.2594 90.5021 99.1318 90.7723 99.7134 91.0425C100.586 91.4479 101.167 92.2586 101.457 93.2036C101.603 94.0143 101.457 94.825 101.022 95.5006C100.586 96.1754 99.7134 96.5807 98.841 96.8509H98.6956C97.8241 96.986 96.9517 96.8509 96.2247 96.5807C95.4978 96.1753 94.917 95.5006 94.6263 94.5548L90.1199 95.6357C90.4107 96.7158 90.8469 97.5265 91.2831 98.067C91.8646 98.8769 92.5907 99.5525 93.4631 99.9578C94.917 100.904 96.3701 101.309 98.1149 101.309L98.9864 105.226L100.877 104.82L103.057 104.416L102.184 100.498C102.911 100.228 103.493 99.8227 104.074 99.2822C105.237 98.3372 105.964 97.2563 106.4 95.9059C106.836 94.5547 106.981 93.2036 106.545 91.8532C105.964 89.8273 104.655 88.2059 102.62 87.2609Z"
			fill="#42AF71"
			fillOpacity="0.2"
		/>
		<path
			d="M36.6294 71.0511L50.0019 128.73L158 107.117L144.627 49.4387L36.6294 71.0511ZM66.8633 118.464C65.5547 112.656 59.3045 108.873 52.909 110.089L47.5309 86.7206C53.7811 85.3694 57.706 79.6961 56.3975 73.8877L127.476 59.7047C128.929 65.5129 135.034 69.0251 141.284 67.8091L146.663 91.3129C140.412 92.6641 136.488 98.3373 137.796 104.146L66.8633 118.464Z"
			fill="#4CD7AD"
			fillOpacity="0.2"
		/>
		<path
			d="M112.544 31.5455L22.9346 79.115L50.8355 124.505L140.445 76.936L112.544 31.5455Z"
			fill="#CDFFEC"
			fillOpacity="0.2"
		/>
		<path
			d="M101.312 67.4037C107.562 77.535 103.783 90.3671 92.8817 96.1754C81.9797 101.984 68.1716 98.4716 61.9213 88.3411C55.6711 78.2106 59.4505 65.3777 70.3516 59.5694C81.2536 53.761 95.0618 57.273 101.312 67.4037Z"
			fill="white"
		/>
		<path
			d="M86.3404 74.8335C85.1772 74.6984 83.7241 74.6984 81.9794 75.1038L80.8171 75.374C80.5263 75.374 80.3809 75.374 80.2355 75.5091C79.3632 75.6442 78.4908 75.6442 78.0555 75.5091C77.4739 75.374 76.8923 74.9686 76.4561 74.2931C76.0199 73.6175 75.8745 73.0778 76.1653 72.4022C76.3107 71.7267 76.7469 71.3213 77.3285 70.916C77.3285 70.916 77.3285 70.916 77.4739 70.916C78.2008 70.5114 78.7816 70.5114 79.5085 70.6465C80.2355 70.7808 80.8171 71.1862 81.2533 71.8618L85.468 69.5656C84.741 68.4847 83.7241 67.6748 82.561 66.9992C81.3987 66.4587 80.2355 66.0534 78.927 66.1885C78.3462 66.1885 77.6193 66.3236 77.0377 66.4587L74.8576 62.9467L72.9675 63.8922L71.2236 64.5679L73.4037 68.2144C72.9675 68.6198 72.5313 69.0251 72.2405 69.5656C71.6598 70.5114 71.2236 71.7266 71.2236 72.9427C71.2236 74.1579 71.5144 75.2389 72.096 76.3198C72.6767 77.2648 73.5491 78.0755 74.5668 78.7511C75.5838 79.2908 76.7469 79.6961 77.9101 79.6961C78.4908 79.6961 79.3632 79.6961 80.2355 79.561C80.3809 79.561 80.6717 79.4259 81.2533 79.2908C81.5432 79.2908 81.834 79.1564 82.1248 79.1564C83.2879 79.0213 84.1603 79.0213 84.8864 79.1564C85.9042 79.2908 86.6312 79.8312 87.2127 80.777C87.6481 81.5877 87.7935 82.3976 87.5035 83.2083C87.2127 84.019 86.6312 84.6946 85.9042 85.0992C85.9042 85.0992 85.7588 85.0992 85.7588 85.2343C84.8864 85.6396 84.1603 85.7747 83.2879 85.6396C82.4156 85.5045 81.6886 84.964 81.2533 84.1541L76.8923 86.4503C77.4739 87.3961 78.2008 88.0709 78.7816 88.4763C79.5086 89.0167 80.3809 89.4221 81.3987 89.6923C82.9972 90.0976 84.5956 90.0976 86.195 89.6923L88.375 93.2045L89.9744 92.2587L91.8636 91.3129L90.1198 87.5312C90.7005 86.9908 91.1367 86.5854 91.4275 85.9099C92.1544 84.6946 92.5906 83.4786 92.5906 82.1274C92.5906 80.777 92.2998 79.4259 91.4275 78.2106C90.4106 76.3198 88.6658 75.1038 86.3404 74.8335Z"
			fill="#42AF71"
			fillOpacity="0.2"
		/>
		<path
			d="M18.0234 77.8052L49.5653 129L145.499 78.0755L113.957 27.0156L18.0234 77.8052ZM62.3567 114.546C59.1589 109.279 52.0363 107.522 46.3675 110.494L33.5764 89.8274C39.0998 86.8556 40.9894 80.2365 37.7916 75.1037L101.021 41.6041C104.218 46.602 111.341 48.358 116.864 45.5213L129.801 66.1884C124.278 69.1602 122.387 75.7793 125.585 80.9121L62.3567 114.546Z"
			fill="#4CD7AD"
			fillOpacity="0.2"
		/>
		<path
			d="M68.2685 4.7229L4.9751 80.4693L49.4029 112.53L112.696 36.7834L68.2685 4.7229Z"
			fill="#CDFFEC"
			fillOpacity="0.2"
		/>
		<path
			d="M72.6776 41.8742C82.561 49.0334 84.3057 62.271 76.747 71.4563C69.0436 80.6418 54.7984 82.2624 44.9147 75.2388C35.0306 68.0792 33.2863 54.8417 40.8447 45.6563C48.5485 36.471 62.7934 34.715 72.6776 41.8742Z"
			fill="white"
		/>
		<path
			d="M61.7754 54.0313C60.6122 54.3015 59.3045 54.8418 57.8506 55.7874L56.8328 56.3277C56.6874 56.4628 56.3975 56.5978 56.2521 56.5978C55.3797 57.0031 54.7982 57.2733 54.2166 57.4083C53.49 57.5434 52.9086 57.2733 52.3272 56.868C51.7458 56.4628 51.3097 55.9225 51.1644 55.2471C51.019 54.5717 51.3097 54.0313 51.7458 53.491C52.1819 52.9507 52.7633 52.5455 53.49 52.5455C54.2166 52.4104 54.9436 52.5455 55.5251 52.9507L58.5775 49.3036C57.4144 48.6282 56.2521 48.0879 54.9436 47.9528C53.6354 47.8178 52.3272 47.9528 51.1644 48.4932C50.583 48.7633 50.0016 49.0335 49.5655 49.4387L46.077 46.8722L44.7689 48.4932L43.606 49.8439L47.0945 52.4104C46.8038 52.9507 46.6584 53.491 46.6584 54.0313C46.5131 55.2471 46.6585 56.4628 47.0945 57.5434C47.5306 58.624 48.2573 59.5696 49.4202 60.3801C50.4376 61.0555 51.4551 61.4607 52.7633 61.7308C53.9258 61.8659 55.2343 61.8659 56.2521 61.4607C56.8328 61.3256 57.5598 60.9204 58.2868 60.5152C58.4322 60.3801 58.7229 60.245 59.1591 59.9748C59.4499 59.8397 59.7399 59.7046 60.0306 59.4345C61.0484 58.8942 61.9208 58.489 62.5023 58.3539C63.5193 58.2188 64.537 58.3539 65.4094 59.0293C66.1355 59.5696 66.5717 60.245 66.7171 61.0555C66.8625 61.8659 66.5717 62.6764 65.9901 63.3518L65.8456 63.4868C65.264 64.1619 64.537 64.5672 63.6646 64.7023C62.7931 64.8375 61.9208 64.5672 61.1938 64.1619L58.1414 67.8092C59.1591 68.4848 60.0306 68.8901 60.7576 69.0252C61.7754 69.2955 62.6477 69.2955 63.81 69.2955C65.5548 69.1604 67.0079 68.4848 68.3164 67.539L71.6596 69.9703L72.8219 68.6199L74.1305 66.9985L70.6419 64.5672C70.9326 63.8921 71.078 63.2167 71.2234 62.5413C71.3688 61.1905 71.2234 59.8397 70.7873 58.624C70.2057 57.4083 69.3333 56.3277 68.171 55.3821C66.1355 54.0313 64.1008 53.491 61.7754 54.0313Z"
			fill="#42AF71"
			fillOpacity="0.2"
		/>
		<path
			d="M0 81.0473L50.1471 117.248L117.882 36.201L67.7349 0L0 81.0473ZM56.1068 99.2824C51.0192 95.6359 43.6062 96.4458 39.6816 101.174L19.3321 86.4504C23.1113 81.7221 22.2392 74.9687 17.1517 71.3214L61.7755 17.9654C66.8626 21.4775 74.1306 20.5319 78.0554 15.9392L98.55 30.6628C94.7706 35.3905 95.643 42.1445 100.73 45.7916L56.1068 99.2824Z"
			fill="#44C194"
			fillOpacity="0.2"
		/>
	</svg>
);

export default CreditIcon;
