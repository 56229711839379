import React from 'react';

const ItemsBorrowed = () => (
	<svg width="75" height="50" viewBox="0 0 75 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M74.9972 0H0V49.9989H74.9972V0Z" fill="#C39EA0" />
		<path d="M75.0002 0.000976562H61.5452V49.9999H75.0002V0.000976562Z" fill="#FA255E" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.4326 0V26.829L18.7006 25.972L17.9608 26.906H17.8282L17.0298 25.972L16.2901 26.906H16.1715L15.3655 25.972L14.6271 26.906H14.5072L13.7088 25.972L12.9627 26.906H12.8441L12.0457 25.972L11.306 26.906H11.1811L10.3814 25.972L9.64166 26.906H9.62256V0H19.4326Z"
			fill="#FFF5F5"
		/>
		<path
			d="M57.0469 39.5001L53.0371 36.2793L49.0261 39.5001H50.665V45.4688H55.408V39.5001H57.0469Z"
			fill="#FFF5F5"
		/>
		<path d="M55.6261 5.21875H34.5737V7.37204H55.6261V5.21875Z" fill="#FA255E" />
		<path d="M55.6261 8.80078H34.5737V10.9541H55.6261V8.80078Z" fill="#FA255E" />
	</svg>
);

export default ItemsBorrowed;
