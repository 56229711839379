import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout, queueModal } from '../../store';
import DesktopHeader from './components/DesktopHeader/index';
import MobileHeader from './components/MobileHeader/index';
import './index.scss';

const NavBar = ({ handleLogout, isLoggedIn, handleSignIn }) => (
	<nav>
		<DesktopHeader handleClick={(handleLogout, isLoggedIn, handleSignIn)} />
		<MobileHeader handleClick={(handleLogout, isLoggedIn, handleSignIn)} />
	</nav>
);

const mapState = (state) => {
	return {
		isLoggedIn: !!state.user.user.id,
	};
};

const mapDispatch = (dispatch) => {
	return {
		handleLogout() {
			dispatch(logout());
		},
		handleSignIn() {
			dispatch(queueModal('SIGNUP'));
		},
	};
};

export default connect(mapState, mapDispatch)(NavBar);

NavBar.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
};
