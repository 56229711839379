import React from 'react';

const Circle = () => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4140_59255)">
			<path
				d="M10.9999 20.1666C16.0625 20.1666 20.1666 16.0625 20.1666 10.9999C20.1666 5.93731 16.0625 1.83325 10.9999 1.83325C5.93731 1.83325 1.83325 5.93731 1.83325 10.9999C1.83325 16.0625 5.93731 20.1666 10.9999 20.1666Z"
				fill="white"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4140_59255">
				<rect width="22" height="22" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default Circle;
