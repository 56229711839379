import React, { Fragment } from 'react';
import './index.scss';
const BASE_CLASS = 'outfit-remove-confirmation-modal';

class RemoveConfirmation extends React.Component {
	constructor(props) {
		super();
	}
	render() {
		return (
			<Fragment>
				<div className={`${BASE_CLASS}__container`} />
				<div className={`${BASE_CLASS}__inner`}>
					<div className={`${BASE_CLASS}__inner__title`}>
						are you sure you want to remove this outfit?
					</div>
					<div className={`${BASE_CLASS}__inner__buttons`}>
						<button
							type="button"
							className={`${BASE_CLASS}__inner__buttons__button`}
							onClick={(e) => {
								this.props.handleDeleteOutfitAccept(e, this.props.outfitId);
								window.location.href = '/outfits';
							}}>
							yes
						</button>
						<button
							type="button"
							className={`${BASE_CLASS}__inner__buttons__button`}
							onClick={this.props.handleDeleteOutfitCancel}>
							cancel
						</button>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default RemoveConfirmation;
