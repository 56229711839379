import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import BlogPost5 from 'public/images/BlogPost5.jpg';
import store from '../../../../store';
import './index.scss';
import axios from 'axios';
import classnames from 'classnames';
import { Heart } from '../../../../../public/index';

const BASE_CLASS = 'post-five';

class PostFive extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			comments: [],
			commentToAdd: '',
			liked: false,
		};

		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.getComments = this.getComments.bind(this);
		this.submitComment = this.submitComment.bind(this);
		this.postComment = this.postComment.bind(this);
		this.togglePostLike = this.togglePostLike.bind(this);
	}

	componentDidMount() {
		this.getComments();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	togglePostLike() {
		this.setState({
			liked: !this.state.liked,
		});

		this.postLike(!this.state.liked);
	}

	async postLike(isLiked) {
		try {
			const post = await axios.put(`/api/blogcomment/5`, { liked: isLiked.toString() });
		} catch (err) {
			console.err(err);
		}
	}

	async getComments() {
		try {
			const res = await axios.get(`/api/blogcomment/5`);
			// const postData = res.data;

			const comments = res.data[0]?.comment;
			const likeCount = res.data[0].likeCount;

			const commentMapped = comments ? comments.map((c) => JSON.parse(c)) : null;

			this.setState({
				comments: commentMapped,
				likeCount: likeCount,
			});
			// }
		} catch (err) {
			console.error(err);
		}
	}

	async postComment(commentObj) {
		try {
			const post = await axios.put(`/api/blogcomment/5`, commentObj);
		} catch (err) {
			console.err(err);
		}
	}

	submitComment() {
		event.preventDefault();
		const commentObj = {
			commenter: this.state.user?.user.name || 'anonymous',
			comment: this.state.commentToAdd,
			userId: this.state.user?.user.id || null,
			date: Date.now(),
		};

		this.postComment(commentObj);

		this.setState({
			commentToAdd: '',
			comments: this.state.comments ? [...this.state.comments, commentObj] : [commentObj],
		});
	}

	handleCommentChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	render() {
		const localLikeCount = this.state.liked ? 1 : 0;
		let totalLocalLikeCount = (this.state.likeCount || 0) + localLikeCount;
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__top`}>
					<Link to="/fashion-fix">Back</Link>
					<h5>
						the fashion fix
						<div />
					</h5>
				</div>
				<h3>The Ultimate Guide to Renting Dresses Online</h3>

				<p>
					In the fast-paced world of fashion, staying on-trend can sometimes feel like a financial
					strain. Enter the world of online dress rentals – a modern solution that allows you to
					rock the latest styles without breaking the bank. Whether it's for a special occasion, a
					wedding, a party, or just because you want to treat yourself, renting dresses online is a
					convenient and affordable way to revamp your wardrobe. In this comprehensive guide, we'll
					walk you through the steps to successfully rent a dress online.
				</p>
				<img src="images/BlogPost5.jpg" alt="10 steps to renting fashion online" />
				<h4>1. Research and Choose a Reputable Rental Platform:</h4>
				<p>
					The first step in renting a dress online is finding a reliable rental platform. Look for
					platforms with positive reviews, a wide range of dress styles, and transparent rental
					policies. Popular online dress rental companies include Rent the Runway, GlamCorner, and
					Lend the Label. Check out their website, read customer testimonials, and explore their
					dress collections to get a sense of their offerings.
				</p>

				<h4>2. Browse the Collection:</h4>
				<p>
					Once you've chosen a platform, dive into their dress collection. Most platforms categorize
					dresses by occasion, style, size, and color, making it easier to narrow down your choices.
					Take your time to explore different options, keeping in mind the event you're renting the
					dress for and your personal style preferences.
				</p>

				<h4>3. Know Your Measurements:</h4>

				<p>
					Accurate measurements are crucial when renting a dress online to ensure a perfect fit.
					Most rental platforms provide size guides and measurement tips to help you choose the
					right size. Use a measuring tape to measure your bust, waist, hips, and length, and
					compare them with the platform's sizing chart. If you're unsure, it's always safer to go a
					size up rather than down, as dresses can often be altered for a better fit.
				</p>

				<h4>4. Read Dress Descriptions and Reviews:</h4>

				<p>
					Before finalizing your choice, carefully read the dress descriptions and look for customer
					reviews. Reviews can offer valuable insights into the fit, fabric, and overall quality of
					the dress. If a dress tends to run large or small, reviewers are likely to mention it,
					helping you make an informed decision.
				</p>
				<h4>5. Check Rental Terms and Fees:</h4>
				<p>
					Be sure to understand the rental terms, fees, and policies before confirming your order.
					Check the rental period – most rentals are for 4-8 days – and inquire about potential late
					fees. Some platforms offer insurance options to cover minor damages or stains, which can
					provide peace of mind during your event.
				</p>

				<h4>6. Place Your Order:</h4>
				<p>
					Once you've found the perfect dress and familiarized yourself with the terms, it's time to
					place your order. Select your preferred size and choose a delivery date that gives you
					ample time before the event. Double-check your order details, address, and payment
					information before confirming the rental.
				</p>
				<h4>7. Plan Ahead for Accessories:</h4>
				<p>
					With the dress chosen, start planning your accessories, shoes, and makeup to complete the
					look. If your chosen dress is a statement piece, opt for simpler accessories that
					complement rather than compete with the dress. Remember that accessories can significantly
					enhance your overall appearance.
				</p>
				<h4>8. Handle with Care:</h4>
				<p>
					When the dress arrives, treat it with care. Try it on as soon as possible to ensure it
					fits properly and addresses any potential issues promptly. While wearing the dress, be
					cautious with food, drinks, and makeup to prevent stains or damage.
				</p>
				<h4>9. Enjoy the Event:</h4>
				<p>
					The main event has arrived, and you're ready to shine in your rented dress! Capture the
					moment with photos and revel in the confidence that comes from wearing a stunning outfit.
				</p>
				<h4>10. Return the Dress:</h4>
				<p>
					After the event, follow the platform's instructions for returning the dress. Most
					companies provide pre-paid shipping labels, making the return process hassle-free. Ensure
					the dress is clean and in the same condition you received it. Don't forget to share your
					own review and experience to help other shoppers!
				</p>

				<h4>The takeaway: Elevate Your Style with Online Dress Rentals</h4>

				<p>
					Renting dresses online is a game-changer for fashion enthusiasts who want to stay stylish
					without the hefty price tag. By following these steps, you can confidently navigate the
					world of online dress rentals, choosing the perfect dress for any occasion and turning
					heads wherever you go. So, why buy when you can rent and truly make a statement? Happy
					renting! 💚
				</p>

				<div className={`${BASE_CLASS}__likes`}>
					<span
						onClick={() => this.togglePostLike()}
						className={classnames('heart', {
							[`liked`]: this.state.liked,
						})}>
						<Heart />
					</span>
					<small>{totalLocalLikeCount || 0} people liked this post</small>
				</div>
				{/* <h4 className={`${BASE_CLASS}__comment-header`}>Further thoughts</h4> */}

				<div className={`${BASE_CLASS}__comments`}>
					{this.state.comments?.map((comm) => (
						<div className={`${BASE_CLASS}__comments__comment`} key={comm.text}>
							<div className={`${BASE_CLASS}__comments__comment__signature`}>
								<span>{comm.commenter}</span>
								<span>on {new Date(comm.date).toDateString()}</span>
							</div>
							<p>{comm.comment}</p>
						</div>
					))}
				</div>
				<form type="submit" onSubmit={() => this.submitComment()} className={`${BASE_CLASS}__form`}>
					<input
						type="text"
						name="commentToAdd"
						placeholder="add a comment"
						onChange={this.handleCommentChange}
						value={this.state.commentToAdd}
					/>
					<button type="submit">post</button>
				</form>
			</div>
		);
	}
}

export default PostFive;
