import React from 'react';

const PendingFace = () => (
	<svg
		width="170"
		height="169"
		viewBox="0 0 170 169"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1 20.2047C12.3195 14.7984 27.3955 9.95251 40.1447 9.95251C44.4051 9.95251 54.125 12.5434 54.125 18.3407"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M124.958 18.3407C129.75 13.5491 168.763 -11.6271 168.763 9.02055"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M86.8554 81.0579C84.3979 93.6527 81.1729 105.214 81.1729 117.994C81.1729 121.497 79.699 127.939 84.7245 127.939C88.7496 127.939 92.7747 127.939 96.7998 127.939"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M92.5588 168.343C115.208 168.343 137.685 154.805 147.964 134.248"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default PendingFace;
