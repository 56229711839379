import React from 'react';

const PromoCode = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20 6H17.82C17.93 5.69 18 5.35 18 5C18 3.34 16.66 2 15 2C13.95 2 13.04 2.54 12.5 3.35L12 4.02L11.5 3.34C10.96 2.54 10.05 2 9 2C7.34 2 6 3.34 6 5C6 5.35 6.07 5.69 6.18 6H4C2.89 6 2.01 6.89 2.01 8L2 19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V8C22 6.89 21.11 6 20 6ZM15 4C15.55 4 16 4.45 16 5C16 5.55 15.55 6 15 6C14.45 6 14 5.55 14 5C14 4.45 14.45 4 15 4ZM10 5C10 4.45 9.55 4 9 4C8.45 4 8 4.45 8 5C8 5.55 8.45 6 9 6C9.55 6 10 5.55 10 5ZM4 19V17H20V19H4ZM4 8V14H20V8H14.92L17 10.83L15.38 12L12 7.4L8.62 12L7 10.83L9.08 8H4Z"
			fill="#442C2E"
		/>
	</svg>
);

export default PromoCode;
