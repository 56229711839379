import React, { Component } from 'react';
import store from '../../store';
// import { Login, Signup } from '../Auth/index';
import AuthForm from '../Auth/index';

export default class Modals extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
		};
	}

	componentDidMount() {
		// TODO: figure out why this makes state refresh in component did update
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	render() {
		return (
			<>
				{/* {this.state.modals.modalToShow === 'LOGIN' && (
					<Login message={this.state.modals.modalEyebrowText} />
				)} */}

				{/* {this.state.modals.modalToShow === 'SIGNUP' && <Signup />} */}
				{this.state.modals.modalToShow === 'LOGIN' && (
					<AuthForm
						message={this.state.modals.modalEyebrowText}
						name="login"
						displayName="Sign in"
					/>
				)}
				{this.state.modals.modalToShow === 'SIGNUP' && (
					<AuthForm name="signup" displayName="Sign up" />
				)}
			</>
		);
	}
}
