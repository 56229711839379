import React from 'react';

const QuoteDown = () => (
	<svg width="33" height="56" viewBox="0 0 33 56" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.2844 0.799999C12.351 0.799999 8.41771 2.6 5.48437 6.19999C2.41771 9.66666 0.884373 14.2667 0.884373 20C0.884373 27.4667 3.08437 34.4667 7.48437 41C11.8844 47.4 17.351 52.2 23.8844 55.4L25.0844 53C22.2844 50.8667 19.8177 48.1333 17.6844 44.8C15.551 41.4667 14.0177 37.3333 13.0844 32.4L17.2844 31.4C21.951 30.3333 25.6177 28.4 28.2844 25.6C30.8177 22.6667 32.0844 19.2 32.0844 15.2C32.0844 10.9333 30.6844 7.46666 27.8844 4.79999C24.951 2.13333 21.4177 0.799999 17.2844 0.799999Z"
			fill="#fa255e"
		/>
	</svg>
);

export default QuoteDown;
