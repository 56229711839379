import axios from 'axios';

// ACTION TYPES

const GET_FRIENDS = 'GET_FRIENDS';
const GOT_FRIEND_CLOSET = 'GOT_FRIEND_CLOSET';
const GOT_FRIENDS_CLOSETS = 'GOT_FRIENDS_CLOSETS';
const GOT_FRIENDS_CLOSETS_LIKES = 'GOT_FRIENDS_CLOSETS_LIKES';
const GET_UPDATED_FRIENDS = 'GET_UPDATED_FRIENDS';

// initial state

const initialState = {
	friends: [],
	friendCloset: [],
	friendsClosets: [],
	friendsClosetsLikes: [],
};

// action creators

const gotFriends = (friends) => ({
	type: GET_FRIENDS,
	friends,
});

const gotUpdatedFriend = (friend) => ({
	type: GET_UPDATED_FRIENDS,
	friend,
});

const gotFriendCloset = (friendClosetAndAccount) => ({
	type: GOT_FRIEND_CLOSET,
	friendClosetAndAccount,
});

const gotFriendsClosets = (friendsClosets) => ({
	type: GOT_FRIENDS_CLOSETS,
	friendsClosets,
});

const gotFriendsClosetsLikes = (friendsClosetsLikes) => ({
	type: GOT_FRIENDS_CLOSETS_LIKES,
	friendsClosetsLikes,
});

// thunk creators

export const getFriends = () => {
	return async (dispatch, getState) => {
		try {
			const res = await axios.get(`/api/friends`);

			dispatch(gotFriends(res.data));
		} catch (err) {
			console.log(err);
		}
	};
};

export const updateFriend = (friend) => {
	return async (dispatch, getState) => {
		const {
			requesteeEmail,
			requesteeId,
			requesteeName,
			requesterEmail,
			requesterName,
			requesterId,
		} = friend;
		try {
			const res = await axios.put(`/api/friends`, {
				requesterId,
				requesteeId,
				requesteeEmail,
				requesterEmail,
				requesterName,
				requesteeName,
			});

			dispatch(gotUpdatedFriend(res.data));
		} catch (err) {
			console.log(err);
		}
	};
};

export const getFriendCloset = (id) => {
	return async (dispatch, getState) => {
		try {
			const res = await axios.get(`/api/friends/${id}`);
			dispatch(gotFriendCloset(res.data));
		} catch (err) {
			console.log(err);
		}
	};
};

export const getFriendsClosetsLikes = (ids) => {
	let arr = [];
	return async (dispatch, getState) => {
		try {
			for (let i = 0; i < ids.length; i++) {
				if (ids[i].likedItems) {
					await axios.get(`api/friends/${ids[i].friendId}`).then((res) => {
						const articleLiked = res.data.friendCloset.filter((item) =>
							ids[i].likedItems.includes(item.id)
						);
						arr.push(articleLiked);
					});
				}
			}
			dispatch(gotFriendsClosetsLikes(arr));
		} catch (err) {
			console.log(err);
		}
	};
};

// export const getFriendsClosetsArticles = (ids) => {
// 	let allArticles = [];
// 	return async (dispatch, getState) => {
// 		try {
// 			for (let i = 0; i < ids.length; i++) {
// 				await axios.get(`api/friends/${ids}`).then((res) => {
// 					// const allArticles = res.data.filter((item) => ids[i].likedItems.includes(item.id));
// 					allArticles.push(res.data);
// 				});
// 			}

// 			console.log('all artciles!', allArticles);
// 			dispatch(gotFriendsClosetsArticles(allArticles));
// 		} catch (err) {
// 			console.log(err);
// 		}
// 	};
// };

export const getFriendsClosets = (ids) => {
	let allClosets = [];
	let i;

	let idsArr = ids.flat();

	return async (dispatch, getState) => {
		for (i = 0; i < idsArr.length; i++) {
			try {
				const res = await axios.get(`/api/friends/${idsArr[i]}`);

				allClosets.push(res.data.friendCloset);
			} catch (err) {
				console.log(err);
			}
		}

		allClosets = allClosets
			.flat()
			.filter((article) => article.public)
			.slice(0, 10)
			.sort((a, b) => b.date - a.date);

		dispatch(gotFriendsClosets(allClosets));
	};
};

// reducer

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_FRIENDS:
			return { ...state, friends: action.friends };
		case GOT_FRIEND_CLOSET:
			return {
				...state,
				friendCloset: action.friendClosetAndAccount.friendCloset,
				friendAccount: action.friendClosetAndAccount.friendAccount,
			};
		case GET_UPDATED_FRIENDS:
			return { ...state, friends: state.friends?.concat(action.friend) };
		case GOT_FRIENDS_CLOSETS:
			return { ...state, friendsClosets: action.friendsClosets };
		case GOT_FRIENDS_CLOSETS_LIKES:
			return { ...state, friendsClosetsLikes: action.friendsClosetsLikes };
		default:
			return state;
	}
}
