import React, { Component, Fragment } from 'react';
import store, { auth } from '../../store';
import './index.scss';
import { dequeueModal, queueModal } from '../../store/modals';
import Checkbox from '../atoms/Checkbox';
import history from '../../../client/history';
import { Close } from '../../../public/icons';

// import isEmailValid from './utils/isEmailValid'
// import isPasswordValid from './utils/isPasswordValid'

const BASE_CLASS = 'auth';

class AuthForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			termsAgreed: false,
			error: null,
		};
		this.alternativeFormName = this.alternativeFormName.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.hideOrCloseModal = this.hideOrCloseModal.bind(this);
		this.handleShowAlternateForm = this.handleShowAlternateForm.bind(this);
		this.handleCloseLogin = this.handleCloseLogin.bind(this);
		this.toggleCheckbox = this.toggleCheckbox.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	alternativeFormName = (name) => {
		return name === 'signup' ? 'Sign in' : 'Sign up';
	};

	handleReset() {
		// close modal & redirect
		store.dispatch(dequeueModal());
		history.push('/forgot');
	}

	handleSubmit(evt) {
		evt.preventDefault();
		if (!this.state.termsAgreed && this.props.name === 'signup') {
			this.setState({
				error: 'Please agree to our Terms & Conditions',
			});
		} else {
			const formName = evt.target.name;
			const email = evt.target.email.value;
			const password = evt.target.password.value;
			const userName = evt.target.userName ? evt.target.userName.value : '';
			const termsAgreed = this.state.termsAgreed;
			store.dispatch(auth(userName, email, password, formName, termsAgreed));
			this.unsubscribe = store.subscribe(() => this.setState(store.getState));
			dataLayer.push({ event: 'signup', signup_email: email });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.user.user !== this.state.user.user) {
			this.setState({
				error: this.state.user.user?.error?.response?.data,
			});
			this.hideOrCloseModal();
		}
	}

	hideOrCloseModal() {
		if (!!this.state.user.user && !this.state.user.user.error) {
			this.setState({
				error: null,
			});

			store.dispatch(dequeueModal());
		}
	}

	handleCloseLogin() {
		this.setState({
			error: null,
		});

		store.dispatch(dequeueModal());
	}

	handleShowAlternateForm() {
		let modalToShow = this.props.name === 'signup' ? 'LOGIN' : 'SIGNUP';

		store.dispatch(dequeueModal());
		this.setState({
			error: null,
		});
		store.dispatch(queueModal(modalToShow));
	}

	toggleCheckbox(property) {
		this.setState({
			[property]: !this.state[property],
		});
	}

	render() {
		return (
			<Fragment>
				{this.props.name === 'login' && (
					<>
						<div className={`${BASE_CLASS}__container`} />
						<div className={`${BASE_CLASS}__inner`}>
							<span onClick={() => this.handleCloseLogin()}>
								<Close />
							</span>
							{this.props.message && <h2>{this.props.message}</h2>}
							<h3>Log in to Social Closet</h3>
							<h5>Log in to rent and lend your wardrobe</h5>

							<form onSubmit={this.handleSubmit} name={this.props.name}>
								<label>Email</label>
								<input name="email" type="email" placeholder="email" />
								<label>Password</label>
								<input name="password" placeholder="password" type="password" />
								{this.state.error && <small>{this.state.error}</small>}
								<button type="submit">sign in</button>
							</form>

							<p>
								Don't have an account? <span onClick={this.handleShowAlternateForm}>Sign up</span>{' '}
								instead.
							</p>

							<p>
								Forgot password? <span onClick={this.handleReset}> Reset.</span>
							</p>
						</div>
					</>
				)}

				{this.props.name === 'signup' && (
					<>
						<div className={`${BASE_CLASS}__container`} />
						<div className={`${BASE_CLASS}__inner`}>
							<span onClick={() => this.handleCloseLogin()}>
								<Close />
							</span>
							{this.props.message && <h2>{this.props.message}</h2>}
							<h3>Join Social Closet</h3>
							<h5>Sign up to rent and lend your wardrobe.</h5>
							<form onSubmit={this.handleSubmit} name={this.props.name}>
								<label>Name</label>
								<input name="userName" type="text" placeholder="name" />
								<label>Email</label>
								<input name="email" type="email" placeholder="email" />
								<label>Password</label>
								<input name="password" placeholder="password" type="password" />
								{this.state.error && <small>{this.state.error}</small>}
								<Checkbox
									termsLink="/terms"
									label="I agree to the Social Closet Terms of Use"
									onChange={() => this.toggleCheckbox('termsAgreed')}
								/>
								<button type="submit">sign up</button>
							</form>
							<p>
								Already have an account? <span onClick={this.handleShowAlternateForm}>Log in</span>{' '}
								instead.
							</p>
						</div>
					</>
				)}
			</Fragment>
		);
	}
}

export default AuthForm;
