import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

export class MapContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// openInfoWindowMarkerId: 3,
			activeMarker: null,
			selectedPlace: null,
			showingInfoWindow: false,
		};

		this.displayMarkers = this.displayMarkers.bind(this);
		this.onMarkerClick = this.onMarkerClick.bind(this);
	}

	onMarkerClick = (prop, marker) => {
		this.setState({
			activeMarker: marker,
			selectedPlace: prop,
			showingInfoWindow: true,
			infoMarkerArticle: this.props.articles.filter((article) => article.id === prop.id),
		});
	};

	// generateIcon = () => ({
	// 	path:
	// 		'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',

	// 	fillColor: '#EB63F5',
	// 	fillOpacity: 0.6,
	// 	strokeWeight: 1,
	// 	rotation: 0,
	// 	scale: 1.5,
	// 	anchor: new google.maps.Point(0, 0),
	// });

	displayMarkers = () => {
		return this.props.articles.map((article, index) => (
			<Marker
				key={article.id}
				id={article.id}
				position={
					article.lenderLatLng
						? { lat: Number(article.lenderLatLng[0]), lng: Number(article.lenderLatLng[1]) }
						: null
				}
				// icon={this.generateIcon()}
				onClick={this.onMarkerClick}
				label={{
					text: `$${article.currentDailyRentalPrice || ''}`,
					color: 'white',
					fontSize: '12px',
				}}
			/>
		));
	};

	render() {
		return (
			<Map
				className="map"
				google={this.props.google}
				initialCenter={{ lat: 40.7516208, lng: -73.975502 }}
				style={{
					width: '100%',
					height: '100%',
				}}
				zoom={16}>
				{this.displayMarkers()}

				<InfoWindow
					marker={this.state.activeMarker}
					//   onClose={this.onInfoWindowClose}
					visible={this.state.showingInfoWindow}>
					<a
						href={`${window.location.origin}/rent/${
							this.state.infoMarkerArticle ? this.state.infoMarkerArticle[0].id : ''
						}`}>
						<img
							src={
								this.state.infoMarkerArticle && this.state.infoMarkerArticle[0]?.thumbnailUrls[0]
							}
						/>
					</a>
					<div>
						<b>
							{this.state.infoMarkerArticle &&
								`$${this.state.infoMarkerArticle[0].currentDailyRentalPrice} / week`}
						</b>
					</div>
					<div>
						{this.state.infoMarkerArticle && `Size ${this.state.infoMarkerArticle[0].size}`}
					</div>
				</InfoWindow>
			</Map>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyChKTCF8jV64Nj8pm4VJkoZuNz4NVLqbAA',
})(MapContainer);
