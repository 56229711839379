import React from 'react';

const UserIcon = () => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.1107 19.056V17.2782C17.1107 16.3352 16.7361 15.4309 16.0693 14.7641C15.4025 14.0973 14.4981 13.7227 13.5551 13.7227H7.3329C6.38991 13.7227 5.48554 14.0973 4.81874 14.7641C4.15195 15.4309 3.77734 16.3352 3.77734 17.2782V19.056"
			stroke="black"
			strokeWidth="1.77778"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.4442 10.1668C12.4079 10.1668 13.9998 8.5749 13.9998 6.61122C13.9998 4.64754 12.4079 3.05566 10.4442 3.05566C8.48055 3.05566 6.88867 4.64754 6.88867 6.61122C6.88867 8.5749 8.48055 10.1668 10.4442 10.1668Z"
			stroke="black"
			strokeWidth="1.77778"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default UserIcon;
