import React, { Component } from 'react';
import { getOutfits, addOutfit } from '../../store/outfit';
import { getDresses } from '../../store/closet';
import store from '../../store';
import axios from 'axios';
import { connect } from 'react-redux';
import DisplayRandomOutfit from '../modals/DisplayRandomOutfitModal';
import SaveOutfitModal from '../modals/SaveOutfitModal';
import OutfitRemoveConfirmationModal from '../modals/OutfitRemoveConfirmationModal';
import getRandomOutfit from './utils/getRandomOutfit';
import { setIsModalOpen } from '../../store/utils';

import classnames from 'classnames';

const BASE_CLASS = 'outfits';
import './index.scss';

class Outfit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			randomTop: {},
			randomBottom: {},
			randomShoes: {},
			randomDress: {},
			isShuffleOpen: false,
			isHoverMenuOpen: false,
			hoverIndex: '',
			randomOutfitSaved: false,
			showNotesForm: false,
			lists: new Set(),
			filteredOutfits: '',
		};
		this.handleShuffleAgainClick = this.handleShuffleAgainClick.bind(this);
		this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
		this.handleHover = this.handleHover.bind(this);
		this.removeOutfit = this.removeOutfit.bind(this);
		this.handleSaveOutfit = this.handleSaveOutfit.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.createOutfit = this.createOutfit.bind(this);
		this.handleCloseNotesForm = this.handleCloseNotesForm.bind(this);
		this.handleNoteSubmit = this.handleNoteSubmit.bind(this);
		this.getLists = this.getLists.bind(this);
		this.handleFilterByList = this.handleFilterByList.bind(this);
	}
	componentDidMount() {
		store.dispatch(getOutfits());
		store.dispatch(getDresses());
		this.getLists();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
		// this.unsubscribe();
	}

	getLists() {
		let listSet = new Set();
		this.state.outfit.outfits.map((outfit) => {
			return outfit.list ? outfit.list.map((listItem) => listItem && listSet.add(listItem)) : null;
		});
		return listSet;
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			this.setState({
				lists: this.getLists(),
			});
			if (this.state.closet.dresses.length) {
				const { randomDress, randomShoes, randomTop, randomBottom } = getRandomOutfit(
					this.state.closet
				);
				this.setState({
					randomTop: randomTop,
					randomBottom: randomBottom,
					randomShoes: randomShoes,
					randomDress: randomDress,
				});
			}
		}
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	handleNoteSubmit(e, note, list, newList) {
		if (newList.length) {
			list.push(newList);
		}

		this.createOutfit(
			this.state.randomTop,
			this.state.randomBottom,
			this.state.randomShoes,
			this.state.randomDress,
			note,
			list
		);
	}

	createOutfit = (top, bottom, shoes, dress, note, list) => {
		let outfit = [top, bottom, shoes, dress, { notes: note, list: list }].filter((item) => item);
		store.dispatch(addOutfit(outfit));
		this.setState({
			randomOutfitSaved: true,
		});
	};

	getRandomInteger(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	handleCloseModalClick() {
		this.setState({
			isShuffleOpen: false,
			randomOutfitSaved: false,
		});
	}

	handleShuffleAgainClick() {
		if (this.state.closet.dresses.length) {
			const { randomDress, randomShoes, randomTop, randomBottom } = getRandomOutfit(
				this.state.closet
			);
			this.setState({
				randomTop: randomTop,
				randomBottom: randomBottom,
				randomShoes: randomShoes,
				randomDress: randomDress,
				isShuffleOpen: true,
				randomOutfitSaved: false,
			});
		}
	}

	handleHover(i) {
		this.setState({
			isHoverMenuOpen: true,
			hoverIndex: i,
		});
	}

	handleBlur() {
		this.setState({
			isHoverMenuOpen: false,
		});
	}

	removeOutfit(i, outfitId) {
		store.dispatch(setIsModalOpen(true));
		window.scrollTo(0, 0);
	}

	handleDeleteOutfitAccept(e, outfitId) {
		e.preventDefault();
		store.dispatch(setIsModalOpen(false));
		axios.delete(`/api/outfits/`, { data: { outfitId } });
	}

	handleDeleteOutfitCancel() {
		store.dispatch(setIsModalOpen(false));
	}

	handleSaveOutfit() {
		this.setState({ showNotesForm: true });
	}

	handleCloseNotesForm() {
		this.setState({
			showNotesForm: false,
		});
	}

	handleFilterByList(event) {
		let selectedList = event.target.value;
		if (selectedList === 'filter by list') {
			this.setState({
				...this.state,
				filteredOutfits: '',
			});
		} else {
			let filteredOutfits = this.state.outfit.outfits.filter((outfit) =>
				outfit.list.includes(selectedList)
			);
			this.setState({
				...this.state,
				filteredOutfits: filteredOutfits,
			});
		}
	}

	render() {
		let listArray = Array.from(this.state.lists);

		const outfits = this.state.filteredOutfits
			? this.state.filteredOutfits
			: this.state.outfit.outfits;

		return (
			<div className={BASE_CLASS}>
				{this.state.isShuffleOpen ? (
					<DisplayRandomOutfit
						top={this.state.randomTop}
						bottom={this.state.randomBottom}
						shoes={this.state.randomShoes}
						dress={this.state.randomDress}
						handleShuffleAgainClick={this.handleShuffleAgainClick}
						handleCloseModalClick={this.handleCloseModalClick}
						//createOutfit={this.createOutfit}
						handleSaveOutfit={this.handleSaveOutfit}
						randomOutfitSaved={this.state.randomOutfitSaved}
						BASE_CLASS="display-random-outfit"
					/>
				) : null}

				{this.state.showNotesForm ? (
					<SaveOutfitModal
						handleCloseNotesForm={this.handleCloseNotesForm}
						handleNoteSubmit={this.handleNoteSubmit}
						groups={this.state.lists}
						getLists={this.getLists}
					/>
				) : null}

				<div className={`${BASE_CLASS}__menu`}>
					<button
						type="submit"
						disabled={
							!this.state.randomBottom &&
							!this.state.randomDress &&
							!this.state.randomShoes &&
							!this.state.randomShoes
						}
						onClick={this.handleShuffleAgainClick}
						className={classnames(
							`${BASE_CLASS}__menu__button`,
							`${BASE_CLASS}__menu__button__shuffle`
						)}>
						shuffle my closet
					</button>
					<select className={`${BASE_CLASS}__menu__button`} onChange={this.handleFilterByList}>
						<option value="default">filter by list</option>
						{listArray.map((list) => (
							<option value={list}>{list}</option>
						))}
					</select>
				</div>
				<div className={`${BASE_CLASS}__title`}>your outfits</div>
				<div className={`${BASE_CLASS}__grid`}>
					{outfits.length ? (
						outfits.map((outfit, i) => (
							<div
								key={i}
								className={classnames(`${BASE_CLASS}__grid__outfit`)}
								onMouseOver={() => this.handleHover(i)}
								onMouseLeave={this.handleBlur}>
								{this.state.isHoverMenuOpen && this.state.hoverIndex === i ? (
									<>
										{this.state.utils.isModalOpen ? (
											<OutfitRemoveConfirmationModal
												handleDeleteOutfitCancel={this.handleDeleteOutfitCancel}
												handleDeleteOutfitAccept={this.handleDeleteOutfitAccept}
												outfitId={outfit.id}
											/>
										) : null}
										<div className={classnames(`${BASE_CLASS}__grid__hoverMenu`, {})}>
											<div className={classnames(`${BASE_CLASS}__grid__hoverMenu__note`, {})}>
												{outfits[i].notes}
												<br />
												<span
													className={classnames(`${BASE_CLASS}__grid__hoverMenu__note_tag`, {})}>
													{outfits[i].list.toString()}
												</span>
											</div>

											<button
												type="button"
												className={`${BASE_CLASS}__grid__hoverMenu__button`}
												onClick={() => this.removeOutfit(i, outfit.id)}>
												remove outfit
											</button>
										</div>
									</>
								) : null}
								<img
									alt={outfit.dressName}
									src={outfit.dressImageURL}
									loading="lazy"
									className={`${BASE_CLASS}__grid__outfit__dress`}
								/>
								<img
									alt={outfit.topName}
									src={outfit.topImageURL}
									loading="lazy"
									className={`${BASE_CLASS}__grid__outfit__top`}
								/>
								<img
									alt={outfit.bottomName}
									src={outfit.bottomImageURL}
									loading="lazy"
									className={`${BASE_CLASS}__grid__outfit__bottom`}
								/>
								<img
									alt={outfit.outerwearName}
									src={outfit.outerwearImageURL}
									loading="lazy"
									className={`${BASE_CLASS}__grid__outfit__outerwear`}
								/>
								<img
									alt={outfit.shoesName}
									src={outfit.shoesImageURL}
									loading="lazy"
									className={`${BASE_CLASS}__grid__outfit__shoes`}
								/>
							</div>
						))
					) : (
						<div className={`${BASE_CLASS}__noresults`}>
							Looks like you haven't created any outfits yet. <a href="/about">See how</a> to get
							started.
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getOutfits: () => dispatch(getOutfits()),
		addOutfit: (articles) => dispatch(addOutfit(articles)),
	};
};

export default connect(mapDispatchToProps)(Outfit);
