import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import getUnixTime from 'date-fns/getUnixTime';

export default function getWeek(day) {
	const thursday = startOfWeek(day, { weekStartsOn: 4 });
	const wednesday = endOfWeek(day, { weekStartsOn: 4 });
	const week = eachDayOfInterval({
		start: thursday,
		end: wednesday,
	});
	const weekTimestamps = week.map((day) => getUnixTime(day));

	return { week, weekTimestamps };
}
