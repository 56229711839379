import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import store from '../../store';
import { setIsModalOpen } from '../../store/utils';
import { getSingleDress, addWear } from '../../store/closet';
import './index.scss';
import RemoveConfirmationModal from '../modals/RemoveConfirmationModal';
import { connect } from 'react-redux';
import VideoPlayer from '../atoms/VideoPlayer';
import differenceInWeeks from 'date-fns/differenceInWeeks';

const BASE_CLASS = 'article';

class ArticleContainer extends Component {
	constructor() {
		super();
		this.state = store.getState();
		this.showModal = this.showModal.bind(this);
		this.getCostPerWear = this.getCostPerWear.bind(this);
	}

	componentDidMount() {
		const dressId = this.props.match.params.dressId;
		store.dispatch(getSingleDress(dressId));
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	showModal() {
		store.dispatch(setIsModalOpen(true));
	}

	async addWear(dressId) {
		store.dispatch(addWear(dressId));
		const dressToAddWear = await this.state.closet.dress;
		dressToAddWear.wearCount += 1;
	}

	async handleAccept(dressId) {
		store.dispatch(setIsModalOpen(false));
		await axios.delete(`/api/closet/${dressId}`);
	}

	handleCancel() {
		store.dispatch(setIsModalOpen(false));
	}

	getCostPerWear() {
		let dress = this.state.closet.dress;
		return dress.cost && dress.wearCount
			? Math.round((dress.cost / dress.wearCount) * 100) / 100
			: null;
	}

	getDate() {
		let date;
		if (this.state.closet.dress.latestWear) {
			date = new Date(this.state.closet.dress.latestWear).toDateString();
		} else date = 'tbd';
		return date;
	}

	getTimePassed() {
		let datePassed;
		if (this.state.closet.dress.datepurchased) {
			let weeks = differenceInWeeks(
				new Date(Date.now()),
				new Date(this.state.closet.dress.datepurchased)
			);

			if (weeks >= 52) {
				let years = Math.floor(weeks / 52);
				datePassed = `${years} year${years > 1 ? 's' : ''} ago`;
			} else if (weeks >= 4 && weeks < 52) {
				let months = Math.floor(weeks / 4);
				datePassed = `${months} month${months > 1 ? 's' : ''} ago`;
			} else if (weeks < 4) {
				datePassed = `this month`;
			}
		} else {
			datePassed = 'tbd';
		}
		return datePassed;
	}

	render() {
		const dress = this.state.closet.dress;

		if (dress) {
			return (
				<div className={BASE_CLASS} key={dress.id}>
					<div className={`${BASE_CLASS}__top`}>
						<div className={`${BASE_CLASS}__top__left`}>
							{!!dress?.imageURL && <VideoPlayer src={dress?.imageURL} />}
							{!!dress.imageUrls && <img src={dress.imageUrls[0]} />}
							<small>
								{dress.name} <br /> {dress.brand}
							</small>
						</div>

						<div className={`${BASE_CLASS}__top__right`}>
							{dress.cost > 0 && (
								<div>
									<small>cost</small>
									<p>${dress.cost}</p>
								</div>
							)}

							{dress.wearCount > 0 && (
								<div>
									<small>wears</small>
									<p>{dress.wearCount} </p>
								</div>
							)}

							{dress.cost > 0 && dress.wearCount > 0 && (
								<div>
									<small>cost per wear</small>
									<p>${this.getCostPerWear()}</p>
								</div>
							)}
							<div>
								<small>last worn</small>
								<p>{this.getDate()}</p>
							</div>

							<div>
								<small>date purchased</small>
								<p>{this.getTimePassed()}</p>
							</div>
							<div>
								<small>tags</small>
								<div>{dress.tags ? dress.tags.map((tag) => <span>{tag}</span>) : null}</div>
							</div>
						</div>
					</div>

					<div className={`${BASE_CLASS}__buttons`}>
						<button type="button" onClick={() => this.showModal()}>
							Delete
						</button>

						<Link to={{ pathname: `/closet/${dress.id}/edit`, state: dress }}>Edit</Link>
						<button
							type="button"
							onClick={() => {
								this.addWear(dress.id);
							}}>
							Add wear
						</button>
					</div>

					{this.state.utils.isModalOpen ? (
						<RemoveConfirmationModal
							handleCancel={this.handleCancel}
							handleAccept={this.handleAccept}
							dressId={dress.id}
						/>
					) : null}
				</div>
			);
		} else {
			return <p>Sorry no article found</p>;
		}
	}
}

const mapState = (state) => {
	return {
		dresses: state.closet.dresses,
	};
};

export default connect(mapState)(ArticleContainer);
