import React from 'react';

const WarningTriangle = () => (
	<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_3979_58462)">
			<path
				d="M10.7188 4.0208L1.89589 18.75C1.71398 19.065 1.61773 19.4222 1.61671 19.7859C1.61569 20.1497 1.70994 20.5074 1.89008 20.8234C2.07022 21.1395 2.32997 21.4028 2.6435 21.5873C2.95702 21.7718 3.31338 21.871 3.67714 21.875H21.323C21.6867 21.871 22.0431 21.7718 22.3566 21.5873C22.6701 21.4028 22.9299 21.1395 23.11 20.8234C23.2902 20.5074 23.3844 20.1497 23.3834 19.7859C23.3824 19.4222 23.2861 19.065 23.1042 18.75L14.2813 4.0208C14.0956 3.71466 13.8341 3.46155 13.5221 3.28589C13.2101 3.11023 12.8581 3.01794 12.5001 3.01794C12.142 3.01794 11.79 3.11023 11.478 3.28589C11.166 3.46155 10.9045 3.71466 10.7188 4.0208V4.0208Z"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.5 17.7084H12.5104"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.5 9.375V13.5417"
				stroke="#111111"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3979_58462">
				<rect width="25" height="25" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default WarningTriangle;
