import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BlogPost1 } from '../../../../../public/icons/index';
import store from '../../../../store';
import './index.scss';
import axios from 'axios';
import classnames from 'classnames';
import { Heart } from '../../../../../public/index';

const BASE_CLASS = 'post-one';

class PostOne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			comments: [],
			commentToAdd: '',
			liked: false,
		};

		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.getComments = this.getComments.bind(this);
		this.submitComment = this.submitComment.bind(this);
		this.postComment = this.postComment.bind(this);
		this.togglePostLike = this.togglePostLike.bind(this);
	}

	componentDidMount() {
		this.getComments();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	togglePostLike() {
		this.setState({
			liked: !this.state.liked,
		});

		this.postLike(!this.state.liked);
	}

	async postLike(isLiked) {
		try {
			const post = await axios.put(`/api/blogcomment/1`, { liked: isLiked.toString() });
		} catch (err) {
			console.err(err);
		}
	}

	async getComments() {
		try {
			const res = await axios.get(`/api/blogcomment/1`);
			const postData = res.data;

			// if (!postData[0]) {
			// adding post for first time:
			// 	const post = await axios.post(`/api/blogcomment/1`, {
			// 		blogId: 1,
			// 		topic: 'wardrobe',
			// 		likeCount: 0,
			// 	});
			// } else {

			const comments = res.data[0]?.comment;
			const likeCount = res.data[0].likeCount;

			const commentMapped = comments ? comments.map((c) => JSON.parse(c)) : null;

			this.setState({
				comments: commentMapped,
				likeCount: likeCount,
			});
			// }
		} catch (err) {
			console.error(err);
		}
	}

	async postComment(commentObj) {
		try {
			const post = await axios.put(`/api/blogcomment/1`, commentObj);
		} catch (err) {
			console.err(err);
		}
	}

	submitComment() {
		event.preventDefault();
		const commentObj = {
			commenter: this.state.user?.user.name || 'anonymous',
			comment: this.state.commentToAdd,
			userId: this.state.user?.user.id || null,
			date: Date.now(),
		};

		this.postComment(commentObj);

		this.setState({
			commentToAdd: '',
			comments: this.state.comments ? [...this.state.comments, commentObj] : [commentObj],
		});
	}

	handleCommentChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	render() {
		const localLikeCount = this.state.liked ? 1 : 0;
		let totalLocalLikeCount = (this.state.likeCount || 0) + localLikeCount;
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__top`}>
					<Link to="/fashion-fix">Back</Link>
					<h5>
						the fashion fix
						<div />
					</h5>
				</div>
				<h3>How to buy clothes you’ll love to wear</h3>

				<p>
					<b>A closet full of clothes but nothing to wear:</b> this is part of the rich world human
					condition in the 21st century. How did we get here? Well, we bought too many clothes (more
					on this another time) that we didn’t love. How do we get out of this – how do we get to a
					point where we have a closet that’s not overflowing and contains only items we want to
					wear? We buy fewer things (more on this another time), and{' '}
					<b>only buy things we love to wear</b>. But how do we buy clothes that we’ll really want
					to wear? The question isn’t always as easy as it seems. The animal instincts at play when
					we browse Instagram or get lured into storefronts don’t always guide us to good purchases.
					Here are five questions we can ask ourselves to check that animal instinct the next time
					we take something from a rack or hover over a checkout button.
				</p>
				<BlogPost1 />
				<h4>1. Do I love it? Does it spark joy?</h4>
				<p>
					This might seem like an obvious one, but a lot of times we buy clothes that we don’t
					necessarily love. “It’s on sale” is a big reason, or maybe we want to replace white jeans
					we lost and find ones aren’t perfect, but will have to do for now because you want white
					jeans. Don’t buy something unless you love it, and a good gut check is:{' '}
					<b>would you want to wear it tomorrow morning, or right now?</b> If not, wait until you
					find the perfect item. Your future self will thank you.
				</p>

				<h4>2. Do I have something similar already?</h4>
				<p>
					This might sound like an obvious one, but with so much in our closets, what’s already in
					them might not always be top-of-mind. Staying organized and keeping your wardrobe easy to
					access can reduce buying things we already have.
				</p>

				<h4>3. Does it flatter me?</h4>

				<p>
					When we wake up and think of our day ahead of us, we want to wear something that makes us
					feel good, not just something that merely fits us. Sometimes a simple alteration will do
					the trick; and in this case, ask yourself at purchase if you’re able to alter it or
					willing to take it to a tailor? But often we buy things that we’ll never feel great in;
					maybe we like the print or a trend, or like the way it looks on other people.{' '}
					<b>
						If something is a trend or you’re buying it even when it doesn’t make you feel great
						about yourself, don’t buy it.
					</b>{' '}
					You’re not going to want to wear it in the morning.
				</p>

				<h4>4. Is it good quality and will I take care of it?</h4>

				<p>
					Clothing isn’t meant to be single-use, so we have to question how it will hold up after
					many wears and washes. Is it well-constructed, or will it lose its shape after a few
					wears? If the fabric starts pilling, if the white starts to yellow, if it needs to be
					ironed, will I take care of it properly? If it needs to be dry cleaned, will I be up for
					that?
				</p>
				<h4>5. Where and when will I wear it?</h4>
				<p>
					This is one of the most important questions we can ask ourselves when we shop. Are you
					buying something for a fantasy lifestyle or an alter ego, or is this something you’ll wear
					in your current life? Think of concrete occasions when you’ll be able to wear the item.
					Think of what you’ll wear it with; will it fit with multiple items you already own, or
					will you have to buy something else to wear with it? Will you wear it dozens of times?
					Will you wear it in a year? If it’s a trend you want to try, or something you’ll only wear
					once, consider renting it, or borrowing something from a friend.
				</p>

				<h4>The takeaway</h4>
				<p>
					Practicing smarter shopping will not only make it easier for us to get dressed and feel
					good in our clothing, but it’s also critical to our environment. Our habit of treating
					clothing as disposable instead of as an investment has caused clothing production to
					double in the past 20 years to <b>100 billion garments</b>, which of course causes an
					array of harmful effects, including open-air landfills in places like Accra, Ghana and the
					Atacama Desert in Chile.
				</p>
				<p>
					The good news is we consumers have a lot of power; in this forum we will discuss more ways
					we can try to fix fashion together. Join the conversation and let us know your thoughts
					and your own tips. 💚
				</p>

				<div className={`${BASE_CLASS}__likes`}>
					<span
						onClick={() => this.togglePostLike()}
						className={classnames('heart', {
							[`liked`]: this.state.liked,
						})}>
						<Heart />
					</span>
					<small>{totalLocalLikeCount || 0} people liked this post</small>
				</div>
				{/* <h4 className={`${BASE_CLASS}__comment-header`}>Further thoughts</h4> */}

				<div className={`${BASE_CLASS}__comments`}>
					{this.state.comments?.map((comm) => (
						<div className={`${BASE_CLASS}__comments__comment`} key={comm.text}>
							<div className={`${BASE_CLASS}__comments__comment__signature`}>
								<span>{comm.commenter}</span>
								<span>on {new Date(comm.date).toDateString()}</span>
							</div>
							<p>{comm.comment}</p>
						</div>
					))}
				</div>
				<form type="submit" onSubmit={() => this.submitComment()} className={`${BASE_CLASS}__form`}>
					<input
						type="text"
						name="commentToAdd"
						placeholder="add a comment"
						onChange={this.handleCommentChange}
						value={this.state.commentToAdd}
					/>
					<button type="submit">post</button>
				</form>
			</div>
		);
	}
}

export default PostOne;
