import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
const BASE_CLASS = 'article-card';
import { Heart } from '../../../../public/index';
import { ShoppingCart } from '../../../../public/icons/index';
import classnames from 'classnames';
import Review from '../Review';
import { InView } from 'react-intersection-observer';

// card types: friendArticle, lendItem, homepage, or null

export default function ArticleCard({
	article,
	likedArticleIds,
	toggleArticleLike,
	cardType,
	selectedWeekDates,
	datesSelected,
}) {
	return (
		<InView triggerOnce="true" threshold=".2">
			{({ inView, ref, entry }) => (
				<div ref={ref} className={classnames(`${BASE_CLASS}`, { inView: inView })}>
					<div className={`${BASE_CLASS}__top`}>
						{cardType !== 'friendArticle' && (
							<>
								<div className={`${BASE_CLASS}__price`}>
									<span>${article.currentDailyRentalPrice}</span>/week
									<p>{!!article.cost && '$' + article.cost}</p>
								</div>

								{cardType !== 'lendItem' && (
									<div className={`${BASE_CLASS}__icons`}>
										{article.list?.includes('sellable') && <ShoppingCart />}
										<div
											onClick={() => toggleArticleLike(article.id)}
											className={classnames('heart-rent', {
												[`liked`]: likedArticleIds && likedArticleIds.includes(article.id),
											})}>
											<Heart />
										</div>
									</div>
								)}
							</>
						)}
					</div>

					<div className={`${BASE_CLASS}__image_container`}>
						{cardType === 'friendArticle' && (
							<Link
								to={{
									pathname: `/friends/${article.userId}`,
									state: { selectedWeekDates, datesSelected },
								}}
								key={article.id}>
								{article.imageUrls && <img src={article.imageUrls[0]} />}
							</Link>
						)}

						{cardType !== 'friendArticle' && cardType !== 'lendItem' && (
							<Link
								to={{
									pathname: `/rent/${article.id}`,
									state: { selectedWeekDates, datesSelected },
									// state: { article, selectedWeekDates, datesSelected },
								}}
								key={article.id}>
								{article.imageUrls && <img src={article.imageUrls[0]} />}
							</Link>
						)}
						{cardType === 'lendItem' && (
							<>
								<Link
									to={{
										pathname: `/lend/${article.id}`,
									}}
									key={article.id}>
									{article.imageUrls && <img src={article.imageUrls[0]} />}
								</Link>
							</>
						)}
					</div>

					{cardType !== 'homepage' && (
						<div className={`${BASE_CLASS}__bottom`}>
							{cardType !== 'friendArticle' &&
								(article.borrowerOverallReview ? (
									<Review reviewStarNumber={article.borrowerOverallReview} />
								) : (
									<Review reviewStarNumber={0} />
								))}

							<p className="brand">{article.brand}</p>
							<p>Size {article.size?.toUpperCase()}</p>
							{cardType === 'lendItem' && <p className="edit">Edit listing</p>}
						</div>
					)}
				</div>
			)}
		</InView>
	);
}
