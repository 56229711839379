import Heart from './heart';
import Article from './article';
import Checkmark from './checkmark';
import ConfirmFace from './confirmFace';
import Cross from './cross';
import Face from './face';
import Icon from './icon';
import PendingFace from './pendingFace';
import Star from './star';
import UserIcon from './user';
import Instagram from './instagram';
import Arrows from './arrows';
import Tiktok from './Tiktok';

export {
	Heart,
	Article,
	Checkmark,
	ConfirmFace,
	Icon,
	PendingFace,
	Face,
	Cross,
	Star,
	UserIcon,
	Instagram,
	Arrows,
	Tiktok,
};
