import React, { Component } from 'react';
import { connect } from 'react-redux';
// import getRandomOutfit from '../Outfits/utils/getRandomOutfit';
// import classnames from 'classnames';
import { getDresses } from '../../store/closet';
// import DisplayRandomOutfit from '../modals/DisplayRandomOutfitModal';
import { getFriends, getFriendsClosets } from '../../store/friends';
import './index.scss';
import store from '../../store';
import { Link } from 'react-router-dom';
import { getOutfits } from '../../store/outfit';
import ArticleCard from '../atoms/ArticleCard';
import axios from 'axios';

const BASE_CLASS = 'home';

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...store.getState(),
			// randomTop: {},
			// randomBottom: {},
			// randomShoes: {},
			// randomDress: {},
			// showOutfitInfoModal: false,
			// showGoalsInfoModal: false,
			// showCPWInfoModal: false,
			likedArticleIds: [],
		};
		this.toggleArticleLike = this.toggleArticleLike.bind(this);
		// this.handleShuffleAgainClick = this.handleShuffleAgainClick.bind(this);
		// this.closeOutfitInfoModal = this.closeOutfitInfoModal.bind(this);
		// this.closeGoalsInfoModal = this.closeGoalsInfoModal.bind(this);
		// this.closeCPWInfoModal = this.closeCPWInfoModal.bind(this);
	}

	componentDidMount() {
		let friendIds = [];
		store.dispatch(getDresses());
		store.dispatch(getOutfits());
		store
			.dispatch(getFriends())
			.then(() => {
				const userId = this.state.user.user.id;

				// friend where user is requester
				const first = this.state.friends.friends
					.filter((f) => f.requesteeId === Number(userId))
					.map((friend) => friend.requesterId);

				// friend where user is requestee
				const second = this.state.friends.friends
					.filter((f) => f.requesterId === Number(userId))
					.map((friend) => friend.requesteeId);

				friendIds.push(first.concat(second));
			})
			.then(() => {
				store.dispatch(getFriendsClosets(friendIds));
			});

		// this.setState({
		// 	showGoalsInfoModal:
		// 		!this.state.user.user.costPerWearGoal &&
		// 		!this.state.user.user.monthlybudgetGoal &&
		// 		!this.state.user.user.percentageWardWornGoal &&
		// 		!this.state.showOutfitInfoModal,
		// });

		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	componentDidUpdate(prevProps, prevState) {
		// if (prevState.closet.dresses !== this.state.closet.dresses) {
		// 	this.handleShuffleAgainClick();
		// }

		// if (prevState.outfit.outfits !== this.state.outfit.outfits) {
		// 	this.setState({
		// 		showOutfitInfoModal: Boolean(!this.state.outfit.outfits.length),
		// 	});
		// }

		if (prevState.closet.publicArticles !== this.state.closet.publicArticles) {
			this.setState({
				likedArticleIds: this.state.user.user.likedArticleIds,
			});
		}
	}

	// showOutfitModal() {
	// 	return this.state.showOutfitInfoModal;
	// }

	// closeOutfitInfoModal() {
	// 	this.setState({
	// 		showOutfitInfoModal: false,
	// 	});
	// }

	// closeGoalsInfoModal() {
	// 	this.setState({
	// 		showGoalsInfoModal: false,
	// 		showCPWInfoModal: !this.state.user.user.costPerWearGoal && !this.state.showOutfitInfoModal,
	// 		// !this.state.showGoalsInfoModal,
	// 	});
	// }

	// closeCPWInfoModal() {
	// 	this.setState({
	// 		showCPWInfoModal: false,
	// 	});
	// }

	componentWillUnmount() {
		this.unsubscribe();
	}

	// handleShuffleAgainClick() {
	// 	const { randomDress, randomShoes, randomTop, randomBottom } = getRandomOutfit(
	// 		this.state.closet
	// 	);
	// 	this.setState({
	// 		randomTop: randomTop,
	// 		randomBottom: randomBottom,
	// 		randomShoes: randomShoes,
	// 		randomDress: randomDress,
	// 	});
	// }

	async toggleArticleLike(articleId) {
		const userId = this.state.user.user.id;

		// if it's already liked, remove like from front & back end:
		if (this.state.likedArticleIds && this.state.likedArticleIds.includes(articleId)) {
			this.setState({
				likedArticleIds: this.state.likedArticleIds.filter((id) => id !== articleId),
			});

			try {
				await axios.put(`/api/users/${userId}`, {
					likedId: articleId,
					addId: false,
				});
			} catch (err) {
				console.error(err);
			}
		} else {
			// if it's not already liked, add like to front & back end:
			this.setState({
				likedArticleIds: this.state.likedArticleIds
					? [...this.state.likedArticleIds, articleId]
					: [articleId],
			});

			try {
				await axios.put(`/api/users/${userId}`, {
					likedId: articleId,
					addId: true,
				});
			} catch (err) {
				console.error(err);
			}
		}
	}

	render() {
		const isRandomOutfitToShow =
			this.state.randomBottom ||
			this.state.randomShoes ||
			this.state.randomDress ||
			this.state.randomTop;

		const userId = this.state.user.user.id;

		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__title`}>welcome {this.props.name || ''}!</div>

				{/* {isRandomOutfitToShow && (
					<>
						<div className={`${BASE_CLASS}__section-title first-title`}>
							want to shop your closet? here's an outfit idea:
						</div>
						<DisplayRandomOutfit
							top={this.state.randomTop}
							bottom={this.state.randomBottom}
							shoes={this.state.randomShoes}
							dress={this.state.randomDress}
							handleShuffleAgainClick={this.handleShuffleAgainClick}
							handleCloseModalClick={() => {}}
							BASE_CLASS="home-random-outfit"
						/>
					</>
				)} */}

				{/* {!!this.state.friends.friendsClosets.length && (
					<div>
						<div className={`${BASE_CLASS}__section-title`}>
							want to raid a friend's closet? here are the latest additions from your crew:
						</div>
						<div className={`${BASE_CLASS}__recents`}>
							{this.state.friends.friendsClosets.map((article) => (
								<ArticleCard article={article} cardType="friendArticle" key={article.id} />
							))}
						</div>
						<Link to="/friends" className={`${BASE_CLASS}__link friends`}>
							see all friends' closets
						</Link>
					</div>
				)} */}

				{!!this.state.closet.publicArticles.length && (
					<div>
						<div className={`${BASE_CLASS}__section-title`}>
							want to rent something? here are some brand new listings:
						</div>
						<div className={`${BASE_CLASS}__recents`}>
							{this.state.closet.publicArticles.map((article) => (
								<ArticleCard
									article={article}
									key={article.id}
									toggleArticleLike={this.toggleArticleLike}
									likedArticleIds={this.state.likedArticleIds}
								/>
							))}
						</div>
						<Link to="/rent" className={`${BASE_CLASS}__link`}>
							see all rentals
						</Link>
					</div>
				)}
			</div>
		);
	}
}

const mapState = (state) => {
	return {
		email: state.user.user.email,
		name: state.user.user.name,
	};
};

export default connect(mapState)(Home);
