import React, { Component } from 'react';
import store from '../../store';
import './index.scss';
import Review from '../atoms/Review';
const BASE_CLASS = 'lenders';
import { getWardrobe } from '../../store/closets';
import axios from 'axios';
import { Link } from 'react-router-dom';
import getAverageUserReviews from '../../utils/getAverageUserReviews';

class Lenders extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			orderedLenders: [],
		};

		// this.getUsers = this.getUsers.bind(this);
		// this.handleFollow = this.handleFollow.bind(this);
		// this.transformLenders = this.transformLenders.bind(this);
	}

	componentDidMount() {
		// this.getUsers();
		// FIXME: update to Ids for prod users. these are hard-coded:
		// store.dispatch(getWardrobe(174));
		// store.dispatch(getWardrobe(3));
		// store.dispatch(getWardrobe(178));
		// store.dispatch(getWardrobe(173));

		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	// // TODO: map through 4 wardrobes that exist on state - 1) find userId for wardrobe.articles[0] - then send this wardrobe index to getAverageUserReviews util function that returns reviews

	// 	if (prevState.closets.wardrobes !== this.state.closets.wardrobes) {
	// 		this.transformLenders(this.state.closets.wardrobes);
	// 	}
	// }

	// transformLenders(wardrobes) {
	// 	let lenderOne;
	// 	let lenderTwo;
	// 	let lenderThree;
	// 	let lenderFour;

	// 	// TODO: lender 1
	// 	if (wardrobes[0] & wardrobes[0].length) {
	// 		const wardrobeOne = wardrobes?.filter((wardrobe) => wardrobe[0]?.userId === 174);
	// 		lenderOne = this.state.lenders?.filter((lender) => lender.id === 174)?.[0];

	// 		if (wardrobeOne[0]) {
	// 			let [lenderReviewsAverage, lenderReviewsNumber] = getAverageUserReviews(wardrobeOne?.[0]);

	// 			lenderOne = {
	// 				...lenderOne,
	// 				lenderReviewsAverage,
	// 				lenderReviewsNumber,
	// 			};
	// 		}
	// 	}

	// 	// TODO: lender 2
	// 	if (wardrobes[1] && wardrobes[1].length) {
	// 		const wardrobeTwo = wardrobes?.filter((wardrobe) => wardrobe[1]?.userId === 3);
	// 		lenderTwo = this.state.lenders?.filter((lender) => lender.id === 3)?.[0];

	// 		if (wardrobeTwo[0]) {
	// 			let [lenderReviewsAverage, lenderReviewsNumber] = getAverageUserReviews(wardrobeTwo?.[0]);

	// 			lenderTwo = {
	// 				...lenderTwo,
	// 				lenderReviewsAverage,
	// 				lenderReviewsNumber,
	// 			};
	// 		}
	// 	}

	// 	// TODO: lender 3
	// 	if (wardrobes[2] && wardrobes[2].length) {
	// 		const wardrobeThree = wardrobes?.filter((wardrobe) => wardrobe[2]?.userId === 178);
	// 		lenderThree = this.state.lenders?.filter((lender) => lender.id === 178)?.[0];

	// 		if (wardrobeThree[0]) {
	// 			let [lenderReviewsAverage, lenderReviewsNumber] = getAverageUserReviews(wardrobeThree?.[0]);

	// 			lenderThree = {
	// 				...lenderThree,
	// 				lenderReviewsAverage,
	// 				lenderReviewsNumber,
	// 			};
	// 		}
	// 	}

	// 	// TODO: lender 4
	// 	if (wardrobes[3] && wardrobes[3].length) {
	// 		const wardrobeFour = wardrobes?.filter((wardrobe) => wardrobe[1]?.userId === 173);
	// 		lenderFour = this.state.lenders?.filter((lender) => lender.id === 173)?.[0];

	// 		if (wardrobeFour[0]) {
	// 			let [lenderReviewsAverage, lenderReviewsNumber] = getAverageUserReviews(wardrobeFour?.[0]);

	// 			lenderFour = {
	// 				...lenderFour,
	// 				lenderReviewsAverage,
	// 				lenderReviewsNumber,
	// 			};
	// 		}
	// 	}

	// 	this.setState({
	// 		lenderOne,
	// 		lenderTwo,
	// 		lenderThree,
	// 		lenderFour,
	// 	});
	// }

	// async getUsers() {
	// 	try {
	// 		const res = await axios.get(`/api/users`);
	// 		const lenders = res.data;

	// 		this.setState({
	// 			lenders: lenders,
	// 		});
	// 	} catch (err) {
	// 		console.error(err);
	// 	}
	// }

	// async handleFollow(lenderId) {
	// 	const userId = this.state.user?.user?.id;

	// 	const first = axios.put(`/api/users/${userId}`, {
	// 		followingId: lenderId,
	// 	});

	// 	const second = axios.put(`/api/users/${lenderId}`, {
	// 		followerId: userId,
	// 	});

	// 	try {
	// 		await Promise.all([first, second]);
	// 	} catch (err) {
	// 		console.error(err);
	// 	}
	// }

	render() {
		// let lender = this.state.lenderOne || [];
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__top`}>
					<small>lender spotlight</small>
					<h3>Kissed by Bo</h3>
					<h4>
						<i>
							<b>Braisha Owens</b> is a fashion designer, model, stylist and speaker from Columbus,
							Ohio. We sat down to ask her about the fashion industry, making wearable art, and
							getting dressed.
						</i>
					</h4>
				</div>

				<div className={`${BASE_CLASS}__section`}>
					<div>
						<div>
							<img src="images/braisha1.png" />
							<small>TIED DRESS, $125/WEEK</small>
						</div>
						<div>
							<h5>How did you get into fashion design?</h5>
							<h6>
								I have to this day sketchbooks from when I was like in the fourth grade, just
								sketching out ideas and outfits. When I talk to the kids at the schools nowadays I
								often tell them, "Guys, whatever you want to be right now, hold on to that because
								life happens and it may not act like it wants you to do it." But you have your sense
								of purpose when you’re young. <br /> <br />I did personal styling for a while, as my
								bread and butter. And then it's crazy but when Virgil Abloh, rest in peace, passed
								away, you would think my dad knew him. He called me on the phone was like, Braisha,
								it's time for you to start sewing. I was like, “How do you know him, daddy?” He was
								like, “I'm just saying, it's time for you to start sewing.” That spoke volumes to
								me.
							</h6>
						</div>
					</div>
				</div>

				<div className={`${BASE_CLASS}__quote-lockup`}>
					<div>
						<img src="images/braisha4.png" />
						<small>DENIM TULLE SKIRT, $150/WEEK</small>
					</div>
					<h3>
						“I can have a paper bag on right now and have everybody in the room wanting to wear it.
						It's not what I have on; It's how I wear it. <br />
						<br /> Confidence shines through everything.”
					</h3>
				</div>
				<Link to={`/lender/${835}`}>RENT KISSED BY BO</Link>

				<div className={`${BASE_CLASS}__section`}>
					<div>
						<div>
							<img src="images/braisha3.png" />
							<small>DENIM CORSET, $150/WEEK </small>
						</div>
						<div>
							<h5>Why denim? And where do you source your fabric?</h5>
							<h6>
								Why denim? Denim is my canvas. You can just do so much to it. You can bleach it,
								distress it, paint on it. I like the contrast of it as well. Like, denim couture,
								you never see that, right? <br /> <br />
								How do I source it? Listen girl, people give me denim all day, every day. I can tell
								you, I never buy denim.
							</h6>
							<h5>
								You say that the first thing you put on has to be confidence. You can't use retail
								therapy to make you feel good; it has to be the reverse.
							</h5>
							<h6>
								I call that fashion wellness. And that’s the philanthropic arm of my brand. It's
								called the <a href="https://www.thepaperbagchronicles.org/">Paperbag Chronicles.</a>{' '}
								I tell people I can have a paper bag on right now and have everybody in the room
								wanting to wear it. It's not what I have on; it's how I wear it. Confidence shines
								through everything.
							</h6>
						</div>
					</div>
				</div>
				<Link to="/lend">Become a lender</Link>
				<div className={`${BASE_CLASS}__paragraphs`}>
					<h5>Is getting dressed stressful for you? How do you manage your own wardrobe?</h5>
					<h6>
						Oh, I love getting dressed. I love to put clothes on. I really enjoy thinking, what can
						I wear with this? In my family, we love to put clothes on. Like, my mom, I call her the
						fashion commissioner because I'm the police. My dad, oh my goodness, he'll take a
						picture and be like, look what I wore to church today. My parents are the cutest things
						ever. They'll be like, we wore this to church, it was cute. It's embedded in us.
					</h6>
					<h5>It's an art for you.</h5>
					<h6>
						It is. It's an art for us, something that we really take pride in. Like, when you see us
						out and about, we got something on. We’re out here. We're cute. We're doing it. We're
						confident. It's a thing for sure.
					</h6>
				</div>
			</div>
		);
	}
}

export default Lenders;
