import React, { Fragment } from 'react';
import './index.scss';
const BASE_CLASS = 'confirmation-modal';
import { WarningTriangle, MessageArrow } from '../../../../public/icons/index';

export default function ConfirmationModal({
	text,
	handleAccept,
	handleCancel,
	continueCtaText,
	cancelCtaText,
	header,
	customClass,
	html,
	icon,
}) {
	return (
		<Fragment>
			<div className={`${BASE_CLASS}__modalcontainer`} />
			<div className={`${BASE_CLASS}__inner`}>
				<h1>{header}</h1>
				{icon === 'warning' && <WarningTriangle />}
				{icon == 'flight-arrow' && <MessageArrow />}
				{html && <div dangerouslySetInnerHTML={html} />}
				{text && <p>{text}</p>}
				<div className={`${BASE_CLASS}__inner__buttons`}>
					<button type="button" onClick={handleCancel}>
						{cancelCtaText}
					</button>
					<button type="button" onClick={handleAccept}>
						{continueCtaText}
					</button>
				</div>
			</div>
		</Fragment>
	);
}
