import React from 'react';

const Shipping = () => (
	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4394_63423)">
			<path
				d="M9.94911 1.875H0.847656V10H9.94911V1.875Z"
				stroke="black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.94922 5H12.3763L14.1966 6.875V10H9.94922V5Z"
				stroke="black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.57843 13.125C4.4162 13.125 5.09534 12.4254 5.09534 11.5625C5.09534 10.6996 4.4162 10 3.57843 10C2.74067 10 2.06152 10.6996 2.06152 11.5625C2.06152 12.4254 2.74067 13.125 3.57843 13.125Z"
				stroke="black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.4661 13.125C12.3039 13.125 12.983 12.4254 12.983 11.5625C12.983 10.6996 12.3039 10 11.4661 10C10.6284 10 9.94922 10.6996 9.94922 11.5625C9.94922 12.4254 10.6284 13.125 11.4661 13.125Z"
				stroke="black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4394_63423">
				<rect width="14.5623" height="15" fill="white" transform="translate(0.241211)" />
			</clipPath>
		</defs>
	</svg>
);

export default Shipping;
