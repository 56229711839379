import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updateDress } from '../../store/closet';
import store from '../../store';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { brands, categories, staticTags, sizes, subcategoryConstants, colors } from '../Add/utils';
import AddSuccess from '../modals/AddSuccessModal';
import Calendar from 'react-calendar';
import './index.scss';
import Checkbox from '../atoms/Checkbox';
const BASE_CLASS = 'edit-form';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import getWeek from '../../utils/getWeekFromDay';
import isThursday from 'date-fns/isThursday';
import nextThursday from 'date-fns/nextThursday';
import { Move, Trash } from '../../../public/icons';
import history from '../../../client/history';
import { setIsModalOpen } from '../../store/utils';
import ConfirmationModal from '../modals/ConfirmationModal';
import axios from 'axios';

// fake data generator
const getThumbnails = (urls) =>
	urls
		? Array.from({ length: urls?.length }, (v, k) => k).map((k) => ({
				id: `item-${k}`,
				url: urls[k],
		  }))
		: null;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

class Update extends Component {
	constructor(dress) {
		super(dress);
		this.state = {
			...store.getState(),
			imageUrls: this.props.location.state.imageUrls,
			thumbnailUrls: this.props.location.state.thumbnailUrls,
			items: getThumbnails(this.props.location.state.thumbnailUrls),
			name: this.props.location.state.name,
			description: this.props.location.state.description,
			wearCount: this.props.location.state.wearCount,
			cost: this.props.location.state.cost,
			submitted: false,
			category: this.props.location.state.category,
			subcategories: [],
			subCategoryOptions: subcategoryConstants[this.props.location.state.category],
			brand: this.props.location.state.brand,
			error: '',
			tags: [...this.props.location.state.tags],
			newTag: '',
			prevTags: new Set(),
			size: this.props.location.state.size,
			public: this.props.location.state.public,
			sellable: this.props.location.state.list?.includes('sellable'),
			rentable: this.props.location.state.rentable,
			comments: [],
			currentDailyRentalPrice: this.props.location.state.currentDailyRentalPrice,
			// delivery: this.props.location.state.delivery,
			terms: this.props.location.state.rentable, // if it was previously rentable, T&C were checked
			calendarDatesAvailable: this.props.location.state.calendarDatesAvailable,
			availableForPickup: this.props.location.state.availableForPickup,
			availableForShip: this.props.location.state.availableForShip,
			availableForShipping: this.props.location.state.list?.includes('shippable'),
			hasRentalRequiredFieldError: false,
			lenderZipCode: this.props.location.state.lenderZipCode,
			colors: [],
			showPurchaseDatePicker: false,
			sellprice: Number(this.props.location.state.sellprice),
		};
		// set default thumbnail to be existing image
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isCategoryActive = this.isCategoryActive.bind(this);
		this.isTagActive = this.isTagActive.bind(this);
		this.handleChangeArray = this.handleChangeArray.bind(this);
		this.toggleCheckbox = this.toggleCheckbox.bind(this);
		this.handleCalendarChange = this.handleCalendarChange.bind(this);
		this.onInput = this.onInput.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.removeImage = this.removeImage.bind(this);
		this.handleAccept = this.handleAccept.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handlePurchaseDateChange = this.handlePurchaseDateChange.bind(this);
	}

	componentDidMount() {
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	isTagActive(tag) {
		return this.state.tags.includes(tag);
	}

	handleChangeArray(event) {
		return !this.state.tags.includes(event.target.value)
			? this.setState({
					[event.target.name]: [...this.state[event.target.name], event.target.value],
			  })
			: this.setState({
					[event.target.name]: this.state[event.target.name].filter(
						(tag) => tag !== event.target.value
					),
			  });
	}

	showModal() {
		store.dispatch(setIsModalOpen(true));
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}

	async handleAccept() {
		const id = this.props.match.params.dressId;
		store.dispatch(setIsModalOpen(false));
		await axios.delete(`/api/closet/${id}`).then(() => history.push('/closet'));
	}

	handleCancel() {
		store.dispatch(setIsModalOpen(false));
	}

	showWidget = (widget) => {
		widget.open();
	};

	checkUploadResult = (resultEvent) => {
		if (resultEvent.event === 'success') {
			// console.log(this.PaymentResponse.currentUser.id);
			this.PaymentResponse.postPhoto({
				user_id: this.props.currentUser.id,
				caption: '',
				url: resultEvent.info.secure_url,
			}).then(this.props.history.push(`/profile`));
		}
	};

	getImageUrl(url) {
		// let uploadIndex = url.indexOf('/upload');

		// return url.slice(0, uploadIndex + 7) + '/a_ignore' + url.slice(uploadIndex + 7);
		return url;
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			error: '',
			subCategoryOptions: subcategoryConstants[event.target.value],
		});
	}

	toggleCheckbox(property) {
		this.setState({
			[property]: !this.state[property],
		});
	}

	onInput(id) {
		var val = document.getElementById(id).value;

		this.setState({
			[id]: val,
		});
	}

	handleCalendarChange(value, event) {
		const timeStampStartOfDay = getUnixTime(startOfDay(value));
		const daysOfSelectedWeek = getWeek(value).weekTimestamps;
		const prevAvailableDates = this.state.calendarDatesAvailable;

		// const prevAvailableDates = this.props.location.state.calendarDatesAvailable;
		const dateAlreadySelected = prevAvailableDates
			? prevAvailableDates.includes(timeStampStartOfDay)
			: null;

		if (dateAlreadySelected) {
			this.setState({
				calendarDatesAvailable: prevAvailableDates.filter((d) => !daysOfSelectedWeek.includes(d)),
			});
		} else {
			this.setState({
				calendarDatesAvailable: prevAvailableDates
					? prevAvailableDates.concat([...daysOfSelectedWeek])
					: [...daysOfSelectedWeek],
			});
		}
	}

	displayItemAvailabilityClass({ activeStartDate, date, view }) {
		const timeStampStartOfDay = getUnixTime(startOfDay(date));
		const timeStampStartOfToday = getUnixTime(startOfDay(Date.now()));
		const timeStampNextThursday = getUnixTime(nextThursday(Date.now()));

		if (Number(timeStampStartOfDay) < Number(timeStampStartOfToday)) {
			return 'past';
		} else if (
			!isThursday(Date.now()) &&
			Number(timeStampStartOfDay) < Number(timeStampNextThursday)
		) {
			return 'past';
		} else if (
			(this.state.calendarDatesAvailable &&
				this.state.calendarDatesAvailable.includes(timeStampStartOfDay)) ||
			(this.props.location.state.calendarDatesAvailable &&
				this.props.location.state.calendarDatesAvailable.includes(timeStampStartOfDay))
		) {
			return 'available';
		}
	}

	displayItemAvailability({ activeStartDate, date, view }) {
		const timeStampStartOfDay = getUnixTime(startOfDay(date));
		const timeStampStartOfToday = getUnixTime(startOfDay(Date.now()));
		const timeStampNextThursday = getUnixTime(nextThursday(Date.now()));

		if (isThursday(Date.now()) && Number(timeStampStartOfDay) < Number(timeStampStartOfToday)) {
			return true;
		} else if (
			!isThursday(Date.now()) &&
			Number(timeStampStartOfDay) < Number(timeStampNextThursday)
		) {
			return true;
		} else if (
			(this.state.calendarDatesAvailable &&
				this.state.calendarDatesAvailable.includes(timeStampStartOfDay)) ||
			(this.props.location.state.calendarDatesAvailable &&
				this.props.location.state.calendarDatesAvailable.includes(timeStampStartOfDay))
		) {
			return false;
		}
	}

	handleSubmit(event) {
		event.preventDefault();
		const dressId = this.props.match.params.dressId;
		if (!this.state.name && !this.state.imageUrls && !this.state.rentable) {
			this.setState({
				error: 'please enter an item name or image',
				hasRentalRequiredFieldError: false,
			});
		} else if (
			this.state.rentable &&
			(!this.state.currentDailyRentalPrice ||
				// !this.state.delivery ||
				// !this.state.terms ||
				!this.state.name ||
				!this.state.imageUrls ||
				!this.state.category ||
				!this.state.brand ||
				!this.state.size ||
				!this.state.lenderZipCode)
		) {
			this.setState({
				error: 'rentable items must have required information',
				hasRentalRequiredFieldError: true,
			});
		} else if (this.state.cost && isNaN(this.state.cost)) {
			this.setState({
				error: 'the item cost must be a number',
				hasRentalRequiredFieldError: false,
			});
		} else if (this.state.wearCount && isNaN(this.state.wearCount)) {
			this.setState({
				error: 'the item wear count must be a number',
				hasRentalRequiredFieldError: false,
			});
		} else {
			if (this.state.newTag) {
				this.state.tags.push(this.state.newTag);
			}

			this.props.updateDress(this.state, dressId).then(() => this.props.history.push('/lend'));
		}
	}

	removeImage(e, item) {
		e.preventDefault();

		const urlToDelete = this.state.items.filter((i) => i.id === item.id)[0].url;
		const indexToRemove = this.state.thumbnailUrls.indexOf(urlToDelete);

		const items = this.state.items.filter((i) => i.id !== item.id);
		const firstHalf = this.state.thumbnailUrls.slice(0, indexToRemove);
		const secondHalf = this.state.thumbnailUrls.slice(indexToRemove + 1);
		const thumbnails = firstHalf.concat(secondHalf);

		const firstHalfImgs = this.state.imageUrls.slice(0, indexToRemove);
		const secondHalfImgs = this.state.imageUrls.slice(indexToRemove + 1);
		const newImages = firstHalfImgs.concat(secondHalfImgs);

		this.setState({
			items,
			thumbnailUrls: thumbnails,
			imageUrls: newImages,
		});
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(this.state.items, result.source.index, result.destination.index);
		const thumbnails = reorder(
			this.state.thumbnailUrls,
			result.source.index,
			result.destination.index
		);
		const images = reorder(this.state.imageUrls, result.source.index, result.destination.index);

		this.setState({
			items,
			thumbnailUrls: thumbnails,
			imageUrls: images,
		});
	}

	isCategoryActive(tag) {
		return this.state.category === tag;
	}

	handlePurchaseDateChange(value, event) {
		this.setState({
			datepurchased: value,
		});
	}

	render() {
		let widget = window.cloudinary.createUploadWidget(
			{
				cloudName: 'micaelascloud',
				uploadPreset: 'ahb8abmz',
				showAdvancedOptions: true,
				maxImageHeight: 700,
				maxImageWidth: 700,
			},
			(error, result) => {
				if (!error && result && result.event === 'success') {
					// console.log('Done! Here is the image info: ', result, result.info);
					//imageArr.push(result.info.url)

					this.setState({
						items: getThumbnails(
							this.state.thumbnailUrls
								? [...this.state.thumbnailUrls, this.getImageUrl(result.info.thumbnail_url)]
								: [this.getImageUrl(result.info.thumbnail_url)]
						),
						imageUrls: this.state.imageUrls
							? [...this.state.imageUrls, this.getImageUrl(result.info.url)]
							: [this.getImageUrl(result.info.url)],
						thumbnailUrls: this.state.thumbnailUrls
							? [...this.state.thumbnailUrls, this.getImageUrl(result.info.thumbnail_url)]
							: [this.getImageUrl(result.info.thumbnail_url)],
					});
				}
			}
		);
		const dressToEdit = this.props.location.state;
		// console.log({ dressToEdit });
		return (
			<div className={BASE_CLASS}>
				{this.state.submitted ? (
					<AddSuccess text="Article has been edited." path="lend" />
				) : (
					<Fragment>
						<h3>Edit an item</h3>
						<form className={`${BASE_CLASS}__form`} onSubmit={this.handleSubmit}>
							<DragDropContext onDragEnd={this.onDragEnd}>
								<Droppable droppableId="droppable" direction="horizontal">
									{(provided, snapshot) => (
										<div
											className={`${BASE_CLASS}__form__thumbnails`}
											ref={provided.innerRef}
											{...provided.droppableProps}>
											{this.state.items &&
												this.state.items.map((item, index) => (
													<Draggable key={item.id} draggableId={item.id} index={index}>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}>
																<div className={`${BASE_CLASS}__form__thumbnails__container`}>
																	<Move />
																	<img src={item.url} />
																	<button onClick={(e) => this.removeImage(e, item)}>
																		<Trash />
																	</button>
																</div>
															</div>
														)}
													</Draggable>
												))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>

							<div
								className={classnames('upload', {
									'rental-required-field-error': this.state.hasRentalRequiredFieldError,
								})}
								onClick={() => this.showWidget(widget)}>
								Upload image
							</div>
							<h4>Description</h4>
							<small
								className={classnames({
									'rental-required-field-error': this.state.hasRentalRequiredFieldError,
								})}>
								ITEM NAME *
							</small>
							<input
								name="name"
								type="text"
								value={this.state.name}
								onChange={this.handleChange}
								placeholder={dressToEdit.name}
							/>

							<small>DESCRIPTION</small>
							<input
								placeholder={dressToEdit.details}
								name="description"
								type="text"
								value={this.state.description}
								onChange={this.handleChange}
							/>

							<small
								className={classnames({
									'rental-required-field-error': this.state.hasRentalRequiredFieldError,
								})}>
								SIZE *
							</small>

							<input
								type="text"
								id="size"
								list="sizes"
								name="size"
								value={this.state.size}
								onChange={() => this.onInput('size')}
							/>
							<datalist id="sizes">
								{sizes.map((item, key) => (
									<option key={key} value={item} />
								))}
							</datalist>

							<small
								className={classnames({
									'rental-required-field-error': this.state.hasRentalRequiredFieldError,
								})}>
								BRAND *
							</small>
							<input
								type="text"
								id="brand"
								list="datalist"
								name="brand"
								value={this.state.brand}
								onChange={() => this.onInput('brand')}
								className={`${BASE_CLASS}__form__input`}
								placeholder={dressToEdit.brand}
							/>
							<datalist id="datalist">
								{brands.map((item, key) => (
									<option key={key} value={item} />
								))}
							</datalist>

							<small
								className={classnames({
									'rental-required-field-error': this.state.hasRentalRequiredFieldError,
								})}>
								{' '}
								CATEGORY *
							</small>
							{/* {categories.map((item, key) => (
								<button
									key={key}
									value={item}
									name="category"
									type="button"
									className={classnames(`${BASE_CLASS}__form__category`, {
										[`${BASE_CLASS}__form__category--selected`]: event.target.value
											? event.target.value === item
											: this.state.category === item,
									})}
									onClick={this.handleChange}>
									{item}
								</button>
							))} */}

							{categories.map((item, key) => (
								<button
									key={key}
									value={item}
									name="category"
									type="button"
									className={classnames(`${BASE_CLASS}__form__category`, {
										[`${BASE_CLASS}__form__category--selected`]: this.isCategoryActive(item),
									})}
									onClick={this.handleChange}>
									{item}
								</button>
							))}

							{this.state.category ? (
								<>
									<small
										className={classnames({
											'rental-required-field-error': this.state.hasRentalRequiredFieldError,
										})}>
										SUBCATEGORY (pick all that apply)
									</small>

									{this.state.subCategoryOptions &&
										this.state.subCategoryOptions.map((item, key) => (
											<button
												key={key}
												value={item}
												name="subcategories"
												type="button"
												className={classnames(`${BASE_CLASS}__form__category`, {
													[`${BASE_CLASS}__form__category--selected`]:
														this.state.subcategories && this.state.subcategories.includes(item),
												})}
												onClick={this.handleChangeArray}>
												{item}
											</button>
										))}
								</>
							) : null}

							<small>COLOR *</small>
							{colors.map((color, key) => (
								<button
									key={key}
									value={color}
									name="colors"
									type="button"
									className={classnames(`${BASE_CLASS}__form__category`, {
										// [`${BASE_CLASS}__form__category--selected`]: this.isTagActive(color),
									})}
									onClick={this.handleChangeArray}>
									{color}
								</button>
							))}

							<small>DATE PURCHASED</small>
							<button
								type="button"
								className={`${BASE_CLASS}__form__tag-cta`}
								onClick={() => this.toggleCheckbox('showPurchaseDatePicker')}>
								{this.state.showPurchaseDatePicker ? 'Hide calendar' : 'Add date'}
							</button>

							{this.state.showPurchaseDatePicker && (
								<Calendar
									className={`${BASE_CLASS}__react-calendar-picker`}
									onClickDay={this.handlePurchaseDateChange}
									defaultActiveStartDate={this.state.today}
									// tileClassName={(activeStartDate, date, view) =>
									// 	this.displayPurchaseDateClass(activeStartDate, date, view)
									// }
								/>
							)}

							<small>TAGS</small>

							{staticTags.map((tagArr, key) => (
								<div className="border-line">
									{tagArr.map((tag, key) => (
										<button
											key={key}
											value={tag}
											name="tags"
											type="button"
											className={classnames(`${BASE_CLASS}__form__category`, {
												[`${BASE_CLASS}__form__category--selected`]: this.isTagActive(tag),
											})}
											onClick={this.handleChangeArray}>
											{tag}
										</button>
									))}
									<span />
								</div>
							))}

							<h4>Settings</h4>

							<Checkbox
								label="available to rent"
								onChange={() => this.toggleCheckbox('rentable')}
								checked={!!this.state.rentable}
								labelHasError={this.state.hasRentalRequiredFieldError}
							/>

							<Checkbox
								label="Open to offers to buy"
								onChange={() => this.toggleCheckbox('sellable')}
								checked={!!this.state.sellable}
							/>
							<Checkbox
								label="Make visible to friends?"
								onChange={() => this.toggleCheckbox('public')}
								checked={!!this.state.public}
								// labelHasError={this.state.hasRentalRequiredFieldError}
							/>

							<small
								className={classnames('sellprice', {
									'rental-required-field-error': this.state.hasRentalRequiredFieldError,
								})}>
								SELL PRICE (optional) *
							</small>
							<input
								type="number"
								id="sellprice"
								name="sellprice"
								value={this.state.sellprice}
								onChange={() => this.onInput('sellprice')}
								className={`${BASE_CLASS}__form__input`}
							/>
							{this.state.rentable ? (
								<>
									<small
										className={classnames({
											'rental-required-field-error': this.state.hasRentalRequiredFieldError,
										})}>
										YOUR ZIP CODE *
									</small>
									<input
										type="text"
										id="lenderZipCode"
										name="lenderZipCode"
										value={this.state.lenderZipCode}
										onChange={() => this.onInput('lenderZipCode')}
										className={`${BASE_CLASS}__form__input`}
									/>
									<small
										className={classnames({
											'rental-required-field-error': this.state.hasRentalRequiredFieldError,
										})}>
										RENTAL FEE (cost per week - remember you'll be paid in person when you meet up)
										*
									</small>
									<input
										type="text"
										id="currentDailyRentalPrice"
										name="currentDailyRentalPrice"
										value={this.state.currentDailyRentalPrice}
										onChange={() => this.onInput('currentDailyRentalPrice')}
										className={`${BASE_CLASS}__form__input`}
									/>
									<h4>Availability</h4>
									<Checkbox
										label="Available for local pickup"
										onChange={() => this.toggleCheckbox('availableForPickup')}
										checked={!!this.state.availableForPickup}
										labelHasError={this.state.hasRentalRequiredFieldError}
									/>
									<small>borrower meets you in your zip code </small>
									<Checkbox
										label="Available for delivery"
										onChange={() => this.toggleCheckbox('availableForShip')}
										checked={!!this.state.availableForShip}
										labelHasError={this.state.hasRentalRequiredFieldError}
									/>{' '}
									<small>borrower pays $10 fee; delivery service picks up and returns item</small>
									<p>
										Select weeks that your item is available to rent. You'll be sent any rental
										requests and have 24 hours to accept or decline. If you approve a rental, you'll
										message the borrower on site, and arrange a public location to meet up in your
										zip code.
									</p>
									<Checkbox
										label="Available for shipping"
										onChange={() => this.toggleCheckbox('availableForShipping')}
										checked={!!this.state.availableForShipping}
										labelHasError={this.state.hasRentalRequiredFieldError}
									/>
									<small>we'll send you a pre-paid label</small>
									<p>
										Add which weeks your item is available. <br />
										You'll get an email when someone requests to rent your item.
									</p>
									<p>
										Rentals begin Thursdays and end Mondays. <br />
										Delivery and returns happen from Monday to Thursday.
									</p>
									{/* <div className="calendar"> */}
									<Calendar
										className={`${BASE_CLASS}__react-calendar`}
										onClickDay={this.handleCalendarChange}
										defaultActiveStartDate={this.state.today}
										tileClassName={(activeStartDate, date, view) =>
											this.displayItemAvailabilityClass(activeStartDate, date, view)
										}
									/>
									{/* </div> */}
								</>
							) : null}

							{this.state.error && <div className={`${BASE_CLASS}__error`}>{this.state.error}</div>}

							<button className={`${BASE_CLASS}__form__submit`} type="submit">
								save edits
							</button>
							<button
								type="button"
								onClick={() => this.showModal()}
								className={`${BASE_CLASS}__form__remove`}>
								remove
							</button>
							{this.state.utils.isModalOpen ? (
								// <RemoveConfirmationModal
								// 	handleCancel={this.handleCancel}
								// 	handleAccept={this.handleAccept}
								// 	dressId={this.props.match.params.dressId}
								// />
								<ConfirmationModal
									header="Are you sure?"
									icon="warning"
									text="Are you sure you want to remove this item?"
									handleCancel={this.handleCancel}
									handleAccept={this.handleAccept}
									continueCtaText="Yes, delete"
									cancelCtaText="Cancel"
								/>
							) : null}
						</form>
					</Fragment>
				)}
			</div>
		);
	}
}

const mapState = (state) => {
	return {
		dress: state.dress,
	};
};

const mapDispatch = (dispatch) => {
	return {
		updateDress: (dress, id) => dispatch(updateDress(dress, id)),
	};
};

export default connect(mapState, mapDispatch)(Update);

// ** TODO:
// add cloudinary functionality to local state
