import React, { Component } from 'react';
// import { getItemToLendRentals } from '../../store/rentals';
import { getSingleDress } from '../../store/closet';
// import { Link } from 'react-router-dom';
import store from '../../store';
// import axios from 'axios';
import './index.scss';
// import CalendarContainer from '../CalendarContainer';
// import ConfirmationModal from '../modals/ConfirmationModal';
const BASE_CLASS = 'lend-item';
// import Review from '../atoms/Review';
// import fromUnixTime from 'date-fns/fromUnixTime';
import RentalItem from '../RentalItem';
import { Link } from 'react-router-dom';

class LendItem extends Component {
	constructor(props) {
		super();
		this.state = {
			...store.getState(),
			// pendingRentals: [],
			// upcomingRentals: [],
			// currentRentals: [],
			// pastRentals: [],
			// showConfirmRentalConfirmationModal: false,
			// showDeclineRentalConfirmationModal: false,
			// rentalIdBeingHandled: null,
			// rentalDatesBeingHandled: [],
		};
		// this.sortRentals = this.sortRentals.bind(this);
		// this.handleConfirmRequest = this.handleConfirmRequest.bind(this);
		// this.handleDeclineRequest = this.handleDeclineRequest.bind(this);
		// this.showConfirmationModal = this.showConfirmationModal.bind(this);
		// this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
	}

	componentDidMount() {
		const articleId = this.props.match.params.articleId;
		// const lenderId = this.state.user.user.id;
		// store.dispatch(getItemToLendRentals(articleId, lenderId));
		store.dispatch(getSingleDress(articleId));
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
		// this.sortRentals();
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	if (prevState.rentals.rentals !== this.state.rentals.rentals) {
	// 		this.sortRentals();
	// 	}
	// }

	// showConfirmationModal(modal, rentalId, rentalDates) {
	// 	window.scrollTo({
	// 		top: 0,
	// 		behavior: 'smooth',
	// 	});

	// 	this.setState({
	// 		[modal]: true,
	// 		rentalIdBeingHandled: rentalId,
	// 		rentalDatesBeingHandled: rentalDates,
	// 	});
	// }

	// closeConfirmationModal(modal) {
	// 	this.setState({
	// 		[modal]: false,
	// 		rentalIdBeingHandled: null,
	// 	});
	// }

	// sortRentals() {
	// 	if (this.state.rentals && this.state.rentals?.rentals) {
	// 		const rentals = this.state.rentals?.rentals;

	// 		this.setState({
	// 			pendingRentals: rentals
	// 				.filter((rental) => rental.status === 'PENDING')
	// 				.map((rental) => {
	// 					return {
	// 						...rental,
	// 						firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
	// 						lastDateOfRental: fromUnixTime(rental.lastDateOfRental).toDateString(),
	// 					};
	// 				}),
	// 			upcomingRentals: rentals
	// 				.filter(
	// 					(rental) =>
	// 						rental.status === 'CONFIRMED_PAID' || rental.status === 'CONFIRMED_AWAITING_PAYMENT'
	// 				)
	// 				.map((rental) => {
	// 					return {
	// 						...rental,
	// 						firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
	// 						lastDateOfRental: fromUnixTime(rental.lastDateOfRental).toDateString(),
	// 					};
	// 				}),

	// 			currentRentals: rentals
	// 				.filter((rental) => rental.status === 'ACTIVE')
	// 				.map((rental) => {
	// 					return {
	// 						...rental,
	// 						firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
	// 						lastDateOfRental: fromUnixTime(rental.lastDateOfRental).toDateString(),
	// 					};
	// 				}),
	// 			pastRentals: rentals
	// 				.filter((rental) => rental.status === 'COMPLETED')
	// 				.map((rental) => {
	// 					return {
	// 						...rental,
	// 						firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
	// 						lastDateOfRental: fromUnixTime(rental.lastDateOfRental).toDateString(),
	// 					};
	// 				}),
	// 		});
	// 	}
	// }

	// async handleConfirmRequest() {
	// 	try {
	// 		const articleId = this.props.match.params.articleId;
	// 		await axios.put(`/api/rentals/${articleId}`, {
	// 			action: 'confirm',
	// 			rentalId: this.state.rentalIdBeingHandled,
	// 			articleId,
	// 			...this.state.closet.dress,
	// 		});

	// 		await axios
	// 			.put(`/api/closet/${articleId}`, {
	// 				unavailableDates: this.state.rentalDatesBeingHandled,
	// 			})
	// 			.then((response) => {
	// 				this.closeConfirmationModal('showConfirmRentalConfirmationModal');
	// 				window.location.reload(false);
	// 			});
	// 	} catch (err) {
	// 		console.error(err);
	// 	}
	// }

	// async handleDeclineRequest() {
	// 	const articleId = this.props.match.params.articleId;
	// 	const rentalId = this.state.rentalIdBeingHandled;

	// 	try {
	// 		await axios
	// 			.put(`/api/rentals/${articleId}`, {
	// 				action: 'decline',
	// 				rentalId,
	// 			})
	// 			.then((response) => {
	// 				this.closeConfirmationModal('showDeclineRentalConfirmationModal');
	// 				window.location.reload(false);
	// 			});
	// 	} catch (err) {
	// 		next(err);
	// 	}
	// }

	render() {
		const article = this.state.closet.dress;

		if (article) {
			return (
				<>
					<div className={BASE_CLASS}>
						{!!article.rentable && (
							<div className={`${BASE_CLASS}__banner`}>This item is rentable</div>
						)}

						{!article.rentable && (
							<div className={`${BASE_CLASS}__banner private`}>This item is private</div>
						)}

						<div className={`${BASE_CLASS}__ctas`}>
							<Link to={{ pathname: `/closet/${article.id}/edit`, state: this.state.closet.dress }}>
								edit rental
							</Link>
							<Link to={{ pathname: `/activity` }}>see activity</Link>
						</div>
					</div>
					<RentalItem />

					{/* {this.state.showConfirmRentalConfirmationModal && (
						<ConfirmationModal
							html={{
								__html:
									"<h2>You're confirming this rental</h2><p>and agree to ship or coordinate delivery of this item. You'll be paid within 24 hours of the rental period.</p>",
							}}
							handleAccept={() => this.handleConfirmRequest()}
							handleCancel={() => this.closeConfirmationModal('showConfirmRentalConfirmationModal')}
							continueCtaText="Sounds good"
							cancelCtaText="Cancel"
						/>
					)} */}
					{/* {this.state.showDeclineRentalConfirmationModal && (
						<ConfirmationModal
							html={{ __html: '<p>Are you sure you want to decline this rental?</p>' }}
							handleAccept={() => this.handleDeclineRequest()}
							handleCancel={() => this.closeConfirmationModal('showDeclineRentalConfirmationModal')}
							continueCtaText="Yes, Decline Rental"
							cancelCtaText="Cancel"
						/>
					)} */}
					{/* <div className={BASE_CLASS} key={article.id}>
						<div className={`${BASE_CLASS}__info`}>
							<h3>{article.name}</h3>
							{article.imageUrls && <img src={article.imageUrls[0]} />}
							<p>{article.brand}</p>
							<p>${article.currentDailyRentalPrice} per week</p>
							<p>{article.size}</p>
							<Link to={{ pathname: `/closet/${article.id}/edit`, state: this.state.closet.dress }}>
								edit rental
							</Link>
							<h4>Reviews you've recieved for this item</h4>
							{article.borrowerOverallReview ? (
								<>
									<p>Overall</p>
									<Review reviewStarNumber={article.borrowerOverallReview} />
								</>
							) : null}
							{article.borrowerDescriptionReview ? (
								<>
									<p>Accuracy of item description</p>
									<Review reviewStarNumber={article.borrowerDescriptionReview} />
								</>
							) : null}
							{article.borrowerDeliveryReview ? (
								<>
									<p>Lender communication & delivery</p>
									<Review reviewStarNumber={article.borrowerDeliveryReview} />
								</>
							) : null}
							{!article.borrowerDeliveryReview &&
								!article.borrowerDescriptionReview &&
								!article.borrowerOverallReview && <p>No one has reviewed this item yet</p>}
						</div>
						<div className={`${BASE_CLASS}__dashboard`}>
							{!!this.state.pendingRentals.length && (
								<>
									<h3>PENDING REQUESTS</h3>
									{this.state.pendingRentals.map((rental) => (
										<div key={rental.id} className={`${BASE_CLASS}__card`}>
											<h5>
												{rental.firstDateOfRental} -{rental.lastDateOfRental}
											</h5>

											<p> ${rental.priceRented} / week</p>
										

											<button
												onClick={() =>
													this.showConfirmationModal(
														'showConfirmRentalConfirmationModal',
														rental.id,
														[rental.firstDateOfRental, rental.lastDateOfRental]
													)
												}>
												confirm
											</button>

											<button
												onClick={() =>
													this.showConfirmationModal(
														'showDeclineRentalConfirmationModal',
														rental.id
													)
												}>
												decline
											</button>
										</div>
									))}
								</>
							)}

							{!!this.state.upcomingRentals.length && (
								<>
									<h3> UPCOMING REQUESTS</h3>
									{this.state.upcomingRentals.map((rental) => (
										<div key={rental.id} className={`${BASE_CLASS}__card`}>
											<h5>
												{rental.firstDateOfRental} - {rental.firstDateOfRental}
											</h5>
											<p>${rental.priceRented} /</p> week
											<Link to={`/rental/${rental.id}`}>contact borrower</Link>
										</div>
									))}
								</>
							)}
							<div className={`${BASE_CLASS}__calendar`}>
								<p>availability:</p>
								{article.id ? <CalendarContainer articleId={article.id} /> : null}
							</div>
						</div>
					</div>
			 */}
				</>
			);
		} else {
			return <p>Sorry no article found</p>;
		}
	}
}

export default LendItem;
