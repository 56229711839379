import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import store from '../../../../store';
import './index.scss';
import axios from 'axios';
import classnames from 'classnames';
import { Heart } from '../../../../../public/index';

const BASE_CLASS = 'post-three';

class PostThree extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			comments: [],
			commentToAdd: '',
			liked: false,
		};

		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.getComments = this.getComments.bind(this);
		this.submitComment = this.submitComment.bind(this);
		this.postComment = this.postComment.bind(this);
		this.togglePostLike = this.togglePostLike.bind(this);
	}

	componentDidMount() {
		this.getComments();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	togglePostLike() {
		this.setState({
			liked: !this.state.liked,
		});

		this.postLike(!this.state.liked);
	}

	async postLike(isLiked) {
		try {
			const post = await axios.put(`/api/blogcomment/3`, { liked: isLiked.toString() });
		} catch (err) {
			console.err(err);
		}
	}

	async getComments() {
		try {
			const res = await axios.get(`/api/blogcomment/3`);
			const postData = res.data;

			if (!postData[0]) {
				// adding post for first time:
				const post = await axios.post(`/api/blogcomment/3`, {
					blogId: 1,
					topic: 'industry-waste',
					likeCount: 0,
				});
			} else {
				const comments = res.data[0]?.comment;
				const likeCount = res.data[0].likeCount;

				const commentMapped = comments ? comments.map((c) => JSON.parse(c)) : null;

				this.setState({
					comments: commentMapped,
					likeCount: likeCount,
				});
			}
		} catch (err) {
			console.error(err);
		}
	}

	async postComment(commentObj) {
		try {
			const post = await axios.put(`/api/blogcomment/3`, commentObj);
		} catch (err) {
			console.err(err);
		}
	}

	submitComment() {
		event.preventDefault();
		const commentObj = {
			commenter: this.state.user?.user.name || 'anonymous',
			comment: this.state.commentToAdd,
			userId: this.state.user?.user.id || null,
			date: Date.now(),
		};

		this.postComment(commentObj);

		this.setState({
			commentToAdd: '',
			comments: this.state.comments ? [...this.state.comments, commentObj] : [commentObj],
		});
	}

	handleCommentChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	render() {
		const localLikeCount = this.state.liked ? 1 : 0;
		let totalLocalLikeCount = (this.state.likeCount || 0) + localLikeCount;
		return (
			<div className={BASE_CLASS}>
				<div className={`${BASE_CLASS}__top`}>
					<Link to="/fashion-fix">Back</Link>
					<h5>
						the fashion fix
						<div />
					</h5>
				</div>
				<h3>Cleaner Color</h3>
				<h6>The cost of dyeing fabrics and a look at some solutions</h6>
				<p>
					In 1856, chemist W.H. Perkin discovered synthetic dye for the first time. Before this,
					fabric was colored with natural dyes, which had less color range, and were less colorfast
					against washing and sunlight. Today more than 3,600 dyes bring beautiful color to our
					wardrobes, but at the cost of massive water consumption, and leaves toxic wastewater
					behind it. In this post, we’ll look closer at the environmental costs of garment dyeing,
					and some ways the fashion industry and consumers can try to address them.
				</p>
				<h4>Garment dyeing is a very water-intensive process</h4>
				<p>
					It takes around 2,000 gallons of water to make one pair of jeans, and in total the fashion
					industry consumes 21 trillion gallons of water every year. The portion of this water used
					in{' '}
					<b>
						dyeing alone – a water-intensive process since textiles go through several cycles of
						dying and washing – contributes to an estimated 16-20% of all industrial water waste.
					</b>
				</p>
				<img src="images/dyePollutionIndia.png" alt="dye pollution in India" />
				<small>Image: REUTERS/Rafiquar Rahman via the World Economic Forum </small>
				<p>
					Countries like Bangladesh, the second biggest clothing manufacturer after China, are
					economically dependent on the fashion industry (it makes up 20% of its economy!), and
					struggle to enforce regulations. Much of the wastewater produced from textile dyeing is
					dumped into rivers and streams, which contains dyes, salts, and many chemicals – up to 72
					toxic chemicals have been found in waterways that come only from textile dyeing. Around
					40% of dyes use chlorine, which is a known carcinogen, and makes even organic chemicals in
					wastewater dangerous when it reacts with them.
				</p>
				<p>
					<b>Once this toxic wastewater is in water sources,</b> it can prevent light from
					penetrating the water surface, blocking photosynthesis and destroying the waterway’s
					ecosystem, killing plants and fish. When this water is used for crop irrigation (as it is
					for farmers with no alternatives), it can block pores in soil, making crops less
					productive, and carrying toxic health hazards from the produce grown to those who consume
					it. Lastly, the toxic chemicals that go into this dyeing process affects workers who
					handle them, as they are commonly exposed to toxic fumes, and do not always have fully
					protective clothing.{' '}
				</p>
				<h4>What can the fashion industry do better?</h4>
				<p>
					Treating wastewater and not dumping it in waterways is an obvious start. There are
					physical, biological and chemical methods to treat this water, though these methods often
					need to be combined, use a lot more water in the process, and still rarely remove 100% of
					the of chemicals.
				</p>
				<p>
					<b>Using less water in the dyeing process would be another big step</b> – by consuming
					less energy, using less water, and reducing the water that needs to be treated in the
					first place. Air dyeing is one approach; this uses 95% less water and almost 90% less
					carbon emissions and less energy. Another approach is pre-treating fabrics; some
					innovative companies are changing the ionic charge on cotton, for example, to make it bond
					with dyes and therefore drastically reduce the water needed.
				</p>
				<p>
					These could be combined with ways to use less toxic materials in dyeing. Some producers
					have started using oxygen-based bleach, such as hydrogen oxide instead of chlorine, which
					breaks down into hydrogen and oxygen. Natural dyes are, of course, still an option, though
					they are not readily scalable to the enormous production volume of the industry today, and
					can use more water than synthetic dyes.
				</p>
				<p>This brings us to our last question: </p>
				<h4>What can we do better?</h4>{' '}
				<p>
					Holding brands accountable to better practices is, of course, an important step. But as
					Ada Kong of Greenpeace East Asia says, "For the volume we are consuming,{' '}
					<b>
						I don't think there is a solution or best scenario without reducing the volume of our
						consumption.
					</b>{' '}
					Even if all of us dress in organic cotton and natural dyes it would still be devastating."
				</p>
				<p>What do you think? Let us know in the comments!</p>
				<div className={`${BASE_CLASS}__likes`}>
					<span
						onClick={() => this.togglePostLike()}
						className={classnames('heart', {
							[`liked`]: this.state.liked,
						})}>
						<Heart />
					</span>
					<small>{totalLocalLikeCount || 0} people liked this post</small>
				</div>
				<div className={`${BASE_CLASS}__comments`}>
					{this.state.comments?.map((comm) => (
						<div className={`${BASE_CLASS}__comments__comment`} key={comm.text}>
							<div className={`${BASE_CLASS}__comments__comment__signature`}>
								<span>{comm.commenter}</span>
								<span>on {new Date(comm.date).toDateString()}</span>
							</div>
							<p>{comm.comment}</p>
						</div>
					))}
				</div>
				<form type="submit" onSubmit={() => this.submitComment()} className={`${BASE_CLASS}__form`}>
					<input
						type="text"
						name="commentToAdd"
						placeholder="add a comment"
						onChange={this.handleCommentChange}
						value={this.state.commentToAdd}
					/>
					<button type="submit">post</button>
				</form>
			</div>
		);
	}
}

export default PostThree;
