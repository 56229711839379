import React from 'react';
import classnames from 'classnames';
import './index.scss';
import { DrawerOpen, DrawerClosed } from '../../../../../../public/icons';

const Hamburger = ({ isActive, handleClick }) => {
	return (
		<button
			className={classnames('hamburger', {
				active: isActive,
				inactive: !isActive,
			})}
			onClick={handleClick}
			type="button"
			aria-label="menu">
			<DrawerOpen
				className={classnames(
					{
						show: !isActive,
					},
					{
						hide: isActive,
					}
				)}
			/>
			<DrawerClosed
				className={classnames(
					{
						show: isActive,
					},
					{
						hide: !isActive,
					}
				)}
			/>
			{/* <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span> */}
		</button>
	);
};

export default Hamburger;
