import React, { Component } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import store from '../../store';
import { getFriends, getFriendCloset } from '../../store/friends';
import { Heart } from '../../../public/index';
import './index.scss';
import { Link } from 'react-router-dom';
import Back from '../../../public/icons/Back';
import history from '../../../client/history';

const BASE_CLASS = 'friendCloset';

class FriendCloset extends Component {
	constructor() {
		super();
		this.state = {
			...store.getState(),
			likedIds: new Array(),
			friendName: '',
			tagSet: [],
			publicItems: [],
			matches: [], // TODO: rename this to tagMatches
			isArticleModalOpen: false,
			hoverIndex: '',
			friend: null,
		};
		this.toggleLikeItem = this.toggleLikeItem.bind(this);
		this.getIsRequester = this.getIsRequester.bind(this);
		this.getFriendName = this.getFriendName.bind(this);
		this.getPublicItems = this.getPublicItems.bind(this);
		this.getTags = this.getTags.bind(this);
		this.clearTagFilter = this.clearTagFilter.bind(this);
		this.getLikedItems = this.getLikedItems.bind(this);
	}

	componentDidMount() {
		const friendId = this.props.match.params.friendId;
		store.dispatch(getFriends());
		store.dispatch(getFriendCloset(friendId));
		// this.getLikedItems();
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.friends.friends !== this.state.friends.friends) {
			this.getFriendName();
			this.getLikedItems();
		} else if (prevState.friends.friendCloset !== this.state.friends.friendCloset) {
			this.getPublicItems();
			this.getTags();
		}
	}

	getLikedItems() {
		const friendId = this.props.match.params.friendId;
		const friend = this.state.friends.friends.filter(
			(friend) => Number(friendId) === friend.requesteeId || Number(friendId) === friend.requesterId
		);

		const isUserRequester = friend[0]?.requesterId === this.state.user.user.id;

		const friendsArticlesUserLikes = isUserRequester
			? friend[0]?.articlesOfRequesteeLikedByRequester
			: friend[0]?.articlesOfRequesterLikedByRequestee;

		this.setState({
			likedIds: friendsArticlesUserLikes,
			friend,
		});
	}

	// showArticleModal(i) {
	// 	// this.unsubscribe = store.subscribe(() => this.setState(store.getState));

	// 	this.setState({
	// 		isArticleModalOpen: true,
	// 		hoverIndex: i,
	// 	});
	// }

	// handleCloseModal() {
	// 	this.setState({
	// 		isArticleModalOpen: false,
	// 	});
	// }

	clearTagFilter() {
		this.setState({
			matches: this.state.publicItems,
		});
	}

	getPublicItems() {
		const publicItems = this.state.friends.friendCloset.filter((item) => item.public);
		this.setState({
			publicItems,
			matches: publicItems,
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	getTags() {
		let tagSet = new Set();
		this.state.friends.friendCloset
			.filter((item) => item.public)
			.map((article) => {
				return article.tags.map((tag) => tagSet.add(tag));
			});

		this.setState({
			tagSet: Array.from(tagSet),
		});
	}

	getFriendName() {
		const friendId = this.props.match.params.friendId;
		const friendName = this.state.friends.friends.filter((f) => f.requesteeId === Number(friendId))
			.length
			? this.state.friends.friends.filter((f) => f.requesteeId === Number(friendId))[0]
					.requesteeName
			: this.state.friends.friends.filter((f) => f.requesterId === Number(friendId))[0]
					.requesterName;

		this.setState({
			friendName,
		});
	}

	filterByTag(tag) {
		const matches =
			this.state.publicItems.length &&
			this.state.publicItems.filter((item) => item.tags.includes(tag));
		this.setState({
			matches,
		});
	}

	getIsRequester(friendId) {
		return Boolean(
			this.state.friends.friends.filter(
				(f) =>
					Number(f.requesterId) === Number(this.state.user.user.id) &&
					Number(f.requesteeId) === Number(friendId)
			).length
		);
	}

	async toggleLikeItem(dressId) {
		const friendId = Number(this.props.match.params.friendId);
		const userId = this.state.user.user.id;
		// if it's already liked, remove like from front & back end:
		if (this.state.likedIds && this.state.likedIds.includes(dressId)) {
			this.setState({
				likedIds: this.state.likedIds.filter((id) => id !== dressId),
			});

			try {
				await axios.put(`/api/friends/${friendId}`, {
					dressId: dressId,
					userId: this.state.user.user.id,
					isRequester: this.getIsRequester(friendId),
					remove: true,
					requesteeId: this.getIsRequester(friendId) ? friendId : userId,
					requesterId: this.getIsRequester(friendId) ? userId : friendId,
				});
			} catch (err) {
				next(err);
			}
		} else {
			// if it's not already liked, add like to front & back end:
			this.setState({
				// likedIds: [...this.state.likedIds, dressId],
				likedIds: this.state.likedIds ? this.state.likedIds.concat(dressId) : [dressId],
			});

			try {
				await axios.put(`/api/friends/${friendId}`, {
					dressId: dressId,
					userId: this.state.user.user.id,
					isRequester: this.getIsRequester(friendId),
					remove: false,
					requesteeId: this.getIsRequester(friendId) ? friendId : userId,
					requesterId: this.getIsRequester(friendId) ? userId : friendId,
				});
			} catch (err) {
				console.error(err);
			}
		}
	}

	getHandle(handle) {
		const index = handle.lastIndexOf('instagram.com/');
		return index !== -1 ? handle.slice(index + 14) : handle;
	}

	render() {
		return (
			<div className={BASE_CLASS}>
				<button onClick={() => history.goBack()} className={`${BASE_CLASS}__back-btn`}>
					<Back />
				</button>
				{this.state.friends.friendAccount && (
					<div className={`${BASE_CLASS}__friend-info`}>
						{!!this.state.friends.friendAccount.avatarUrl && (
							<img src={this.state.friends.friendAccount.avatarUrl} />
						)}
						<div>
							<h4>{this.state.friendName}</h4>
							<h4>
								{this.state.friends.friendAccount.instagramHandle
									? `${'@' + this.getHandle(this.state.friends.friendAccount.instagramHandle)}`
									: this.state.friends.friendAccount.username
									? this.state.friends.friendAccount.username
									: null}
							</h4>
							<p>{this.state.friends.friendAccount.bio}</p>
						</div>
					</div>
				)}

				<div className={`${BASE_CLASS}__wrapper`}>
					{!!this.state.tagSet.length && (
						<>
							<h5>
								{this.state.friendName}'s lists
								<div />
							</h5>
							<div className={`${BASE_CLASS}__tags`}>
								<p onClick={() => this.clearTagFilter()}>ALL</p>
								{this.state.tagSet.map((tag) => (
									<p onClick={() => this.filterByTag(tag)} key={tag}>
										#{tag}
									</p>
								))}
							</div>
						</>
					)}

					{this.state.matches.length ? (
						<div className={`${BASE_CLASS}__container`}>
							{this.state.matches.map((dress) => (
								<div className={`${BASE_CLASS}__friend-card`}>
									<div>
										<span
											onClick={() => this.toggleLikeItem(dress.id)}
											className={classnames('heart', {
												liked: this.state.likedIds && this.state.likedIds.includes(dress.id),
											})}>
											<Heart />
										</span>
										<Link
											to={{
												pathname: `/friends/${this.props.match.params.friendId}/${dress.id}`,
												state: JSON.parse(
													JSON.stringify({
														// dress,
														friend: this.state.friend,
													})
												),
											}}>
											{dress.imageUrls && <img src={dress.imageUrls[0]} loading="lazy" />}
										</Link>
									</div>
									<Link
										to={{
											pathname: `/friends/${this.props.match.params.friendId}/${dress.id}`,
											state: JSON.parse(
												JSON.stringify({
													// dress,
													friend: this.state.friend,
												})
											),
										}}>
										<h4>{dress.brand}</h4>
										<p>{dress.size}</p>
									</Link>
								</div>
							))}
						</div>
					) : (
						<div className={`${BASE_CLASS}__empty`}>
							Sorry, looks like {this.state.friendName || 'your friend'} hasn't uploaded their
							closet yet.
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default FriendCloset;
