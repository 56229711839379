import React, { Component } from 'react';
import store from '../../../store';
import './index.scss';

const BASE_CLASS = 'save-outfit-modal';

export default class SaveOutfitModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			note: '',
			openFieldList: '',
			listForRandomOutfit: [],
			listSet: new Set(),
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleListChange = this.handleListChange.bind(this);
		this.handleAddToList = this.handleAddToList.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.getLists() !== state.listSet) {
			return {
				listSet: props.getLists(),
			};
		}
	}

	handleChange(event) {
		this.setState({
			note: event.target.value,
		});
	}

	handleListChange(event) {
		this.setState({
			openFieldList: event.target.value,
		});
	}

	handleAddToList(e) {
		this.setState({
			listForRandomOutfit: [...this.state.listForRandomOutfit, event.target.value],
		});
	}

	render() {
		const listArray = Array.from(this.state.listSet);

		return (
			<>
				<div className={`${BASE_CLASS}__container`} />
				<div className={`${BASE_CLASS}__inner`}>
					<h2 className={`${BASE_CLASS}__inner__title`}>
						want to add a note or save this to a list?
					</h2>
					<form
						onSubmit={(e) =>
							this.props.handleNoteSubmit(
								e,
								this.state.note,
								this.state.listForRandomOutfit,
								this.state.openFieldList
							)
						}
						className={`${BASE_CLASS}__inner__form`}>
						<div className={`${BASE_CLASS}__inner__cursor`}>
							<input
								type="textarea"
								placeholder="e.g.must wear with pearls!"
								className={`${BASE_CLASS}__inner__input`}
								name="note"
								value={this.state.note}
								onChange={this.handleChange}
							/>
							<i />
						</div>
						<div className={`${BASE_CLASS}__inner__form__lists`}>
							<select
								onChange={this.handleAddToList}
								className={`${BASE_CLASS}__inner__form__lists__dropdown`}>
								<option selected value="choose a list">
									add to list
								</option>
								{listArray.map((listName) => (
									<option value={listName}>{listName} </option>
								))}
							</select>
							<input
								type="textarea"
								placeholder="e.g. italy!"
								className={`${BASE_CLASS}__inner__form__lists__field`}
								name="listForRandomOutfit"
								value={this.state.openFieldList}
								onChange={this.handleListChange}
							/>
						</div>

						<div>
							<button type="submit" text="submit" className={`${BASE_CLASS}__inner__submitBtn`}>
								save outfit
							</button>
							<button
								type="button"
								text="close"
								className={`${BASE_CLASS}__inner__closeBtn`}
								onClick={this.props.handleCloseNotesForm}>
								close
							</button>
						</div>
					</form>
				</div>
			</>
		);
	}
}
