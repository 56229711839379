import React, { Component, Fragment } from 'react';
import { getAllBorrowerRentals, getAllLenderRentals } from '../../store/rentals';
import { getPublicArticles } from '../../store/closet';
import axios from 'axios';
import store from '../../store';
import fromUnixTime from 'date-fns/fromUnixTime';
import { getDresses } from '../../store/closet';
import ConfirmationModal from '../modals/ConfirmationModal';
import './index.scss';
import { me } from '../../store/user';
import ActivityCard from '../atoms/ActivityCard';
import { Link } from 'react-router-dom';
// import Back from '../../../public/icons/Back';
import history from '../../../client/history';

// import {
// 	getPercentageOfWardrobeWorn,
// 	getAvgCostPerWear,
// 	// getMonthlySpend,
// 	getAverageWears,
// } from '../Dashboard/utils/calculations.js';

const BASE_CLASS = 'activity';

class Activity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...store.getState(),
			requestedRentals: [],
			awaitingPaymentRentals: [],
			upcomingRentals: [],
			currentRentals: [],
			pastRentals: [],
			pendingLenderRentals: [],
			upcomingLenderRentals: [],
			currentLenderRentals: [],
			pastLenderRentals: [],
			showConfirmRentalConfirmationModal: false,
			showDeclineRentalConfirmationModal: false,
			showConfirmFriendConfirmationModal: false,
			showDeclineFriendConfirmationModal: false,
			rentalIdBeingHandled: null,
			articleItemIdBeingHandled: null,
			rentalDatesBeingHandled: null,
			// friendsPending: [],
			// friendsToConfirm: [],
			// avgCostPerWear: null,
			// monthlySpend: null,
			// percentOfWardrobeWorn: null,
			// averageWears: null,
			friendRequestConfirmed: false,
			friendRequestDeclined: false,
		};

		this.getBorrowerRentals = this.getBorrowerRentals.bind(this);
		this.getLenderRentals = this.getLenderRentals.bind(this);
		this.showConfirmationModal = this.showConfirmationModal.bind(this);
		this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
		this.handleConfirmRequest = this.handleConfirmRequest.bind(this);
		this.getConfirmLendingModalHtml = this.getConfirmLendingModalHtml.bind(this);
	}

	componentDidMount() {
		store.dispatch(getAllBorrowerRentals());
		store.dispatch(getDresses());
		store.dispatch(getAllLenderRentals());
		store.dispatch(getPublicArticles());
		this.getLenderRentals();
		this.getBorrowerRentals();
		// this.getCalculations();
		store.dispatch(me());
		this.unsubscribe = store.subscribe(() => this.setState(store.getState));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.rentals.borrowerRentals !== this.state.rentals.borrowerRentals) {
			this.getBorrowerRentals();
		}

		if (prevState.rentals.lenderRentals !== this.state.rentals.lenderRentals) {
			this.getLenderRentals();
		}

		// if (prevState.closet.dresses !== this.state.closet.dresses) {
		// 	this.getCalculations();
		// }
	}

	// getCalculations() {
	// 	const articles = this.state.closet.dresses;
	// 	// const percentOfWardrobeWorn = getPercentageOfWardrobeWorn(articles);
	// 	// const monthlySpend = getMonthlySpend(articles).monthlySpend;
	// 	// const avgCostPerWear = getAvgCostPerWear(articles);
	// 	// const averageWears = getAverageWears(articles);

	// 	// this.setState({
	// 	// 	// avgCostPerWear,
	// 	// 	// monthlySpend,
	// 	// 	// percentOfWardrobeWorn,
	// 	// 	// averageWears,
	// 	// });
	// }

	getBorrowerRentals() {
		if (this.state.rentals && this.state.rentals.borrowerRentals) {
			const rentals = this.state.rentals.borrowerRentals;

			this.setState({
				awaitingPaymentRentals: rentals
					.filter((rental) => rental.status === 'CONFIRMED_AWAITING_PAYMENT')
					.map((rental) => {
						return {
							...rental,
							firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
						};
					}),
				upcomingRentals: rentals
					.filter((rental) => rental.status === 'CONFIRMED_PAID') //FIXME: was confirmed-paid with stripe integration
					.map((rental) => {
						return {
							...rental,
							firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
						};
					}),
				currentRentals: rentals
					.filter(
						(rental) =>
							rental.status === 'ACTIVE' ||
							rental.status === 'ACTIVECONFIRMING' ||
							rental.status === 'ACTIVECONFIRMED'
					)
					.map((rental) => {
						return {
							...rental,
							lastDateOfRental: fromUnixTime(rental.lastDateOfRental).toDateString(),
						};
					}),
				requestedRentals: rentals
					.filter((rental) => rental.status === 'PENDING')
					.map((rental) => {
						return {
							...rental,
							firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
						};
					}),

				pastRentals: rentals
					.filter((rental) => rental.status === 'COMPLETED')
					.filter(
						(rental) =>
							!(
								!!rental.borrowerOverallReview ||
								!!rental.borrowerOpenReview ||
								rental.borrowerDescriptionReview
							)
					)
					.map((rental) => {
						return {
							...rental,
							firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
						};
					}),
			});
		}
	}

	getLenderRentals() {
		if (this.state.rentals && this.state.rentals.lenderRentals) {
			this.setState({
				pendingLenderRentals: this.state.rentals.lenderRentals
					.filter((rental) => rental.status === 'PENDING')
					.map((rental) => {
						return {
							...rental,
							firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
						};
					}),
				upcomingLenderRentals: this.state.rentals.lenderRentals
					.filter(
						(rental) =>
							rental.status === 'CONFIRMED_AWAITING_PAYMENT' || rental.status === 'CONFIRMED_PAID'
					)
					.map((rental) => {
						return {
							...rental,
							firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
						};
					}),
				currentLenderRentals: this.state.rentals.lenderRentals
					.filter(
						(rental) =>
							rental.status === 'ACTIVE' ||
							rental.status === 'ACTIVECONFIRMING' ||
							rental.status === 'ACTIVECONFIRMED'
					)
					.map((rental) => {
						return {
							...rental,
							lastDateOfRental: fromUnixTime(rental.lastDateOfRental).toDateString(),
						};
					}),
				pastLenderRentals: this.state.rentals.lenderRentals
					.filter((rental) => rental.status === 'COMPLETED')
					.map((rental) => {
						return {
							...rental,
							firstDateOfRental: fromUnixTime(rental.firstDateOfRental).toDateString(),
						};
					}),
			});
		}
	}

	closeConfirmationModal(modal) {
		this.setState({
			[modal]: false,
			rentalIdBeingHandled: null,
			articleItemIdBeingHandled: null,
			// friendBeingHandled: null,
		});
	}

	async handleDeclineRental() {
		const rentalId = this.state.rentalIdBeingHandled;
		const articleId = this.state.articleItemIdBeingHandled;

		try {
			await axios
				.put(`/api/rentals/${articleId}`, {
					action: 'decline',
					rentalId,
				})
				.then((response) => {
					this.closeConfirmationModal('showDeclineRentalConfirmationModal');
					window.location.reload(false);
				});
		} catch (err) {
			next(err);
		}
	}

	async handleConfirmRequest() {
		const articleId = this.state.articleItemIdBeingHandled;

		const first = axios.put(`/api/rentals/${articleId}`, {
			action: 'confirm',
			rentalId: this.state.rentalIdBeingHandled,
			articleId,
			...this.state.closet.dresses.filter(
				(dress) => dress.id === this.state.articleItemIdBeingHandled
			)[0],
		});

		const second = axios.put(`/api/closet/${articleId}`, {
			unavailableDates: this.state.rentalDatesBeingHandled,
		});

		try {
			await Promise.all([first, second]).then((response) => {
				this.closeConfirmationModal('showConfirmRentalConfirmationModal');
				window.location.reload(false);
			});
		} catch (err) {
			console.error(err);
		}
	}

	showConfirmationModal(modal, rentalId, articleId, rentalDates, friend) {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});

		// let stripeId = this.state.user.user.stripeConnectedAccountId;

		// if (!stripeId) {
		// 	this.setState({
		// 		showConfirmAndCreateStripeAcctModal: true,
		// 		rentalIdBeingHandled: rentalId,
		// 		rentalDatesBeingHandled: rentalDates,
		// 		articleItemIdBeingHandled: articleId,
		// 	});
		// } else {
		this.setState({
			[modal]: true,
			rentalIdBeingHandled: rentalId,
			articleItemIdBeingHandled: articleId,
			rentalDatesBeingHandled: rentalDates,
			// friendBeingHandled: friend,
		});
		// }
	}

	getConfirmLendingModalHtml() {
		const rental = this.state.rentals.lenderRentals?.filter(
			(rntl) => rntl.id == this.state.rentalIdBeingHandled
		)[0];

		const delOpt = {
			pickup: Boolean(rental.availableForPickup),
			ship: Boolean(rental.availableForShip),
			deliver: Boolean(rental.deliveryAddress),
		};

		const option = Object.entries(delOpt).filter((i) => !!i[1])[0][0];
		const rentalFee = Number(rental.priceRented) * 0.82;

		function getDeliveryFee(opt) {
			switch (opt) {
				case 'ship':
					return [
						`You'll send this item by mail; we'll email you a pre-paid shipping label.`,
						Math.max(rentalFee, 0),
					];
				case 'pickup':
					return [
						"You'll coordinate with the borrower to find a public place and time to meet up in your zip code.",
						Math.max(rentalFee, 0),
					];
				case 'deliver':
					return [
						"The item will be delivered to the borrower via Doordash. We'll reach out to find which address the item should be picked up at.",
						Math.max(rentalFee, 0),
					];
				default:
					[
						"You'll coordinate with the lender to find a public place to meet up near the lender's zip code.",
						Math.max(rentalFee, 0),
					];
			}
		}

		const confirmModalHtml = {
			__html: `
				<p>${getDeliveryFee(option)?.[0]}</p>
				<p>You'll be paid $${getDeliveryFee(option)?.[1]?.toFixed(2)} total, paid out through Stripe.</p>
				<p>By lending your item, you are agreeing to our <a href="/terms" target="_blank">terms and conditions</a>.</p>
			`,
		};

		return confirmModalHtml;
	}

	render() {
		return (
			<>
				{this.state.showConfirmRentalConfirmationModal && (
					<ConfirmationModal
						header="All good?"
						icon="flight-arrow"
						html={this.getConfirmLendingModalHtml()}
						handleAccept={() => this.handleConfirmRequest()}
						handleCancel={() => this.closeConfirmationModal('showConfirmRentalConfirmationModal')}
						continueCtaText="Sounds good"
						cancelCtaText="Cancel"
					/>
				)}

				{this.state.showDeclineRentalConfirmationModal && (
					<ConfirmationModal
						header="Are you sure?"
						icon="warning"
						text="Are you sure you want to decline this rental?"
						handleAccept={() => this.handleDeclineRental()}
						handleCancel={() => this.closeConfirmationModal('showDeclineRentalConfirmationModal')}
						continueCtaText="Yes, Decline"
						cancelCtaText="Cancel"
					/>
				)}
				<div className="container-activity">
					<div className={BASE_CLASS}>
						<h1>Your Rental Activity</h1>
						<p>
							See pending requests, upcoming rentals, <br />
							and past rentals waiting for your review
						</p>
						<div className={`${BASE_CLASS}__lending-activity`}>
							{!this.state.pendingLenderRentals.length &&
								!this.state.currentLenderRentals.length &&
								!this.state.upcomingLenderRentals.length &&
								!this.state.pastLenderRentals.length && (
									<p className={`${BASE_CLASS}__table__default`}>
										Looks like you don't have any active rentals.{' '}
										<Link to="/add">Add something to lend.</Link>
									</p>
								)}

							<div className={`${BASE_CLASS}__table`}>
								{!!this.state.pendingLenderRentals.length && (
									<>
										<h5>
											requests from borrowers<span>(confirm ASAP)</span>
											<div />
										</h5>
										<div className={`${BASE_CLASS}__table__row`}>
											{this.state.pendingLenderRentals.map((rental) => (
												<ActivityCard
													rental={rental}
													onConfirm={() =>
														this.showConfirmationModal(
															'showConfirmRentalConfirmationModal',
															rental.id,
															rental.articleItemId,
															[rental.firstDateOfRental, rental.lastDateOfRental]
														)
													}
													onDecline={() =>
														this.showConfirmationModal(
															'showDeclineRentalConfirmationModal',
															rental.id,
															rental.articleItemId
														)
													}
													cardType="pendingLenderRentals"
												/>
											))}
										</div>
									</>
								)}
								{!!this.state.upcomingLenderRentals.length && (
									<>
										<h5>
											prepare to lend <div />
										</h5>
										<div className={`${BASE_CLASS}__table__row`}>
											{this.state.upcomingLenderRentals.map((rental) => (
												<ActivityCard rental={rental} cardType="upcomingLenderRentals" />
											))}
										</div>
									</>
								)}

								{!!this.state.currentLenderRentals.length && (
									<>
										<h5>
											prepare for return
											<div />
										</h5>
										<div className={`${BASE_CLASS}__table__row`}>
											{this.state.currentLenderRentals.map((rental) => (
												<ActivityCard rental={rental} cardType="endingLenderRentals" />
											))}
										</div>
									</>
								)}

								{!!this.state.pastLenderRentals.length && (
									<>
										<h4>
											past rentals
											<div />
										</h4>
										<div className={`${BASE_CLASS}__table__row`}>
											{this.state.pastLenderRentals.map((rental) => (
												<ActivityCard rental={rental} cardType="endedLenderRentals" />
											))}
										</div>
									</>
								)}
							</div>
						</div>
						<div className={`${BASE_CLASS}__renting-activity`}>
							{!this.state.awaitingPaymentRentals.length &&
								!this.state.upcomingRentals.length &&
								!this.state.currentRentals.length &&
								!this.state.requestedRentals.length &&
								!this.state.pastRentals.length && (
									<p className={`${BASE_CLASS}__table__default`}>
										Looks like you don't have any active rentals.{' '}
										<Link to="/rent">Find something to rent.</Link>
									</p>
								)}

							{!!this.state.awaitingPaymentRentals.length && (
								<div className={`${BASE_CLASS}__table`}>
									<h5>
										pay now <div />
									</h5>
									<div className={`${BASE_CLASS}__table__row`}>
										{this.state.awaitingPaymentRentals.map((rental) => (
											<ActivityCard rental={rental} cardType="awaitingPaymentRentals" />
										))}
									</div>
								</div>
							)}

							{!!this.state.upcomingRentals.length && (
								<div className={`${BASE_CLASS}__table`}>
									<h5>
										prepare to pick up <div />
									</h5>
									<div className={`${BASE_CLASS}__table__row`}>
										{this.state.upcomingRentals.map((rental) => (
											<ActivityCard rental={rental} cardType="upcomingBorrowerRentals" />
										))}
									</div>
								</div>
							)}

							{!!this.state.currentRentals.length && (
								<div className={`${BASE_CLASS}__table`}>
									<h5>
										prepare to return
										<div />
									</h5>
									<div className={`${BASE_CLASS}__table__row`}>
										{this.state.currentRentals.map((rental) => (
											<ActivityCard rental={rental} cardType="endingBorrowerRentals" />
										))}
									</div>
								</div>
							)}

							{!!this.state.requestedRentals.length && (
								<div className={`${BASE_CLASS}__table`}>
									<h5>
										pending requests
										<div />
									</h5>
									<div className={`${BASE_CLASS}__table__row`}>
										{this.state.requestedRentals.map((rental) => (
											<ActivityCard rental={rental} cardType="pendingBorrowerRentals" />
										))}
									</div>
								</div>
							)}

							{!!this.state.pastRentals.length && (
								<div className={`${BASE_CLASS}__table`}>
									<h5>
										leave a review
										<div />
									</h5>
									<div className={`${BASE_CLASS}__table__row`}>
										{this.state.pastRentals.map((rental) => (
											<ActivityCard rental={rental} cardType="endedBorrowerRentals" />
										))}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Activity;
