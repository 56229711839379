import axios from 'axios';

/*
 * INITIAL STATE
 */

// todo: should initial state just be empty array to avoid ...
const initialState = {
	wardrobes: [],
};

/**
 * ACTION TYPES
 */
const GET_WARDROBE = 'GET_WARDROBE';

/**
 * ACTION CREATORS
 */

const gotWardrobe = (wardrobe) => ({ type: GET_WARDROBE, wardrobe });

/**
 * THUNK CREATORS
 */
export const getWardrobe = (userId) => {
	return async (dispatch, getState) => {
		try {
			const res = await axios.get(`/api/closets/${userId}`);
			dispatch(gotWardrobe(res.data));
		} catch (err) {
			console.error(err);
		}
	};
};

/**
 * REDUCER
 */
export default function (state = initialState, action) {
	switch (action.type) {
		case GET_WARDROBE:
			return { ...state, wardrobes: [...state.wardrobes, action.wardrobe] };

		default:
			return state;
	}
}
