import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	// Login,
	// Signup,
	Home,
	Add,
	Closet,
	Dashboard,
	Article,
	Edit,
	// Outfits,
	ResetPassword,
	ForgotPassword,
	Account,
	About,
	Friends,
	FriendCloset,
	FriendArticle,
	Terms,
	Privacy,
	LendHome,
	Lending,
	RentHome,
	RentalItem,
	LendItem,
	RentalPage,
	Faq,
	HomeUnauth,
	Activity,
	Profile,
	MyProfile,
	Lenders,
	Promo,
	Forum,
	PostOne,
	PostTwo,
	PostThree,
	PostFour,
	PostFive,
	HowItWorks,
	Favorites,
	Inbox,
} from './components';
import { me } from './store';

/**
 * COMPONENT
 */
class Routes extends Component {
	componentDidMount() {
		this.props.loadInitialData();
	}

	render() {
		const { isLoggedIn } = this.props;

		return (
			<Switch>
				{/* Routes placed here are available to all visitors */}
				<Route path="/login" component={HomeUnauth} />
				<Route path="/signup" component={HomeUnauth} />
				<Route path="/reset" component={ResetPassword} />
				<Route path="/forgot" component={ForgotPassword} />
				<Route path="/about" component={About} />
				<Route path="/howitworks" component={HowItWorks} />
				<Route path="/faq" component={Faq} />
				<Route path="/terms" component={Terms} />
				<Route path="/privacy" component={Privacy} />
				<Route exact path="/rent" component={RentHome} />
				<Route exact path="/rent/:articleId" component={RentalItem} />
				<Route exact path="/lender/:userId" component={Profile} />
				<Route path="/lenders" component={Lenders} />
				<Route exact path="/promo" component={Promo} />
				<Route exact path="/fashion-fix" component={Forum} />
				<Route exact path="/buy-clothes-you-love" component={PostOne} />
				<Route exact path="/intro-to-tencel" component={PostTwo} />
				<Route exact path="/cleaner-color" component={PostThree} />
				<Route exact path="/fashion-tech" component={PostFour} />
				<Route exact path="/clothing-rental-guide" component={PostFive} />

				{!isLoggedIn && (
					<Switch>
						<Route exact path="/" component={HomeUnauth} />
						<Route path="/home" component={HomeUnauth} />
						<Route exact path="/lend" component={Lending} />
					</Switch>
				)}

				{isLoggedIn && (
					<Switch>
						<Route exact path="/" component={HomeUnauth} />
						<Route path="/home" component={HomeUnauth} />
						<Route exact path="/inbox" component={Inbox} />
						<Route exact path="/closet" component={Closet} />
						<Route exact path="/lend" component={LendHome} />
						{/* <Route path="/outfits" component={Outfits} /> */}
						<Route path="/dashboard" component={Dashboard} />
						<Route path="/account" component={Account} />
						<Route path="/add" component={Add} />
						<Route path="/profile" component={MyProfile} />
						<Route exact path="/favorites" component={Favorites} />
						<Route exact path="/friends" component={Friends} />
						<Route exact path="/friends/:friendId" component={FriendCloset} />
						<Route exact path="/friends/:friendId/:dressId" component={FriendArticle} />
						<Route exact path="/closet/:dressId" component={Article} />
						<Route exact path="/closet/:dressId/edit" component={Edit} />

						<Route exact path="/rent/:articleId" component={RentalItem} key={Math.random()} />
						<Route exact path="/lend/:articleId" component={LendItem} />
						<Route exact path="/rental/:rentalId" component={RentalPage} />
						<Route exact path="/activity" component={Activity} />
					</Switch>
				)}
				{/* Displays our Login component as a fallback */}

				<Route component={HomeUnauth} />
			</Switch>
		);
	}
}

/**
 * CONTAINER
 */
const mapState = (state) => {
	return {
		// Being 'logged in' for our purposes will be defined has having a state.user that has a truthy id.
		isLoggedIn: !!state.user.user.id,
	};
};

const mapDispatch = (dispatch) => {
	return {
		loadInitialData() {
			dispatch(me());
		},
	};
};

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(connect(mapState, mapDispatch)(Routes));

/**
 * PROP TYPES
 */
Routes.propTypes = {
	loadInitialData: PropTypes.func.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
};
